import { defaultNationCode } from "@/constants";
import type { UseFormReturn } from "react-hook-form";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { emailRE, nameRE } from "@/constants/regex";
import { superRefinePhoneNumber } from "@/lib/helpers/phone-number-validation";

const signUpSchema = z
  .object({
    firstName: z
      .string()
      .min(1, "First Name is required")
      .refine((value) => nameRE.test(value), {
        message: "Please avoid using special characters",
      }),
    lastName: z
      .string()
      .min(1, "Last Name is required")
      .refine((value) => nameRE.test(value), {
        message: "Please avoid using special characters",
      }),
    phoneNumber: z
      .string()
      .min(1, "Phone Number is required")
      .min(6, "Invalid phone number"),
    nationCode: z.string().min(1, "Nation Code is required"),
    // cannot use zod email validation since we allowed "+" in email previously
    // email: z.string().email("Invalid email"),
    email: z
      .string()
      .min(1, "Email is required")
      .regex(emailRE, "Invalid email"),
  })
  .superRefine((arg, ctx) => {
    superRefinePhoneNumber({
      code: arg.nationCode,
      phoneNumber: arg.phoneNumber,
      ctx,
    });
  });

export type SignUpFormData = z.infer<typeof signUpSchema>;

export interface SignUpFormReturn extends UseFormReturn<SignUpFormData> {
  defaultValues: SignUpFormData;
}

const initialValues = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  nationCode: defaultNationCode,
};

export const useSignUpForm = (): SignUpFormReturn => ({
  defaultValues: initialValues,
  ...useForm<SignUpFormData>({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: zodResolver(signUpSchema),
  }),
});
