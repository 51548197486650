import { BottomDrawerModal } from "@/components/shared/bottom-drawer-modal";
import {
  Button,
  DialogFooter,
  DialogHeader,
  View,
  DialogTitle,
} from "@/components/ui";
import { Divider } from "@/components/shared/divider";
import useShareSocialMedia from "./useSharerSocialMedia";

export function ShareReferralModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose(): void;
}) {
  const {
    copyLinkAction,
    sharerOtherAction,
    whatsappShareAction,
    telegramShareAction,
    smsShareAction,
    facebookMessengerShareAction,
  } = useShareSocialMedia();

  if (open) {
    return (
      <BottomDrawerModal open={open} onOpenChange={onClose}>
        <DialogHeader>
          <DialogTitle>Share this code with family and friends</DialogTitle>
        </DialogHeader>
        <View className="my-2 flex flex-1 flex-row justify-center gap-4">
          <Button
            variant="link"
            iconName="whatsappLogo"
            onClick={whatsappShareAction}
            children="WhatsApp"
            iconAlignment="top"
          />
          <Button
            variant="link"
            iconName="telegramLogo"
            onClick={telegramShareAction}
            children="Telegram"
            iconAlignment="top"
          />
          <Button
            variant="link"
            iconName="messengerLogo"
            onClick={facebookMessengerShareAction}
            children="Messenger"
            iconAlignment="top"
          />
          <Button
            variant="link"
            iconName="chatDots"
            onClick={smsShareAction}
            children="SMS"
            iconAlignment="top"
          />
        </View>
        <Divider />
        <DialogFooter>
          <View className="flex flex-1 flex-row justify-center gap-2">
            <Button
              variant="subtle"
              onClick={copyLinkAction}
              children="Copy"
              iconName="copy"
            />

            <Button
              variant="subtle"
              onClick={sharerOtherAction}
              children="Other"
              iconName="dotsThreeOutline"
            />
          </View>
        </DialogFooter>
      </BottomDrawerModal>
    );
  }

  return null;
}
