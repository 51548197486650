import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import { BottomActions } from "@/components/shared/bottom-actions";
import { Portal } from "@/components/shared/portal";
import { Button, Card, CardContent, Skeleton, View } from "@/components/ui";
import type { ReactNode } from "react";
import { useEffect, useMemo, useState } from "react";
import {
  CaretRight,
  SealPercent,
  Receipt,
  Notepad,
} from "@/components/shared/icons";
import { IconButton } from "@/components/ui/icon-button";
import { PaymentStatusBadge } from "@/components/shared/invoices/invoice-card/payment-status-badge";
import type { InvoiceDetail as InvoiceFragment } from "@/types/invoice";
import { PaymentMethodScreenModal } from "@/types/invoice";
import type { LineItemDetail } from "@/types/line-item";
import { useInvoiceDetailStore } from "@/store/invoices/invoiceDetail";
import { Link } from "@/components/shared/link";
import { Divider } from "@/components/shared/divider";
import { formatDate } from "@/lib/helpers/date";
import { LineItemList } from "@/components/shared/line-item/line-item-list";
import { getInvoiceLineItems } from "@/lib/line-items/helpers";
import { ListHeading } from "@/components/shared/list-header";
import { formatPrice, SINGAPORE_TAX } from "@/lib/helpers/number";
import { useRoute } from "@/components/shared/router";
import { useScrollRefresh } from "@/components/shared/scroll-content/scroll-refresh-context";
import { Typography } from "@/components/shared/typography";
import { useInvoiceState } from "@/store/invoices";
import { ApplyVoucherModal } from "../apply-voucher-modal";
import { extractTokenFromUrl, GST_REGISTRATION } from "@/lib/helpers/string";
import { useAvailableClientVouchersStore } from "@/store/invoices/useAvailableClientVouchers";
import { useAuthState } from "@/store/auth";
import { useRemoveVoucherInvoiceStore } from "@/store/invoices/removeVoucherInvoice";
import {
  InvoicePaymentStatusEnum,
  VoucherDiscountTypeEnum,
} from "@/__generated__/graphql";
import { useShallow } from "zustand/react/shallow";
import { copyClipboard } from "@/lib/helpers/copy-clipboard";

export type InvoiceDetailData = Omit<
  InvoiceFragment,
  | "dueDate"
  | "issueDate"
  | "fromDate"
  | "toDate"
  | "lineItems"
  | "clientPaymentIndicated"
> & {
  balance: number;
  dueDate: Date;
  issueDate: Date;
  fromDate: Date;
  toDate: Date;
  lineItems: LineItemDetail[];
  clientPaymentIndicated: boolean;
};

export function getInvoiceDetailData(
  invoice: InvoiceFragment,
): InvoiceDetailData {
  return {
    id: invoice.id,
    amount: invoice.amount,
    balance: invoice.totalAmount - invoice.paidAmount,
    paidAmount: invoice.paidAmount,
    baseAmount: invoice.baseAmount,
    paymentStatus: invoice.paymentStatus,
    fromDate: new Date(invoice.fromDate),
    toDate: new Date(invoice.toDate ?? 0),
    issueDate: new Date(invoice.issueDate ?? 0),
    dueDate: new Date(invoice.dueDate ?? 0),
    status: invoice.status,
    pdfUrl: invoice.pdfUrl,
    totalAmount: invoice.totalAmount,
    pdfReceiptUrl: invoice.pdfReceiptUrl,
    clientPaymentIndicated: Boolean(invoice.clientPaymentIndicated),
    appliedVoucher: invoice.appliedVoucher ?? null,
    lineItems: invoice.lineItems?.map(getInvoiceLineItems) ?? [],
  };
}

export function InvoiceDetail({ invoiceId }: { invoiceId: string }) {
  const { isMobile } = useWindowDimensions();
  const { refreshing, setRefreshing } = useScrollRefresh();
  const { push } = useRoute();

  const { setInvoiceId, showPaymentMethodModal } = useInvoiceState();
  const { fetch, data, loading, refetch, error } = useInvoiceDetailStore();

  const [showApplyVoucherModal, setShowApplyVoucherModal] = useState(false);

  const invoice = data as InvoiceDetailData;

  useEffect(() => {
    if (refreshing) {
      void refetch();
      setRefreshing(false);
    }
  }, [refreshing]);

  useEffect(() => {
    if (error) {
      push({
        pageKey: "invoices",
      });
    }
  }, [error]);

  useEffect(() => {
    void fetch({
      requestPayload: {
        id: invoiceId,
      },
    });
  }, [invoiceId]);

  const handleVoucher = () => {
    setShowApplyVoucherModal(true);
  };
  const onCloseVoucherModal = () => {
    setShowApplyVoucherModal(false);
  };

  const handlePay = () => {
    setInvoiceId(invoiceId);
    showPaymentMethodModal(PaymentMethodScreenModal.SELECT_PAYMENT);

    if (isMobile) {
      push({ pageKey: "selectPayment" });
    }
  };

  const renderButton = useMemo(() => {
    if (
      loading ||
      ![
        InvoicePaymentStatusEnum.Unpaid,
        InvoicePaymentStatusEnum.Underpaid,
      ].includes(invoice.paymentStatus) ||
      invoice.clientPaymentIndicated
    ) {
      return null;
    }
    if (isMobile) {
      return (
        <BottomActions>
          <View className="flex w-full flex-1 flex-col gap-2">
            <AvailableVoucher onClick={handleVoucher} />
            <Button
              variant="primary"
              color="CTA"
              onClick={handlePay}
              children="Pay"
            />
          </View>
        </BottomActions>
      );
    }

    return (
      <Button
        variant="primary"
        color="CTA"
        className="min-w-[100px]"
        rounded="full"
        onClick={handlePay}
        children="Pay"
      />
    );
  }, [isMobile, invoice.paymentStatus, invoice.clientPaymentIndicated]);

  return (
    <View className="my-0 md:my-5">
      {!isMobile &&
        [
          InvoicePaymentStatusEnum.Unpaid,
          InvoicePaymentStatusEnum.Underpaid,
        ].includes(invoice.paymentStatus) &&
        !invoice.clientPaymentIndicated && (
          <View className="mb-5">
            <AvailableVoucher onClick={handleVoucher} />
          </View>
        )}
      <Card
        border={isMobile ? "none" : "default"}
        className="mb-[144px] md:mb-0"
      >
        <CardContent className="flex flex-col gap-2">
          <View className="flex flex-row justify-between gap-2">
            <View className="flex flex-row items-center gap-1">
              <Typography variant="label-lg">Invoice #{invoiceId}</Typography>
              <IconButton
                variant="ghost"
                color="foreground"
                size="sm"
                className="p-0"
                iconName="copy"
                onClick={() => copyClipboard(`Invoice #${invoiceId}`)}
              />
            </View>
            {loading ? (
              <Skeleton className="h-5 w-[100px]" />
            ) : (
              <Link
                href={
                  invoice.pdfUrl
                    ? `view-invoice-pdf?token=${extractTokenFromUrl(invoice.pdfUrl)}`
                    : "#"
                }
              >
                <View className="flex flex-row items-center gap-1">
                  <Typography variant="label-lg" color="brand-primary">
                    View PDF
                  </Typography>
                  <CaretRight className="size-4 text-brand-primary" />
                </View>
              </Link>
            )}
          </View>
          <Divider />
          {invoice.paymentStatus === InvoicePaymentStatusEnum.Paid && (
            <>
              <View className="flex flex-row justify-between gap-2">
                <View className="flex flex-row items-center gap-1">
                  <Typography variant="label-lg">
                    Receipt #{invoiceId}
                  </Typography>
                  <IconButton
                    variant="ghost"
                    color="foreground"
                    className="p-0"
                    size="sm"
                    iconName="copy"
                    onClick={() => copyClipboard(`Receipt #${invoiceId}`)}
                  />
                </View>
                <Link
                  href={
                    invoice.pdfReceiptUrl
                      ? `view-receipt-pdf?token=${extractTokenFromUrl(invoice.pdfReceiptUrl)}`
                      : "#"
                  }
                >
                  <View className="flex flex-row items-center gap-1">
                    <Typography className="text-sm font-medium text-brand-primary">
                      View PDF
                    </Typography>
                    <CaretRight className="size-4 text-brand-primary" />
                  </View>
                </Link>
              </View>
              <Divider />
            </>
          )}
          <ListItem label="Status">
            {loading ? (
              <Skeleton className="h-5 w-[100px]" />
            ) : (
              <PaymentStatusBadge
                balance={invoice.balance}
                amount={invoice.amount}
                clientPaymentIndicated={invoice.clientPaymentIndicated}
                dueDate={invoice.dueDate}
                status={invoice.paymentStatus}
              />
            )}
          </ListItem>
          <Divider />
          <ListItem label="Invoice Date">
            {loading ? (
              <Skeleton className="h-4 w-[200px]" />
            ) : (
              <Typography variant="label-lg">
                {formatDate(invoice.issueDate, "dd MMM yyyy")}
              </Typography>
            )}
          </ListItem>
          <Divider />
          <ListItem label="GST Registration No">
            <Typography variant="label-lg">{GST_REGISTRATION}</Typography>
          </ListItem>
          <Divider />
          <ListItem label="Due Date">
            {loading ? (
              <Skeleton className="h-4 w-[200px]" />
            ) : (
              <Typography variant="label-lg">
                {formatDate(invoice.dueDate, "dd MMM yyyy")}
              </Typography>
            )}
          </ListItem>
        </CardContent>
        <Divider />
        <CardContent className="flex flex-col gap-2">
          <View className="flex flex-col gap-1">
            <View className="py-2">
              <ListHeading icon={Notepad} title="Items" />
            </View>
            <LineItemList lineItems={invoice.lineItems} withPrice />
          </View>
          <Divider className="py-2" />
          <View className="flex flex-col gap-1">
            <View className="py-2">
              <ListHeading icon={Receipt} title="Payment Summary" />
            </View>
            <ListItem label="SubTotal">
              {loading ? (
                <Skeleton className="h-4 w-[200px]" />
              ) : (
                <Typography variant="label-lg">
                  {formatPrice(invoice.amount)}
                </Typography>
              )}
            </ListItem>
            {/* TODO: get discount value from BE */}
            {/* <ListItem label="Discount (15%)">
              {loading ? (
                <Skeleton className="h-4 w-[200px]" />
              ) : (
                <Typography variant="label-lg">
                  {formatPrice(10)}
                </Typography>
              )}
            </ListItem> */}
            <ListItem label={`GST (${SINGAPORE_TAX * 100}%)`}>
              {loading ? (
                <Skeleton className="h-4 w-[200px]" />
              ) : (
                <Typography variant="label-lg">
                  {formatPrice(invoice.amount)}
                </Typography>
              )}
            </ListItem>
          </View>
          <Divider className="py-2" />
          <View className="flex flex-row items-end justify-between gap-2">
            <Typography variant="label-xl">Total Amount</Typography>
            {loading ? (
              <Skeleton className="h-4 w-[200px]" />
            ) : (
              <Typography variant="bold-xl" color="brand-primary">
                {formatPrice(invoice.totalAmount)}
                <Typography
                  as="span"
                  variant="body-sm"
                  color="foreground-intermediate"
                  className="ml-1"
                >
                  incl. GST
                </Typography>
              </Typography>
            )}
          </View>
        </CardContent>
      </Card>
      <Portal name="BottomActions">{renderButton}</Portal>
      <ApplyVoucherModal
        invoice={invoice}
        open={showApplyVoucherModal}
        onClose={onCloseVoucherModal}
      />
    </View>
  );
}

function ListItem({ label, children }: { label: string; children: ReactNode }) {
  return (
    <View className="flex flex-row items-center justify-between gap-1">
      <Typography variant="body-lg" color="foreground-intermediate">
        {label}
      </Typography>
      {children}
    </View>
  );
}

function AvailableVoucher({ onClick }: { onClick: () => void }) {
  const { invoiceId, appliedVoucher, balance } = useInvoiceDetailStore(
    useShallow(({ data }) => ({
      invoiceId: data?.id as string,
      balance: data?.balance ?? 0,
      appliedVoucher: data?.appliedVoucher ?? null,
    })),
  );

  const {
    fetch: getVouchers,
    data,
    loading,
  } = useAvailableClientVouchersStore();

  const { fetch: removeVoucher, loading: loadingRemoveVoucher } =
    useRemoveVoucherInvoiceStore();

  const clientId = useAuthState((state) => state.data.userInfo.id);

  const { isMobile } = useWindowDimensions();

  const { replace } = useRoute();

  useEffect(() => {
    if (clientId) {
      getVouchers({
        requestPayload: {
          clientId,
        },
      });
    }
  }, [clientId]);

  const handleRemoveVoucher = async () => {
    const res = await removeVoucher({
      requestPayload: {
        input: {
          invoiceId,
        },
      },
    });
    if (!res.data) {
      return replace({
        pageKey: "invoices",
      });
    }

    replace({
      pageKey: "invoiceDetail",
      params: {
        id: res.data.id,
      },
    });
  };

  const numberVouchers = data?.length ?? 0;

  const discountValue = useMemo(() => {
    let discount = appliedVoucher?.discountValue ?? 0;
    if (appliedVoucher?.discountType === VoucherDiscountTypeEnum.Percentage) {
      discount = (balance * appliedVoucher.discountValue) / 100;
    }

    return discount;
  }, [appliedVoucher]);

  if (loading || loadingRemoveVoucher) {
    return <Skeleton className="h-6 w-full" />;
  }

  if (numberVouchers === 0) {
    return null;
  }

  return (
    <Card onClick={() => isMobile && !appliedVoucher && onClick()}>
      <CardContent className="flex flex-row items-center justify-between gap-2 p-3">
        <SealPercent className="size-5 text-green-400" weight="fill" />
        {appliedVoucher ? (
          <View
            className="flex flex-1 cursor-pointer flex-row items-center gap-1"
            onClick={onClick}
          >
            <Typography variant="label-lg">
              Voucher applied. You saved{" "}
              <Typography as="span" variant="label-lg" color="success">
                {formatPrice(discountValue, 0)}
              </Typography>
            </Typography>
            <CaretRight className="size-4" />
          </View>
        ) : (
          <View className="flex-1">
            <Typography variant="body-lg">
              You have {numberVouchers} vouchers waiting for you!
            </Typography>
          </View>
        )}
        {appliedVoucher ? (
          <View onClick={handleRemoveVoucher}>
            <Typography variant="label-lg" color="danger">
              Remove
            </Typography>
          </View>
        ) : (
          <View
            className="flex cursor-pointer flex-row items-center gap-2"
            onClick={onClick}
          >
            <Typography
              variant="label-lg"
              color="brand-primary"
              className="hidden md:block"
            >
              Apply Voucher
            </Typography>
            <CaretRight className="size-4" />
          </View>
        )}
      </CardContent>
    </Card>
  );
}
