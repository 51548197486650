import { createRequestFactory } from "@/lib/request-factory";
import {
  CreateAddressDocument,
  type CreateAddressMutation,
  type CreateAddressMutationVariables,
} from "@/__generated__/graphql";
import { useAuthState } from "@/store/auth";
import type { Address } from "@/types/users";

type Response = {
  address: Address;
};

export const useCreateAddressStore = createRequestFactory<
  CreateAddressMutation,
  Response,
  CreateAddressMutationVariables
>({
  method: "mutation",
  graphqlDocument: CreateAddressDocument,
  transformFunction: (res) => {
    if (res.createAddress?.address) {
      return { address: res.createAddress.address } as Response;
    }
    throw new Error("Create Address failed");
  },
  onFetchSuccess: (data) => {
    const {
      setUserInfo,
      data: { userInfo },
    } = useAuthState.getState();

    const newAddress = data.address;
    const existingAddresses = userInfo.addresses || [];
    const updatedAddresses = [...existingAddresses, newAddress];
    setUserInfo({ addresses: updatedAddresses });
  },
});
