import { BottomDrawerModal } from "@/components/shared/bottom-drawer-modal";
import { Button, DialogHeader, DialogTitle, View } from "@/components/ui";
import { IconButton } from "@/components/ui/icon-button";
import type { RescheduleVisitWorkerType } from "@/store/visits/visitDetail";
import { useVisitDetailStore } from "@/store/visits/visitDetail";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import { getCapitalizeWord } from "@/lib/utils";
import { getWorkerLabel } from "@/lib/service/helpers";
import {
  PackageDepartmentEnum,
  VisitStatusEnum,
} from "@/__generated__/graphql";
import { useRoute } from "@/components/shared/router";
import { useMemo } from "react";
import { addMonths, isWithinInterval } from "date-fns";
import { formatDate } from "@/lib/helpers/date";
import { InfoDialog } from "@/components/shared/info-dialog";
import { Typography } from "@/components/shared/typography";
import { useIntl } from "react-intl";

export function RescheduleVisitConfirmation({
  visitId,
  department = PackageDepartmentEnum.HomeCleaning,
  visitStatus,
  serviceDate,
  hasWorkerAssignment,
}: {
  visitId: string;
  department?: PackageDepartmentEnum;
  visitStatus: VisitStatusEnum;
  serviceDate: Date;
  hasWorkerAssignment: boolean;
}) {
  const intl = useIntl();
  const showConfirmation = useVisitDetailStore(
    (state) => state.data.showRescheduleVisitConfirmation,
  );

  const onClose = useVisitDetailStore(
    (state) => state.closeRescheduleVisitConfirmation,
  );
  const setRescheduleWorkerType = useVisitDetailStore(
    (state) => state.setRescheduleVisitWorkerType,
  );
  const visitToken = useVisitDetailStore((state) => state.data.visitToken);

  const { push } = useRoute();

  const { isDesktop } = useWindowDimensions();

  const cleanerLabel = getWorkerLabel(department);

  const handleReschedule = (type: RescheduleVisitWorkerType) => () => {
    setRescheduleWorkerType(type);
    onClose();
    let query: { id: string } | { token: string } = {
      id: visitId,
    };

    if (visitToken) {
      query = {
        token: visitToken,
      };
    }

    push({
      pageKey: "rescheduleVisit",
      query,
    });
  };

  const oneMonthBeforeVisitStart = addMonths(serviceDate, -1);

  const isReadyToSchedule = useMemo(() => {
    return isWithinInterval(new Date(), {
      start: oneMonthBeforeVisitStart,
      end: serviceDate,
    });
  }, [serviceDate, oneMonthBeforeVisitStart]);

  if (
    visitStatus === VisitStatusEnum.PendingClientSchedule &&
    !isReadyToSchedule
  ) {
    return (
      <InfoDialog
        open
        title="Reschedule Visit"
        subtitle={`You can reschedule this visit after ${formatDate(
          oneMonthBeforeVisitStart,
          "dd/MM/yyyy",
        )}`}
        onClose={onClose}
        buttonText="Got it, thanks!"
      />
    );
  }

  if (showConfirmation) {
    return (
      <BottomDrawerModal open onOpenChange={onClose}>
        <DialogHeader>
          <View className="relative flex flex-row items-center justify-between gap-2">
            <DialogTitle>
              {intl.formatMessage({
                defaultMessage: "Reschedule",
                id: "reschedule",
              })}
            </DialogTitle>
            {isDesktop && (
              <IconButton
                variant="ghost"
                color="foreground"
                size="md"
                iconName="x"
                onClick={onClose}
              />
            )}
          </View>
        </DialogHeader>
        <View className="flex flex-col gap-2">
          <View className="py-4">
            <Typography variant={{ sm: "body-lg", md: "body-xl" }}>
              Reschedule this visit with either your current {cleanerLabel}{" "}
              (subject to availability) or choose any {cleanerLabel} for the
              greatest flexibility!
            </Typography>
          </View>
          {hasWorkerAssignment && (
            <Button
              variant="primary"
              color="CTA"
              onClick={handleReschedule("CURRENT_WORKER")}
              children={`Reschedule with My Current ${getCapitalizeWord(cleanerLabel)}`}
            />
          )}

          <Button
            variant="secondary"
            color="CTA2"
            onClick={handleReschedule("ANY_WORKER")}
            children={`Reschedule with Any ${getCapitalizeWord(cleanerLabel)}`}
          />
        </View>
      </BottomDrawerModal>
    );
  }

  return null;
}
