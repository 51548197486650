import {
  VisitStatusEnum,
  type PackageDepartmentEnum,
  type PackageServiceTypeEnum,
  type VisitsByClientQuery,
} from "@/__generated__/graphql";
import { Button, Card, CardContent, View } from "@/components/ui";
import { formatPrice } from "@/lib/helpers/number";
import { packageServiceTypeLabel } from "@/lib/service/helpers";
import type { ReactNode } from "react";
import { useMemo } from "react";
import { WorkerAvatar } from "./worker-avatar";
import { VisitStatusBadge } from "./visit-status-badge";
import { handleWhatsappNumber } from "@/lib/platform";
import { cn } from "@/lib/utils";
import { VisitRating } from "./visit-rating";
import {
  formatVisitAddress,
  formatVisitSchedule,
  visitWorkerFullName,
} from "../utils";
import { Typography } from "../../typography";

export type VisitWorker = {
  fullName: string;
  phoneNumber: string | null;
  avatarUrl: string | null;
};

export type VisitData = {
  id: string;
  status: VisitStatusEnum;
  department: PackageDepartmentEnum;
  serviceType: PackageServiceTypeEnum;
  serviceDate: Date;
  startTime: string;
  endTime: string;
  address: string;
  rating: number;
  worker: VisitWorker;
  value: number;
};

interface Props {
  visitData: VisitData;
  handleViewDetail(visitId: string): void;
  handleSetSchedule?(visitId: string): void;
  handleRateVisit?(visitId: string, rate?: number): void;
  handleRebook?(department: PackageDepartmentEnum): void;
}

export function mapToVisitData(
  visit: VisitsByClientQuery["visitsByClient"][number],
): VisitData {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const job = visit.job!;
  const fullAddress = job.package.fullAddress;
  const unitNumber = job.package.unitNumber ?? "";
  const postalCode = job.package.postalCode;

  const cardData: VisitData = {
    id: visit.id,
    status: visit.status,
    department: job.package.department,
    serviceType: job.package.serviceType,
    serviceDate: new Date(visit.serviceDate),
    startTime: String(visit.startTime),
    endTime: String(visit.endTime),
    value: visit.clientBillingValue ?? 0,
    address: formatVisitAddress(fullAddress, unitNumber, postalCode),
    worker: {
      fullName: visitWorkerFullName(visit.status, visit.worker),
      phoneNumber: visit.worker?.contactNumber ?? null,
      avatarUrl: visit.worker?.avatarUrl ?? null,
    },
    rating: visit.rating ?? 0,
  };

  return cardData;
}

export function VisitCard({
  visitData,
  handleViewDetail,
  handleSetSchedule,
  handleRebook,
  handleRateVisit,
}: Props) {
  const handleContactProfessional = () => {
    if (visitData.worker.phoneNumber) {
      handleWhatsappNumber(visitData.worker.phoneNumber);
    }
  };

  const buttonContactProfessional = useMemo(
    () => (
      <Button
        variant="secondary"
        color="success"
        key="buttonContactProfessional"
        fullWidth="full"
        size={{ sm: "sm", md: "md" }}
        iconSize={{ sm: "sm", md: "md" }}
        onClick={handleContactProfessional}
        iconName="whatsappLogo"
        iconColor="fill-success"
        children="Contact Professional"
      />
    ),
    [handleContactProfessional],
  );

  const renderButton = useMemo(() => {
    const buttons: ReactNode[] = [];

    if (visitData.status === VisitStatusEnum.Scheduled) {
      if (visitData.worker.phoneNumber) buttons.push(buttonContactProfessional);

      buttons.push(
        <Button
          variant="primary"
          color="CTA2"
          key="view-detail-btn"
          className="max-w-[50%] flex-1"
          fullWidth="full"
          size={{ sm: "sm", md: "md" }}
          onClick={() => handleViewDetail(visitData.id)}
          children="Manage Appointment"
        />,
      );
    }

    if (
      [
        VisitStatusEnum.PendingClientSchedule,
        VisitStatusEnum.UnableToSchedule,
      ].includes(visitData.status)
    ) {
      buttons.push(
        <Button
          variant="primary"
          color="CTA2"
          key="set-schedule-btn"
          className="max-w-[50%] flex-1"
          size={{ sm: "sm", md: "md" }}
          onClick={() => handleSetSchedule?.(visitData.id)}
          fullWidth="full"
          children="Set Schedule"
        />,
      );
    }

    if (
      [
        VisitStatusEnum.Expired,
        VisitStatusEnum.CancelledByClient,
        VisitStatusEnum.CancelledByOps,
        VisitStatusEnum.CancelledByWorker,
        VisitStatusEnum.MissedByWorker,
        VisitStatusEnum.Completed,
      ].includes(visitData.status)
    ) {
      buttons.push(
        <Button
          variant="secondary"
          color="CTA2"
          key="view-detail-btn"
          fullWidth="full"
          className="max-w-[50%] flex-1 bg-white"
          size={{ sm: "sm", md: "md" }}
          onClick={() => handleViewDetail(visitData.id)}
          children="View Details"
        />,
      );
      if (visitData.status === VisitStatusEnum.Completed) {
        buttons.push(
          <Button
            variant="primary"
            color="CTA2"
            key="rebook-btn"
            fullWidth="full"
            className="max-w-[50%] flex-1"
            size={{ sm: "sm", md: "md" }}
            onClick={() => handleRebook?.(visitData.department)}
            children="Rebook"
          />,
        );
      }
    }

    return buttons;
  }, [visitData.id, visitData.status, visitData.worker, visitData.department]);

  return (
    <Card gap="lg" radius="xl">
      <CardContent className="pb-0">
        <View className="flex flex-row justify-between">
          <Typography variant="label-2xl">
            {packageServiceTypeLabel(
              visitData.serviceType,
              visitData.department,
            )}
          </Typography>
          <Typography variant="label-2xl">
            {formatPrice(visitData.value, 0)}
          </Typography>
        </View>
        <Typography variant="body-xl" color="foreground-intermediate">
          {formatVisitSchedule(
            visitData.startTime,
            visitData.endTime,
            visitData.serviceDate,
          )}
        </Typography>
        <Typography variant="body-xl" color="foreground-intermediate">
          {visitData.address}
        </Typography>
      </CardContent>
      <Divider />
      <CardContent className="flex flex-row justify-between gap-2 py-0">
        <View className="max-w-[50%] flex-1">
          <WorkerAvatar
            fullName={visitData.worker.fullName}
            avatarUrl={visitData.worker.avatarUrl}
          />
        </View>
        <VisitStatusBadge status={visitData.status} />
      </CardContent>
      {renderButton.length !== 0 && (
        <>
          <Divider />
          <CardContent
            className={cn(
              "flex flex-row gap-2",
              visitData.rating ? "py-0" : "pt-0",
            )}
          >
            {renderButton}
          </CardContent>
        </>
      )}
      {visitData.status === VisitStatusEnum.Completed && (
        <>
          <Divider />
          <CardContent className="pt-0">
            <VisitRating
              rating={visitData.rating}
              onClick={(rate) => handleRateVisit?.(visitData.id, rate)}
            />
          </CardContent>
        </>
      )}
    </Card>
  );
}

function Divider() {
  return (
    <View className="native:border-b-border native:border-solid mx-4 border-b border-dashed border-border-medium" />
  );
}
