import "@react-pdf-viewer/core/lib/styles/index.css";
import { Suspense } from "react";
import { View } from "@/components/ui";
import { SpecialZoomLevel, Worker, Viewer } from "@react-pdf-viewer/core";

const PDF_WORKER_JS =
  "https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js";

export function PDFViewer({ url }: { url: string }) {
  return (
    <View className="flex w-full flex-1">
      <Suspense>
        <Worker workerUrl={PDF_WORKER_JS}>
          <Viewer defaultScale={SpecialZoomLevel.PageWidth} fileUrl={url} />
        </Worker>
      </Suspense>
    </View>
  );
}

export function generatePDFUrl(fileString: string) {
  const pdfBlob = getBlobFromBase64(fileString, "application/pdf");

  return window.URL.createObjectURL(pdfBlob);
}

function getBlobFromBase64(
  fileUrl: string,
  type = "data:application/octet-stream;base64",
): Blob {
  const fileContent = base64ToArrayBuffer(fileUrl);

  return new Blob([fileContent], {
    type,
  });
}

function base64ToArrayBuffer(base64: string) {
  const binary_string = atob(base64);
  const len = binary_string.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }

  return bytes.buffer;
}
