import { View } from "@/components/ui";
import { Checkbox } from "@/components/ui/checkbox";
import type { Control } from "react-hook-form";
import { Controller } from "react-hook-form";
import type { ContactFormData } from "@/store/auth/forms/useContactForm";
import { Typography } from "@/components/shared/typography";
import { useIntl } from "react-intl";

interface PrimaryContactCheckboxProps {
  control: Control<ContactFormData>;
}

export const PrimaryAccountCheckbox = ({
  control,
}: PrimaryContactCheckboxProps) => {
  const intl = useIntl();
  return (
    <View className="native:hidden mt-6 flex flex-row items-center gap-3">
      <Controller
        name="primary"
        control={control}
        render={({ field }) => (
          <Checkbox
            checked={!!field.value}
            onCheckedChange={field.onChange}
            className="size-6"
          />
        )}
      />
      <Typography variant="body-md" color="muted-foreground">
        {intl.formatMessage({
          defaultMessage: "Make this my default contact",
          id: "make-default-contact",
        })}
      </Typography>
    </View>
  );
};
