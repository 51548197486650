import { MERCHANT_NAME, UEN_NUMBER } from "@/constants/invoices";
import { _nations } from "@/constants/nation";
import { ApolloError } from "@apollo/client";

export const GST_REGISTRATION = "201727751R";

export const fullName = ({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName: string;
}): string => {
  return `${firstName} ${lastName}`;
};

export function extractTextAfterColon(input: string) {
  const colonPosition = input.indexOf(":");
  return colonPosition !== -1 ? input.slice(colonPosition + 1).trim() : input;
}

export const getUnitNumber = (
  unitNumber: string | undefined,
): { unitFloor: string; apartmentNumber: string } => {
  if (!unitNumber) return { unitFloor: "", apartmentNumber: "" };

  const [floorPart, apartmentNumber = ""] = unitNumber.split("-");
  const unitFloor = floorPart.split("#")[1] ?? "";

  return { unitFloor, apartmentNumber };
};

export const createUnitNumber = (
  unitFloor?: string,
  apartmentNumber?: string,
) => {
  if (
    (unitFloor === "" && apartmentNumber === "") ||
    (unitFloor === "0" && apartmentNumber === "0")
  )
    return "";
  if (unitFloor === "") return `#NA-${apartmentNumber}`;
  if (apartmentNumber === "") return `#${unitFloor}-NA`;

  return `#${unitFloor}-${apartmentNumber}`;
};

export const setUnitNumber = (
  outputType: "unitFloor" | "apartmentNumber",
  unitNumber: string | undefined,
): string | undefined => {
  if (!unitNumber) {
    return "";
  }

  if (!unitNumber.includes("#")) {
    if (outputType === "unitFloor") {
      return unitNumber;
    }
    return "NA";
  } else if (unitNumber.includes("#")) {
    const splittedUnitNumber = unitNumber.split("#")[1];
    if (splittedUnitNumber.includes("-")) {
      // #222-122
      const [unitFloor, apartmentNumber] = splittedUnitNumber.split("-");
      if (outputType === "unitFloor") {
        return unitFloor;
      }
      return apartmentNumber;
    } else {
      // #222
      if (outputType === "unitFloor") {
        return splittedUnitNumber;
      }
      return "NA";
    }
  }
};

const getNationInfo = (phoneNumber: string) => {
  const fullNumber = phoneNumber.startsWith("+")
    ? phoneNumber.slice(1)
    : phoneNumber;
  return _nations.find((nation) => fullNumber.startsWith(nation.tel));
};

export const getNationCode = (phoneNumber: string): string => {
  const nationInfo = getNationInfo(phoneNumber);

  if (nationInfo) {
    return `${nationInfo.short}/${nationInfo.tel}`;
  }

  return "";
};

export const getPhoneNumber = (phoneNumber: string): string => {
  const nationInfo = getNationInfo(phoneNumber);

  if (nationInfo) {
    return phoneNumber.startsWith("+")
      ? phoneNumber.slice(1 + nationInfo.tel.length)
      : phoneNumber.slice(nationInfo.tel.length);
  }

  return phoneNumber.startsWith("+") ? phoneNumber.slice(1) : phoneNumber;
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const maskName = (fullName: string) => {
  //will remove this masking when the client name response is updated from BE
  if (fullName.length <= 3) {
    return fullName;
  }
  return fullName.slice(0, 3) + "*".repeat(fullName.length - 4);
};

export const getErrorMessage = (err: unknown, fallback: string): string => {
  if (err instanceof ApolloError) {
    if (err.graphQLErrors.length > 0) {
      return err.graphQLErrors[0].message;
    } else if (err.networkError) {
      return err.networkError.message;
    }
  }

  if (err instanceof Error) {
    return err.message;
  }

  return fallback;
};

export const formatText = (str?: string | null) => {
  if (!str) return "";
  return str
    .split("_")
    .map((str) => capitalizeFirstLetter(str))
    .join(" ");
};

export function generateWALink(phone: string, message: string) {
  return "//wa.me/" + phone + "?text=" + message;
}

export function cleanupNumber(number: string): string {
  return number.replace(/\D/g, "");
}

export function getUserNameShortCut({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName: string;
}) {
  return [firstName, lastName]
    .map((name) => (name && name.length > 0 ? name[0] : ""))
    .join("");
}

export function generatePaymentQRUrl({
  invoiceId,
  totalAmount,
}: {
  invoiceId: string;
  totalAmount: number;
}) {
  return `https://sslsecure.maybank.com.sg/scripts/mbb_qrcode/mbb_qrcode.jsp?proxyValue=${UEN_NUMBER}&proxyType=UEN&merchantName=${MERCHANT_NAME}&amount=${totalAmount}&reference=${invoiceId}`;
}

export function extractTokenFromUrl(url: string): string | null {
  try {
    const urlObj = new URL(url);
    return urlObj.searchParams.get("token");
  } catch (error) {
    console.error("Invalid URL:", error);
    return null;
  }
}
