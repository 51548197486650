import React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  View,
} from "@/components/ui";
import { OTP } from "@/components/shared/otp";
import { ErrorMessage } from "../login-register/error-message";
import { Typography } from "../typography";
import { IfElse } from "@/components/shared/if-else";
import { Spin } from "@/components/shared/spin";
import { DialogCloseIcon } from "@/components/shared/dialog-close";

export function OtpModal({
  open,
  onClose,
  buttonRender,
  errorMessage,
  phoneNumber,
  pinCode,
  setPinCode,
  time,
  count,
  onResendPinCode,
  resetLoading,
}: {
  open: boolean;
  onClose(): void;
  buttonRender: React.ReactElement;
  errorMessage?: string;
  phoneNumber?: string;
  pinCode: string;
  setPinCode(pinCode: string): void;
  time: string;
  count: number;
  onResendPinCode(): void;
  resetLoading: boolean;
}) {
  return (
    <Dialog open={open}>
      <DialogContent className="w-[343px]">
        <DialogCloseIcon onClose={onClose} />
        <DialogHeader>
          <DialogTitle>OTP Verification</DialogTitle>
          <DialogDescription>
            {`A verification phone number has been sent to (${phoneNumber}).`}
          </DialogDescription>
        </DialogHeader>
        <View>
          <View className="mt-2">
            <OTP pinCode={pinCode} onOTPChange={setPinCode} />
          </View>
          <ErrorMessage errorMessage={errorMessage} className="mt-2" />
          {buttonRender}
          <View className="pb-2 pt-10">
            <IfElse
              if={!!count}
              else={
                <View className="flex flex-row items-center justify-center gap-2">
                  {!!resetLoading && <Spin />}
                  <Typography
                    variant="body-lg"
                    color="info"
                    align="center"
                    onClick={onResendPinCode}
                    className="cursor-pointer"
                  >
                    Resend Code to SMS and WhatsApp
                  </Typography>
                </View>
              }
            >
              <Typography
                variant="body-lg"
                color="secondary-text"
                align="center"
              >
                Remaining time: {time}s
              </Typography>
            </IfElse>
          </View>
        </View>
      </DialogContent>
    </Dialog>
  );
}
