import { Card, CardContent, Button, View } from "@/components/ui";
import { Typography } from "@/components/shared/typography";
import { OtpModal } from "../../otp-modal";
import { useEffect, useState } from "react";
import { useClientVerifyPhoneNumberStore } from "@/store/auth/clientVerifyPhoneNumber";
import { useClientConfirmPhoneNumberStore } from "@/store/auth/clientConfirmPhoneNumber";
import { useAuthState } from "@/store/auth";
import { useCountdown } from "@/components/hooks/use-count-down";
import { ConfirmAccountDeletionModal } from "./confirm-account-deletion";
import { showToast } from "@/components/ui/toast/show-toast";
import { useDeleteClientStore } from "@/store/auth/deleteClient";
import { DeleteSucceedModal } from "./delete-succeed-modal";

export const AccountDeletionCard = () => {
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [succeedModalOpen, setSucceedModalOpen] = useState(false);
  const [pinCode, setPinCode] = useState("");
  const { time, count, resetCount } = useCountdown(59);
  const [confirmError, setConfirmError] = useState("");
  const { fetch: verifyPhoneNumber, loading: verifyLoading } =
    useClientVerifyPhoneNumberStore();
  const { fetch: confirmPhoneNumber, loading: confirmLoading } =
    useClientConfirmPhoneNumberStore();
  const { fetch: deleteClient, loading: deleteLoading } =
    useDeleteClientStore();
  const {
    data: { userInfo },
  } = useAuthState();

  useEffect(() => {
    if (otpModalOpen) {
      setPinCode("");
      resetCount();
      setConfirmError("");
    }
  }, [otpModalOpen]);

  const onVerifyPhoneNumber = () => {
    verifyPhoneNumber({
      requestPayload: {
        input: {
          phone: userInfo?.user?.phoneNumber || "",
          enableWhatsappOtp: true,
        },
      },
    }).then((data) => {
      if (data.error && !data.error?.includes("Last pin code is still active"))
        return;
      setOtpModalOpen(true);
    });
  };

  const onConfirmPhoneNumber = () => {
    setConfirmError("");
    confirmPhoneNumber(
      {
        requestPayload: {
          input: {
            phone: userInfo?.user?.phoneNumber || "",
            code: pinCode,
          },
        },
      },
      { selfHandleError: true },
    ).then((data) => {
      if (data.error) {
        setConfirmError(data.error);
        return;
      }
      setOtpModalOpen(false);
      setConfirmModalOpen(true);
    });
  };

  const onDeleteAccount = () => {
    if (!checked) {
      showToast({
        title: "Please confirm that you agree",
        type: "info",
      });
      return;
    }
    if (userInfo?.id) {
      deleteClient({
        requestPayload: {
          input: {
            id: userInfo.id,
          },
        },
      }).then((data) => {
        if (data.error) return;
        setConfirmModalOpen(false);
        setSucceedModalOpen(true);
      });
    }
  };

  const onResendPinCode = () => {
    verifyPhoneNumber(
      {
        requestPayload: {
          input: {
            phone: userInfo?.user?.phoneNumber || "",
            enableWhatsappOtp: true,
          },
        },
      },
      {
        selfHandleError: true,
      },
    ).then((res) => {
      if (res.error) {
        if (res.error?.includes("Last pin code is still active")) {
          showToast({
            title: res.error,
            type: "info",
          });
        }
        showToast({
          title: res.error,
          type: "error",
        });
        return;
      }
      resetCount();
      showToast({
        title: "Success resend pin code",
        type: "success",
      });
    });
  };

  return (
    <Card variant="danger">
      <CardContent className="flex flex-col gap-2 md:flex-row md:items-center">
        <View>
          <Typography
            variant={{ sm: "label-lg", md: "label-xl" }}
            color="danger"
          >
            Delete your Data and Account
          </Typography>
          <Typography variant={{ sm: "body-md", md: "body-lg" }} color="danger">
            Are you sure you want to delete your account? After deleting your
            account, you will not be able to login anymore.
          </Typography>
        </View>
        <Button
          variant="primary"
          color="danger"
          rounded="full"
          loading={verifyLoading}
          onClick={onVerifyPhoneNumber}
          children="Delete Account"
        />
      </CardContent>

      <OtpModal
        resetLoading={verifyLoading}
        time={time}
        count={count}
        onResendPinCode={onResendPinCode}
        open={otpModalOpen}
        onClose={() => setOtpModalOpen(false)}
        buttonRender={
          <Button
            variant="primary"
            color="CTA"
            className="mt-6 w-full"
            disabled={!count}
            onClick={onConfirmPhoneNumber}
            loading={confirmLoading}
            children="Confirm"
          />
        }
        errorMessage={confirmError || ""}
        phoneNumber={userInfo?.user?.phoneNumber || ""}
        pinCode={pinCode}
        setPinCode={setPinCode}
      />

      <ConfirmAccountDeletionModal
        open={confirmModalOpen}
        setOpen={setConfirmModalOpen}
        checked={checked}
        setChecked={setChecked}
        onDeleteAccount={onDeleteAccount}
        loading={deleteLoading}
      />

      <DeleteSucceedModal
        open={succeedModalOpen}
        setOpen={setSucceedModalOpen}
      />
    </Card>
  );
};
