import type { RecommendedService } from "@/components/shared/recommended-card";
import { RecommendedCard } from "@/components/shared/recommended-card";
import { View } from "@/components/ui";
import { cn } from "@/lib/utils";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";

export const SuggestionServiceList = ({
  items,
}: {
  items: RecommendedService[];
}) => {
  const { isDesktop } = useWindowDimensions();

  return (
    <View className="-mx-2 flex flex-row flex-wrap">
      {items.map((card, index) => (
        <View
          key={`${card.title}-${index}`}
          className={cn("p-2", isDesktop ? "basis-1/3" : "basis-1/2")}
        >
          <RecommendedCard {...card} />
        </View>
      ))}
    </View>
  );
};
