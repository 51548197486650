import { createRequestFactory } from "@/lib/request-factory";
import {
  ClientReferralCodeDocument,
  type ClientReferralCodeQueryVariables,
  type ClientReferralCodeQuery,
} from "@/__generated__/graphql";

export const useClientReferralCodeStore = createRequestFactory<
  ClientReferralCodeQuery,
  string,
  ClientReferralCodeQueryVariables
>({
  method: "query",
  graphqlDocument: ClientReferralCodeDocument,
  transformFunction: (data) => {
    return data.clientReferralCode.code ?? "";
  },
});
