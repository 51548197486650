import { InvoicePaymentStatusEnum } from "@/__generated__/graphql";
import { Badge } from "@/components/ui";
import { today } from "@/lib/helpers/date";
import { useMemo } from "react";

export function PaymentStatusBadge({
  amount,
  balance,
  dueDate,
  status,
  clientPaymentIndicated,
}: {
  amount: number;
  balance: number;
  dueDate: Date;
  status: InvoicePaymentStatusEnum;
  clientPaymentIndicated: boolean;
}) {
  const { tagLabel, tagVariant } = useMemo(() => {
    const isUnpaid = [
      InvoicePaymentStatusEnum.Unpaid,
      InvoicePaymentStatusEnum.Underpaid,
    ].includes(status);

    if (status === InvoicePaymentStatusEnum.Paid) {
      return { tagVariant: "success", tagLabel: "Paid" } as const;
    }

    if (
      status === InvoicePaymentStatusEnum.StripeProcessing ||
      clientPaymentIndicated
    ) {
      return { tagVariant: "info", tagLabel: "Processing" } as const;
    }

    if (isUnpaid) {
      const dueTag =
        balance < amount ? "Underpaid" : dueDate >= today() ? "Due" : "Overdue";
      return { tagVariant: "danger", tagLabel: dueTag } as const;
    }

    return { tagVariant: "success", tagLabel: status } as const;
  }, [status, amount, balance, dueDate, clientPaymentIndicated]);

  return <Badge variant={tagVariant}>{tagLabel}</Badge>;
}
