import {
  SealPercent,
  Copy,
  HouseSimple,
  MapPin,
  Medal,
  Bell,
  BellRinging,
  ClipboardText,
  SignOut,
  CaretLeft,
  CaretRight,
  CaretDown,
  CaretUp,
  Question,
  X,
  Invoice,
  House,
  MapPinArea,
  Sparkle,
  SquaresFour,
  ThumbsUp,
  Circle,
  Check,
  ShoppingCart,
  Star,
  CurrencyCircleDollar,
  ArrowLeft,
  ArrowRight,
  ShareFat,
  Notepad,
  Warning,
  ChatDots,
  UserCircle,
  Eye,
  EyeClosed,
  WhatsappLogo,
  PlusSquare,
  Clock,
  User,
  CreditCard,
  FileText,
  ShieldCheck,
  Info,
  CheckCircle,
  WarningCircle,
  XCircle,
  MapPinPlus,
  CalendarPlus,
  CalendarX,
  Funnel,
  Phone,
  Key,
  Timer,
  Plus,
  Minus,
  CalendarDots,
  MapPinLine,
  Receipt,
  NotePencil,
  DotsThreeOutline,
  MapPinSimpleArea,
  MagnifyingGlass,
  Wallet,
  ArrowCounterClockwise,
  Package,
  Files,
  HourglassMedium,
  CalendarCheck,
  Basket,
  UserSwitch,
  CameraPlus,
  Trash,
  AddressBook,
  UsersThree,
  TrayArrowUp,
  Cube,
  StackPlus,
  DotOutline,
  Translate,
  Bank,
  GoogleLogo,
  ServiceAcIcon,
  ServiceHomeCleaningIcon,
  ServiceMattressIcon,
  ServiceManicureIcon,
  ServicePramCarSeatIcon,
  ServiceSofaIcon,
  ServiceRugCleaningIcon,
  ServiceCarServiceIcon,
  ServiceAllIcon,
  ServiceHandimanIcon,
  ServicePetIcon,
  BadgedUserProfileIcon,
  UserProfileIcon,
  AppleIcon,
  GoogleIcon,
  EmailIcon,
  PasswordIcon,
  LuceSparkle,
  Loading,
  SmileySad,
  Dot,
  Lightning,
  TelegramLogo,
  MessengerLogo,
} from ".";

export const iconMap = {
  sealPercent: SealPercent,
  copy: Copy,
  houseSimple: HouseSimple,
  mapPin: MapPin,
  medal: Medal,
  bell: Bell,
  bellRinging: BellRinging,
  clipboardText: ClipboardText,
  signOut: SignOut,
  caretLeft: CaretLeft,
  caretRight: CaretRight,
  caretDown: CaretDown,
  caretUp: CaretUp,
  question: Question,
  x: X,
  invoice: Invoice,
  house: House,
  mapPinArea: MapPinArea,
  sparkle: Sparkle,
  squaresFour: SquaresFour,
  thumbsUp: ThumbsUp,
  circle: Circle,
  check: Check,
  shoppingCart: ShoppingCart,
  star: Star,
  currencyCircleDollar: CurrencyCircleDollar,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  shareFat: ShareFat,
  notepad: Notepad,
  warning: Warning,
  chatDots: ChatDots,
  userCircle: UserCircle,
  eye: Eye,
  eyeClosed: EyeClosed,
  whatsappLogo: WhatsappLogo,
  plusSquare: PlusSquare,
  clock: Clock,
  user: User,
  creditCard: CreditCard,
  fileText: FileText,
  shieldCheck: ShieldCheck,
  info: Info,
  checkCircle: CheckCircle,
  warningCircle: WarningCircle,
  xCircle: XCircle,
  mapPinPlus: MapPinPlus,
  calendarPlus: CalendarPlus,
  calendarX: CalendarX,
  funnel: Funnel,
  phone: Phone,
  key: Key,
  timer: Timer,
  plus: Plus,
  minus: Minus,
  calendarDots: CalendarDots,
  mapPinLine: MapPinLine,
  receipt: Receipt,
  notePencil: NotePencil,
  dotsThreeOutline: DotsThreeOutline,
  mapPinSimpleArea: MapPinSimpleArea,
  magnifyingGlass: MagnifyingGlass,
  wallet: Wallet,
  arrowCounterClockwise: ArrowCounterClockwise,
  package: Package,
  files: Files,
  hourglassMedium: HourglassMedium,
  calendarCheck: CalendarCheck,
  basket: Basket,
  userSwitch: UserSwitch,
  cameraPlus: CameraPlus,
  trash: Trash,
  addressBook: AddressBook,
  usersThree: UsersThree,
  trayArrowUp: TrayArrowUp,
  cube: Cube,
  stackPlus: StackPlus,
  dotOutline: DotOutline,
  translate: Translate,
  bank: Bank,
  googleLogo: GoogleLogo,
  smileySad: SmileySad,
  dot: Dot,
  lightning: Lightning,
  telegramLogo: TelegramLogo,
  messengerLogo: MessengerLogo,

  // Additional generated icons
  serviceAc: ServiceAcIcon,
  serviceHomeCleaning: ServiceHomeCleaningIcon,
  serviceMattressIcon: ServiceMattressIcon,
  serviceManicure: ServiceManicureIcon,
  servicePramCarSeat: ServicePramCarSeatIcon,
  serviceSofa: ServiceSofaIcon,
  serviceRugCleaning: ServiceRugCleaningIcon,

  serviceCarService: ServiceCarServiceIcon,
  serviceHandiman: ServiceHandimanIcon,
  servicePet: ServicePetIcon,
  serviceAll: ServiceAllIcon,

  badgedUserProfile: BadgedUserProfileIcon,
  userProfile: UserProfileIcon,
  apple: AppleIcon,
  google: GoogleIcon,
  email: EmailIcon,
  password: PasswordIcon,
  luceSparkle: LuceSparkle,
  loading: Loading,
};

export type IconNames = keyof typeof iconMap;
