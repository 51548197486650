import { View } from "@/components/ui";
import { GetTheAppNow } from "../get-the-app-now";
import { NavbarListItem } from "../navbar-list-item";
import { CaretDown, Question, SignOut } from "@/components/shared/icons";
import { UserAvatar } from "../user-avatar";
import { useState } from "react";
import { cn } from "@/lib/utils";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu/index.web";
import { useAuthState } from "@/store/auth";
import { Link } from "@/components/shared/link";
import { Divider } from "@/components/shared/divider";
import { useRoute } from "@/components/shared/router";
import { useIntl } from "react-intl";
import { AuthModals } from "@/types/users";
import { links } from "@/constants";
import { handleOpenLink } from "@/lib/platform";
import { useAuth } from "../../auth-provider";

export function UserDropdown() {
  const [open, setOpen] = useState(false);
  const { push } = useRoute();
  const {
    data: { userInfo },
    showModal,
  } = useAuthState();
  const intl = useIntl();
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return (
      <DropdownMenu open={open} onOpenChange={setOpen}>
        <DropdownMenuTrigger style={{ outline: "none" }}>
          <View
            className={cn(
              "ease flex cursor-pointer items-center rounded-full bg-brand-over-static pr-1 text-white transition",
            )}
          >
            <View className="mx-1">
              <UserAvatar
                badged={!!userInfo.billingAccount.totalOverdueInvoice}
              />
            </View>
            <View
              className={cn("transition duration-300 ease-in-out", {
                "rotate-180": open,
              })}
            >
              <CaretDown />
            </View>
          </View>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="mr-8 mt-2 rounded-lg border-brand-static-border bg-brand-static p-4 text-brand-on-static">
          <View className="grid w-[250px] gap-2">
            <Link href="/profile">
              <NavbarListItem
                title={intl.formatMessage({
                  defaultMessage: "Account",
                  id: "account",
                })}
                icon={<UserAvatar />}
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              />
            </Link>
            {/* TODO: Disable notification on first launch */}
            {/* <Divider className="opacity-25" />
            <Link href="/notifications">
              // <NavbarListItem
                icon={<Bell size={22} />}
                title={intl.formatMessage({
                  defaultMessage: "Notification",
                  id: "notification",
                })}
                badge={3}
              />
            </Link> */}
            <Divider className="opacity-25" />
            <View>
              <NavbarListItem
                icon={<Question size={22} />}
                title={intl.formatMessage({
                  defaultMessage: "Help",
                  id: "help",
                })}
                onClick={() => {
                  setOpen(false);
                  handleOpenLink(links.helpCenter);
                }}
              />
              <NavbarListItem
                icon={<SignOut size={22} />}
                title={intl.formatMessage({
                  defaultMessage: "Logout",
                  id: "logout",
                })}
                onClick={() => {
                  setOpen(false);
                  showModal(AuthModals.LOGOUT);
                }}
              />
            </View>
            <Divider className="opacity-25" />
            <GetTheAppNow className="py-3" />
          </View>
        </DropdownMenuContent>
      </DropdownMenu>
    );
  }

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger style={{ outline: "none" }}>
        <View
          className={cn(
            "ease flex cursor-pointer items-center rounded-full bg-brand-over-static pr-1.5 text-white transition",
          )}
        >
          <View>
            <UserAvatar />
          </View>
          <View
            className={cn("transition duration-300 ease-in-out", {
              "rotate-180": open,
            })}
          >
            <CaretDown />
          </View>
        </View>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="mr-8 mt-2 rounded-lg border-brand-static-border bg-brand-static p-4 text-brand-on-static">
        <View className="grid w-[250px] gap-2">
          <NavbarListItem
            title={intl.formatMessage({
              defaultMessage: "Sign In",
              id: "sign-in",
            })}
            onClick={() => {
              setOpen(false);
              // showModal(AuthModals.LOGIN);
              push({ pageKey: "login" });
            }}
          />
          <NavbarListItem
            title="Sign Up"
            onClick={() => {
              setOpen(false);
              // showModal(AuthModals.SIGN_UP);
              push({ pageKey: "signup" });
            }}
          />
          <Divider className="opacity-25" />
          <NavbarListItem
            icon={<Question size={22} />}
            title="Help"
            onClick={() => {
              setOpen(false);
              handleOpenLink(links.helpCenter);
            }}
          />
          <Divider className="opacity-25" />
          <GetTheAppNow size="small" className="pb-3 pt-10" />
        </View>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
