export function formatPrice(value: number, decimal: number = 2): string {
  return `$${value.toFixed(decimal)}`;
}

export const SINGAPORE_TAX = 0.09;

export function singaporeTaxCalculation(price: number) {
  const tax = Number((price * SINGAPORE_TAX).toFixed(2));
  return {
    tax,
    price: tax + price,
  };
}

export function formatNumber(num: number) {
  if (num >= 1e9) {
    return (num / 1e9).toFixed(1) + "B";
  } else if (num >= 1e6) {
    return (num / 1e6).toFixed(1) + "M";
  } else if (num >= 1e3) {
    return (num / 1e3).toFixed(1) + "k";
  } else {
    return num.toString();
  }
}
