/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Date type. For example: `2019-01-21` */
  Date: { input: string; output: string };
  /** Date and time */
  DateTime: { input: string; output: string };
  /** Represents untyped JSON */
  JSON: { input: any; output: any };
  /** input type for incoming uploaded files */
  UploadedFile: { input: any; output: any };
};

export type AccountDeleted = {
  __typename?: "AccountDeleted";
  entity: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export type Address = {
  __typename?: "Address";
  client: Client;
  fullAddress: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  postalCode: Scalars["String"]["output"];
  primary: Scalars["Boolean"]["output"];
  unitNumber?: Maybe<Scalars["String"]["output"]>;
};

export type AddressInput = {
  fullAddress: Scalars["String"]["input"];
  postalCode: Scalars["String"]["input"];
  primary?: InputMaybe<Scalars["Boolean"]["input"]>;
  unitNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type AllClientReferral = {
  __typename?: "AllClientReferral";
  clientReferrals: Array<ClientReferral>;
  code?: Maybe<Scalars["String"]["output"]>;
  count: Scalars["Int"]["output"];
  url?: Maybe<Scalars["String"]["output"]>;
};

export type AllClients = {
  __typename?: "AllClients";
  clients: Array<Client>;
  count: Scalars["Int"]["output"];
};

export type AllNewRecurringClients = {
  __typename?: "AllNewRecurringClients";
  count: Scalars["Int"]["output"];
  newRecurringClients: Array<NewRecurringClient>;
};

export type AllNewRecurringClientsFilter = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  managerId?: InputMaybe<Scalars["ID"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<SortEnum>>;
  sortBy?: InputMaybe<Array<NewRecurringClientSortByEnum>>;
};

export type AllPackageDetails = {
  __typename?: "AllPackageDetails";
  count: Scalars["Int"]["output"];
  packageDetails: Array<PackageDetail>;
};

export type AllRatingHistory = {
  __typename?: "AllRatingHistory";
  count: Scalars["Int"]["output"];
  ratingHistories: Array<RatingHistory>;
};

export type AllWorkerReferral = {
  __typename?: "AllWorkerReferral";
  count: Scalars["Int"]["output"];
  workerReferrals: Array<WorkerReferral>;
};

export type AllWorkers = {
  __typename?: "AllWorkers";
  count: Scalars["Int"]["output"];
  workers: Array<Worker>;
};

export type AllWorkersFilter = {
  department?: InputMaybe<WorkerDepartmentEnum>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  managerId?: InputMaybe<Scalars["ID"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  sort?: InputMaybe<Array<SortEnum>>;
  sortBy?: InputMaybe<Array<WorkerSortByEnum>>;
  status?: InputMaybe<WorkerStatusEnum>;
  workerSkillIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

/** Possible associated user types */
export type AssociatedUser = Client | RegularUser | Worker;

export type Audit = {
  __typename?: "Audit";
  action: Scalars["String"]["output"];
  associated?: Maybe<Auditable>;
  associatedId?: Maybe<Scalars["String"]["output"]>;
  associatedType?: Maybe<Scalars["String"]["output"]>;
  auditDateTime?: Maybe<Scalars["DateTime"]["output"]>;
  auditable?: Maybe<Auditable>;
  auditableId: Scalars["String"]["output"];
  auditableType: Scalars["String"]["output"];
  auditedChangesJson: Scalars["String"]["output"];
  comment?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  user?: Maybe<RegularUser>;
  userFullname?: Maybe<Scalars["String"]["output"]>;
  version: Scalars["Int"]["output"];
};

/** Possible auditable types */
export type Auditable =
  | Address
  | BillingAccount
  | BillingCycle
  | BonusPay
  | ClassicIdentity
  | Client
  | ClientIntegration
  | ClientUser
  | Contact
  | Contract
  | CyclePeriod
  | Invoice
  | Job
  | LeaveApplication
  | OvertimePay
  | Package
  | PackageRequest
  | PackageRequestTask
  | PaymentTransaction
  | Payroll
  | PayrollAdjustment
  | Payslip
  | RegularUser
  | Task
  | Transaction
  | Visit
  | VisitBillingAdjustment
  | VisitPayrollAdjustment
  | Worker
  | WorkerAllowance
  | WorkerLeaveAccount
  | WorkerTask
  | WorkerUser;

export enum AuditableTypesEnum {
  Client = "CLIENT",
  Invoice = "INVOICE",
  Job = "JOB",
  Package = "PACKAGE",
  Visit = "VISIT",
  Worker = "WORKER",
  WorkerTask = "WORKER_TASK",
}

export type AuthIdentity = {
  user: User;
};

export type AverageByDay = {
  __typename?: "AverageByDay";
  averageByDay: Scalars["String"]["output"];
  fri: Scalars["Float"]["output"];
  mon: Scalars["Float"]["output"];
  sat: Scalars["Float"]["output"];
  sun: Scalars["Float"]["output"];
  thu: Scalars["Float"]["output"];
  tue: Scalars["Float"]["output"];
  wed: Scalars["Float"]["output"];
};

export type AverageByTime = {
  __typename?: "AverageByTime";
  afternoon: Scalars["Float"]["output"];
  averageByTime: Scalars["String"]["output"];
  evening: Scalars["Float"]["output"];
  morning: Scalars["Float"]["output"];
};

export type AverageOverall = {
  __typename?: "AverageOverall";
  averageOverall: Scalars["String"]["output"];
  total: Scalars["Float"]["output"];
};

export enum BankAccountEnum {
  Dbs = "DBS",
  Stripe = "STRIPE",
}

export enum BankNameEnum {
  Anz = "ANZ",
  BnpParibas = "BNPParibas",
  BangkokBank = "BangkokBank",
  BankofIndia = "BankofIndia",
  BankofSingapore = "BankofSingapore",
  Cimb = "CIMB",
  Citibank = "Citibank",
  DbsBank = "DBSBank",
  Hsbc = "HSBC",
  HongLeongFinance = "HongLeongFinance",
  IcbcBank = "ICBCBank",
  IcicBank = "ICICBank",
  IndianOverseasBank = "IndianOverseasBank",
  IslamicBankofAsia = "IslamicBankofAsia",
  JpMorgan = "JPMorgan",
  Maybank = "Maybank",
  Ocbc = "OCBC",
  RhbBank = "RHBBank",
  Sbi = "SBI",
  StandardChartered = "StandardChartered",
  TrustBank = "TrustBank",
  Uob = "UOB",
}

export type BillingAccount = {
  __typename?: "BillingAccount";
  balance: Scalars["Float"]["output"];
  client?: Maybe<Client>;
  id: Scalars["ID"]["output"];
  overdueAmount?: Maybe<Scalars["Float"]["output"]>;
  payrollAdjustments?: Maybe<Array<PayrollAdjustment>>;
  totalOverdueInvoice?: Maybe<Scalars["Int"]["output"]>;
  worker?: Maybe<Worker>;
};

export type BillingCycle = {
  __typename?: "BillingCycle";
  billingAccount?: Maybe<BillingAccount>;
  endDate?: Maybe<Scalars["Date"]["output"]>;
  id: Scalars["ID"]["output"];
  startDate: Scalars["Date"]["output"];
};

export enum BillingTypeEnum {
  Postpaid = "POSTPAID",
  Prepaid = "PREPAID",
}

export type BonusPay = {
  __typename?: "BonusPay";
  amount: Scalars["Float"]["output"];
  bonusType: BonusPayBonusTypeEnum;
  bonusTypeValue: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  totalVisit: Scalars["Float"]["output"];
  workerContractPayrollPolicy: WorkerContractPayrollPolicy;
};

export enum BonusPayBonusTypeEnum {
  Deduction = "DEDUCTION",
  DurationPerUnit = "DURATION_PER_UNIT",
  Hour = "HOUR",
  Price = "PRICE",
  Session = "SESSION",
  Unit = "UNIT",
}

export type BookingSuspended = {
  __typename?: "BookingSuspended";
  amountOverdueInvoices?: Maybe<Scalars["Float"]["output"]>;
  entity: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  totalOverdueInvoices?: Maybe<Scalars["Int"]["output"]>;
};

export type BooleanResult = {
  __typename?: "BooleanResult";
  result: Scalars["Boolean"]["output"];
};

export type BusyTimeslot = {
  __typename?: "BusyTimeslot";
  endAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  linkedObject?: Maybe<LinkedObject>;
  objectData?: Maybe<Scalars["JSON"]["output"]>;
  startAt: Scalars["DateTime"]["output"];
  worker: Worker;
};

export enum CancelVisitReasonEnum {
  CancelledByClient = "CANCELLED_BY_CLIENT",
  CancelledByOps = "CANCELLED_BY_OPS",
  CancelledByWorker = "CANCELLED_BY_WORKER",
  MissedByWorker = "MISSED_BY_WORKER",
  UnableToSchedule = "UNABLE_TO_SCHEDULE",
}

export type ChangeHistories = {
  __typename?: "ChangeHistories";
  audits: Array<Audit>;
  count: Scalars["Int"]["output"];
};

export type ChangeHistoriesFilter = {
  id: Scalars["ID"]["input"];
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<SortEnum>>;
  sortBy?: InputMaybe<Array<ChangeHistorySortByEnum>>;
  type: AuditableTypesEnum;
};

export enum ChangeHistorySortByEnum {
  CreatedAt = "created_at",
  Id = "id",
  UpdatedAt = "updated_at",
  Version = "version",
}

/** Result of success change package */
export type ChangePackageOk = {
  __typename?: "ChangePackageOk";
  package: Package;
};

export enum ChannelEnum {
  Email = "EMAIL",
  PushNotif = "PUSH_NOTIF",
  Sms = "SMS",
  Whatsapp = "WHATSAPP",
}

/** Classic identity information */
export type ClassicIdentity = AuthIdentity & {
  __typename?: "ClassicIdentity";
  email?: Maybe<Scalars["String"]["output"]>;
  emailConfirmed?: Maybe<Scalars["String"]["output"]>;
  user: User;
};

export type Client = {
  __typename?: "Client";
  accountType: ClientAccountTypeEnum;
  addresses: Array<Address>;
  billingAccount: BillingAccount;
  businessCentralId?: Maybe<Scalars["String"]["output"]>;
  contacts: Array<Contact>;
  contracts?: Maybe<Array<Contract>>;
  createdAt: Scalars["Date"]["output"];
  creditAccounts: Array<CreditAccount>;
  documents?: Maybe<Array<SavedFile>>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  notificationHistories?: Maybe<Array<NotificationHistory>>;
  packages?: Maybe<Array<Package>>;
  paymentMethods: Array<StripePaymentMethod>;
  preferences: Scalars["JSON"]["output"];
  profitabilityLtv: Scalars["Float"]["output"];
  profitabilityMonthlyValue: Scalars["Float"]["output"];
  status: ClientStatusEnum;
  user: ClientUser;
  xeroId?: Maybe<Scalars["String"]["output"]>;
};

export type ClientPackagesArgs = {
  status?: InputMaybe<PackageStatusEnum>;
};

export enum ClientAccountTypeEnum {
  Corporate = "CORPORATE",
  Individual = "INDIVIDUAL",
}

export type ClientAddresses = {
  __typename?: "ClientAddresses";
  addresses: Array<Address>;
  count: Scalars["Int"]["output"];
};

export type ClientAddressesFilterInput = {
  clientId: Scalars["ID"]["input"];
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The connection type for Client. */
export type ClientConnection = {
  __typename?: "ClientConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ClientEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Client>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ClientContacts = {
  __typename?: "ClientContacts";
  contacts: Array<Contact>;
  count: Scalars["Int"]["output"];
};

export type ClientContactsFilterInput = {
  clientId: Scalars["ID"]["input"];
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ClientConversation = {
  __typename?: "ClientConversation";
  author: ClientConversationAuthorEnum;
  authorName: Scalars["String"]["output"];
  body?: Maybe<Scalars["String"]["output"]>;
  conversationChannel: ClientConversationChannelEnum;
  createdAt: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  medias?: Maybe<Array<Scalars["String"]["output"]>>;
};

export enum ClientConversationAuthorEnum {
  Agent = "AGENT",
  Client = "CLIENT",
  System = "SYSTEM",
}

export enum ClientConversationChannelEnum {
  Call = "CALL",
  Email = "EMAIL",
  Whatsapp = "WHATSAPP",
}

export enum ClientConversationSortByEnum {
  CreatedAt = "created_at",
  DateCreated = "date_created",
  Id = "id",
}

export type ClientConversations = {
  __typename?: "ClientConversations";
  conversations: Array<ClientConversation>;
  count: Scalars["Int"]["output"];
};

export type ClientConversationsInput = {
  clientId: Scalars["ID"]["input"];
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<SortEnum>>;
  sortBy?: InputMaybe<Array<ClientConversationSortByEnum>>;
};

/** Result of success create reservation */
export type ClientCreateReservationOk = {
  __typename?: "ClientCreateReservationOk";
  reservations: Array<Reservation>;
};

export type ClientCrm = {
  __typename?: "ClientCrm";
  closeDate?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  lastActivityDate?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  owner?: Maybe<Scalars["String"]["output"]>;
  stage: Scalars["String"]["output"];
  type: ClientCrmTypeEnum;
  url: Scalars["String"]["output"];
};

export type ClientCrmByFilters = {
  __typename?: "ClientCrmByFilters";
  clientCrms: Array<ClientCrm>;
  count: Scalars["Int"]["output"];
};

export type ClientCrmByFiltersFilter = {
  clientId: Scalars["ID"]["input"];
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  query?: InputMaybe<Scalars["String"]["input"]>;
  stage?: InputMaybe<ClientCrmStageEnum>;
};

export enum ClientCrmStageEnum {
  All = "ALL",
  Closed = "CLOSED",
  Open = "OPEN",
}

export enum ClientCrmTypeEnum {
  Deal = "DEAL",
  Ticket = "TICKET",
}

/** An edge in a connection. */
export type ClientEdge = {
  __typename?: "ClientEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<Client>;
};

export type ClientFeedback = {
  __typename?: "ClientFeedback";
  client: Client;
  feedbackMsg?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  platform: UserPlatformEnum;
};

export type ClientFindPastWorkers = {
  __typename?: "ClientFindPastWorkers";
  count: Scalars["Int"]["output"];
  workers: Array<Worker>;
};

export type ClientFindPastWorkersInput = {
  clientId: Scalars["ID"]["input"];
  department?: InputMaybe<PackageDepartmentEnum>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ClientIntegration = {
  __typename?: "ClientIntegration";
  id: Scalars["ID"]["output"];
  integrationId?: Maybe<Scalars["String"]["output"]>;
  integrationParty: ClientIntegrationPartyTypeEnum;
  synced: Scalars["Boolean"]["output"];
};

export enum ClientIntegrationPartyTypeEnum {
  ActiveCampaign = "ACTIVE_CAMPAIGN",
  BusinessCentral = "BUSINESS_CENTRAL",
  Hubspot = "HUBSPOT",
  Stripe = "STRIPE",
  Xero = "XERO",
}

export type ClientInvoiceHistory = {
  __typename?: "ClientInvoiceHistory";
  items: Array<XeroHistoryItem>;
  outstandingAmount: Scalars["Float"]["output"];
};

export type ClientIssueDetails = {
  __typename?: "ClientIssueDetails";
  id: Scalars["ID"]["output"];
  issueType: IssueTypeEnum;
  name: Scalars["String"]["output"];
};

export type ClientNote = {
  __typename?: "ClientNote";
  body?: Maybe<Scalars["String"]["output"]>;
  client: Client;
  createdAt: Scalars["Date"]["output"];
  id: Scalars["ID"]["output"];
  updatedAt: Scalars["Date"]["output"];
  user: RegularUser;
};

export type ClientReferral = {
  __typename?: "ClientReferral";
  code: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  package?: Maybe<Package>;
  referralValue: Scalars["Float"]["output"];
  referred: Client;
  referredName: Scalars["String"]["output"];
  referrer: Client;
  status?: Maybe<ClientStatusReferralEnum>;
};

export type ClientReferralCode = {
  __typename?: "ClientReferralCode";
  code: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
};

export type ClientReferralFilter = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  referrerId?: InputMaybe<Scalars["ID"]["input"]>;
  sort?: InputMaybe<Array<SortEnum>>;
  sortBy?: InputMaybe<Array<ClientReferralSortByEnum>>;
  status?: InputMaybe<Array<ClientStatusReferralEnum>>;
};

export enum ClientReferralSortByEnum {
  UpdatedAt = "updated_at",
}

export type ClientReview = {
  __typename?: "ClientReview";
  clientLifetimeBooking?: Maybe<Scalars["Int"]["output"]>;
  clientName: Scalars["String"]["output"];
  department?: Maybe<PackageDepartmentEnum>;
  id: Scalars["ID"]["output"];
  rating: Scalars["Int"]["output"];
  ratingAttachment?: Maybe<Array<SavedFile>>;
  ratingComment?: Maybe<Scalars["String"]["output"]>;
  serviceDate?: Maybe<Scalars["Date"]["output"]>;
  serviceType?: Maybe<Array<PackageServiceTypeEnum>>;
};

export type ClientReviews = {
  __typename?: "ClientReviews";
  averageRating: Scalars["Float"]["output"];
  clientReviews: Array<ClientReview>;
  count: Scalars["Int"]["output"];
};

export type ClientReviewsInput = {
  department?: InputMaybe<PackageDepartmentEnum>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  rating?: InputMaybe<Scalars["Float"]["input"]>;
  serviceType?: InputMaybe<PackageServiceTypeEnum>;
  sort?: InputMaybe<Array<SortEnum>>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
};

export type ClientScheduleVariant = {
  __typename?: "ClientScheduleVariant";
  afternoon?: Maybe<Array<FreeTimeslot>>;
  allDay?: Maybe<Array<FreeTimeslot>>;
  day: Scalars["Int"]["output"];
  evening?: Maybe<Array<FreeTimeslot>>;
  morning?: Maybe<Array<FreeTimeslot>>;
};

export enum ClientSortByEnum {
  AccountType = "account_type",
  CreatedAt = "created_at",
  Name = "name",
  Status = "status",
  UpdatedAt = "updated_at",
}

export enum ClientStatusEnum {
  Active = "ACTIVE",
  Deleted = "DELETED",
  Inactive = "INACTIVE",
  Restricted = "RESTRICTED",
}

export enum ClientStatusReferralEnum {
  Paid = "PAID",
  Pending = "PENDING",
}

export type ClientUser = {
  __typename?: "ClientUser";
  email: Scalars["String"]["output"];
  emailVerifiedAt?: Maybe<Scalars["Date"]["output"]>;
  id: Scalars["ID"]["output"];
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
  phoneNumberVerifiedAt?: Maybe<Scalars["Date"]["output"]>;
};

export type ClientVoucher = {
  __typename?: "ClientVoucher";
  code: Scalars["String"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  discountType: VoucherDiscountTypeEnum;
  discountValue: Scalars["Int"]["output"];
  expiresInDays: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  masterVoucher: MasterVoucher;
  status: VoucherStatusEnum;
  validUntil: Scalars["Date"]["output"];
};

export type ClientsFilter = {
  accountType?: InputMaybe<ClientAccountTypeEnum>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  sort?: InputMaybe<Array<SortEnum>>;
  sortBy?: InputMaybe<Array<ClientSortByEnum>>;
  status?: InputMaybe<Array<ClientStatusEnum>>;
};

export type CohortData = {
  __typename?: "CohortData";
  _0?: Maybe<Scalars["Float"]["output"]>;
  _1?: Maybe<Scalars["Float"]["output"]>;
  _2?: Maybe<Scalars["Float"]["output"]>;
  _3?: Maybe<Scalars["Float"]["output"]>;
  _4?: Maybe<Scalars["Float"]["output"]>;
  _5?: Maybe<Scalars["Float"]["output"]>;
  _6?: Maybe<Scalars["Float"]["output"]>;
  _7?: Maybe<Scalars["Float"]["output"]>;
  _8?: Maybe<Scalars["Float"]["output"]>;
  _9?: Maybe<Scalars["Float"]["output"]>;
  _10?: Maybe<Scalars["Float"]["output"]>;
  _11?: Maybe<Scalars["Float"]["output"]>;
  month: Scalars["Int"]["output"];
  startMonth: Scalars["String"]["output"];
  totalNewClients?: Maybe<Scalars["Float"]["output"]>;
};

/** Confirmation of actions by user */
export type ConfirmationType = {
  confirmed?: InputMaybe<Scalars["Boolean"]["input"]>;
  key: Scalars["String"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
};

export type Contact = {
  __typename?: "Contact";
  client: Client;
  email: Array<Scalars["String"]["output"]>;
  firstName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  lastName: Scalars["String"]["output"];
  phoneNumber: Array<Scalars["String"]["output"]>;
  primary: Scalars["Boolean"]["output"];
};

export type ContactInput = {
  email: Array<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  phoneNumber: Array<Scalars["String"]["input"]>;
  primary?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Contract = {
  __typename?: "Contract";
  address: Address;
  autoConfirmInvoice: Scalars["Boolean"]["output"];
  autoSendInvoiceEmail: Scalars["Boolean"]["output"];
  billingTerms: ContractBillingTermsEnum;
  client: Client;
  contact: Contact;
  contractDocumentFile?: Maybe<SavedFile>;
  department?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  instructions?: Maybe<Scalars["String"]["output"]>;
  invoiceFrequency: ContractInvoiceFrequencyEnum;
  packageCodes?: Maybe<Array<Scalars["String"]["output"]>>;
  packages?: Maybe<Array<Package>>;
  poNumber?: Maybe<Scalars["String"]["output"]>;
  status: ContractStatusEnum;
};

export enum ContractBillingTermsEnum {
  Immediate = "IMMEDIATE",
  Net_7 = "NET_7",
  Net_14 = "NET_14",
  Net_15 = "NET_15",
  Net_30 = "NET_30",
  Net_45 = "NET_45",
  Net_60 = "NET_60",
  Net_90 = "NET_90",
}

export enum ContractInvoiceFrequencyEnum {
  PerMonth = "PER_MONTH",
  PerVisit = "PER_VISIT",
}

export enum ContractPayrollUnitEnum {
  Hourly = "HOURLY",
  Monthly = "MONTHLY",
}

export enum ContractStatusEnum {
  Active = "ACTIVE",
  Terminated = "TERMINATED",
}

export enum ContractTypeEnum {
  Casual = "CASUAL",
  Contract = "CONTRACT",
  FullTime = "FULL_TIME",
  Hourly = "HOURLY",
}

/** Result of success create package */
export type CreatePackageOk = {
  __typename?: "CreatePackageOk";
  package: Package;
};

export enum CreationReasonEnum {
  Migration = "MIGRATION",
  NewSale = "NEW_SALE",
  PackageUpdate = "PACKAGE_UPDATE",
}

/** Input for creation source */
export type CreationSourceInput = {
  device?: InputMaybe<UserDeviceEnum>;
  platform?: InputMaybe<UserPlatformEnum>;
};

export type CreationUserSource = {
  __typename?: "CreationUserSource";
  device: UserDeviceEnum;
  id: Scalars["ID"]["output"];
  linkedObject: UserLinkedObject;
  platform: UserPlatformEnum;
};

export type CreditAccount = {
  __typename?: "CreditAccount";
  balance: Scalars["Float"]["output"];
  client: Client;
  creditType: CreditTypeEnum;
  debitedAmount: Scalars["Float"]["output"];
  expirationDate?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  linkedObject?: Maybe<Package>;
  transactions: Array<CreditAccountTransaction>;
};

export enum CreditAccountSortByEnum {
  Balance = "balance",
  CreatedAt = "created_at",
  CreditType = "credit_type",
  ExpirationDate = "expiration_date",
  Id = "id",
}

export enum CreditAccountStatusEnum {
  Active = "ACTIVE",
  Expired = "EXPIRED",
}

export type CreditAccountTransaction = {
  __typename?: "CreditAccountTransaction";
  amount: Scalars["Float"]["output"];
  authorizedAt?: Maybe<Scalars["DateTime"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  invoice?: Maybe<Invoice>;
  status: CreditAccountTransactionStatusEnum;
  transactionType: CreditAccountTransactionTypeEnum;
};

export enum CreditAccountTransactionStatusEnum {
  Authorized = "AUTHORIZED",
  Expired = "EXPIRED",
  Pending = "PENDING",
  Void = "VOID",
}

export enum CreditAccountTransactionTypeEnum {
  CashWithdrawal = "CASH_WITHDRAWAL",
  CreditAbsorption = "CREDIT_ABSORPTION",
  InitialBalance = "INITIAL_BALANCE",
  Payment = "PAYMENT",
  Referral = "REFERRAL",
  Refund = "REFUND",
  Reward = "REWARD",
  ServiceCompensation = "SERVICE_COMPENSATION",
  TaxWriteOff = "TAX_WRITE_OFF",
  TopUp = "TOP_UP",
}

export type CreditAccountsByFilters = {
  __typename?: "CreditAccountsByFilters";
  count: Scalars["Int"]["output"];
  creditAccounts: Array<CreditAccount>;
};

export type CreditAccountsFilter = {
  clientId?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  creditType?: InputMaybe<Array<CreditTypeEnum>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<SortEnum>>;
  sortBy?: InputMaybe<Array<CreditAccountSortByEnum>>;
  status?: InputMaybe<Array<CreditAccountStatusEnum>>;
};

export enum CreditTypeEnum {
  General = "GENERAL",
  Package = "PACKAGE",
}

export type CsvErrors = {
  __typename?: "CsvErrors";
  error: Scalars["String"]["output"];
  row?: Maybe<Scalars["String"]["output"]>;
  rowIndex?: Maybe<Scalars["Int"]["output"]>;
};

export type CyclePeriod = {
  __typename?: "CyclePeriod";
  endDate: Scalars["Date"]["output"];
  id: Scalars["ID"]["output"];
  package: Package;
  startDate: Scalars["Date"]["output"];
  status: Scalars["String"]["output"];
};

/** Input for device-specific data */
export type DeviceInput = {
  appVersion?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  platform?: InputMaybe<Scalars["String"]["input"]>;
  platformVersion?: InputMaybe<Scalars["String"]["input"]>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
  uid?: InputMaybe<Scalars["String"]["input"]>;
};

export enum DiscountTypeEnum {
  Fixed = "FIXED",
  Percent = "PERCENT",
}

export enum EmailStatusEnum {
  Delivered = "DELIVERED",
  InProcess = "IN_PROCESS",
  NotDelivered = "NOT_DELIVERED",
  WaitingToBeSent = "WAITING_TO_BE_SENT",
}

export type EntityExistsProblem = {
  __typename?: "EntityExistsProblem";
  entity: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

/** Input for expo token */
export type ExpoTokenInput = {
  token?: InputMaybe<Scalars["String"]["input"]>;
};

export type FreeTimeslot = {
  __typename?: "FreeTimeslot";
  averageTravelTime: Scalars["Float"]["output"];
  discountValue?: Maybe<Scalars["Int"]["output"]>;
  duration: Scalars["Int"]["output"];
  endAt: Scalars["DateTime"]["output"];
  endDate: Scalars["Date"]["output"];
  endTime: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  nextTravelTime: Scalars["Float"]["output"];
  rateValue?: Maybe<Scalars["Int"]["output"]>;
  sessionValue?: Maybe<Scalars["Int"]["output"]>;
  startAt: Scalars["DateTime"]["output"];
  startDate: Scalars["Date"]["output"];
  startTime: Scalars["String"]["output"];
  startTravelTime: Scalars["Float"]["output"];
  worker: Worker;
};

export type GetWorkerTipInput = {
  department?: InputMaybe<WorkerDepartmentEnum>;
  nationality?: InputMaybe<NationalityEnum>;
};

/** Possible incentive object types */
export type IncentiveObject = MasterPromoCode | MasterVoucher;

export enum IncentiveTypesEnum {
  Credit = "CREDIT",
  PromoCode = "PROMO_CODE",
  Voucher = "VOUCHER",
}

/** Input for current installation (= device) */
export type InstallationInput = {
  /** Currently device info not stored anywhere on DB */
  device?: InputMaybe<DeviceInput>;
  pushNotificationTokens?: InputMaybe<PushNotificationTokensInput>;
};

/** Result of successful invite sent */
export type InviteUserOk = {
  __typename?: "InviteUserOk";
  email: Scalars["String"]["output"];
};

/** Result of inviteUser mutation */
export type InviteUserResult = InviteUserOk | ValidationProblem;

export type Invoice = {
  __typename?: "Invoice";
  amount: Scalars["Float"]["output"];
  appliedVoucher?: Maybe<ClientVoucher>;
  baseAmount: Scalars["Float"]["output"];
  billingAccount?: Maybe<BillingAccount>;
  billingCycle?: Maybe<BillingCycle>;
  clientPaymentIndicated?: Maybe<Scalars["DateTime"]["output"]>;
  contract?: Maybe<Contract>;
  dateOfPayment?: Maybe<Scalars["Date"]["output"]>;
  dueDate?: Maybe<Scalars["Date"]["output"]>;
  emailSendTime?: Maybe<Scalars["DateTime"]["output"]>;
  emailStatus?: Maybe<EmailStatusEnum>;
  fromDate: Scalars["Date"]["output"];
  id: Scalars["ID"]["output"];
  issueDate?: Maybe<Scalars["Date"]["output"]>;
  lastWorker?: Maybe<Worker>;
  lineItems?: Maybe<Array<InvoiceLineItem>>;
  paidAmount: Scalars["Float"]["output"];
  paymentProofFile?: Maybe<SavedFile>;
  paymentStatus: InvoicePaymentStatusEnum;
  pdfReceiptUrl?: Maybe<Scalars["String"]["output"]>;
  pdfUrl?: Maybe<Scalars["String"]["output"]>;
  status: InvoiceStatusEnum;
  toDate?: Maybe<Scalars["Date"]["output"]>;
  totalAmount: Scalars["Float"]["output"];
  transactions?: Maybe<Array<Transaction>>;
  whatsappNotificationSent: Scalars["Boolean"]["output"];
};

export type InvoiceErrors = {
  __typename?: "InvoiceErrors";
  error: Scalars["String"]["output"];
  id?: Maybe<Scalars["ID"]["output"]>;
};

export type InvoiceLineItem = {
  __typename?: "InvoiceLineItem";
  amount: Scalars["Float"]["output"];
  description: Scalars["String"]["output"];
  productCode: Scalars["String"]["output"];
  quantity: Scalars["Float"]["output"];
};

export enum InvoicePaymentStatusEnum {
  Paid = "PAID",
  StripeProcessing = "STRIPE_PROCESSING",
  Underpaid = "UNDERPAID",
  Unpaid = "UNPAID",
}

export enum InvoiceSortByEnum {
  Amount = "amount",
  BaseAmount = "base_amount",
  BillingAccountClientName = "billing_account_client_name",
  ContractId = "contract_id",
  CreatedAt = "created_at",
  DueDate = "due_date",
  Id = "id",
  IssueDate = "issue_date",
  PaymentStatus = "payment_status",
  UpdatedAt = "updated_at",
}

export enum InvoiceStatusEnum {
  Cancelled = "CANCELLED",
  Confirmed = "CONFIRMED",
  Deleted = "DELETED",
  New = "NEW",
}

export type InvoicesByContactNumberFilters = {
  contactNumber: Scalars["String"]["input"];
  fromDate?: InputMaybe<Scalars["Date"]["input"]>;
  paymentStatuses?: InputMaybe<Array<InvoicePaymentStatusEnum>>;
  toDate?: InputMaybe<Scalars["Date"]["input"]>;
};

export type InvoicesByFilters = {
  __typename?: "InvoicesByFilters";
  count: Scalars["Int"]["output"];
  invoices: Array<Invoice>;
};

export type InvoicesFilter = {
  clientId?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  department?: InputMaybe<PackageDepartmentEnum>;
  emailStatus?: InputMaybe<EmailStatusEnum>;
  fromDate?: InputMaybe<Scalars["Date"]["input"]>;
  fromDateFrom?: InputMaybe<Scalars["Date"]["input"]>;
  fromDateTo?: InputMaybe<Scalars["Date"]["input"]>;
  issueDateFrom?: InputMaybe<Scalars["Date"]["input"]>;
  issueDateTo?: InputMaybe<Scalars["Date"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  managerId?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  overdueDaysFrom?: InputMaybe<Scalars["Int"]["input"]>;
  overdueDaysTo?: InputMaybe<Scalars["Int"]["input"]>;
  paymentProofStatus?: InputMaybe<PaymentProofStatusEnum>;
  paymentStatus?: InputMaybe<Array<InvoicePaymentStatusEnum>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  sort?: InputMaybe<Array<SortEnum>>;
  sortBy?: InputMaybe<Array<InvoiceSortByEnum>>;
  status?: InputMaybe<Array<InvoiceStatusEnum>>;
  toDate?: InputMaybe<Scalars["Date"]["input"]>;
  toDateFrom?: InputMaybe<Scalars["Date"]["input"]>;
  toDateTo?: InputMaybe<Scalars["Date"]["input"]>;
};

export type InvoicingInput = {
  autoConfirmInvoice: Scalars["Boolean"]["input"];
  autoSendInvoiceEmail: Scalars["Boolean"]["input"];
  billingTerms: ContractBillingTermsEnum;
  invoiceFrequency: ContractInvoiceFrequencyEnum;
  purchaseOrderNumber?: InputMaybe<Scalars["String"]["input"]>;
  specialInvoicingInstructions?: InputMaybe<Scalars["String"]["input"]>;
};

export enum IssueTypeEnum {
  BookingComment = "BOOKING_COMMENT",
  CleanerReplacement = "CLEANER_REPLACEMENT",
  General = "GENERAL",
  PackageTermination = "PACKAGE_TERMINATION",
  PackageUpdate = "PACKAGE_UPDATE",
  ReviewSolicitation = "REVIEW_SOLICITATION",
  UnscheduledVisit = "UNSCHEDULED_VISIT",
  VisitReschedule = "VISIT_RESCHEDULE",
  Warranty = "WARRANTY",
}

export type Job = {
  __typename?: "Job";
  allowedActions?: Maybe<Array<JobActionsEnum>>;
  endDate?: Maybe<Scalars["Date"]["output"]>;
  id: Scalars["ID"]["output"];
  package: Package;
  scheduleType: JobScheduleTypeEnum;
  startDate: Scalars["Date"]["output"];
  status: JobStatusEnum;
  tasks: Array<Task>;
  updatedAt: Scalars["DateTime"]["output"];
  visits?: Maybe<Array<Visit>>;
  worker?: Maybe<Worker>;
  workerContract?: Maybe<WorkerContract>;
};

export type JobVisitsArgs = {
  from: Scalars["Date"]["input"];
  status?: InputMaybe<VisitStatusEnum>;
  to: Scalars["Date"]["input"];
};

export enum JobActionsEnum {
  Delete = "DELETE",
  Terminate = "TERMINATE",
}

export enum JobFrequencyEnum {
  AdHoc = "AD_HOC",
  Fortnight = "FORTNIGHT",
  Week = "WEEK",
}

export type JobInput = {
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  startDate: Scalars["Date"]["input"];
  tasks: Array<JobTaskInput>;
  workerContractId?: InputMaybe<Scalars["ID"]["input"]>;
  workerId?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum JobScheduleTypeEnum {
  OneTime = "ONE_TIME",
  Recurrent = "RECURRENT",
}

export enum JobSortByEnum {
  CreatedAt = "created_at",
  EndDate = "end_date",
  ScheduleType = "schedule_type",
  StartDate = "start_date",
  Status = "status",
  UpdatedAt = "updated_at",
}

export enum JobStatusEnum {
  Assigned = "ASSIGNED",
  Unassigned = "UNASSIGNED",
}

export type JobTaskInput = {
  id: Scalars["ID"]["input"];
};

export type JobsByFilters = {
  __typename?: "JobsByFilters";
  count: Scalars["Int"]["output"];
  jobs: Array<Job>;
};

export type JobsFilter = {
  department?: InputMaybe<PackageDepartmentEnum>;
  endDateFrom?: InputMaybe<Scalars["Date"]["input"]>;
  endDateTo?: InputMaybe<Scalars["Date"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  managerId?: InputMaybe<Scalars["ID"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  scheduleType?: InputMaybe<Array<JobScheduleTypeEnum>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  serviceType?: InputMaybe<Array<PackageServiceTypeEnum>>;
  sort?: InputMaybe<Array<SortEnum>>;
  sortBy?: InputMaybe<Array<JobSortByEnum>>;
  startDateFrom?: InputMaybe<Scalars["Date"]["input"]>;
  startDateTo?: InputMaybe<Scalars["Date"]["input"]>;
  status?: InputMaybe<Array<JobStatusEnum>>;
  workerId?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export enum LanguageSpokenEnum {
  BahasaIndonesia = "BahasaIndonesia",
  Bengali = "Bengali",
  Burmese = "Burmese",
  Chinese = "Chinese",
  English = "English",
  Hindi = "Hindi",
  Malay = "Malay",
  Punjabi = "Punjabi",
  Sinhala = "Sinhala",
  Tamil = "Tamil",
  Telagu = "Telagu",
  Vietnamese = "Vietnamese",
}

export type LeaveApplication = {
  __typename?: "LeaveApplication";
  affectedVisits?: Maybe<Array<Visit>>;
  createdAt: Scalars["DateTime"]["output"];
  currentZone?: Maybe<Scalars["String"]["output"]>;
  endDate: Scalars["DateTime"]["output"];
  endDateUtc: Scalars["DateTime"]["output"];
  endTime: Scalars["String"]["output"];
  hoursApplied: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  leaveEndDate: Scalars["Date"]["output"];
  leaveStartDate: Scalars["Date"]["output"];
  leaveType: LeaveTypeEnum;
  reason?: Maybe<Scalars["String"]["output"]>;
  startDate: Scalars["DateTime"]["output"];
  startDateUtc: Scalars["DateTime"]["output"];
  startTime: Scalars["String"]["output"];
  status: LeaveApplicationStatusEnum;
  totalAffectedVisits?: Maybe<Scalars["Int"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  worker: Worker;
};

export type LeaveApplicationAffectedVisitsArgs = {
  stage?: InputMaybe<LeaveApplicationStageEnum>;
};

export type LeaveApplicationTotalAffectedVisitsArgs = {
  stage?: InputMaybe<LeaveApplicationStageEnum>;
};

export enum LeaveApplicationSortByEnum {
  CreatedAt = "created_at",
  EndDate = "end_date",
  HoursApplied = "hours_applied",
  LeaveType = "leave_type",
  StartDate = "start_date",
  Status = "status",
  UpdatedAt = "updated_at",
  WorkerFirstName = "worker_first_name",
  WorkerLastName = "worker_last_name",
}

export enum LeaveApplicationStageEnum {
  History = "HISTORY",
  Requested = "REQUESTED",
}

export enum LeaveApplicationStatusEnum {
  Approved = "APPROVED",
  Cancelled = "CANCELLED",
  Rejected = "REJECTED",
  Submitted = "SUBMITTED",
}

export type LeaveApplicationsByFilters = {
  __typename?: "LeaveApplicationsByFilters";
  count: Scalars["Int"]["output"];
  leaves: Array<LeaveApplication>;
};

export type LeaveApplicationsFilter = {
  department?: InputMaybe<Array<WorkerDepartmentEnum>>;
  hoursApplied?: InputMaybe<Scalars["Float"]["input"]>;
  leaveType?: InputMaybe<Array<LeaveTypeEnum>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  managerIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  nationality?: InputMaybe<Array<NationalityEnum>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  sort?: InputMaybe<Array<SortEnum>>;
  sortBy?: InputMaybe<Array<LeaveApplicationSortByEnum>>;
  startDateFrom?: InputMaybe<Scalars["Date"]["input"]>;
  startDateTo?: InputMaybe<Scalars["Date"]["input"]>;
  status?: InputMaybe<Array<LeaveApplicationStatusEnum>>;
  workerIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export enum LeaveTypeEnum {
  Al = "AL",
  Cl = "CL",
  Hl = "HL",
  Npl = "NPL",
  Sl = "SL",
}

export type LifeBarChartData = {
  __typename?: "LifeBarChartData";
  dormant: Scalars["Int"]["output"];
  month: Scalars["String"]["output"];
  new: Scalars["Int"]["output"];
  resurrected: Scalars["Int"]["output"];
  retained: Scalars["Int"]["output"];
};

export type LineItemInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  lineItemId: Scalars["ID"]["input"];
  unitValue: Scalars["Float"]["input"];
  units: Scalars["Int"]["input"];
};

/** linked object for busy timeslot */
export type LinkedObject = Visit | WorkerContract;

export type Manager = {
  __typename?: "Manager";
  hubspotId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  lastWorkingDay?: Maybe<Scalars["Date"]["output"]>;
  managerRole: ManagerRole;
  opsExecCode?: Maybe<Scalars["String"]["output"]>;
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
  status: ManagerStatusEnum;
  user: RegularUser;
};

export type ManagerByFilters = {
  __typename?: "ManagerByFilters";
  count: Scalars["Int"]["output"];
  managers: Array<Manager>;
};

export type ManagerByFiltersInput = {
  role?: InputMaybe<ManagerRoleEnum>;
  status?: InputMaybe<Array<ManagerStatusEnum>>;
};

export type ManagerRole = {
  __typename?: "ManagerRole";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type ManagerRoleByFilters = {
  __typename?: "ManagerRoleByFilters";
  count: Scalars["Int"]["output"];
  managerRoles: Array<ManagerRole>;
};

export type ManagerRoleByFiltersInput = {
  name?: InputMaybe<ManagerRoleEnum>;
};

export enum ManagerRoleEnum {
  OpsExecutive = "OPS_EXECUTIVE",
  SuperAdmin = "SUPER_ADMIN",
}

export enum ManagerStatusEnum {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
  PendingOffboarding = "PENDING_OFFBOARDING",
}

export type MasterNotification = {
  __typename?: "MasterNotification";
  category: MasterNotificationCategoryEnum;
  channel: ChannelEnum;
  id: Scalars["ID"]["output"];
  intent: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  notificationTemplates: Array<NotificationTemplate>;
  receiver: ReceiverEnum;
  relatedObject: RelatedObjectEnum;
  subCategory: Scalars["String"]["output"];
  trigger: TriggerEnum;
};

export type MasterNotificationByFilter = {
  __typename?: "MasterNotificationByFilter";
  count: Scalars["Int"]["output"];
  masterNotifications: Array<MasterNotification>;
};

export enum MasterNotificationCategoryEnum {
  Authentication = "AUTHENTICATION",
  Booking = "BOOKING",
  FeedbackAndReview = "FEEDBACK_AND_REVIEW",
  Invitation = "INVITATION",
  Invoice = "INVOICE",
  NewUser = "NEW_USER",
  PackageAndVisit = "PACKAGE_AND_VISIT",
  Payment = "PAYMENT",
  Payroll = "PAYROLL",
  Referral = "REFERRAL",
  Reward = "REWARD",
  SpecialOfferAndPromotion = "SPECIAL_OFFER_AND_PROMOTION",
  Visits = "VISITS",
  WorkerUpselling = "WORKER_UPSELLING",
}

export type MasterNotificationFilter = {
  channel?: InputMaybe<ChannelEnum>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  receiver?: InputMaybe<ReceiverEnum>;
  relatedObject?: InputMaybe<Scalars["String"]["input"]>;
  sort?: InputMaybe<Array<SortEnum>>;
  sortBy?: InputMaybe<Array<NotificationSortByEnum>>;
};

export type MasterPromoCode = {
  __typename?: "MasterPromoCode";
  createdAt: Scalars["DateTime"]["output"];
  departments?: Maybe<Array<PackageDepartmentEnum>>;
  discountType: DiscountTypeEnum;
  discountValue: Scalars["Float"]["output"];
  firstTransactionOnly: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  promoCode: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  validUntil?: Maybe<Scalars["DateTime"]["output"]>;
};

export type MasterPromoCodeByFilters = {
  __typename?: "MasterPromoCodeByFilters";
  count: Scalars["Int"]["output"];
  masterPromoCodes: Array<MasterPromoCode>;
};

export type MasterPromoCodeFilter = {
  discountType?: InputMaybe<DiscountTypeEnum>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  promoCode?: InputMaybe<Scalars["String"]["input"]>;
  sort?: InputMaybe<Array<SortEnum>>;
  sortBy?: InputMaybe<Array<SortByEnum>>;
};

export type MasterReward = {
  __typename?: "MasterReward";
  creditValue: Scalars["Float"]["output"];
  defaultServiceUsage?: Maybe<PackageDepartmentEnum>;
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  incentiveObject?: Maybe<IncentiveObject>;
  incentiveType?: Maybe<IncentiveTypesEnum>;
  name: Scalars["String"]["output"];
  status?: Maybe<MasterRewardStatusEnum>;
  validDuration?: Maybe<Scalars["Int"]["output"]>;
  validFrom: Scalars["Date"]["output"];
  validTo?: Maybe<Scalars["Date"]["output"]>;
};

export enum MasterRewardSortByEnum {
  CreatedAt = "created_at",
  Name = "name",
  Status = "status",
  UpdatedAt = "updated_at",
}

export enum MasterRewardStatusEnum {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export type MasterRewardsByFilters = {
  __typename?: "MasterRewardsByFilters";
  count: Scalars["Int"]["output"];
  masterRewards: Array<MasterReward>;
};

export type MasterRewardsFilter = {
  incentiveType?: InputMaybe<IncentiveTypesEnum>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  sort?: InputMaybe<Array<SortEnum>>;
  sortBy?: InputMaybe<Array<MasterRewardSortByEnum>>;
  status?: InputMaybe<Array<MasterRewardStatusEnum>>;
};

export type MasterVoucher = {
  __typename?: "MasterVoucher";
  code: Scalars["String"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  discountType: Scalars["String"]["output"];
  discountValue: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  maxDiscountValue?: Maybe<Scalars["Float"]["output"]>;
  validityPeriod: Scalars["Int"]["output"];
};

export type MobileAppVersion = {
  __typename?: "MobileAppVersion";
  /** Current Client app build number */
  clientAppBuildNumber: Scalars["Int"]["output"];
  /** Current Worker app build number */
  workerAppBuildNumber: Scalars["Int"]["output"];
};

export type Mutation = {
  __typename?: "Mutation";
  acknowledgePayroll?: Maybe<AcknowledgePayrollPayload>;
  addClientDocuments?: Maybe<AddClientDocumentsPayload>;
  addContractDocument?: Maybe<AddContractDocumentPayload>;
  addPaymentProof?: Maybe<AddPaymentProofPayload>;
  addPortfolios?: Maybe<AddPortfoliosPayload>;
  addProofOfAgreements?: Maybe<AddProofOfAgreementsPayload>;
  addStaffReviewAttachment?: Maybe<AddStaffReviewAttachmentPayload>;
  addVisitLineItem?: Maybe<AddVisitLineItemPayload>;
  addVisitNoteAttachments?: Maybe<AddVisitNoteAttachmentsPayload>;
  addWorkerDocument?: Maybe<AddWorkerDocumentPayload>;
  addWorkerDocumentFiles?: Maybe<AddWorkerDocumentFilesPayload>;
  applyPayroll?: Maybe<ApplyPayrollPayload>;
  approveLeaveApplication?: Maybe<ApproveLeaveApplicationPayload>;
  assignJob?: Maybe<AssignJobPayload>;
  assignPackageRequest?: Maybe<AssignPackageRequestPayload>;
  assignTasks?: Maybe<AssignTasksPayload>;
  assignVisit?: Maybe<AssignVisitPayload>;
  cancelLeaveApplication?: Maybe<CancelLeaveApplicationPayload>;
  cancelVisit?: Maybe<CancelVisitPayload>;
  cancelVisitWithToken?: Maybe<CancelVisitWithTokenPayload>;
  changePackage?: Maybe<ChangePackagePayload>;
  claimReward?: Maybe<ClaimRewardPayload>;
  clientApplyCredit?: Maybe<ClientApplyCreditPayload>;
  clientApplyVoucher?: Maybe<ClientApplyVoucherPayload>;
  clientCancelReservation?: Maybe<ClientCancelReservationPayload>;
  clientCancelVisit?: Maybe<ClientCancelVisitPayload>;
  clientConfirmPhoneNumber?: Maybe<ClientConfirmPhoneNumberPayload>;
  clientConfirmReservation?: Maybe<ClientConfirmReservationPayload>;
  clientCreateReservation?: Maybe<ClientCreateReservationPayload>;
  clientRemoveVoucher?: Maybe<ClientRemoveVoucherPayload>;
  clientRescheduleVisit?: Maybe<ClientRescheduleVisitPayload>;
  clientRescheduleVisitWithToken?: Maybe<ClientRescheduleVisitWithTokenPayload>;
  /** validates client promo code */
  clientValidatePromoCode?: Maybe<ClientValidatePromoCodePayload>;
  /** Sends an pin to the client phone for verification */
  clientVerifyPhoneNumber?: Maybe<ClientVerifyPhoneNumberPayload>;
  completeVisit?: Maybe<CompleteVisitPayload>;
  /** Complete worker task */
  completeWorkerTask?: Maybe<CompleteWorkerTaskPayload>;
  confirmInvoice?: Maybe<ConfirmInvoicePayload>;
  confirmInvoiceBatch?: Maybe<ConfirmInvoiceBatchPayload>;
  confirmPackageRequest?: Maybe<ConfirmPackageRequestPayload>;
  confirmPayroll?: Maybe<ConfirmPayrollPayload>;
  confirmPayrollBatch?: Maybe<ConfirmPayrollBatchPayload>;
  convertBankStatementCsv?: Maybe<ConvertBankStatementCsvPayload>;
  /** Create single address */
  createAddress?: Maybe<CreateAddressPayload>;
  /** Create addresses in batch */
  createAddressBatch?: Maybe<CreateAddressBatchPayload>;
  createAdhocPackage?: Maybe<CreateAdhocPackagePayload>;
  createBookingRequest?: Maybe<CreateBookingRequestPayload>;
  /** Create single client */
  createClient?: Maybe<CreateClientPayload>;
  createClientFeedback?: Maybe<CreateClientFeedbackPayload>;
  createClientIssueRequest?: Maybe<CreateClientIssueRequestPayload>;
  createClientIssueRequestWithToken?: Maybe<CreateClientIssueRequestWithTokenPayload>;
  /** Create single client note */
  createClientNote?: Maybe<CreateClientNotePayload>;
  createClientVoucher?: Maybe<CreateClientVoucherPayload>;
  /** Create single client contact */
  createContact?: Maybe<CreateContactPayload>;
  /** Create client contacts in batch */
  createContactBatch?: Maybe<CreateContactBatchPayload>;
  createContract?: Maybe<CreateContractPayload>;
  createFinalPayroll?: Maybe<CreateFinalPayrollPayload>;
  createJob?: Maybe<CreateJobPayload>;
  createLeaveApplication?: Maybe<CreateLeaveApplicationPayload>;
  createMasterPromoCode?: Maybe<CreateMasterPromoCodePayload>;
  createPackage?: Maybe<CreatePackagePayload>;
  createPackageIssueRequest?: Maybe<CreatePackageIssueRequestPayload>;
  createPackageRequest?: Maybe<CreatePackageRequestPayload>;
  /** Create new payment method for client */
  createPaymentMethod?: Maybe<CreatePaymentMethodPayload>;
  createPayrollAdjustment?: Maybe<CreatePayrollAdjustmentPayload>;
  createPromotion?: Maybe<CreatePromotionPayload>;
  createRentalPackage?: Maybe<CreateRentalPackagePayload>;
  createStripeSetupIntent?: Maybe<CreateStripeSetupIntentPayload>;
  createVisitBillingAdjustment?: Maybe<CreateVisitBillingAdjustmentPayload>;
  createVisitPayrollAdjustment?: Maybe<CreateVisitPayrollAdjustmentPayload>;
  createWorker?: Maybe<CreateWorkerPayload>;
  createWorkerAttendance?: Maybe<CreateWorkerAttendancePayload>;
  createWorkerContract?: Maybe<CreateWorkerContractPayload>;
  createWorkerContractPayrollPolicy?: Maybe<CreateWorkerContractPayrollPolicyPayload>;
  /** Create a new worker task */
  createWorkerTask?: Maybe<CreateWorkerTaskPayload>;
  createWorkerTip?: Maybe<CreateWorkerTipPayload>;
  /** Delete single client address, by given id */
  deleteAddress?: Maybe<DeleteAddressPayload>;
  /** Delete client addresses by list of ids */
  deleteAddressBatch?: Maybe<DeleteAddressBatchPayload>;
  /** Delete client by given id */
  deleteClient?: Maybe<DeleteClientPayload>;
  deleteClientDocuments?: Maybe<DeleteClientDocumentsPayload>;
  /** Delete client note by given id */
  deleteClientNote?: Maybe<DeleteClientNotePayload>;
  deleteClientVoucher?: Maybe<DeleteClientVoucherPayload>;
  /** Delete client contact by given id */
  deleteContact?: Maybe<DeleteContactPayload>;
  /** Delete client contacts by given list of ids */
  deleteContactBatch?: Maybe<DeleteContactBatchPayload>;
  deleteContract?: Maybe<DeleteContractPayload>;
  deleteInvoice?: Maybe<DeleteInvoicePayload>;
  deleteJob?: Maybe<DeleteJobPayload>;
  deleteLeaveApplication?: Maybe<DeleteLeaveApplicationPayload>;
  deletePackage?: Maybe<DeletePackagePayload>;
  deletePackageRequest?: Maybe<DeletePackageRequestPayload>;
  /** Delete existing payment method */
  deletePaymentMethod?: Maybe<DeletePaymentMethodPayload>;
  deletePayrollAdjustment?: Maybe<DeletePayrollAdjustmentPayload>;
  deletePortfolios?: Maybe<DeletePortfoliosPayload>;
  deletePromotion?: Maybe<DeletePromotionPayload>;
  deleteProofOfAgreements?: Maybe<DeleteProofOfAgreementsPayload>;
  deleteStaffReviewAttachment?: Maybe<DeleteStaffReviewAttachmentPayload>;
  deleteTransaction?: Maybe<DeleteTransactionPayload>;
  deleteVisitBillingAdjustment?: Maybe<DeleteVisitBillingAdjustmentPayload>;
  deleteVisitLineItem?: Maybe<DeleteVisitLineItemPayload>;
  deleteVisitPayrollAdjustment?: Maybe<DeleteVisitPayrollAdjustmentPayload>;
  deleteWorker?: Maybe<DeleteWorkerPayload>;
  deleteWorkerAttendance?: Maybe<DeleteWorkerAttendancePayload>;
  deleteWorkerContract?: Maybe<DeleteWorkerContractPayload>;
  deleteWorkerDocument?: Maybe<DeleteWorkerDocumentPayload>;
  deleteWorkerDocumentFiles?: Maybe<DeleteWorkerDocumentFilesPayload>;
  /** Delete worker task */
  deleteWorkerTask?: Maybe<DeleteWorkerTaskPayload>;
  deleteWorkerTip?: Maybe<DeleteWorkerTipPayload>;
  finishVisit?: Maybe<FinishVisitPayload>;
  generateInvoiceCsv?: Maybe<GenerateInvoiceCsvPayload>;
  generateInvoicePdf?: Maybe<GenerateInvoicePdfPayload>;
  generateInvoiceReceiptPdf?: Maybe<GenerateInvoiceReceiptPdfPayload>;
  generateOutstandingPayrollsCsv?: Maybe<GenerateOutstandingPayrollsCsvPayload>;
  generatePayrollCsv?: Maybe<GeneratePayrollCsvPayload>;
  /** Sends an email with an invitation */
  inviteUser?: Maybe<InviteUserPayload>;
  /** Pay invoice with Stripe */
  payInvoice?: Maybe<PayInvoicePayload>;
  rateVisit?: Maybe<RateVisitPayload>;
  rateVisitWithToken?: Maybe<RateVisitWithTokenPayload>;
  readNotification?: Maybe<ReadNotificationPayload>;
  regenerateInvoice?: Maybe<RegenerateInvoicePayload>;
  regeneratePayroll?: Maybe<RegeneratePayrollPayload>;
  rejectLeaveApplication?: Maybe<RejectLeaveApplicationPayload>;
  rejectPackageRequest?: Maybe<RejectPackageRequestPayload>;
  removePaymentProof?: Maybe<RemovePaymentProofPayload>;
  removeVisitNoteAttachments?: Maybe<RemoveVisitNoteAttachmentsPayload>;
  rescheduleVisitWithToken?: Maybe<RescheduleVisitWithTokenPayload>;
  /** Resets a classic user password using his email */
  resetPasswordFromEmail?: Maybe<ResetPasswordFromEmailPayload>;
  /** Resets a classic user password using his phone number */
  resetPasswordFromPhone?: Maybe<ResetPasswordFromPhonePayload>;
  restoreLeaveApplication?: Maybe<RestoreLeaveApplicationPayload>;
  restoreVisit?: Maybe<RestoreVisitPayload>;
  restoreWorkerTask?: Maybe<RestoreWorkerTaskPayload>;
  sendInvoiceEmail?: Maybe<SendInvoiceEmailPayload>;
  /** Sends an pin to the user phone */
  sendPinCodeToPhone?: Maybe<SendPinCodeToPhonePayload>;
  sendReferralInvitation?: Maybe<SendReferralInvitationPayload>;
  /** Sends an email with the password reset pin to the user */
  sendResetPassword?: Maybe<SendResetPasswordPayload>;
  /** send verification email with code */
  sendVerificationUser?: Maybe<SendVerificationUserPayload>;
  /** Sign in */
  signInClassic?: Maybe<SignInClassicPayload>;
  /** Sign in */
  signInClient?: Maybe<SignInClientPayload>;
  signInClientSocialAccount?: Maybe<SignInClientSocialAccountPayload>;
  /** Sign in */
  signInWithPhone?: Maybe<SignInWithPhonePayload>;
  /** Sign out */
  signOut?: Maybe<SignOutPayload>;
  /** Sign up */
  signUp?: Maybe<SignUpPayload>;
  startVisit?: Maybe<StartVisitPayload>;
  terminateJob?: Maybe<TerminateJobPayload>;
  terminatePackage?: Maybe<TerminatePackagePayload>;
  terminateWorker?: Maybe<TerminateWorkerPayload>;
  terminateWorkerContract?: Maybe<TerminateWorkerContractPayload>;
  /** Create new payment method for client */
  tokenizePaymentMethod?: Maybe<TokenizePaymentMethodPayload>;
  /** update account phone number */
  updateAccountPhoneNumber?: Maybe<UpdateAccountPhoneNumberPayload>;
  /** Update client address by given id */
  updateAddress?: Maybe<UpdateAddressPayload>;
  /** Update client addresses by given list of id and updates */
  updateAddressBatch?: Maybe<UpdateAddressBatchPayload>;
  /** Update client by given argument */
  updateClient?: Maybe<UpdateClientPayload>;
  /** Update client note by given argument */
  updateClientNote?: Maybe<UpdateClientNotePayload>;
  /** Update password client from internal admin app */
  updateClientPassword?: Maybe<UpdateClientPasswordPayload>;
  /** update client's preferences */
  updateClientPreferences?: Maybe<UpdateClientPreferencesPayload>;
  updateClientVoucher?: Maybe<UpdateClientVoucherPayload>;
  /** Update client contact by given id */
  updateContact?: Maybe<UpdateContactPayload>;
  /** Update client contacts in batch */
  updateContactBatch?: Maybe<UpdateContactBatchPayload>;
  updateContract?: Maybe<UpdateContractPayload>;
  /** Updates current installation for the user */
  updateInstallation?: Maybe<UpdateInstallationPayload>;
  updateLeaveApplication?: Maybe<UpdateLeaveApplicationPayload>;
  updateManager?: Maybe<UpdateManagerPayload>;
  updateMasterPromoCode?: Maybe<UpdateMasterPromoCodePayload>;
  updatePackage?: Maybe<UpdatePackagePayload>;
  updatePackageJobs?: Maybe<UpdatePackageJobsPayload>;
  updatePaymentIndicated?: Maybe<UpdatePaymentIndicatedPayload>;
  /** Update existing payment method */
  updatePaymentMethod?: Maybe<UpdatePaymentMethodPayload>;
  updatePayrollAdjustment?: Maybe<UpdatePayrollAdjustmentPayload>;
  updatePromotion?: Maybe<UpdatePromotionPayload>;
  /** Updates a user email */
  updateUserEmail?: Maybe<UpdateUserEmailPayload>;
  /** Updates a user password */
  updateUserPassword?: Maybe<UpdateUserPasswordPayload>;
  /** Updates viewer profile */
  updateViewer?: Maybe<UpdateViewerPayload>;
  updateVisit?: Maybe<UpdateVisitPayload>;
  updateVisitBillingAdjustment?: Maybe<UpdateVisitBillingAdjustmentPayload>;
  updateVisitLineItem?: Maybe<UpdateVisitLineItemPayload>;
  updateVisitLineItems?: Maybe<UpdateVisitLineItemsPayload>;
  updateVisitPayrollAdjustment?: Maybe<UpdateVisitPayrollAdjustmentPayload>;
  updateWorker?: Maybe<UpdateWorkerPayload>;
  updateWorkerContract?: Maybe<UpdateWorkerContractPayload>;
  updateWorkerContractDay?: Maybe<UpdateWorkerContractDayPayload>;
  updateWorkerContractSkills?: Maybe<UpdateWorkerContractSkillsPayload>;
  updateWorkerDocument?: Maybe<UpdateWorkerDocumentPayload>;
  updateWorkerPreferences?: Maybe<UpdateWorkerPreferencesPayload>;
  /** Update existing worker task */
  updateWorkerTask?: Maybe<UpdateWorkerTaskPayload>;
  updateWorkerTip?: Maybe<UpdateWorkerTipPayload>;
  uploadOutstandingPayrollsCsv?: Maybe<UploadOutstandingPayrollsCsvPayload>;
  verificationAddressWorker?: Maybe<VerificationAddressWorkerPayload>;
  /** verify user email */
  verifyUser?: Maybe<VerifyUserPayload>;
  withdrawCredit?: Maybe<WithdrawCreditPayload>;
  withdrawCreditAuthorized?: Maybe<WithdrawCreditAuthorizedPayload>;
  workerConfirmReservation?: Maybe<WorkerConfirmReservationPayload>;
  workerCreateReservation?: Maybe<WorkerCreateReservationPayload>;
};

export type MutationAcknowledgePayrollArgs = {
  input: AcknowledgePayrollInput;
};

export type MutationAddClientDocumentsArgs = {
  input: AddClientDocumentsInput;
};

export type MutationAddContractDocumentArgs = {
  input: AddContractDocumentInput;
};

export type MutationAddPaymentProofArgs = {
  input: AddPaymentProofInput;
};

export type MutationAddPortfoliosArgs = {
  input: AddPortfoliosInput;
};

export type MutationAddProofOfAgreementsArgs = {
  input: AddProofOfAgreementsInput;
};

export type MutationAddStaffReviewAttachmentArgs = {
  input: AddStaffReviewAttachmentInput;
};

export type MutationAddVisitLineItemArgs = {
  input: AddVisitLineItemInput;
};

export type MutationAddVisitNoteAttachmentsArgs = {
  input: AddVisitNoteAttachmentsInput;
};

export type MutationAddWorkerDocumentArgs = {
  input: AddWorkerDocumentInput;
};

export type MutationAddWorkerDocumentFilesArgs = {
  input: AddWorkerDocumentFilesInput;
};

export type MutationApplyPayrollArgs = {
  input: ApplyPayrollInput;
};

export type MutationApproveLeaveApplicationArgs = {
  input: ApproveLeaveApplicationInput;
};

export type MutationAssignJobArgs = {
  input: AssignJobInput;
};

export type MutationAssignPackageRequestArgs = {
  input: AssignPackageRequestInput;
};

export type MutationAssignTasksArgs = {
  input: AssignTasksInput;
};

export type MutationAssignVisitArgs = {
  input: AssignVisitInput;
};

export type MutationCancelLeaveApplicationArgs = {
  input: CancelLeaveApplicationInput;
};

export type MutationCancelVisitArgs = {
  input: CancelVisitInput;
};

export type MutationCancelVisitWithTokenArgs = {
  input: CancelVisitWithTokenInput;
};

export type MutationChangePackageArgs = {
  input: ChangePackageInput;
};

export type MutationClaimRewardArgs = {
  input: ClaimRewardInput;
};

export type MutationClientApplyCreditArgs = {
  input: ClientApplyCreditInput;
};

export type MutationClientApplyVoucherArgs = {
  input: ClientApplyVoucherInput;
};

export type MutationClientCancelReservationArgs = {
  input: ClientCancelReservationInput;
};

export type MutationClientCancelVisitArgs = {
  input: ClientCancelVisitInput;
};

export type MutationClientConfirmPhoneNumberArgs = {
  input: ClientConfirmPhoneNumberInput;
};

export type MutationClientConfirmReservationArgs = {
  input: ClientConfirmReservationInput;
};

export type MutationClientCreateReservationArgs = {
  input: ClientCreateReservationInput;
};

export type MutationClientRemoveVoucherArgs = {
  input: ClientRemoveVoucherInput;
};

export type MutationClientRescheduleVisitArgs = {
  input: ClientRescheduleVisitInput;
};

export type MutationClientRescheduleVisitWithTokenArgs = {
  input: ClientRescheduleVisitWithTokenInput;
};

export type MutationClientValidatePromoCodeArgs = {
  input: ClientValidatePromoCodeInput;
};

export type MutationClientVerifyPhoneNumberArgs = {
  input: ClientVerifyPhoneNumberInput;
};

export type MutationCompleteVisitArgs = {
  input: CompleteVisitInput;
};

export type MutationCompleteWorkerTaskArgs = {
  input: CompleteWorkerTaskInput;
};

export type MutationConfirmInvoiceArgs = {
  input: ConfirmInvoiceInput;
};

export type MutationConfirmInvoiceBatchArgs = {
  input: ConfirmInvoiceBatchInput;
};

export type MutationConfirmPackageRequestArgs = {
  input: ConfirmPackageRequestInput;
};

export type MutationConfirmPayrollArgs = {
  input: ConfirmPayrollInput;
};

export type MutationConfirmPayrollBatchArgs = {
  input: ConfirmPayrollBatchInput;
};

export type MutationConvertBankStatementCsvArgs = {
  input: ConvertBankStatementCsvInput;
};

export type MutationCreateAddressArgs = {
  input: CreateAddressInput;
};

export type MutationCreateAddressBatchArgs = {
  input: CreateAddressBatchInput;
};

export type MutationCreateAdhocPackageArgs = {
  input: CreateAdhocPackageInput;
};

export type MutationCreateBookingRequestArgs = {
  input: CreateBookingRequestInput;
};

export type MutationCreateClientArgs = {
  input: CreateClientInput;
};

export type MutationCreateClientFeedbackArgs = {
  input: CreateClientFeedbackInput;
};

export type MutationCreateClientIssueRequestArgs = {
  input: CreateClientIssueRequestInput;
};

export type MutationCreateClientIssueRequestWithTokenArgs = {
  input: CreateClientIssueRequestWithTokenInput;
};

export type MutationCreateClientNoteArgs = {
  input: CreateClientNoteInput;
};

export type MutationCreateClientVoucherArgs = {
  input: CreateClientVoucherInput;
};

export type MutationCreateContactArgs = {
  input: CreateContactInput;
};

export type MutationCreateContactBatchArgs = {
  input: CreateContactBatchInput;
};

export type MutationCreateContractArgs = {
  input: CreateContractInput;
};

export type MutationCreateFinalPayrollArgs = {
  input: CreateFinalPayrollInput;
};

export type MutationCreateJobArgs = {
  input: CreateJobInput;
};

export type MutationCreateLeaveApplicationArgs = {
  input: CreateLeaveApplicationInput;
};

export type MutationCreateMasterPromoCodeArgs = {
  input: CreateMasterPromoCodeInput;
};

export type MutationCreatePackageArgs = {
  input: CreatePackageInput;
};

export type MutationCreatePackageIssueRequestArgs = {
  input: CreatePackageIssueRequestInput;
};

export type MutationCreatePackageRequestArgs = {
  input: CreatePackageRequestInput;
};

export type MutationCreatePaymentMethodArgs = {
  input: CreatePaymentMethodInput;
};

export type MutationCreatePayrollAdjustmentArgs = {
  input: CreatePayrollAdjustmentInput;
};

export type MutationCreatePromotionArgs = {
  input: CreatePromotionInput;
};

export type MutationCreateRentalPackageArgs = {
  input: CreateRentalPackageInput;
};

export type MutationCreateStripeSetupIntentArgs = {
  input: CreateStripeSetupIntentInput;
};

export type MutationCreateVisitBillingAdjustmentArgs = {
  input: CreateVisitBillingAdjustmentInput;
};

export type MutationCreateVisitPayrollAdjustmentArgs = {
  input: CreateVisitPayrollAdjustmentInput;
};

export type MutationCreateWorkerArgs = {
  input: CreateWorkerInput;
};

export type MutationCreateWorkerAttendanceArgs = {
  input: CreateWorkerAttendanceInput;
};

export type MutationCreateWorkerContractArgs = {
  input: CreateWorkerContractInput;
};

export type MutationCreateWorkerContractPayrollPolicyArgs = {
  input: CreateWorkerContractPayrollPolicyInput;
};

export type MutationCreateWorkerTaskArgs = {
  input: CreateWorkerTaskInput;
};

export type MutationCreateWorkerTipArgs = {
  input: CreateWorkerTipInput;
};

export type MutationDeleteAddressArgs = {
  input: DeleteAddressInput;
};

export type MutationDeleteAddressBatchArgs = {
  input: DeleteAddressBatchInput;
};

export type MutationDeleteClientArgs = {
  input: DeleteClientInput;
};

export type MutationDeleteClientDocumentsArgs = {
  input: DeleteClientDocumentsInput;
};

export type MutationDeleteClientNoteArgs = {
  input: DeleteClientNoteInput;
};

export type MutationDeleteClientVoucherArgs = {
  input: DeleteClientVoucherInput;
};

export type MutationDeleteContactArgs = {
  input: DeleteContactInput;
};

export type MutationDeleteContactBatchArgs = {
  input: DeleteContactBatchInput;
};

export type MutationDeleteContractArgs = {
  input: DeleteContractInput;
};

export type MutationDeleteInvoiceArgs = {
  input: DeleteInvoiceInput;
};

export type MutationDeleteJobArgs = {
  input: DeleteJobInput;
};

export type MutationDeleteLeaveApplicationArgs = {
  input: DeleteLeaveApplicationInput;
};

export type MutationDeletePackageArgs = {
  input: DeletePackageInput;
};

export type MutationDeletePackageRequestArgs = {
  input: DeletePackageRequestInput;
};

export type MutationDeletePaymentMethodArgs = {
  input: DeletePaymentMethodInput;
};

export type MutationDeletePayrollAdjustmentArgs = {
  input: DeletePayrollAdjustmentInput;
};

export type MutationDeletePortfoliosArgs = {
  input: DeletePortfoliosInput;
};

export type MutationDeletePromotionArgs = {
  input: DeletePromotionInput;
};

export type MutationDeleteProofOfAgreementsArgs = {
  input: DeleteProofOfAgreementsInput;
};

export type MutationDeleteStaffReviewAttachmentArgs = {
  input: DeleteStaffReviewAttachmentInput;
};

export type MutationDeleteTransactionArgs = {
  input: DeleteTransactionInput;
};

export type MutationDeleteVisitBillingAdjustmentArgs = {
  input: DeleteVisitBillingAdjustmentInput;
};

export type MutationDeleteVisitLineItemArgs = {
  input: DeleteVisitLineItemInput;
};

export type MutationDeleteVisitPayrollAdjustmentArgs = {
  input: DeleteVisitPayrollAdjustmentInput;
};

export type MutationDeleteWorkerArgs = {
  input: DeleteWorkerInput;
};

export type MutationDeleteWorkerAttendanceArgs = {
  input: DeleteWorkerAttendanceInput;
};

export type MutationDeleteWorkerContractArgs = {
  input: DeleteWorkerContractInput;
};

export type MutationDeleteWorkerDocumentArgs = {
  input: DeleteWorkerDocumentInput;
};

export type MutationDeleteWorkerDocumentFilesArgs = {
  input: DeleteWorkerDocumentFilesInput;
};

export type MutationDeleteWorkerTaskArgs = {
  input: DeleteWorkerTaskInput;
};

export type MutationDeleteWorkerTipArgs = {
  input: DeleteWorkerTipInput;
};

export type MutationFinishVisitArgs = {
  input: FinishVisitInput;
};

export type MutationGenerateInvoiceCsvArgs = {
  input: GenerateInvoiceCsvInput;
};

export type MutationGenerateInvoicePdfArgs = {
  input: GenerateInvoicePdfInput;
};

export type MutationGenerateInvoiceReceiptPdfArgs = {
  input: GenerateInvoiceReceiptPdfInput;
};

export type MutationGenerateOutstandingPayrollsCsvArgs = {
  input: GenerateOutstandingPayrollsCsvInput;
};

export type MutationGeneratePayrollCsvArgs = {
  input: GeneratePayrollCsvInput;
};

export type MutationInviteUserArgs = {
  input: InviteUserInput;
};

export type MutationPayInvoiceArgs = {
  input: PayInvoiceInput;
};

export type MutationRateVisitArgs = {
  input: RateVisitInput;
};

export type MutationRateVisitWithTokenArgs = {
  input: RateVisitWithTokenInput;
};

export type MutationReadNotificationArgs = {
  input: ReadNotificationInput;
};

export type MutationRegenerateInvoiceArgs = {
  input: RegenerateInvoiceInput;
};

export type MutationRegeneratePayrollArgs = {
  input: RegeneratePayrollInput;
};

export type MutationRejectLeaveApplicationArgs = {
  input: RejectLeaveApplicationInput;
};

export type MutationRejectPackageRequestArgs = {
  input: RejectPackageRequestInput;
};

export type MutationRemovePaymentProofArgs = {
  input: RemovePaymentProofInput;
};

export type MutationRemoveVisitNoteAttachmentsArgs = {
  input: RemoveVisitNoteAttachmentsInput;
};

export type MutationRescheduleVisitWithTokenArgs = {
  input: RescheduleVisitWithTokenInput;
};

export type MutationResetPasswordFromEmailArgs = {
  input: ResetPasswordFromEmailInput;
};

export type MutationResetPasswordFromPhoneArgs = {
  input: ResetPasswordFromPhoneInput;
};

export type MutationRestoreLeaveApplicationArgs = {
  input: RestoreLeaveApplicationInput;
};

export type MutationRestoreVisitArgs = {
  input: RestoreVisitInput;
};

export type MutationRestoreWorkerTaskArgs = {
  input: RestoreWorkerTaskInput;
};

export type MutationSendInvoiceEmailArgs = {
  input: SendInvoiceEmailInput;
};

export type MutationSendPinCodeToPhoneArgs = {
  input: SendPinCodeToPhoneInput;
};

export type MutationSendReferralInvitationArgs = {
  input: SendReferralInvitationInput;
};

export type MutationSendResetPasswordArgs = {
  input: SendResetPasswordInput;
};

export type MutationSendVerificationUserArgs = {
  input: SendVerificationUserInput;
};

export type MutationSignInClassicArgs = {
  input: SignInClassicInput;
};

export type MutationSignInClientArgs = {
  input: SignInClientInput;
};

export type MutationSignInClientSocialAccountArgs = {
  input: SignInClientSocialAccountInput;
};

export type MutationSignInWithPhoneArgs = {
  input: SignInWithPhoneInput;
};

export type MutationSignOutArgs = {
  input: SignOutInput;
};

export type MutationSignUpArgs = {
  input: SignUpInput;
};

export type MutationStartVisitArgs = {
  input: StartVisitInput;
};

export type MutationTerminateJobArgs = {
  input: TerminateJobInput;
};

export type MutationTerminatePackageArgs = {
  input: TerminatePackageInput;
};

export type MutationTerminateWorkerArgs = {
  input: TerminateWorkerInput;
};

export type MutationTerminateWorkerContractArgs = {
  input: TerminateWorkerContractInput;
};

export type MutationTokenizePaymentMethodArgs = {
  input: TokenizePaymentMethodInput;
};

export type MutationUpdateAccountPhoneNumberArgs = {
  input: UpdateAccountPhoneNumberInput;
};

export type MutationUpdateAddressArgs = {
  input: UpdateAddressInput;
};

export type MutationUpdateAddressBatchArgs = {
  input: UpdateAddressBatchInput;
};

export type MutationUpdateClientArgs = {
  input: UpdateClientInput;
};

export type MutationUpdateClientNoteArgs = {
  input: UpdateClientNoteInput;
};

export type MutationUpdateClientPasswordArgs = {
  input: UpdateClientPasswordInput;
};

export type MutationUpdateClientPreferencesArgs = {
  input: UpdateClientPreferencesInput;
};

export type MutationUpdateClientVoucherArgs = {
  input: UpdateClientVoucherInput;
};

export type MutationUpdateContactArgs = {
  input: UpdateContactInput;
};

export type MutationUpdateContactBatchArgs = {
  input: UpdateContactBatchInput;
};

export type MutationUpdateContractArgs = {
  input: UpdateContractInput;
};

export type MutationUpdateInstallationArgs = {
  input: UpdateInstallationInput;
};

export type MutationUpdateLeaveApplicationArgs = {
  input: UpdateLeaveApplicationInput;
};

export type MutationUpdateManagerArgs = {
  input: UpdateManagerInput;
};

export type MutationUpdateMasterPromoCodeArgs = {
  input: UpdateMasterPromoCodeInput;
};

export type MutationUpdatePackageArgs = {
  input: UpdatePackageInput;
};

export type MutationUpdatePackageJobsArgs = {
  input: UpdatePackageJobsInput;
};

export type MutationUpdatePaymentIndicatedArgs = {
  input: UpdatePaymentIndicatedInput;
};

export type MutationUpdatePaymentMethodArgs = {
  input: UpdatePaymentMethodInput;
};

export type MutationUpdatePayrollAdjustmentArgs = {
  input: UpdatePayrollAdjustmentInput;
};

export type MutationUpdatePromotionArgs = {
  input: UpdatePromotionInput;
};

export type MutationUpdateUserEmailArgs = {
  input: UpdateUserEmailInput;
};

export type MutationUpdateUserPasswordArgs = {
  input: UpdateUserPasswordInput;
};

export type MutationUpdateViewerArgs = {
  input: UpdateViewerInput;
};

export type MutationUpdateVisitArgs = {
  input: UpdateVisitInput;
};

export type MutationUpdateVisitBillingAdjustmentArgs = {
  input: UpdateVisitBillingAdjustmentInput;
};

export type MutationUpdateVisitLineItemArgs = {
  input: UpdateVisitLineItemInput;
};

export type MutationUpdateVisitLineItemsArgs = {
  input: UpdateVisitLineItemsInput;
};

export type MutationUpdateVisitPayrollAdjustmentArgs = {
  input: UpdateVisitPayrollAdjustmentInput;
};

export type MutationUpdateWorkerArgs = {
  input: UpdateWorkerInput;
};

export type MutationUpdateWorkerContractArgs = {
  input: UpdateWorkerContractInput;
};

export type MutationUpdateWorkerContractDayArgs = {
  input: UpdateWorkerContractDayInput;
};

export type MutationUpdateWorkerContractSkillsArgs = {
  input: UpdateWorkerContractSkillsInput;
};

export type MutationUpdateWorkerDocumentArgs = {
  input: UpdateWorkerDocumentInput;
};

export type MutationUpdateWorkerPreferencesArgs = {
  input: UpdateWorkerPreferencesInput;
};

export type MutationUpdateWorkerTaskArgs = {
  input: UpdateWorkerTaskInput;
};

export type MutationUpdateWorkerTipArgs = {
  input: UpdateWorkerTipInput;
};

export type MutationUploadOutstandingPayrollsCsvArgs = {
  input: UploadOutstandingPayrollsCsvInput;
};

export type MutationVerificationAddressWorkerArgs = {
  input: VerificationAddressWorkerInput;
};

export type MutationVerifyUserArgs = {
  input: VerifyUserInput;
};

export type MutationWithdrawCreditArgs = {
  input: WithdrawCreditInput;
};

export type MutationWithdrawCreditAuthorizedArgs = {
  input: WithdrawCreditAuthorizedInput;
};

export type MutationWorkerConfirmReservationArgs = {
  input: WorkerConfirmReservationInput;
};

export type MutationWorkerCreateReservationArgs = {
  input: WorkerCreateReservationInput;
};

export enum NationalityEnum {
  Bangladeshi = "Bangladeshi",
  Burmese = "Burmese",
  Cambodian = "Cambodian",
  Chinese = "Chinese",
  Indian = "Indian",
  Indonesian = "Indonesian",
  Malaysian = "Malaysian",
  SingaporePr = "Singapore_PR",
  Singaporean = "Singaporean",
  SriLankan = "SriLankan",
  Thai = "Thai",
  Vietnamese = "Vietnamese",
}

export type NewRecurringClient = {
  __typename?: "NewRecurringClient";
  client: Client;
  firstVisitDate?: Maybe<Scalars["Date"]["output"]>;
  package: Package;
  worker?: Maybe<Worker>;
};

export enum NewRecurringClientSortByEnum {
  Address = "address",
  ClientName = "client_name",
  FirstVisitDate = "first_visit_date",
  ManagerCode = "manager_code",
  PackageCode = "package_code",
  WorkerName = "worker_name",
}

export type NotFoundProblem = {
  __typename?: "NotFoundProblem";
  entity: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export type NotWhitelistedProblem = {
  __typename?: "NotWhitelistedProblem";
  /** @deprecated Safelisting behaviour doesn't use problems anymore */
  message: Scalars["String"]["output"];
};

export type Notification = {
  __typename?: "Notification";
  createdAt: Scalars["Date"]["output"];
  id: Scalars["ID"]["output"];
  message: Scalars["String"]["output"];
  read: Scalars["Boolean"]["output"];
  updatedAt: Scalars["Date"]["output"];
  user: WorkerUser;
};

export type NotificationHistory = {
  __typename?: "NotificationHistory";
  category: MasterNotificationCategoryEnum;
  channel: ChannelEnum;
  deliveredAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  messageBody: Scalars["String"]["output"];
  notificationName: Scalars["String"]["output"];
  receiverObjectId: Scalars["String"]["output"];
  receiverObjectType: Scalars["String"]["output"];
  recipient?: Maybe<Scalars["String"]["output"]>;
  retryCount: Scalars["Int"]["output"];
  status: NotificationStatusEnum;
  subCategory: Scalars["String"]["output"];
  userName: Scalars["String"]["output"];
};

export type NotificationHistoryByFilter = {
  __typename?: "NotificationHistoryByFilter";
  count: Scalars["Int"]["output"];
  notificationHistories: Array<NotificationHistory>;
};

export type NotificationHistoryFilter = {
  category?: InputMaybe<MasterNotificationCategoryEnum>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  notificationName?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  receiverId?: InputMaybe<Scalars["ID"]["input"]>;
  receiverType?: InputMaybe<ReceiverEnum>;
  sort?: InputMaybe<Array<SortEnum>>;
  sortBy?: InputMaybe<Array<LeaveApplicationSortByEnum>>;
  status?: InputMaybe<NotificationStatusEnum>;
  templateId?: InputMaybe<Scalars["ID"]["input"]>;
  userName?: InputMaybe<Scalars["String"]["input"]>;
};

export enum NotificationSortByEnum {
  Channel = "channel",
  Id = "id",
  Name = "name",
  Receiver = "receiver",
  RelatedObject = "related_object",
}

export enum NotificationStatusEnum {
  Canceled = "canceled",
  Completed = "completed",
  Delivered = "delivered",
  Failed = "failed",
  Pending = "pending",
  Read = "read",
  Retrying = "retrying",
  Scheduled = "scheduled",
  Sent = "sent",
  Undelivered = "undelivered",
}

export type NotificationTemplate = {
  __typename?: "NotificationTemplate";
  body?: Maybe<Scalars["String"]["output"]>;
  channel?: Maybe<ChannelEnum>;
  id: Scalars["ID"]["output"];
  language: Scalars["String"]["output"];
  masterNotification: MasterNotification;
  name: Scalars["String"]["output"];
};

export type OffboardingsFilterInput = {
  department?: InputMaybe<WorkerDepartmentEnum>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  managerId?: InputMaybe<Scalars["ID"]["input"]>;
  nameLike?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<SortEnum>>;
  sortBy?: InputMaybe<Array<WorkerSortByEnum>>;
};

/** Input for OneSignal classic token */
export type OneSignalClassicTokenInput = {
  playerId: Scalars["String"]["input"];
};

/** Input for OneSignal VoIP token */
export type OneSignalVoipTokenInput = {
  playerId: Scalars["String"]["input"];
};

export type OnemapAddress = {
  __typename?: "OnemapAddress";
  address: Scalars["String"]["output"];
  blkNo: Scalars["String"]["output"];
  building: Scalars["String"]["output"];
  roadName: Scalars["String"]["output"];
};

export type OvertimePay = {
  __typename?: "OvertimePay";
  amount: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  overtimeHour: Scalars["Float"]["output"];
  workerContractPayrollPolicy: WorkerContractPayrollPolicy;
};

export type Package = {
  __typename?: "Package";
  accessType?: Maybe<Scalars["String"]["output"]>;
  additionalNotes?: Maybe<Scalars["String"]["output"]>;
  allowedActions?: Maybe<Array<PackageActionsEnum>>;
  billingUnit: PackageDetailBillingUnitEnum;
  client?: Maybe<Client>;
  contract: Contract;
  creditAccount?: Maybe<CreditAccount>;
  department: PackageDepartmentEnum;
  endDate?: Maybe<Scalars["Date"]["output"]>;
  fullAddress: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  jobs?: Maybe<Array<Job>>;
  lastCompletedVisit?: Maybe<Visit>;
  packageDescription: Scalars["String"]["output"];
  packageDetail: PackageDetail;
  packageLineItems: Array<PackageLineItem>;
  packageType: PackageTypeEnum;
  postalCode: Scalars["String"]["output"];
  repeatEvery: PackageRecurrenceEnum;
  repeatEveryTimes: Scalars["Int"]["output"];
  serviceBillingValue: Scalars["Float"]["output"];
  serviceType: PackageServiceTypeEnum;
  startDate: Scalars["Date"]["output"];
  status: PackageStatusEnum;
  tasks?: Maybe<Array<Task>>;
  terminationCategory?: Maybe<PackageTerminationCategoryEnum>;
  terminationComment?: Maybe<Scalars["String"]["output"]>;
  terminationReason?: Maybe<PackageTerminationReasonEnum>;
  unitNumber?: Maybe<Scalars["String"]["output"]>;
  unitValue: Scalars["Float"]["output"];
};

export enum PackageActionsEnum {
  Delete = "DELETE",
}

export enum PackageDepartmentEnum {
  Aircon = "AIRCON",
  Babysitter = "BABYSITTER",
  CarpetUpholstery = "CARPET_UPHOLSTERY",
  CarWash = "CAR_WASH",
  Handyman = "HANDYMAN",
  HomeBeauty = "HOME_BEAUTY",
  HomeCleaning = "HOME_CLEANING",
  Massage = "MASSAGE",
  OfficeCleaning = "OFFICE_CLEANING",
  PetCare = "PET_CARE",
}

export type PackageDetail = {
  __typename?: "PackageDetail";
  billingUnit: PackageDetailBillingUnitEnum;
  code: Scalars["String"]["output"];
  department: PackageDepartmentEnum;
  description: Scalars["String"]["output"];
  duration: Scalars["Float"]["output"];
  durationPerUnit: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  invoiceFrequency: ContractInvoiceFrequencyEnum;
  maxUnits: Scalars["Float"]["output"];
  minUnits: Scalars["Float"]["output"];
  packageType: PackageTypeEnum;
  repeatEvery: PackageRecurrenceEnum;
  repeatEveryTimes: Scalars["Int"]["output"];
  serviceBillingValue: Scalars["Float"]["output"];
  serviceType: PackageServiceTypeEnum;
  session: PackageDetailSessionEnum;
  totalValue: Scalars["Float"]["output"];
  unitSize: UnitSizeEnum;
  unitType: PackageUnitTypeEnum;
  unitValue: Scalars["Float"]["output"];
  units: Scalars["Float"]["output"];
  workerSkill: WorkerSkill;
};

export enum PackageDetailBillingUnitEnum {
  Fixed = "FIXED",
  Hourly = "HOURLY",
  PerSqft = "PER_SQFT",
}

export enum PackageDetailSessionEnum {
  All = "ALL",
  Day = "DAY",
  Evening = "EVENING",
}

export enum PackageDetailSortByEnum {
  Code = "code",
  CreatedAt = "created_at",
  Description = "description",
  Duration = "duration",
  Id = "id",
  UpdatedAt = "updated_at",
}

export type PackageDetailsFilter = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  department?: InputMaybe<PackageDepartmentEnum>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  duration?: InputMaybe<Scalars["Float"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  repeatEvery?: InputMaybe<Array<PackageRecurrenceEnum>>;
  repeatEveryTimes?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  serviceTypes?: InputMaybe<Array<ServiceTypeEnum>>;
  session?: InputMaybe<PackageDetailSessionEnum>;
  sort?: InputMaybe<Array<SortEnum>>;
  sortBy?: InputMaybe<Array<PackageDetailSortByEnum>>;
  unitTypes?: InputMaybe<Array<PackageUnitTypeEnum>>;
};

export type PackageLineItem = {
  __typename?: "PackageLineItem";
  billingUnit: PackageDetailBillingUnitEnum;
  billingValue: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  packageDescription: Scalars["String"]["output"];
  packageDetail: PackageDetail;
  unitValue: Scalars["Float"]["output"];
  units: Scalars["Float"]["output"];
  xeroId?: Maybe<Scalars["String"]["output"]>;
};

export type PackageLineItemInput = {
  billingUnit: PackageDetailBillingUnitEnum;
  packageDescription: Scalars["String"]["input"];
  packageDetailId: Scalars["ID"]["input"];
  unitValue: Scalars["Float"]["input"];
  units?: InputMaybe<Scalars["Float"]["input"]>;
};

export enum PackageRecurrenceEnum {
  AdHoc = "AD_HOC",
  Day = "DAY",
  Fortnight = "FORTNIGHT",
  Month = "MONTH",
  Week = "WEEK",
  Year = "YEAR",
}

export type PackageRequest = {
  __typename?: "PackageRequest";
  additionalNotes?: Maybe<Scalars["String"]["output"]>;
  client?: Maybe<Client>;
  createdAt: Scalars["DateTime"]["output"];
  endDate?: Maybe<Scalars["Date"]["output"]>;
  expiredAt: Scalars["DateTime"]["output"];
  firstName: Scalars["String"]["output"];
  fullAddress: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  linkedJob?: Maybe<Job>;
  notificationLastSendTime?: Maybe<Scalars["DateTime"]["output"]>;
  packageDetail: PackageDetail;
  packageRequestsTasks?: Maybe<Array<PackageRequestTask>>;
  postalCode: Scalars["String"]["output"];
  startDate: Scalars["Date"]["output"];
  status: PackageRequestStatusEnum;
  travelTime?: Maybe<Scalars["Int"]["output"]>;
  unitNumber?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  worker?: Maybe<Worker>;
};

export enum PackageRequestSortByEnum {
  CreatedAt = "created_at",
  FirstName = "first_name",
  Id = "id",
  LastName = "last_name",
  PackageCode = "package_code",
  StartDate = "start_date",
  Status = "status",
  UpdatedAt = "updated_at",
}

export enum PackageRequestStatusEnum {
  Confirmed = "CONFIRMED",
  Created = "CREATED",
  Expired = "EXPIRED",
  PendingClientConfirmation = "PENDING_CLIENT_CONFIRMATION",
  Rejected = "REJECTED",
  SentToAllWorkers = "SENT_TO_ALL_WORKERS",
  SentToPriorityWorkers = "SENT_TO_PRIORITY_WORKERS",
}

export type PackageRequestTask = {
  __typename?: "PackageRequestTask";
  day: Scalars["Int"]["output"];
  endTime: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  packageRequest: PackageRequest;
  startTime: Scalars["String"]["output"];
};

export type PackageRequestsByFilters = {
  __typename?: "PackageRequestsByFilters";
  count: Scalars["Int"]["output"];
  packageRequests: Array<PackageRequest>;
};

export type PackageRequestsFilter = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  packageCode?: InputMaybe<Scalars["String"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  sort?: InputMaybe<Array<SortEnum>>;
  sortBy?: InputMaybe<Array<PackageRequestSortByEnum>>;
  status?: InputMaybe<PackageRequestStatusEnum>;
};

export enum PackageServiceTypeEnum {
  AddOn = "ADD_ON",
  Adjustment = "ADJUSTMENT",
  AdHoc = "AD_HOC",
  Aircon = "AIRCON",
  Babysitting = "BABYSITTING",
  CarpetUpholstery = "CARPET_UPHOLSTERY",
  ChemicalOverhaul = "CHEMICAL_OVERHAUL",
  ChemicalWash = "CHEMICAL_WASH",
  Combo = "COMBO",
  CondenserWash = "CONDENSER_WASH",
  DeepCleaning = "DEEP_CLEANING",
  DeepExtraction = "DEEP_EXTRACTION",
  Discount = "DISCOUNT",
  Dismantling = "DISMANTLING",
  Electrician = "ELECTRICIAN",
  Equipment = "EQUIPMENT",
  Fee = "FEE",
  GasLeakTesting = "GAS_LEAK_TESTING",
  GasTopUp = "GAS_TOP_UP",
  GeneralServicing = "GENERAL_SERVICING",
  Hardware = "HARDWARE",
  HomeCleaning = "HOME_CLEANING",
  Installation = "INSTALLATION",
  JetWash = "JET_WASH",
  Lashes = "LASHES",
  Manicure = "MANICURE",
  Massage = "MASSAGE",
  Mpv = "MPV",
  OfficeCleaning = "OFFICE_CLEANING",
  Pedicure = "PEDICURE",
  PetCare = "PET_CARE",
  Plumbing = "PLUMBING",
  RepairDiagnostic = "REPAIR_DIAGNOSTIC",
  RepairFollowUp = "REPAIR_FOLLOW_UP",
  Sedan = "SEDAN",
  SteamCleaning = "STEAM_CLEANING",
  Suv = "SUV",
  UltraVioletCleaning = "ULTRA_VIOLET_CLEANING",
  Upgrade = "UPGRADE",
  Warranty = "WARRANTY",
}

export enum PackageStatusEnum {
  Active = "ACTIVE",
  Created = "CREATED",
  Failed = "FAILED",
  Terminated = "TERMINATED",
}

export enum PackageTerminationCategoryEnum {
  PackageChange = "PACKAGE_CHANGE",
  Termination = "TERMINATION",
}

export enum PackageTerminationReasonEnum {
  ClientTermination = "CLIENT_TERMINATION",
  ClientWillSelfClean = "CLIENT_WILL_SELF_CLEAN",
  FrequentCancellation = "FREQUENT_CANCELLATION",
  GoingOverseas = "GOING_OVERSEAS",
  HiredFullTimeCleaner = "HIRED_FULL_TIME_CLEANER",
  MovingOut = "MOVING_OUT",
  NonPayingClient = "NON_PAYING_CLIENT",
  OpsInitiated = "OPS_INITIATED",
  OpsTerminated = "OPS_TERMINATED",
  Others = "OTHERS",
  PackageChange = "PACKAGE_CHANGE",
  PoorCleaningQuality = "POOR_CLEANING_QUALITY",
  PricingIssue = "PRICING_ISSUE",
  ServiceFrustrations = "SERVICE_FRUSTRATIONS",
  SwitchedToCompetitor = "SWITCHED_TO_COMPETITOR",
  TemporaryCleaningOnly = "TEMPORARY_CLEANING_ONLY",
  UnwillingToShareReason = "UNWILLING_TO_SHARE_REASON",
}

export enum PackageTypeEnum {
  Rental = "RENTAL",
  Service = "SERVICE",
}

export enum PackageUnitTypeEnum {
  All = "ALL",
  BabyPram = "BABY_PRAM",
  BodyMassage = "BODY_MASSAGE",
  Carpet = "CARPET",
  Cat = "CAT",
  Ceiling = "CEILING",
  ChildSeat = "CHILD_SEAT",
  Curtain = "CURTAIN",
  Dog = "DOG",
  Ducted = "DUCTED",
  Electrician = "ELECTRICIAN",
  FootMassage = "FOOT_MASSAGE",
  Handyman = "HANDYMAN",
  Installation = "INSTALLATION",
  Lashes = "LASHES",
  Mattress = "MATTRESS",
  Mpv = "MPV",
  Nail = "NAIL",
  Plumbing = "PLUMBING",
  Portable = "PORTABLE",
  R22 = "R22",
  R410A = "R410A",
  Rug = "RUG",
  Sedan = "SEDAN",
  Sofa = "SOFA",
  Suv = "SUV",
  Wall = "WALL",
  Window = "WINDOW",
}

export type PackagesByFilters = {
  __typename?: "PackagesByFilters";
  count: Scalars["Int"]["output"];
  packages: Array<Package>;
};

export type PackagesByFiltersInput = {
  billingUnit?: InputMaybe<PackageDetailBillingUnitEnum>;
  clientId?: InputMaybe<Scalars["ID"]["input"]>;
  department?: InputMaybe<PackageDepartmentEnum>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<PackageStatusEnum>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]["output"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]["output"]>;
};

export type PastWeekFreeTimeslot = {
  __typename?: "PastWeekFreeTimeslot";
  fri: Scalars["String"]["output"];
  mon: Scalars["String"]["output"];
  postalCode: Scalars["String"]["output"];
  sat: Scalars["String"]["output"];
  sun: Scalars["String"]["output"];
  thu: Scalars["String"]["output"];
  timeSection: Scalars["String"]["output"];
  tue: Scalars["String"]["output"];
  wed: Scalars["String"]["output"];
};

export enum PaymentProofStatusEnum {
  NotUploaded = "NOT_UPLOADED",
  Uploaded = "UPLOADED",
}

export type PaymentTransaction = {
  __typename?: "PaymentTransaction";
  amount: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  paymentDate: Scalars["Date"]["output"];
  paymentReason: PaymentTransactionReasonsEnum;
  paymentRemarks?: Maybe<Scalars["String"]["output"]>;
  referenceNumber?: Maybe<Scalars["String"]["output"]>;
  transferMode: PaymentTransactionModesEnum;
};

export enum PaymentTransactionModesEnum {
  BankTransfer = "BANK_TRANSFER",
  Cash = "CASH",
  Cheque = "CHEQUE",
}

export enum PaymentTransactionReasonsEnum {
  BulkSalaryPayment = "BULK_SALARY_PAYMENT",
  ManualPayment = "MANUAL_PAYMENT",
  Others = "OTHERS",
  SalaryAdvance = "SALARY_ADVANCE",
}

export type Payroll = {
  __typename?: "Payroll";
  acknowledgeAt?: Maybe<Scalars["DateTime"]["output"]>;
  amount: Scalars["Float"]["output"];
  basicPay: Scalars["Float"]["output"];
  billingAccount?: Maybe<BillingAccount>;
  billingCycle?: Maybe<BillingCycle>;
  bonus: Scalars["Float"]["output"];
  bonusPays?: Maybe<Array<BonusPay>>;
  csvUrlToken?: Maybe<Scalars["String"]["output"]>;
  employerCpf: Scalars["Float"]["output"];
  fromDate: Scalars["Date"]["output"];
  gross: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  nett: Scalars["Float"]["output"];
  overtime: Scalars["Float"]["output"];
  overtimePays?: Maybe<Array<OvertimePay>>;
  paidAmount?: Maybe<Scalars["Float"]["output"]>;
  paymentStatus: PayrollPaymentStatusEnum;
  paymentTransactions?: Maybe<Array<PaymentTransaction>>;
  payrollAdjustments?: Maybe<Array<PayrollAdjustment>>;
  payslips?: Maybe<Array<Payslip>>;
  status: PayrollStatusEnum;
  toDate?: Maybe<Scalars["Date"]["output"]>;
  totalAllowances: Scalars["Float"]["output"];
  totalDeductions: Scalars["Float"]["output"];
  verificationAddressVideo?: Maybe<SavedFile>;
  workerAcknowledgementUrl?: Maybe<Scalars["String"]["output"]>;
  workerAllowances?: Maybe<Array<WorkerAllowance>>;
  workerCpf: Scalars["Float"]["output"];
};

export type PayrollAdjustment = {
  __typename?: "PayrollAdjustment";
  adjustmentType?: Maybe<Scalars["String"]["output"]>;
  amount: Scalars["Float"]["output"];
  billingAccount: BillingAccount;
  comment?: Maybe<Scalars["String"]["output"]>;
  cpfContributable: Scalars["Boolean"]["output"];
  endDate?: Maybe<Scalars["Date"]["output"]>;
  id: Scalars["ID"]["output"];
  payroll?: Maybe<Payroll>;
  startDate: Scalars["Date"]["output"];
};

export type PayrollAdjustmentsFilter = {
  billingAccountId?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  cpfContributable?: InputMaybe<Scalars["Boolean"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  endDateFrom?: InputMaybe<Scalars["Date"]["input"]>;
  endDateTo?: InputMaybe<Scalars["Date"]["input"]>;
  id?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  startDateFrom?: InputMaybe<Scalars["Date"]["input"]>;
  startDateTo?: InputMaybe<Scalars["Date"]["input"]>;
  workerId?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export enum PayrollPaymentStatusEnum {
  Overpaid = "OVERPAID",
  Paid = "PAID",
  Underpaid = "UNDERPAID",
  Unpaid = "UNPAID",
}

export type PayrollPolicy = {
  __typename?: "PayrollPolicy";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  policyType: PolicyTypeEnum;
};

export type PayrollPolicyInput = {
  payload: Array<PayrollPolicyPayloadInput>;
  payrollPolicyId: Scalars["Int"]["input"];
};

export type PayrollPolicyPayloadInput = {
  deduction?: InputMaybe<Scalars["Int"]["input"]>;
  max?: InputMaybe<Scalars["Int"]["input"]>;
  min?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["Float"]["input"]>;
};

export enum PayrollSortByEnum {
  BillingAccountWorkerFirstName = "billing_account_worker_first_name",
  BillingAccountWorkerLastName = "billing_account_worker_last_name",
  CreatedAt = "created_at",
  Gross = "gross",
  Id = "id",
  Nett = "nett",
  PaymentStatus = "payment_status",
  UpdatedAt = "updated_at",
}

export enum PayrollStatusEnum {
  Cancelled = "CANCELLED",
  Confirmed = "CONFIRMED",
  New = "NEW",
}

export type PayrollsByFilters = {
  __typename?: "PayrollsByFilters";
  count: Scalars["Int"]["output"];
  payrolls: Array<Payroll>;
};

export type PayrollsFilter = {
  fromDate?: InputMaybe<Scalars["Date"]["input"]>;
  fromDateFrom?: InputMaybe<Scalars["Date"]["input"]>;
  fromDateTo?: InputMaybe<Scalars["Date"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  managerId?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  paymentStatus?: InputMaybe<Array<PayrollPaymentStatusEnum>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  sort?: InputMaybe<Array<SortEnum>>;
  sortBy?: InputMaybe<Array<PayrollSortByEnum>>;
  status?: InputMaybe<Array<PayrollStatusEnum>>;
  toDate?: InputMaybe<Scalars["Date"]["input"]>;
  toDateFrom?: InputMaybe<Scalars["Date"]["input"]>;
  toDateTo?: InputMaybe<Scalars["Date"]["input"]>;
  workerAcknowledgement?: InputMaybe<Scalars["Boolean"]["input"]>;
  workerId?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type Payslip = {
  __typename?: "Payslip";
  additionAdjustment?: Maybe<Array<Transaction>>;
  allowances?: Maybe<Array<Transaction>>;
  amount: Scalars["Float"]["output"];
  basicPay: Scalars["Float"]["output"];
  bonus: Scalars["Float"]["output"];
  bonuses?: Maybe<Array<Transaction>>;
  createdAt: Scalars["DateTime"]["output"];
  deductionAdjustment?: Maybe<Array<Transaction>>;
  deductions?: Maybe<Array<Transaction>>;
  downloadUrl?: Maybe<Scalars["String"]["output"]>;
  employerCpf: Scalars["Float"]["output"];
  gross: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  nett: Scalars["Float"]["output"];
  overtime: Scalars["Float"]["output"];
  overtimes?: Maybe<Array<Transaction>>;
  paidAmount?: Maybe<Scalars["Float"]["output"]>;
  paymentStatus: PayrollPaymentStatusEnum;
  payroll: Payroll;
  status: PayrollStatusEnum;
  totalAllowances: Scalars["Float"]["output"];
  totalDeductions: Scalars["Float"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  workerCpf: Scalars["Float"]["output"];
};

export type Ping = {
  __typename?: "Ping";
  /** A succesfull ping will pong! */
  ping: Scalars["Boolean"]["output"];
};

export enum PolicyTypeEnum {
  Allowance = "ALLOWANCE",
  Bonus = "BONUS",
  Deduction = "DEDUCTION",
  Overtime = "OVERTIME",
  UnitValue = "UNIT_VALUE",
}

export type ProjectedPayroll = {
  __typename?: "ProjectedPayroll";
  basicPay: Scalars["Float"]["output"];
  billingAccount?: Maybe<BillingAccount>;
  billingCycle?: Maybe<BillingCycle>;
  bonus: Scalars["Float"]["output"];
  enhancedOffDayPay: Scalars["Float"]["output"];
  fromDate: Scalars["Date"]["output"];
  gmv: Scalars["Float"]["output"];
  gross: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  lastUpdated: Scalars["DateTime"]["output"];
  nett: Scalars["Float"]["output"];
  overtime: Scalars["Float"]["output"];
  productivityPay: Scalars["Float"]["output"];
  publicHolidayPay: Scalars["Float"]["output"];
  standardOffDayPay: Scalars["Float"]["output"];
  toDate?: Maybe<Scalars["Date"]["output"]>;
  totalAllowances: Scalars["Float"]["output"];
  totalDeductions: Scalars["Float"]["output"];
  totalVisits: Scalars["Int"]["output"];
  workHours: Scalars["Int"]["output"];
  workerCpf: Scalars["Float"]["output"];
};

export type ProjectedPayrollFilterInput = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  managerId?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  sort?: InputMaybe<Array<SortEnum>>;
  sortBy?: InputMaybe<Array<ProjectedPayrollSortByEnum>>;
  workerId?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export enum ProjectedPayrollSortByEnum {
  BillingAccountWorkerFirstName = "billing_account_worker_first_name",
  BillingAccountWorkerLastName = "billing_account_worker_last_name",
  CreatedAt = "created_at",
  Gmv = "gmv",
  Gross = "gross",
  Id = "id",
  Nett = "nett",
  UpdatedAt = "updated_at",
}

export type ProjectedPayrollsByFilters = {
  __typename?: "ProjectedPayrollsByFilters";
  count: Scalars["Int"]["output"];
  projectedPayrolls: Array<ProjectedPayroll>;
};

export type Promotion = {
  __typename?: "Promotion";
  buttonLabel?: Maybe<Scalars["String"]["output"]>;
  endDate: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  promotionImages: Array<SavedFile>;
  startDate: Scalars["DateTime"]["output"];
  status: PromotionStatusEnum;
  title: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
};

export type PromotionFilter = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  promotionId?: InputMaybe<Scalars["ID"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  sort?: InputMaybe<Array<SortEnum>>;
  sortBy?: InputMaybe<Array<PromotionSortByEnum>>;
  status?: InputMaybe<PromotionStatusEnum>;
};

export enum PromotionSortByEnum {
  EndDate = "end_date",
  StartDate = "start_date",
  Title = "title",
}

export enum PromotionStatusEnum {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export type Promotions = {
  __typename?: "Promotions";
  count: Scalars["Int"]["output"];
  promotions: Array<Promotion>;
};

/** Input for push notification tokens */
export type PushNotificationTokensInput = {
  expo?: InputMaybe<ExpoTokenInput>;
  oneSignalClassic?: InputMaybe<OneSignalClassicTokenInput>;
  oneSignalVoip?: InputMaybe<OneSignalVoipTokenInput>;
};

/** The query root of this schema */
export type Query = {
  __typename?: "Query";
  LeaveApplication: LeaveApplication;
  LeaveApplications: Array<LeaveApplication>;
  /** Find address by given postal code */
  addressByPostalCode?: Maybe<OnemapAddress>;
  /** Find all clients by given filters */
  allClients: AllClients;
  /** Get all managers */
  allManagers: Array<RegularUser>;
  /** Find all new recurring clients by given filters */
  allNewRecurringClients: AllNewRecurringClients;
  allPackageDetails: AllPackageDetails;
  allWorkerSkills: Array<WorkerSkill>;
  allWorkers: AllWorkers;
  changeHistories: ChangeHistories;
  /** Find client by given id */
  client: Client;
  clientAddresses: ClientAddresses;
  /** Find client by given phone number */
  clientByPhoneNumber: Client;
  clientContacts: ClientContacts;
  clientConversations?: Maybe<ClientConversations>;
  /** Get client Hubstpot CRM object */
  clientCrmByFilters?: Maybe<ClientCrmByFilters>;
  clientFindBookingSchedule: Array<ClientScheduleVariant>;
  clientFindPastWorkers: Array<Worker>;
  clientFindPastWorkersV2: ClientFindPastWorkers;
  clientFindScheduleVariants: Array<ClientScheduleVariant>;
  clientInvoicePaymentsFromXero: ClientInvoiceHistory;
  clientIssueDetails: Array<ClientIssueDetails>;
  /** Find all client notes by given client id */
  clientNotesByClient: Array<ClientNote>;
  clientPackageRequests: Array<PackageRequest>;
  clientReferralByFilters: AllClientReferral;
  /** Find latest client referral code */
  clientReferralCode: ClientReferralCode;
  clientReviews: ClientReviews;
  clientViewVouchers: Array<ClientVoucher>;
  clientViewVouchersByFiltersV2: VouchersByFilters;
  /** Find clients by given search argument */
  clients: ClientConnection;
  completedOffboardings: AllWorkers;
  contract: Contract;
  contractsByClient?: Maybe<Array<Contract>>;
  /** Find a credit account by given id */
  creditAccountById: CreditAccount;
  /** Find all client credit accounts with filters */
  creditAccountsByFilters: CreditAccountsByFilters;
  cumulativeLifetimeRevenue: Array<CohortData>;
  customerLifetimeRevenue: Array<CohortData>;
  customerLifetimeValue: Array<CohortData>;
  customerRetentionByCohort: Array<CohortData>;
  dailySchedule: Array<Visit>;
  findAvailableWorkers: Array<Worker>;
  findCustomerCohortData: Array<CohortData>;
  findFreeTimeslots: Array<FreeTimeslot>;
  findLifeBarChartValue: Array<LifeBarChartData>;
  findRetoolData: RetoolData;
  findScheduleVariants: Array<ScheduleVariant>;
  findTotalFreeTimeslot: TotalFreeTimeslot;
  findVisitRelatedInvoices: Array<Invoice>;
  getClientVoucherById?: Maybe<ClientVoucher>;
  getMasterVouchers: Array<MasterVoucher>;
  getPromotion?: Maybe<Promotion>;
  getWorkerTip?: Maybe<WorkerTip>;
  /** Simple healthcheck to check that server is responding */
  healthcheck?: Maybe<Ping>;
  invoiceById: Invoice;
  invoicesByClient: Array<Invoice>;
  invoicesByContactNumber?: Maybe<Array<Invoice>>;
  invoicesByFilters: InvoicesByFilters;
  job: Job;
  jobsByFilters: JobsByFilters;
  jobsByPackage: Array<Job>;
  jobsByWorker: Array<Job>;
  leaveApplicationsByFilters: LeaveApplicationsByFilters;
  managerByFilters: ManagerByFilters;
  /** Find manager by id */
  managerById: Manager;
  managerRoleByFilters: ManagerRoleByFilters;
  masterNotificationByFilters: MasterNotificationByFilter;
  masterNotificationById: MasterNotification;
  masterPromoCodeByFilters: MasterPromoCodeByFilters;
  masterPromoCodeById: MasterPromoCode;
  masterRewardsByFilters: MasterRewardsByFilters;
  /** Check current mobile app version */
  mobileAppVersion?: Maybe<MobileAppVersion>;
  netRevenueByCohort: Array<CohortData>;
  netRevenueRetention: Array<CohortData>;
  notificationHistoryByFilters: NotificationHistoryByFilter;
  /** Find notification template by given id */
  notificationTemplateById: NotificationTemplate;
  notificationsByUser: Array<Notification>;
  package: Package;
  packageRequestsByFilters: PackageRequestsByFilters;
  packagesByContract?: Maybe<Array<Package>>;
  packagesByFilters: PackagesByFilters;
  payrollAdjustmentsByFilters: Array<PayrollAdjustment>;
  payrollById: Payroll;
  payrollsByFilters: PayrollsByFilters;
  projectedPayrollById: ProjectedPayroll;
  projectedPayrollsByFilters: ProjectedPayrollsByFilters;
  promotionByFilters: Promotions;
  ratingHistoryByFilter: AllRatingHistory;
  reservationById?: Maybe<Reservation>;
  rewardsByFilters: RewardsByFilters;
  salesAgentCommissionByFilters?: Maybe<SalesAgentCommissions>;
  sessionsByWorker?: Maybe<Array<Session>>;
  singleInvoicePaymentsFromXero: Array<XeroHistoryItem>;
  stripePaymentMethods: Array<StripePaymentMethod>;
  task: Task;
  transactionsByFilters: Array<Transaction>;
  uncompletedOffboardings: AllWorkers;
  unratedVisitByClient?: Maybe<Visit>;
  unratedVisitByToken?: Maybe<Visit>;
  unratedVisitsByFilters: UnratedVisitsByFilters;
  /** Find user by given email */
  userByEmail?: Maybe<User>;
  /** Find user by given id */
  userById?: Maybe<RegularUser>;
  userEmailExist: Scalars["Boolean"]["output"];
  /** All users */
  users?: Maybe<UserConnection>;
  validateContact: BooleanResult;
  /** Viewer (current user) */
  viewer?: Maybe<Viewer>;
  visit: Visit;
  visitAcknowledgementByFilters: VisitAcknowledgementByFilter;
  visitByClientWithToken?: Maybe<Visit>;
  visitReviewsFilter: VisitsByFilters;
  visitsByClient: Array<Visit>;
  visitsByContactNumber?: Maybe<Array<Visit>>;
  visitsByFilters: VisitsByFilters;
  visitsByJob?: Maybe<Array<Visit>>;
  visitsByWorker?: Maybe<Array<Visit>>;
  visitsForWorker: Array<Visit>;
  vouchersByFilters: VouchersByFilters;
  worker: Worker;
  workerAttendances: WorkerAttendances;
  workerContract: WorkerContract;
  workerContractDetails: Array<WorkerContractDetail>;
  workerContracts: Array<WorkerContract>;
  workerDocumentById: WorkerDocument;
  workerDocuments: WorkerDocuments;
  workerPackageRequests: WorkerPackageRequests;
  workerReferralByFilters: AllWorkerReferral;
  workerTask?: Maybe<WorkerTask>;
  workerTasksByFilters: WorkerTasksByFilters;
  workerTip: WorkerTip;
  workerTipsByFilters: WorkerTipsByFilters;
  workers: WorkerConnection;
  worklogByPayroll?: Maybe<Array<WorkerWorklog>>;
};

/** The query root of this schema */
export type QueryLeaveApplicationArgs = {
  id: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryAddressByPostalCodeArgs = {
  postalCode: Scalars["String"]["input"];
};

/** The query root of this schema */
export type QueryAllClientsArgs = {
  filters?: InputMaybe<ClientsFilter>;
};

/** The query root of this schema */
export type QueryAllNewRecurringClientsArgs = {
  filters?: InputMaybe<AllNewRecurringClientsFilter>;
};

/** The query root of this schema */
export type QueryAllPackageDetailsArgs = {
  filters?: InputMaybe<PackageDetailsFilter>;
};

/** The query root of this schema */
export type QueryAllWorkersArgs = {
  filters?: InputMaybe<AllWorkersFilter>;
};

/** The query root of this schema */
export type QueryChangeHistoriesArgs = {
  filters: ChangeHistoriesFilter;
};

/** The query root of this schema */
export type QueryClientArgs = {
  id: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryClientAddressesArgs = {
  filters: ClientAddressesFilterInput;
};

/** The query root of this schema */
export type QueryClientByPhoneNumberArgs = {
  phoneNumber: Scalars["String"]["input"];
};

/** The query root of this schema */
export type QueryClientContactsArgs = {
  filters: ClientContactsFilterInput;
};

/** The query root of this schema */
export type QueryClientConversationsArgs = {
  filters: ClientConversationsInput;
};

/** The query root of this schema */
export type QueryClientCrmByFiltersArgs = {
  filters: ClientCrmByFiltersFilter;
};

/** The query root of this schema */
export type QueryClientFindBookingScheduleArgs = {
  clientId?: InputMaybe<Scalars["ID"]["input"]>;
  department?: InputMaybe<WorkerDepartmentEnum>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  frequencyOfJob?: InputMaybe<ScheduleJobFrequencyEnum>;
  lineItemIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  packageDetailId?: InputMaybe<Scalars["Int"]["input"]>;
  postalCode: Scalars["String"]["input"];
  preferredWorkerIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  scheduleMatching?: InputMaybe<ScheduleMatchingEnum>;
  sessionDuration: Scalars["Int"]["input"];
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  startTime?: InputMaybe<Scalars["String"]["input"]>;
  timeRanges?: InputMaybe<Array<TimeRange>>;
  workerSkillIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  workersIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

/** The query root of this schema */
export type QueryClientFindPastWorkersArgs = {
  clientId: Scalars["ID"]["input"];
  department?: InputMaybe<PackageDepartmentEnum>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The query root of this schema */
export type QueryClientFindPastWorkersV2Args = {
  filters?: InputMaybe<ClientFindPastWorkersInput>;
};

/** The query root of this schema */
export type QueryClientFindScheduleVariantsArgs = {
  department: WorkerDepartmentEnum;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  frequencyOfJob?: InputMaybe<ScheduleJobFrequencyEnum>;
  lineItemIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  maxSearchDistance?: InputMaybe<Scalars["Int"]["input"]>;
  minSearchDistance?: InputMaybe<Scalars["Int"]["input"]>;
  postalCode: Scalars["String"]["input"];
  scheduleMatching?: InputMaybe<ScheduleMatchingEnum>;
  sessionDuration: Scalars["Int"]["input"];
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  timeRanges?: InputMaybe<Array<TimeRange>>;
  workerSkillIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  workersIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

/** The query root of this schema */
export type QueryClientInvoicePaymentsFromXeroArgs = {
  clientId: Scalars["ID"]["input"];
  endDate: Scalars["Date"]["input"];
  startDate: Scalars["Date"]["input"];
};

/** The query root of this schema */
export type QueryClientIssueDetailsArgs = {
  type: IssueTypeEnum;
};

/** The query root of this schema */
export type QueryClientNotesByClientArgs = {
  clientId: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryClientPackageRequestsArgs = {
  clientId: Scalars["ID"]["input"];
  status?: InputMaybe<Array<PackageRequestStatusEnum>>;
};

/** The query root of this schema */
export type QueryClientReferralByFiltersArgs = {
  filters?: InputMaybe<ClientReferralFilter>;
};

/** The query root of this schema */
export type QueryClientReferralCodeArgs = {
  clientId: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryClientReviewsArgs = {
  filters?: InputMaybe<ClientReviewsInput>;
};

/** The query root of this schema */
export type QueryClientViewVouchersArgs = {
  clientId: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryClientViewVouchersByFiltersV2Args = {
  filters?: InputMaybe<VouchersFilter>;
};

/** The query root of this schema */
export type QueryClientsArgs = {
  accountType?: InputMaybe<ClientAccountTypeEnum>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  nameLike?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<ClientStatusEnum>;
};

/** The query root of this schema */
export type QueryCompletedOffboardingsArgs = {
  filters?: InputMaybe<OffboardingsFilterInput>;
};

/** The query root of this schema */
export type QueryContractArgs = {
  id: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryContractsByClientArgs = {
  clientId: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryCreditAccountByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryCreditAccountsByFiltersArgs = {
  filters?: InputMaybe<CreditAccountsFilter>;
};

/** The query root of this schema */
export type QueryCumulativeLifetimeRevenueArgs = {
  retentionType: RetentionTypeEnum;
  year: Scalars["String"]["input"];
};

/** The query root of this schema */
export type QueryCustomerLifetimeRevenueArgs = {
  retentionType: RetentionTypeEnum;
  year: Scalars["String"]["input"];
};

/** The query root of this schema */
export type QueryCustomerLifetimeValueArgs = {
  retentionType: RetentionTypeEnum;
  year: Scalars["String"]["input"];
};

/** The query root of this schema */
export type QueryCustomerRetentionByCohortArgs = {
  activationBased?: InputMaybe<Scalars["Boolean"]["input"]>;
  firstChannelMedium?: InputMaybe<Scalars["String"]["input"]>;
  firstChannelSource?: InputMaybe<Scalars["String"]["input"]>;
  firstDeviceUsed?: InputMaybe<Scalars["String"]["input"]>;
  firstPlatformUsed?: InputMaybe<Scalars["String"]["input"]>;
  firstServiceBooked?: InputMaybe<Scalars["String"]["input"]>;
  firstSessionCreator?: InputMaybe<Scalars["String"]["input"]>;
  fromDate?: InputMaybe<Scalars["Date"]["input"]>;
  mobileAppUser?: InputMaybe<Scalars["Boolean"]["input"]>;
  percentage?: InputMaybe<Scalars["Boolean"]["input"]>;
  retentionType?: InputMaybe<RetentionTypeEnum>;
  toDate?: InputMaybe<Scalars["Date"]["input"]>;
};

/** The query root of this schema */
export type QueryDailyScheduleArgs = {
  assignedTo?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  day: Scalars["Date"]["input"];
  status?: InputMaybe<VisitStatusEnum>;
};

/** The query root of this schema */
export type QueryFindAvailableWorkersArgs = {
  department: WorkerDepartmentEnum;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  frequencyOfJob?: InputMaybe<ScheduleJobFrequencyEnum>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  postalCode: Scalars["String"]["input"];
  scheduleMatching?: InputMaybe<ScheduleMatchingEnum>;
  sessionDuration: Scalars["Int"]["input"];
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  timeRanges?: InputMaybe<Array<TimeRange>>;
};

/** The query root of this schema */
export type QueryFindCustomerCohortDataArgs = {
  retentionType: RetentionTypeEnum;
  year: Scalars["String"]["input"];
};

/** The query root of this schema */
export type QueryFindFreeTimeslotsArgs = {
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  frequencyOfJob: ScheduleJobFrequencyEnum;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  postalCode: Scalars["String"]["input"];
  scheduleMatching?: InputMaybe<ScheduleMatchingEnum>;
  sessionDuration: Scalars["Int"]["input"];
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  timeRanges?: InputMaybe<Array<TimeRange>>;
  workersIds: Array<Scalars["ID"]["input"]>;
};

/** The query root of this schema */
export type QueryFindLifeBarChartValueArgs = {
  retentionType: RetentionTypeEnum;
  year: Scalars["String"]["input"];
};

/** The query root of this schema */
export type QueryFindRetoolDataArgs = {
  postalCodes: Array<Scalars["String"]["input"]>;
  startDate: Scalars["Date"]["input"];
};

/** The query root of this schema */
export type QueryFindScheduleVariantsArgs = {
  department?: InputMaybe<WorkerDepartmentEnum>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  frequencyOfJob?: InputMaybe<ScheduleJobFrequencyEnum>;
  lineItemIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  maxSearchDistance?: InputMaybe<Scalars["Int"]["input"]>;
  minSearchDistance?: InputMaybe<Scalars["Int"]["input"]>;
  postalCode: Scalars["String"]["input"];
  scheduleMatching?: InputMaybe<ScheduleMatchingEnum>;
  sessionDuration: Scalars["Int"]["input"];
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  timeRanges?: InputMaybe<Array<TimeRange>>;
  workerSkillIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  workersIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

/** The query root of this schema */
export type QueryFindTotalFreeTimeslotArgs = {
  date: Scalars["DateTime"]["input"];
  frequencyOfJob: ScheduleJobFrequencyEnum;
  postalCode: Scalars["String"]["input"];
  timeSection?: InputMaybe<TimeSectionEnum>;
};

/** The query root of this schema */
export type QueryFindVisitRelatedInvoicesArgs = {
  visitId: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryGetClientVoucherByIdArgs = {
  id: Scalars["String"]["input"];
};

/** The query root of this schema */
export type QueryGetWorkerTipArgs = {
  filters: GetWorkerTipInput;
};

/** The query root of this schema */
export type QueryInvoiceByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryInvoicesByClientArgs = {
  clientId: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryInvoicesByContactNumberArgs = {
  filters: InvoicesByContactNumberFilters;
};

/** The query root of this schema */
export type QueryInvoicesByFiltersArgs = {
  filters?: InputMaybe<InvoicesFilter>;
};

/** The query root of this schema */
export type QueryJobArgs = {
  id: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryJobsByFiltersArgs = {
  filters?: InputMaybe<JobsFilter>;
};

/** The query root of this schema */
export type QueryJobsByPackageArgs = {
  packageId: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryJobsByWorkerArgs = {
  workerId: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryLeaveApplicationsByFiltersArgs = {
  filters?: InputMaybe<LeaveApplicationsFilter>;
};

/** The query root of this schema */
export type QueryManagerByFiltersArgs = {
  filters?: InputMaybe<ManagerByFiltersInput>;
};

/** The query root of this schema */
export type QueryManagerByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryManagerRoleByFiltersArgs = {
  filters?: InputMaybe<ManagerRoleByFiltersInput>;
};

/** The query root of this schema */
export type QueryMasterNotificationByFiltersArgs = {
  filters?: InputMaybe<MasterNotificationFilter>;
};

/** The query root of this schema */
export type QueryMasterNotificationByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryMasterPromoCodeByFiltersArgs = {
  filters?: InputMaybe<MasterPromoCodeFilter>;
};

/** The query root of this schema */
export type QueryMasterPromoCodeByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryMasterRewardsByFiltersArgs = {
  filters?: InputMaybe<MasterRewardsFilter>;
};

/** The query root of this schema */
export type QueryNetRevenueByCohortArgs = {
  retentionType: RetentionTypeEnum;
  year: Scalars["String"]["input"];
};

/** The query root of this schema */
export type QueryNetRevenueRetentionArgs = {
  retentionType: RetentionTypeEnum;
  year: Scalars["String"]["input"];
};

/** The query root of this schema */
export type QueryNotificationHistoryByFiltersArgs = {
  filters?: InputMaybe<NotificationHistoryFilter>;
};

/** The query root of this schema */
export type QueryNotificationTemplateByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryPackageArgs = {
  id: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryPackageRequestsByFiltersArgs = {
  filters?: InputMaybe<PackageRequestsFilter>;
};

/** The query root of this schema */
export type QueryPackagesByContractArgs = {
  contractId: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryPackagesByFiltersArgs = {
  filters: PackagesByFiltersInput;
};

/** The query root of this schema */
export type QueryPayrollAdjustmentsByFiltersArgs = {
  filters?: InputMaybe<PayrollAdjustmentsFilter>;
};

/** The query root of this schema */
export type QueryPayrollByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryPayrollsByFiltersArgs = {
  filters?: InputMaybe<PayrollsFilter>;
};

/** The query root of this schema */
export type QueryProjectedPayrollByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryProjectedPayrollsByFiltersArgs = {
  filters?: InputMaybe<ProjectedPayrollFilterInput>;
};

/** The query root of this schema */
export type QueryPromotionByFiltersArgs = {
  filters?: InputMaybe<PromotionFilter>;
};

/** The query root of this schema */
export type QueryRatingHistoryByFilterArgs = {
  filters?: InputMaybe<VisitReviewsFilter>;
};

/** The query root of this schema */
export type QueryReservationByIdArgs = {
  reservationId: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryRewardsByFiltersArgs = {
  filters?: InputMaybe<RewardsFilter>;
};

/** The query root of this schema */
export type QuerySalesAgentCommissionByFiltersArgs = {
  accountType?: InputMaybe<ClientAccountTypeEnum>;
  agentId?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  department?: InputMaybe<PackageDepartmentEnum>;
  fromDate?: InputMaybe<Scalars["Date"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  repeatEvery?: InputMaybe<PackageRecurrenceEnum>;
  repeatEveryTimes?: InputMaybe<Scalars["Int"]["input"]>;
  salesAgentShortcode?: InputMaybe<Array<Scalars["String"]["input"]>>;
  sort?: InputMaybe<Array<SortEnum>>;
  sortBy?: InputMaybe<Array<SalesAgentCommissionSortByEnum>>;
  toDate?: InputMaybe<Scalars["Date"]["input"]>;
};

/** The query root of this schema */
export type QuerySessionsByWorkerArgs = {
  serviceDateFrom?: InputMaybe<Scalars["Date"]["input"]>;
  serviceDateTo?: InputMaybe<Scalars["Date"]["input"]>;
  status?: InputMaybe<SessionStatusEnum>;
  workerId: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QuerySingleInvoicePaymentsFromXeroArgs = {
  invoiceId: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryStripePaymentMethodsArgs = {
  clientId?: InputMaybe<Scalars["ID"]["input"]>;
};

/** The query root of this schema */
export type QueryTaskArgs = {
  id: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryTransactionsByFiltersArgs = {
  filters?: InputMaybe<TransactionsFilter>;
};

/** The query root of this schema */
export type QueryUncompletedOffboardingsArgs = {
  filters?: InputMaybe<OffboardingsFilterInput>;
};

/** The query root of this schema */
export type QueryUnratedVisitByClientArgs = {
  clientId: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryUnratedVisitByTokenArgs = {
  token: Scalars["String"]["input"];
};

/** The query root of this schema */
export type QueryUnratedVisitsByFiltersArgs = {
  filters?: InputMaybe<UnratedVisitsByFiltersInput>;
};

/** The query root of this schema */
export type QueryUserByEmailArgs = {
  email: Scalars["String"]["input"];
};

/** The query root of this schema */
export type QueryUserByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryUserEmailExistArgs = {
  email: Scalars["String"]["input"];
};

/** The query root of this schema */
export type QueryUsersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The query root of this schema */
export type QueryValidateContactArgs = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
};

/** The query root of this schema */
export type QueryVisitArgs = {
  id: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryVisitAcknowledgementByFiltersArgs = {
  filters?: InputMaybe<VisitAcknowledgementFilter>;
};

/** The query root of this schema */
export type QueryVisitByClientWithTokenArgs = {
  token: Scalars["String"]["input"];
};

/** The query root of this schema */
export type QueryVisitReviewsFilterArgs = {
  filters?: InputMaybe<VisitReviewsFilter>;
};

/** The query root of this schema */
export type QueryVisitsByClientArgs = {
  clientId: Scalars["ID"]["input"];
  from: Scalars["Date"]["input"];
  status?: InputMaybe<Array<VisitStatusEnum>>;
  to: Scalars["Date"]["input"];
};

/** The query root of this schema */
export type QueryVisitsByContactNumberArgs = {
  filters: VisitsByContactNumberFilters;
};

/** The query root of this schema */
export type QueryVisitsByFiltersArgs = {
  filters?: InputMaybe<VisitsFilter>;
};

/** The query root of this schema */
export type QueryVisitsByJobArgs = {
  jobId: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryVisitsByWorkerArgs = {
  workerId: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryVisitsForWorkerArgs = {
  assignedTo: Scalars["ID"]["input"];
  from: Scalars["Date"]["input"];
  status?: InputMaybe<VisitStatusEnum>;
  to: Scalars["Date"]["input"];
};

/** The query root of this schema */
export type QueryVouchersByFiltersArgs = {
  filters?: InputMaybe<VouchersFilter>;
};

/** The query root of this schema */
export type QueryWorkerArgs = {
  id: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryWorkerAttendancesArgs = {
  attendanceStatus?: InputMaybe<Array<WorkerAttendanceStatusEnum>>;
  department?: InputMaybe<WorkerDepartmentEnum>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  managerId?: InputMaybe<Scalars["ID"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  sort?: InputMaybe<Array<SortEnum>>;
  sortBy?: InputMaybe<Array<WorkerAttendanceSortByEnum>>;
  submittedDate?: InputMaybe<Scalars["Date"]["input"]>;
  submittedDateFrom?: InputMaybe<Scalars["Date"]["input"]>;
  submittedDateTo?: InputMaybe<Scalars["Date"]["input"]>;
  workerId?: InputMaybe<Scalars["ID"]["input"]>;
};

/** The query root of this schema */
export type QueryWorkerContractArgs = {
  id: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryWorkerContractDetailsArgs = {
  filters?: InputMaybe<WorkerContractDetailsInputFilter>;
};

/** The query root of this schema */
export type QueryWorkerContractsArgs = {
  workerId: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryWorkerDocumentByIdArgs = {
  id: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryWorkerDocumentsArgs = {
  docType?: InputMaybe<WorkerDocumentTypeEnum>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<SortEnum>;
  sortBy?: InputMaybe<Scalars["String"]["input"]>;
  workerId?: InputMaybe<Scalars["ID"]["input"]>;
};

/** The query root of this schema */
export type QueryWorkerPackageRequestsArgs = {
  filters?: InputMaybe<WorkerPackageRequestsFilter>;
};

/** The query root of this schema */
export type QueryWorkerReferralByFiltersArgs = {
  department?: InputMaybe<Array<PackageDepartmentEnum>>;
  referrerId?: InputMaybe<Scalars["ID"]["input"]>;
  status?: InputMaybe<Array<WorkerStatusReferralEnum>>;
};

/** The query root of this schema */
export type QueryWorkerTaskArgs = {
  id: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryWorkerTasksByFiltersArgs = {
  filters?: InputMaybe<WorkerTasksFilter>;
};

/** The query root of this schema */
export type QueryWorkerTipArgs = {
  id: Scalars["ID"]["input"];
};

/** The query root of this schema */
export type QueryWorkerTipsByFiltersArgs = {
  filters?: InputMaybe<WorkerTipFilterInput>;
};

/** The query root of this schema */
export type QueryWorkersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  nameLike?: InputMaybe<Scalars["String"]["input"]>;
};

/** The query root of this schema */
export type QueryWorklogByPayrollArgs = {
  id: Scalars["ID"]["input"];
};

export type RatingHistory = {
  __typename?: "RatingHistory";
  date: Scalars["Date"]["output"];
  rating: Scalars["Float"]["output"];
  totalRatings: Scalars["Int"]["output"];
};

export enum ReceiverEnum {
  Client = "CLIENT",
  Ops = "OPS",
  Worker = "WORKER",
}

export type RegularUser = {
  __typename?: "RegularUser";
  avatarUrl?: Maybe<Scalars["String"]["output"]>;
  email: Scalars["String"]["output"];
  firstName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  location?: Maybe<Scalars["String"]["output"]>;
  managerDetail?: Maybe<Manager>;
  managerStatus?: Maybe<ManagerStatusEnum>;
  name?: Maybe<Scalars["String"]["output"]>;
  phoneNumber: Scalars["String"]["output"];
};

export enum RelatedObjectEnum {
  Billing = "BILLING",
  Client = "CLIENT",
  Contract = "CONTRACT",
  Job = "JOB",
  PinCode = "PIN_CODE",
  Referral = "REFERRAL",
  Reward = "REWARD",
  Upselling = "UPSELLING",
  User = "USER",
  Visit = "VISIT",
}

export type Reservation = {
  __typename?: "Reservation";
  client: Client;
  createdAt: Scalars["DateTime"]["output"];
  endTime: Scalars["String"]["output"];
  freeTimeslot: FreeTimeslot;
  frequencyOfJob: ScheduleJobFrequencyEnum;
  id: Scalars["ID"]["output"];
  serviceDate: Scalars["Date"]["output"];
  startTime: Scalars["String"]["output"];
  status: ReservationStatusEnum;
  updatedAt: Scalars["DateTime"]["output"];
};

export enum ResetPasswordEnum {
  ClaimAccount = "CLAIM_ACCOUNT",
  ConfirmEmail = "CONFIRM_EMAIL",
  ForgotPassword = "FORGOT_PASSWORD",
}

export type RetoolData = {
  __typename?: "RetoolData";
  averageByDay: Array<AverageByDay>;
  averageByTime: Array<AverageByTime>;
  averageOverall: Array<AverageOverall>;
  pastWeekFreeTimeslots: Array<PastWeekFreeTimeslot>;
};

export type Reward = {
  __typename?: "Reward";
  associatedUser: AssociatedUser;
  id: Scalars["ID"]["output"];
  masterReward: MasterReward;
  status?: Maybe<RewardStatusEnum>;
  validFrom: Scalars["Date"]["output"];
  validTo?: Maybe<Scalars["Date"]["output"]>;
};

export enum RewardSortByEnum {
  CreatedAt = "created_at",
  Status = "status",
  UpdatedAt = "updated_at",
}

export enum RewardStatusEnum {
  Available = "AVAILABLE",
  Cancelled = "CANCELLED",
  Earned = "EARNED",
  Used = "USED",
}

export type RewardsByFilters = {
  __typename?: "RewardsByFilters";
  count: Scalars["Int"]["output"];
  rewards: Array<Reward>;
};

export type RewardsFilter = {
  incentiveType?: InputMaybe<IncentiveTypesEnum>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  masterRewardId?: InputMaybe<Scalars["ID"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  sort?: InputMaybe<Array<SortEnum>>;
  sortBy?: InputMaybe<Array<RewardSortByEnum>>;
  status?: InputMaybe<Array<RewardStatusEnum>>;
  userId?: InputMaybe<Scalars["ID"]["input"]>;
  validTo?: InputMaybe<Scalars["Date"]["input"]>;
};

export type SalesAgentCommission = {
  __typename?: "SalesAgentCommission";
  client: Client;
  commissionValue: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  packageDescription: Scalars["String"]["output"];
  salesAgent: Scalars["String"]["output"];
};

export enum SalesAgentCommissionSortByEnum {
  CustomerFirstName = "customer_first_name",
  CustomerLastName = "customer_last_name",
  Id = "id",
}

export type SalesAgentCommissions = {
  __typename?: "SalesAgentCommissions";
  count: Scalars["Int"]["output"];
  salesAgentCommissions: Array<SalesAgentCommission>;
  totalCommission: Scalars["Float"]["output"];
};

/** return type for saved file */
export type SavedFile = {
  __typename?: "SavedFile";
  createdAt: Scalars["DateTime"]["output"];
  downloadUrl: Scalars["String"]["output"];
  filename: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  key: Scalars["String"]["output"];
};

export enum ScheduleJobFrequencyEnum {
  AdHoc = "AD_HOC",
  Fortnight = "FORTNIGHT",
  Weekly = "WEEKLY",
  Year = "YEAR",
}

export enum ScheduleMatchingEnum {
  All = "ALL",
  Any = "ANY",
}

export type ScheduleVariant = {
  __typename?: "ScheduleVariant";
  averageTravelTime: Scalars["Int"]["output"];
  combination: Array<FreeTimeslot>;
  note?: Maybe<Scalars["String"]["output"]>;
  worker: Worker;
};

/** Result of sendForgotPasswordResult mutation */
export type SendForgotPasswordResult = AccountDeleted | NotFoundProblem;

/** Result of successful send pin code to phone */
export type SendPinCodeToPhoneOk = {
  __typename?: "SendPinCodeToPhoneOk";
  expiresAt: Scalars["String"]["output"];
};

export enum ServiceTypeEnum {
  AddOn = "ADD_ON",
  Adjustment = "ADJUSTMENT",
  AdHoc = "AD_HOC",
  Aircon = "AIRCON",
  Babysitting = "BABYSITTING",
  CarpetUpholstery = "CARPET_UPHOLSTERY",
  ChemicalOverhaul = "CHEMICAL_OVERHAUL",
  ChemicalWash = "CHEMICAL_WASH",
  Combo = "COMBO",
  CondenserWash = "CONDENSER_WASH",
  DeepCleaning = "DEEP_CLEANING",
  DeepExtraction = "DEEP_EXTRACTION",
  Discount = "DISCOUNT",
  Dismantling = "DISMANTLING",
  Electrician = "ELECTRICIAN",
  Equipment = "EQUIPMENT",
  Fee = "FEE",
  GasLeakTesting = "GAS_LEAK_TESTING",
  GasTopUp = "GAS_TOP_UP",
  GeneralServicing = "GENERAL_SERVICING",
  Hardware = "HARDWARE",
  HomeCleaning = "HOME_CLEANING",
  Installation = "INSTALLATION",
  JetWash = "JET_WASH",
  Lashes = "LASHES",
  Manicure = "MANICURE",
  Massage = "MASSAGE",
  Mpv = "MPV",
  OfficeCleaning = "OFFICE_CLEANING",
  Pedicure = "PEDICURE",
  PetCare = "PET_CARE",
  Plumbing = "PLUMBING",
  RepairDiagnostic = "REPAIR_DIAGNOSTIC",
  RepairFollowUp = "REPAIR_FOLLOW_UP",
  Sedan = "SEDAN",
  SteamCleaning = "STEAM_CLEANING",
  Suv = "SUV",
  UltraVioletCleaning = "ULTRA_VIOLET_CLEANING",
  Upgrade = "UPGRADE",
  Warranty = "WARRANTY",
}

export type Session = {
  __typename?: "Session";
  actualEndTime?: Maybe<Scalars["String"]["output"]>;
  actualStartTime?: Maybe<Scalars["String"]["output"]>;
  client?: Maybe<Client>;
  endTime?: Maybe<Scalars["String"]["output"]>;
  formattedAddress: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  serviceDate: Scalars["Date"]["output"];
  sessionType: SessionTypeEnum;
  startTime?: Maybe<Scalars["String"]["output"]>;
  status: VisitStatusEnum;
  updatedAt: Scalars["DateTime"]["output"];
  worker?: Maybe<Worker>;
  workerContract?: Maybe<WorkerContract>;
};

export enum SessionStatusEnum {
  Completed = "COMPLETED",
  Expired = "EXPIRED",
  Scheduled = "SCHEDULED",
}

export enum SessionTypeEnum {
  Break = "BREAK",
  Task = "TASK",
  Visit = "VISIT",
}

export enum SexEnum {
  Female = "Female",
  Male = "Male",
  NotDecided = "NotDecided",
}

/** Result of success sign in for client */
export type SignInClientOk = {
  __typename?: "SignInClientOk";
  client: Client;
  jwt: Scalars["String"]["output"];
  jwtExpiry: Scalars["Int"]["output"];
  refreshToken: Scalars["String"]["output"];
};

/** Result of success sign in */
export type SignInOk = {
  __typename?: "SignInOk";
  jwt: Scalars["String"]["output"];
  jwtExpiry: Scalars["Int"]["output"];
  refreshToken: Scalars["String"]["output"];
  viewer: Viewer;
};

export enum SignInProviderEnum {
  Apple = "APPLE",
  Facebook = "FACEBOOK",
  Google = "GOOGLE",
  Password = "PASSWORD",
  PhoneNumber = "PHONE_NUMBER",
}

/** Result of success sign in for worker */
export type SignInWorkerOk = {
  __typename?: "SignInWorkerOk";
  jwt: Scalars["String"]["output"];
  jwtExpiry: Scalars["Int"]["output"];
  refreshToken: Scalars["String"]["output"];
  worker: Worker;
};

/** Result of success sign up */
export type SignUpOk = {
  __typename?: "SignUpOk";
  client: Client;
  creationSource: CreationUserSource;
  jwt: Scalars["String"]["output"];
  jwtExpiry: Scalars["Int"]["output"];
  refreshToken: Scalars["String"]["output"];
  viewer: Viewer;
};

export type SignUpRequired = {
  __typename?: "SignUpRequired";
  entity: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export enum SortByEnum {
  DiscountType = "discount_type",
  DiscountValue = "discount_value",
  ExpirationDate = "expiration_date",
  FirstTransactionOnly = "first_transaction_only",
  PromoCode = "promo_code",
}

export enum SortEnum {
  Asc = "ASC",
  Desc = "DESC",
}

export enum StatusTypeEnum {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export enum StripeCardBrandsEnum {
  AmericanExpress = "AMERICAN_EXPRESS",
  DinersClub = "DINERS_CLUB",
  Discover = "DISCOVER",
  Jcb = "JCB",
  MasterCard = "MASTER_CARD",
  UnionPay = "UNION_PAY",
  Unknown = "UNKNOWN",
  Visa = "VISA",
}

export type StripePaymentMethod = {
  __typename?: "StripePaymentMethod";
  brand: StripeCardBrandsEnum;
  cardType: Scalars["String"]["output"];
  country: Scalars["String"]["output"];
  expMonth: Scalars["String"]["output"];
  expYear: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  last4: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type Task = {
  __typename?: "Task";
  day: Scalars["Int"]["output"];
  endTime: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  package: Package;
  startTime: Scalars["String"]["output"];
  taskLineItems: Array<TaskLineItem>;
  visitLineItems: Array<VisitLineItem>;
};

export type TaskInput = {
  day: Scalars["Int"]["input"];
  endTime?: InputMaybe<Scalars["String"]["input"]>;
  startTime?: InputMaybe<Scalars["String"]["input"]>;
  workingHours?: InputMaybe<Array<TaskWorkingHoursInput>>;
};

export type TaskLineItem = {
  __typename?: "TaskLineItem";
  billingUnit: Scalars["String"]["output"];
  billingValue: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  packageDescription: Scalars["String"]["output"];
  packageDetail: PackageDetail;
  task: Task;
  unitValue: Scalars["Float"]["output"];
};

export enum TaskPaymentTypesEnum {
  ContractPayment = "CONTRACT_PAYMENT",
  CustomPayment = "CUSTOM_PAYMENT",
  NoPayment = "NO_PAYMENT",
}

export type TaskWorkingHoursInput = {
  endTime: Scalars["String"]["input"];
  startTime: Scalars["String"]["input"];
};

export type TimeRange = {
  day: Scalars["Int"]["input"];
  endTime: Scalars["String"]["input"];
  startTime: Scalars["String"]["input"];
};

export enum TimeSectionEnum {
  Afternoon = "AFTERNOON",
  All = "ALL",
  Evening = "EVENING",
  Morning = "MORNING",
}

export type TotalFreeTimeslot = {
  __typename?: "TotalFreeTimeslot";
  afternoon?: Maybe<Scalars["Int"]["output"]>;
  date?: Maybe<Scalars["Date"]["output"]>;
  evening?: Maybe<Scalars["Int"]["output"]>;
  freeTimeslotIds?: Maybe<Array<Scalars["Int"]["output"]>>;
  morning?: Maybe<Scalars["Int"]["output"]>;
};

export type Transaction = {
  __typename?: "Transaction";
  amount: Scalars["Float"]["output"];
  billingAccount: BillingAccount;
  billingCycle: BillingCycle;
  clientContractId?: Maybe<Scalars["ID"]["output"]>;
  comment?: Maybe<Scalars["String"]["output"]>;
  cpfContributable?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["ID"]["output"];
  linkedObjectId: Scalars["ID"]["output"];
  linkedObjectType: Scalars["ID"]["output"];
  status: TransactionStatusEnum;
  transactionType: TransactionTypeEnum;
};

export enum TransactionStatusEnum {
  Active = "ACTIVE",
  Cancelled = "CANCELLED",
  Deleted = "DELETED",
}

export enum TransactionTypeEnum {
  Allowance = "ALLOWANCE",
  Bonus = "BONUS",
  Deduction = "DEDUCTION",
  DiscountLineItem = "DISCOUNT_LINE_ITEM",
  Invoice = "INVOICE",
  Overtime = "OVERTIME",
  PackageLineItem = "PACKAGE_LINE_ITEM",
  Payroll = "PAYROLL",
  PayrollAdjustment = "PAYROLL_ADJUSTMENT",
  PayrollPayment = "PAYROLL_PAYMENT",
  Task = "TASK",
  TopUp = "TOP_UP",
  Visit = "VISIT",
  VisitLineItem = "VISIT_LINE_ITEM",
  VoucherDiscount = "VOUCHER_DISCOUNT",
}

export type TransactionsFilter = {
  billingAccountId?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  billingCycleId?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  status?: InputMaybe<Array<TransactionStatusEnum>>;
  transactionType?: InputMaybe<Array<TransactionTypeEnum>>;
};

export enum TriggerEnum {
  AutomaticWorker = "AUTOMATIC_WORKER",
  Event = "EVENT",
}

export enum UnitSizeEnum {
  All = "ALL",
  ArmChair = "ARM_CHAIR",
  BabyCot = "BABY_COT",
  Chair = "CHAIR",
  C_250SqftAbove = "C_250SQFT_ABOVE",
  C_600SqftBelow = "C_600SQFT_BELOW",
  C_601SqftTo_900Sqft = "C_601SQFT_TO_900SQFT",
  C_901SqftTo_1500Sqft = "C_901SQFT_TO_1500SQFT",
  C_4000SqftAbove = "C_4000SQFT_ABOVE",
  ExtraLarge = "EXTRA_LARGE",
  King = "KING",
  Large = "LARGE",
  Medium = "MEDIUM",
  Queen = "QUEEN",
  Seater_1 = "SEATER_1",
  Seater_2 = "SEATER_2",
  Seater_3 = "SEATER_3",
  Seater_4 = "SEATER_4",
  Seater_5 = "SEATER_5",
  Single = "SINGLE",
  Small = "SMALL",
  SuperKing = "SUPER_KING",
  SuperSingle = "SUPER_SINGLE",
}

export type UnratedVisitsByFilters = {
  __typename?: "UnratedVisitsByFilters";
  count: Scalars["Int"]["output"];
  visits: Array<Visit>;
};

export type UnratedVisitsByFiltersInput = {
  clientId: Scalars["ID"]["input"];
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<SortEnum>>;
  sortBy?: InputMaybe<Array<VisitSortByEnum>>;
};

export type UpdateAddressBatch = {
  fullAddress?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  primary?: InputMaybe<Scalars["Boolean"]["input"]>;
  unitNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateContactBatch = {
  email?: InputMaybe<Array<Scalars["String"]["input"]>>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Array<Scalars["String"]["input"]>>;
  primary?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Possible user types */
export type User = ClientUser | RegularUser | WorkerUser;

/** The connection type for User. */
export type UserConnection = {
  __typename?: "UserConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<User>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export enum UserDeviceEnum {
  Android = "ANDROID",
  Browser = "BROWSER",
  Ios = "IOS",
  Others = "OTHERS",
  System = "SYSTEM",
}

/** An edge in a connection. */
export type UserEdge = {
  __typename?: "UserEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

export enum UserEnum {
  Client = "Client",
  Regular = "Regular",
  Worker = "Worker",
}

/** linked object for package or user */
export type UserLinkedObject = ClientUser | Package | RegularUser | WorkerUser;

export enum UserPlatformEnum {
  ClientApp = "CLIENT_APP",
  ClientWeb = "CLIENT_WEB",
  InternalWeb = "INTERNAL_WEB",
  Script = "SCRIPT",
  WorkerApp = "WORKER_APP",
}

export type UserUpdateInput = {
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  lastName?: InputMaybe<Scalars["String"]["input"]>;
};

export type UtmInput = {
  campaign?: InputMaybe<Scalars["String"]["input"]>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  medium?: InputMaybe<Scalars["String"]["input"]>;
  source?: InputMaybe<Scalars["String"]["input"]>;
  term?: InputMaybe<Scalars["String"]["input"]>;
};

export type ValidationError = {
  __typename?: "ValidationError";
  field: Scalars["String"]["output"];
  messages: Array<Scalars["String"]["output"]>;
};

/** Happen when arguments in input is not valid */
export type ValidationProblem = {
  __typename?: "ValidationProblem";
  entity: Scalars["String"]["output"];
  errors?: Maybe<Array<ValidationError>>;
  message: Scalars["String"]["output"];
};

export type Viewer = {
  __typename?: "Viewer";
  birthDate?: Maybe<Scalars["Date"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  /** Current user */
  currentUser?: Maybe<User>;
  gender?: Maybe<SexEnum>;
  id: Scalars["ID"]["output"];
  isOnboarded: Scalars["Boolean"]["output"];
  isSafelisted: Scalars["Boolean"]["output"];
  location?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

/** Base user attributs */
export type ViewerDataInput = {
  avatarUrl?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
};

export type Visit = {
  __typename?: "Visit";
  actualEndTime?: Maybe<Scalars["String"]["output"]>;
  actualStartTime?: Maybe<Scalars["String"]["output"]>;
  assignment: VisitAssignmentEnum;
  billingAdjustmentAmount: Scalars["Float"]["output"];
  clientBillingValue?: Maybe<Scalars["Float"]["output"]>;
  clientName: Scalars["String"]["output"];
  endTime?: Maybe<Scalars["String"]["output"]>;
  formattedAddress: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  job?: Maybe<Job>;
  modificationLink?: Maybe<Scalars["String"]["output"]>;
  noteAttachments?: Maybe<Array<SavedFile>>;
  originalBillingAmount: Scalars["Float"]["output"];
  originalPayrollAmount: Scalars["Float"]["output"];
  originalWorker?: Maybe<Worker>;
  rating?: Maybe<Scalars["Float"]["output"]>;
  ratingComment?: Maybe<Scalars["String"]["output"]>;
  reportDateIssue?: Maybe<Scalars["DateTime"]["output"]>;
  requiredPackageWorkerSkillIds: Array<Scalars["ID"]["output"]>;
  reviewAttachments?: Maybe<Array<SavedFile>>;
  serviceDate: Scalars["Date"]["output"];
  staffReviewAttachments?: Maybe<Array<SavedFile>>;
  startTime?: Maybe<Scalars["String"]["output"]>;
  status: VisitStatusEnum;
  statusComment?: Maybe<Scalars["String"]["output"]>;
  task: Task;
  updatedAt: Scalars["DateTime"]["output"];
  visitBillingAdjustments?: Maybe<Array<VisitBillingAdjustment>>;
  visitLineItems: Array<VisitLineItem>;
  visitPayrollAdjustments?: Maybe<Array<VisitPayrollAdjustment>>;
  worker?: Maybe<Worker>;
  workerContract?: Maybe<WorkerContract>;
  workerPayrollValue?: Maybe<Scalars["Float"]["output"]>;
};

export type VisitAcknowledgement = {
  __typename?: "VisitAcknowledgement";
  acknowledgementType: AcknowledgementTypeEnum;
  createdAt: Scalars["Date"]["output"];
  id: Scalars["ID"]["output"];
  message: Scalars["String"]["output"];
  upcomingVisit: Visit;
  updatedAt: Scalars["Date"]["output"];
  visit: Visit;
  worker: Worker;
};

export type VisitAcknowledgementByFilter = {
  __typename?: "VisitAcknowledgementByFilter";
  count: Scalars["Int"]["output"];
  visitAcknowledgements: Array<VisitAcknowledgement>;
};

export type VisitAcknowledgementFilter = {
  acknowledgementType?: InputMaybe<AcknowledgementTypeEnum>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  serviceDateFrom?: InputMaybe<Scalars["Date"]["input"]>;
  serviceDateTo?: InputMaybe<Scalars["Date"]["input"]>;
  sort?: InputMaybe<Array<SortEnum>>;
  sortBy?: InputMaybe<Array<VisitSortByEnum>>;
  upcomingVisitId?: InputMaybe<Scalars["ID"]["input"]>;
  visitId?: InputMaybe<Scalars["ID"]["input"]>;
  workerId?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum VisitAssignmentEnum {
  Assigned = "ASSIGNED",
  Replaced = "REPLACED",
  Unassigned = "UNASSIGNED",
}

export type VisitBillingAdjustment = {
  __typename?: "VisitBillingAdjustment";
  adjustmentType: VisitBillingAdjustmentTypes;
  amount: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  reason?: Maybe<Scalars["String"]["output"]>;
  reasonType: VisitBillingAdjustmentReasonTypes;
  visit: Visit;
};

export enum VisitBillingAdjustmentReasonTypes {
  Auto = "AUTO",
  Manual = "MANUAL",
}

export enum VisitBillingAdjustmentTypes {
  Addon = "ADDON",
  Deduction = "DEDUCTION",
  Discount = "DISCOUNT",
}

export type VisitLineItem = {
  __typename?: "VisitLineItem";
  billingUnit: Scalars["String"]["output"];
  billingValue: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  packageDescription: Scalars["String"]["output"];
  packageDetail: PackageDetail;
  task: Task;
  unitValue: Scalars["Float"]["output"];
  units: Scalars["Float"]["output"];
  visit: Visit;
};

export type VisitLineItemInput = {
  billingUnit: PackageDetailBillingUnitEnum;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  packageDescription: Scalars["String"]["input"];
  packageDetailId: Scalars["ID"]["input"];
  unitValue: Scalars["Float"]["input"];
  units: Scalars["Float"]["input"];
};

export type VisitPayrollAdjustment = {
  __typename?: "VisitPayrollAdjustment";
  adjustmentType: VisitPayrollAdjustmentTypes;
  amount: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  reason?: Maybe<Scalars["String"]["output"]>;
  reasonType: VisitPayrollAdjustmentReasonTypes;
  visit: Visit;
  worker?: Maybe<Worker>;
};

export enum VisitPayrollAdjustmentReasonTypes {
  Auto = "AUTO",
  Manual = "MANUAL",
}

export enum VisitPayrollAdjustmentTypes {
  Addon = "ADDON",
  Deduction = "DEDUCTION",
}

export type VisitReviewsFilter = {
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  rating?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<SortEnum>>;
  sortBy?: InputMaybe<Array<VisitSortByEnum>>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  workerId?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum VisitSortByEnum {
  ClientBillingValue = "client_billing_value",
  CreatedAt = "created_at",
  EndTime = "end_time",
  Id = "id",
  ServiceDate = "service_date",
  StartTime = "start_time",
  Status = "status",
  TaskPackageAddressFullAddress = "task_package_address_full_address",
  TaskPackageContractClientName = "task_package_contract_client_name",
  UpdatedAt = "updated_at",
  WorkerFirstName = "worker_first_name",
  WorkerLastName = "worker_last_name",
}

export enum VisitStatusEnum {
  CancelledByClient = "CANCELLED_BY_CLIENT",
  CancelledByOps = "CANCELLED_BY_OPS",
  CancelledByWorker = "CANCELLED_BY_WORKER",
  Completed = "COMPLETED",
  Expired = "EXPIRED",
  Finished = "FINISHED",
  MissedByWorker = "MISSED_BY_WORKER",
  PendingClientSchedule = "PENDING_CLIENT_SCHEDULE",
  Scheduled = "SCHEDULED",
  Started = "STARTED",
  UnableToSchedule = "UNABLE_TO_SCHEDULE",
}

export type VisitsByContactNumberFilters = {
  contactNumber: Scalars["String"]["input"];
  department?: InputMaybe<PackageDepartmentEnum>;
  packageDescriptionLike?: InputMaybe<Scalars["String"]["input"]>;
  serviceDateFrom?: InputMaybe<Scalars["Date"]["input"]>;
  serviceDateTo?: InputMaybe<Scalars["Date"]["input"]>;
  statuses?: InputMaybe<Array<VisitStatusEnum>>;
  workerNameLike?: InputMaybe<Scalars["String"]["input"]>;
};

export type VisitsByFilters = {
  __typename?: "VisitsByFilters";
  count: Scalars["Int"]["output"];
  visits: Array<Visit>;
};

export type VisitsFilter = {
  assignment?: InputMaybe<Array<VisitAssignmentEnum>>;
  clientId?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  department?: InputMaybe<PackageDepartmentEnum>;
  from?: InputMaybe<Scalars["Date"]["input"]>;
  jobId?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  managerId?: InputMaybe<Scalars["ID"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  serviceDateFrom?: InputMaybe<Scalars["Date"]["input"]>;
  serviceDateTo?: InputMaybe<Scalars["Date"]["input"]>;
  serviceType?: InputMaybe<Array<PackageServiceTypeEnum>>;
  sort?: InputMaybe<Array<SortEnum>>;
  sortBy?: InputMaybe<Array<VisitSortByEnum>>;
  status?: InputMaybe<Array<VisitStatusEnum>>;
  to?: InputMaybe<Scalars["Date"]["input"]>;
  workerId?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export enum VoucherDiscountTypeEnum {
  Fixed = "FIXED",
  Percentage = "PERCENTAGE",
}

export enum VoucherStatusEnum {
  Active = "ACTIVE",
  Expired = "EXPIRED",
  Redeemed = "REDEEMED",
}

export type VouchersByFilters = {
  __typename?: "VouchersByFilters";
  count: Scalars["Int"]["output"];
  vouchers: Array<ClientVoucher>;
};

export type VouchersFilter = {
  clientId?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<VoucherStatusEnum>;
};

export type Worker = {
  __typename?: "Worker";
  accountNumber: Scalars["String"]["output"];
  address: Scalars["String"]["output"];
  addressCheckRequired?: Maybe<Scalars["Boolean"]["output"]>;
  addressUnitNumber?: Maybe<Scalars["String"]["output"]>;
  avatarUrl?: Maybe<Scalars["String"]["output"]>;
  bankName: BankNameEnum;
  billingAccount: BillingAccount;
  busyTimeslots?: Maybe<Array<BusyTimeslot>>;
  contactNumber: Scalars["String"]["output"];
  department: WorkerDepartmentEnum;
  dob: Scalars["Date"]["output"];
  documents?: Maybe<Array<SavedFile>>;
  email?: Maybe<Scalars["String"]["output"]>;
  emergencyContactName?: Maybe<Scalars["String"]["output"]>;
  emergencyContactNumber?: Maybe<Scalars["String"]["output"]>;
  employmentStartDate: Scalars["Date"]["output"];
  firstName: Scalars["String"]["output"];
  freeTimeslots?: Maybe<Array<FreeTimeslot>>;
  gender: SexEnum;
  id: Scalars["ID"]["output"];
  jobs?: Maybe<Array<Job>>;
  languageSpoken: Array<LanguageSpokenEnum>;
  lastName: Scalars["String"]["output"];
  lastWorkingDay?: Maybe<Scalars["Date"]["output"]>;
  leaveApplications?: Maybe<Array<LeaveApplication>>;
  manager?: Maybe<RegularUser>;
  nameOnId?: Maybe<Scalars["String"]["output"]>;
  nationality: NationalityEnum;
  notificationHistories?: Maybe<Array<NotificationHistory>>;
  nric: Scalars["String"]["output"];
  payrollAcknowledgementRequired: Scalars["Boolean"]["output"];
  postalCode: Scalars["String"]["output"];
  preferences: Scalars["JSON"]["output"];
  projectedPayrolls?: Maybe<Array<ProjectedPayroll>>;
  ratingsCount: Scalars["Int"]["output"];
  referralCodeValue?: Maybe<Scalars["String"]["output"]>;
  servicePostalCode: Scalars["String"]["output"];
  status: WorkerStatusEnum;
  techSavy: Scalars["Boolean"]["output"];
  totalCompletedVisitHoursInMonth?: Maybe<Scalars["Int"]["output"]>;
  totalCompletedVisits?: Maybe<Scalars["Int"]["output"]>;
  totalCompletedVisitsInMonth?: Maybe<Scalars["Int"]["output"]>;
  totalVisitHoursInMonth?: Maybe<Scalars["Int"]["output"]>;
  totalVisitsInMonth?: Maybe<Scalars["Int"]["output"]>;
  upsellingServices?: Maybe<Array<WorkerUpsellingServiceEnum>>;
  user: WorkerUser;
  visits?: Maybe<Array<Visit>>;
  workerAbilities: Array<WorkerAbilitiesEnum>;
  workerAttendances?: Maybe<Array<WorkerAttendance>>;
  workerContracts?: Maybe<Array<WorkerContract>>;
  workerLeaveAccount?: Maybe<WorkerLeaveAccount>;
  workerRating: Scalars["Float"]["output"];
  workerTasks?: Maybe<Array<WorkerTask>>;
  workerType: WorkerWorkerTypeEnum;
};

export type WorkerLeaveApplicationsArgs = {
  from?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<LeaveApplicationStatusEnum>;
  to?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type WorkerVisitsArgs = {
  from: Scalars["Date"]["input"];
  status?: InputMaybe<Array<VisitStatusEnum>>;
  to: Scalars["Date"]["input"];
};

export type WorkerWorkerContractsArgs = {
  status?: InputMaybe<WorkerContractStatusEnum>;
};

export type WorkerWorkerTasksArgs = {
  from: Scalars["Date"]["input"];
  to: Scalars["Date"]["input"];
};

export enum WorkerAbilitiesEnum {
  CanChangePackage = "CAN_CHANGE_PACKAGE",
  CanCompleteVisit = "CAN_COMPLETE_VISIT",
  CanEditVisitNotes = "CAN_EDIT_VISIT_NOTES",
  CanEditVisitSchedules = "CAN_EDIT_VISIT_SCHEDULES",
  CanModifyVisitLineItems = "CAN_MODIFY_VISIT_LINE_ITEMS",
  CanUpsell = "CAN_UPSELL",
  CanViewProofOfInvoice = "CAN_VIEW_PROOF_OF_INVOICE",
  CanViewVisitConnectedInvoice = "CAN_VIEW_VISIT_CONNECTED_INVOICE",
  CanViewVisitPrice = "CAN_VIEW_VISIT_PRICE",
}

export type WorkerAllowance = {
  __typename?: "WorkerAllowance";
  amount: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  workerContractPayrollPolicy: WorkerContractPayrollPolicy;
};

export type WorkerAttendance = {
  __typename?: "WorkerAttendance";
  attendancePhotos: Array<SavedFile>;
  attendanceStatus: WorkerAttendanceStatusEnum;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  submittedDate: Scalars["DateTime"]["output"];
  submittedDateFrom: Scalars["Date"]["output"];
  submittedDateTo: Scalars["Date"]["output"];
  submittedTime: Scalars["String"]["output"];
  worker: Worker;
};

export enum WorkerAttendanceSortByEnum {
  AttendanceStatus = "attendance_status",
  CreatedAt = "created_at",
  SubmittedDate = "submitted_date",
  UpdatedAt = "updated_at",
  WorkerDeparment = "worker_deparment",
  WorkerFirstName = "worker_first_name",
  WorkerLastName = "worker_last_name",
}

export enum WorkerAttendanceStatusEnum {
  Missed = "MISSED",
  NoJob = "NO_JOB",
  Submitted = "SUBMITTED",
}

export type WorkerAttendances = {
  __typename?: "WorkerAttendances";
  count: Scalars["Int"]["output"];
  workerAttendances: Array<WorkerAttendance>;
};

/** The connection type for Worker. */
export type WorkerConnection = {
  __typename?: "WorkerConnection";
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<WorkerEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Worker>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type WorkerContract = {
  __typename?: "WorkerContract";
  annualLeave: Scalars["Int"]["output"];
  contractName: Scalars["String"]["output"];
  contractType: ContractTypeEnum;
  createdAt: Scalars["Date"]["output"];
  endDate?: Maybe<Scalars["Date"]["output"]>;
  hospitalLeave: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  jobs?: Maybe<Array<Job>>;
  payrollUnit: ContractPayrollUnitEnum;
  portfolios?: Maybe<Array<SavedFile>>;
  proofOfAgreements?: Maybe<Array<SavedFile>>;
  sickLeave: Scalars["Int"]["output"];
  signedContractUrls?: Maybe<Array<Scalars["String"]["output"]>>;
  startDate: Scalars["Date"]["output"];
  status?: Maybe<WorkerContractStatusEnum>;
  unitValue: Scalars["Float"]["output"];
  workerContractDays?: Maybe<Array<WorkerContractDay>>;
  workerContractDetail: WorkerContractDetail;
  workerContractPayrollPolicies?: Maybe<Array<WorkerContractPayrollPolicy>>;
  workerContractSkills: Array<WorkerContractSkill>;
};

export type WorkerContractDay = {
  __typename?: "WorkerContractDay";
  day: Scalars["Int"]["output"];
  dayType: WorkerContractDayTypeEnum;
  endTime: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  restDay: Scalars["Boolean"]["output"];
  startTime: Scalars["String"]["output"];
  weekType: WorkerContractWeekTypeEnum;
  workerContract: WorkerContract;
};

export type WorkerContractDayInput = {
  day: Scalars["Int"]["input"];
  dayType: WorkerContractDayTypeEnum;
  endTime: Scalars["String"]["input"];
  restDay: Scalars["Boolean"]["input"];
  startTime: Scalars["String"]["input"];
  weekType: WorkerContractWeekTypeEnum;
};

export enum WorkerContractDayTypeEnum {
  /** Worker off day as a overtime work day */
  EnhancedWorkingOffDay = "ENHANCED_WORKING_OFF_DAY",
  /** Worker doesnt have to work on off day / real off day */
  NonWorkingOffDay = "NON_WORKING_OFF_DAY",
  /** Worker work day as a regular day */
  RegularWorkingDay = "REGULAR_WORKING_DAY",
  /** Worker off day as a standard work day */
  StandardWorkingOffDay = "STANDARD_WORKING_OFF_DAY",
}

export type WorkerContractDetail = {
  __typename?: "WorkerContractDetail";
  annualLeave: Scalars["Boolean"]["output"];
  contractName: Scalars["String"]["output"];
  contractType: ContractTypeEnum;
  createdAt: Scalars["Date"]["output"];
  fixedSkillAllowance: Scalars["Float"]["output"];
  hospitalLeave: Scalars["Boolean"]["output"];
  housingAllowance: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  paidTravelTime: Scalars["Boolean"]["output"];
  payrollUnit: ContractPayrollUnitEnum;
  sickLeave: Scalars["Boolean"]["output"];
  status: Scalars["String"]["output"];
  supervisorAllowance: Scalars["Float"]["output"];
  travelAllowance: Scalars["Float"]["output"];
  unitValue: Scalars["Float"]["output"];
  variablePerformanceAllowance: Scalars["Float"]["output"];
  variablePhoneAllowance: Scalars["Float"]["output"];
  workerSkill: WorkerSkill;
};

export type WorkerContractDetailsInputFilter = {
  contractType?: InputMaybe<ContractTypeEnum>;
  status?: InputMaybe<StatusTypeEnum>;
};

export type WorkerContractPayrollPolicy = {
  __typename?: "WorkerContractPayrollPolicy";
  id: Scalars["ID"]["output"];
  payload: Array<WorkerContractPayrollPolicyPayload>;
  payrollPolicy: PayrollPolicy;
  workerContract: WorkerContract;
};

export type WorkerContractPayrollPolicyPayload = {
  __typename?: "WorkerContractPayrollPolicyPayload";
  applicableAfterCalendarMonths?: Maybe<Scalars["Int"]["output"]>;
  deduction?: Maybe<Scalars["Int"]["output"]>;
  firstCalendarMonthsApplied?: Maybe<Scalars["Int"]["output"]>;
  max?: Maybe<Scalars["Int"]["output"]>;
  min?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  serviceType?: Maybe<PackageServiceTypeEnum>;
  value?: Maybe<Scalars["Int"]["output"]>;
  valueUnit?: Maybe<WorkerContractPayrollPolicyValueUnitEnums>;
};

export enum WorkerContractPayrollPolicyValueUnitEnums {
  Price = "PRICE",
  Session = "SESSION",
  Unit = "UNIT",
}

export type WorkerContractSkill = {
  __typename?: "WorkerContractSkill";
  id: Scalars["ID"]["output"];
  workerContract: WorkerContract;
  workerSkill: WorkerSkill;
};

export enum WorkerContractStatusEnum {
  Active = "ACTIVE",
  Terminated = "TERMINATED",
}

export enum WorkerContractWeekTypeEnum {
  All = "ALL",
  Even = "EVEN",
  Odd = "ODD",
}

export enum WorkerDepartmentEnum {
  Aircon = "AIRCON",
  Babysitter = "BABYSITTER",
  CarpetUpholstery = "CARPET_UPHOLSTERY",
  CarWash = "CAR_WASH",
  Handyman = "HANDYMAN",
  HomeBeauty = "HOME_BEAUTY",
  HomeCleaning = "HOME_CLEANING",
  Massage = "MASSAGE",
  OfficeCleaning = "OFFICE_CLEANING",
  PetCare = "PET_CARE",
}

export type WorkerDocument = {
  __typename?: "WorkerDocument";
  additionalDescription: Scalars["String"]["output"];
  docNumber?: Maybe<Scalars["String"]["output"]>;
  docType: WorkerDocumentTypeEnum;
  documents: Array<SavedFile>;
  expirationDate?: Maybe<Scalars["Date"]["output"]>;
  id: Scalars["ID"]["output"];
  issuanceDate?: Maybe<Scalars["Date"]["output"]>;
  worker: Worker;
  workerId: Scalars["ID"]["output"];
};

export enum WorkerDocumentTypeEnum {
  BankAccount = "BANK_ACCOUNT",
  CertificateVaccination = "CERTIFICATE_VACCINATION",
  NricIc = "NRIC_IC",
  Others = "OTHERS",
  Passport = "PASSPORT",
  ResignationLetter = "RESIGNATION_LETTER",
  TerminationLetter = "TERMINATION_LETTER",
  TrainingCertificate = "TRAINING_CERTIFICATE",
  WokerContract = "WOKER_CONTRACT",
  WorkPermit = "WORK_PERMIT",
}

export type WorkerDocuments = {
  __typename?: "WorkerDocuments";
  count: Scalars["Int"]["output"];
  workerDocuments: Array<WorkerDocument>;
};

/** An edge in a connection. */
export type WorkerEdge = {
  __typename?: "WorkerEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node?: Maybe<Worker>;
};

export type WorkerLeaveAccount = {
  __typename?: "WorkerLeaveAccount";
  annualLeaveAccrued: Scalars["Float"]["output"];
  annualLeaveBalance: Scalars["Float"]["output"];
  annualLeaveTaken: Scalars["Float"]["output"];
  hospitalLeaveAccrued: Scalars["Float"]["output"];
  hospitalLeaveBalance: Scalars["Float"]["output"];
  hospitalLeaveTaken: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  noPayLeaveTaken: Scalars["Float"]["output"];
  sickLeaveAccrued: Scalars["Float"]["output"];
  sickLeaveBalance: Scalars["Float"]["output"];
  sickLeaveTaken: Scalars["Float"]["output"];
  worker: Worker;
};

export enum WorkerPackageRequestSortByEnum {
  CreatedAt = "created_at",
  Id = "id",
  TravelTime = "travel_time",
  UpdatedAt = "updated_at",
}

export enum WorkerPackageRequestStatusEnum {
  Active = "ACTIVE",
  History = "HISTORY",
}

export type WorkerPackageRequests = {
  __typename?: "WorkerPackageRequests";
  count: Scalars["Int"]["output"];
  packageRequests: Array<PackageRequest>;
};

export type WorkerPackageRequestsFilter = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  repeatEvery?: InputMaybe<Array<PackageRecurrenceEnum>>;
  sort?: InputMaybe<SortEnum>;
  sortBy?: InputMaybe<WorkerPackageRequestSortByEnum>;
  status?: InputMaybe<WorkerPackageRequestStatusEnum>;
  workerId: Array<Scalars["ID"]["input"]>;
};

export type WorkerReferral = {
  __typename?: "WorkerReferral";
  commissionAmount: Scalars["Float"]["output"];
  commissionEarnedDate?: Maybe<Scalars["Date"]["output"]>;
  id: Scalars["ID"]["output"];
  package: Package;
  referralCodeId: Scalars["ID"]["output"];
  referred: Client;
  referrer: Worker;
  status: WorkerStatusReferralEnum;
};

export type WorkerSkill = {
  __typename?: "WorkerSkill";
  department: WorkerDepartmentEnum;
  id: Scalars["ID"]["output"];
  skillName: Scalars["String"]["output"];
};

export enum WorkerSortByEnum {
  ContactNumber = "contact_number",
  CreatedAt = "created_at",
  Department = "department",
  FirstName = "first_name",
  LastName = "last_name",
  LastWorkingDay = "last_working_day",
  Nationality = "nationality",
  Status = "status",
  UpdatedAt = "updated_at",
  WorkerRating = "worker_rating",
}

export enum WorkerStatusEnum {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export enum WorkerStatusReferralEnum {
  Cancelled = "CANCELLED",
  Paid = "PAID",
  Unpaid = "UNPAID",
}

export type WorkerTask = {
  __typename?: "WorkerTask";
  accessInstruction?: Maybe<Scalars["String"]["output"]>;
  additionalNotes?: Maybe<Scalars["String"]["output"]>;
  endTime: Scalars["String"]["output"];
  fullAddress: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  paymentAmount: Scalars["Float"]["output"];
  paymentType: TaskPaymentTypesEnum;
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
  postalCode: Scalars["String"]["output"];
  startTime: Scalars["String"]["output"];
  status: WorkerTaskStatusesEnum;
  taskDate: Scalars["Date"]["output"];
  unitNumber?: Maybe<Scalars["String"]["output"]>;
  worker: Worker;
  workerContractId?: Maybe<Scalars["ID"]["output"]>;
  workerId: Scalars["ID"]["output"];
};

export enum WorkerTaskSortByEnum {
  CreatedAt = "created_at",
  Id = "id",
  Status = "status",
  TaskDate = "task_date",
  UpdatedAt = "updated_at",
}

export enum WorkerTaskStatusesEnum {
  Completed = "COMPLETED",
  Expired = "EXPIRED",
  Scheduled = "SCHEDULED",
}

export type WorkerTasksByFilters = {
  __typename?: "WorkerTasksByFilters";
  count: Scalars["Int"]["output"];
  workerTasks: Array<WorkerTask>;
};

export type WorkerTasksFilter = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<SortEnum>>;
  sortBy?: InputMaybe<Array<WorkerTaskSortByEnum>>;
  status?: InputMaybe<Array<WorkerTaskStatusesEnum>>;
  taskDateFrom?: InputMaybe<Scalars["Date"]["input"]>;
  taskDateTo?: InputMaybe<Scalars["Date"]["input"]>;
  workerContractId?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  workerId?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type WorkerTip = {
  __typename?: "WorkerTip";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  department: WorkerDepartmentEnum;
  id: Scalars["ID"]["output"];
  message: Scalars["String"]["output"];
  nationality: NationalityEnum;
  title: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type WorkerTipFilterInput = {
  department?: InputMaybe<WorkerDepartmentEnum>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  nationality?: InputMaybe<NationalityEnum>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  sort?: InputMaybe<Array<SortEnum>>;
  sortBy?: InputMaybe<Array<WorkerTipSortByEnum>>;
};

export enum WorkerTipSortByEnum {
  CreatedAt = "created_at",
  Department = "department",
  Nationality = "nationality",
  UpdatedAt = "updated_at",
}

export type WorkerTipsByFilters = {
  __typename?: "WorkerTipsByFilters";
  count: Scalars["Int"]["output"];
  workerTips: Array<WorkerTip>;
};

export enum WorkerUpsellingServiceEnum {
  Aircon = "AIRCON",
  CarpetUpholstery = "CARPET_UPHOLSTERY",
  HomeBeauty = "HOME_BEAUTY",
  HomeCleaning = "HOME_CLEANING",
  MattressCleaning = "MATTRESS_CLEANING",
  OfficeCleaning = "OFFICE_CLEANING",
  SofaCleaning = "SOFA_CLEANING",
}

export type WorkerUser = {
  __typename?: "WorkerUser";
  email: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  lastLogin?: Maybe<Scalars["Date"]["output"]>;
  phoneNumber: Scalars["String"]["output"];
  totalExpoPushNotificationTokens: Scalars["Int"]["output"];
  worker?: Maybe<Worker>;
};

export enum WorkerWorkerTypeEnum {
  Cleaner = "CLEANER",
  Technician = "TECHNICIAN",
}

export type WorkerWorklog = {
  __typename?: "WorkerWorklog";
  description: Scalars["String"]["output"];
  endTime?: Maybe<Scalars["String"]["output"]>;
  formattedAddress?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  serviceDate: Scalars["Date"]["output"];
  sessionType: SessionTypeEnum;
  startTime?: Maybe<Scalars["String"]["output"]>;
  worker?: Maybe<Worker>;
};

export type XeroHistoryItem = {
  __typename?: "XeroHistoryItem";
  amount: Scalars["Float"]["output"];
  date: Scalars["DateTime"]["output"];
  invoiceId?: Maybe<Scalars["String"]["output"]>;
  invoiceIssueDate?: Maybe<Scalars["DateTime"]["output"]>;
  paymentRef?: Maybe<Scalars["String"]["output"]>;
  paymentStatus?: Maybe<XeroInvoiceStatusEnum>;
  type: XeroHistoryItemTypesEnum;
};

export enum XeroHistoryItemTypesEnum {
  Invoice = "INVOICE",
  Payment = "PAYMENT",
}

export enum XeroInvoiceStatusEnum {
  Authorised = "AUTHORISED",
  Deleted = "DELETED",
  Draft = "DRAFT",
  Paid = "PAID",
  Voided = "VOIDED",
}

/** Autogenerated input type of acknowledgePayroll */
export type AcknowledgePayrollInput = {
  acknowledgeAt: Scalars["DateTime"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  payrollId: Scalars["ID"]["input"];
  signatureFile: Scalars["UploadedFile"]["input"];
};

/** Autogenerated return type of acknowledgePayroll */
export type AcknowledgePayrollPayload = {
  __typename?: "acknowledgePayrollPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  payroll: Payroll;
};

export enum AcknowledgementTypeEnum {
  BreakTimeAcknowledgement = "BREAK_TIME_ACKNOWLEDGEMENT",
  EarlierCompletedVisit = "EARLIER_COMPLETED_VISIT",
  WorkingTimeAcknowledgement = "WORKING_TIME_ACKNOWLEDGEMENT",
}

/** Autogenerated input type of addClientDocuments */
export type AddClientDocumentsInput = {
  clientId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  files: Array<Scalars["UploadedFile"]["input"]>;
};

/** Autogenerated return type of addClientDocuments */
export type AddClientDocumentsPayload = {
  __typename?: "addClientDocumentsPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  uploadedFiles: Array<SavedFile>;
};

/** Autogenerated input type of addContractDocument */
export type AddContractDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  contractId: Scalars["ID"]["input"];
  file: Scalars["UploadedFile"]["input"];
};

/** Autogenerated return type of addContractDocument */
export type AddContractDocumentPayload = {
  __typename?: "addContractDocumentPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  uploadedFiles: SavedFile;
};

/** Autogenerated input type of addPaymentProof */
export type AddPaymentProofInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  file: Scalars["UploadedFile"]["input"];
  invoiceId: Scalars["ID"]["input"];
};

/** Autogenerated return type of addPaymentProof */
export type AddPaymentProofPayload = {
  __typename?: "addPaymentProofPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  uploadedFile: SavedFile;
};

/** Autogenerated input type of addPortfolios */
export type AddPortfoliosInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  files: Array<Scalars["UploadedFile"]["input"]>;
  workerContractId: Scalars["ID"]["input"];
};

/** Autogenerated return type of addPortfolios */
export type AddPortfoliosPayload = {
  __typename?: "addPortfoliosPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  uploadedFiles: Array<SavedFile>;
};

/** Autogenerated input type of addProofOfAgreements */
export type AddProofOfAgreementsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  contractId: Scalars["ID"]["input"];
  files: Array<Scalars["UploadedFile"]["input"]>;
};

/** Autogenerated return type of addProofOfAgreements */
export type AddProofOfAgreementsPayload = {
  __typename?: "addProofOfAgreementsPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  uploadedFiles: Array<SavedFile>;
};

/** Autogenerated input type of addStaffReviewAttachment */
export type AddStaffReviewAttachmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  files: Array<Scalars["UploadedFile"]["input"]>;
  visitId: Scalars["ID"]["input"];
};

/** Autogenerated return type of addStaffReviewAttachment */
export type AddStaffReviewAttachmentPayload = {
  __typename?: "addStaffReviewAttachmentPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  visit: Visit;
};

/** Autogenerated input type of addVisitLineItem */
export type AddVisitLineItemInput = {
  billingUnit: PackageDetailBillingUnitEnum;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  packageDescription: Scalars["String"]["input"];
  packageDetailId: Scalars["ID"]["input"];
  unitValue: Scalars["Float"]["input"];
  visitId: Scalars["ID"]["input"];
};

/** Autogenerated return type of addVisitLineItem */
export type AddVisitLineItemPayload = {
  __typename?: "addVisitLineItemPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  visitLineItem: VisitLineItem;
};

/** Autogenerated input type of addVisitNoteAttachments */
export type AddVisitNoteAttachmentsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  files: Array<Scalars["UploadedFile"]["input"]>;
  visitId: Scalars["ID"]["input"];
};

/** Autogenerated return type of addVisitNoteAttachments */
export type AddVisitNoteAttachmentsPayload = {
  __typename?: "addVisitNoteAttachmentsPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  uploadedFiles: Array<SavedFile>;
};

/** Autogenerated input type of addWorkerDocumentFiles */
export type AddWorkerDocumentFilesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  files: Array<Scalars["UploadedFile"]["input"]>;
  workerDocumentId: Scalars["ID"]["input"];
};

/** Autogenerated return type of addWorkerDocumentFiles */
export type AddWorkerDocumentFilesPayload = {
  __typename?: "addWorkerDocumentFilesPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  workerDocuments: Array<SavedFile>;
};

/** Autogenerated input type of addWorkerDocument */
export type AddWorkerDocumentInput = {
  additionalDescription: Scalars["String"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  docNumber?: InputMaybe<Scalars["String"]["input"]>;
  docType: WorkerDocumentTypeEnum;
  expirationDate?: InputMaybe<Scalars["Date"]["input"]>;
  files: Array<Scalars["UploadedFile"]["input"]>;
  issuanceDate?: InputMaybe<Scalars["Date"]["input"]>;
  workerId: Scalars["ID"]["input"];
};

/** Autogenerated return type of addWorkerDocument */
export type AddWorkerDocumentPayload = {
  __typename?: "addWorkerDocumentPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  workerDocument: WorkerDocument;
};

/** Autogenerated input type of applyPayroll */
export type ApplyPayrollInput = {
  amount: Scalars["Float"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  paymentReason: PaymentTransactionReasonsEnum;
  payrollId: Scalars["ID"]["input"];
  referenceNumber?: InputMaybe<Scalars["String"]["input"]>;
  transferMode: PaymentTransactionModesEnum;
};

/** Autogenerated return type of applyPayroll */
export type ApplyPayrollPayload = {
  __typename?: "applyPayrollPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  payroll: Payroll;
};

/** Autogenerated input type of approveLeaveApplication */
export type ApproveLeaveApplicationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  leaveApplicationId: Scalars["ID"]["input"];
};

/** Autogenerated return type of approveLeaveApplication */
export type ApproveLeaveApplicationPayload = {
  __typename?: "approveLeaveApplicationPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  leaveApplication: LeaveApplication;
};

/** Autogenerated input type of assignJob */
export type AssignJobInput = {
  allowOverlap?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  jobId: Scalars["ID"]["input"];
  workerContractId?: InputMaybe<Scalars["ID"]["input"]>;
  workerId?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Autogenerated return type of assignJob */
export type AssignJobPayload = {
  __typename?: "assignJobPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  job: Job;
};

/** Autogenerated input type of assignPackageRequest */
export type AssignPackageRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  packageRequestId: Scalars["ID"]["input"];
  workerContractId?: InputMaybe<Scalars["ID"]["input"]>;
  workerId: Scalars["ID"]["input"];
};

/** Autogenerated return type of assignPackageRequest */
export type AssignPackageRequestPayload = {
  __typename?: "assignPackageRequestPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of assignTasks */
export type AssignTasksInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  startDate: Scalars["Date"]["input"];
  tasks: Array<JobTaskInput>;
  workerContractId: Scalars["ID"]["input"];
  workerId: Scalars["ID"]["input"];
};

/** Autogenerated return type of assignTasks */
export type AssignTasksPayload = {
  __typename?: "assignTasksPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  job: Job;
};

/** Autogenerated input type of assignVisit */
export type AssignVisitInput = {
  allowOverlap?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  visitId: Scalars["ID"]["input"];
  workerContractId?: InputMaybe<Scalars["ID"]["input"]>;
  workerId?: InputMaybe<Scalars["ID"]["input"]>;
  workerPayrollValue?: InputMaybe<Scalars["Float"]["input"]>;
};

/** Autogenerated return type of assignVisit */
export type AssignVisitPayload = {
  __typename?: "assignVisitPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  visit: Visit;
};

/** Autogenerated input type of cancelLeaveApplication */
export type CancelLeaveApplicationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  leaveApplicationId: Scalars["ID"]["input"];
};

/** Autogenerated return type of cancelLeaveApplication */
export type CancelLeaveApplicationPayload = {
  __typename?: "cancelLeaveApplicationPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  leaveApplication: LeaveApplication;
};

/** Autogenerated input type of cancelVisit */
export type CancelVisitInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  reason?: InputMaybe<CancelVisitReasonEnum>;
  visitId: Scalars["ID"]["input"];
};

/** Autogenerated return type of cancelVisit */
export type CancelVisitPayload = {
  __typename?: "cancelVisitPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  visit: Visit;
};

/** Autogenerated input type of cancelVisitWithToken */
export type CancelVisitWithTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  confirmations?: InputMaybe<Array<ConfirmationType>>;
  reason?: InputMaybe<CancelVisitReasonEnum>;
  token: Scalars["String"]["input"];
};

/** Autogenerated return type of cancelVisitWithToken */
export type CancelVisitWithTokenPayload = {
  __typename?: "cancelVisitWithTokenPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  visit: Visit;
};

/** Autogenerated input type of changePackage */
export type ChangePackageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  lineItems: Array<LineItemInput>;
  packageId: Scalars["ID"]["input"];
  repeatEveryTimes: Scalars["Int"]["input"];
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startTime?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of changePackage */
export type ChangePackagePayload = {
  __typename?: "changePackagePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result?: Maybe<ChangePackageResult>;
};

/** Result of changePackage mutation */
export type ChangePackageResult = ChangePackageOk | ValidationProblem;

/** Autogenerated input type of claimReward */
export type ClaimRewardInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

/** Autogenerated return type of claimReward */
export type ClaimRewardPayload = {
  __typename?: "claimRewardPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  reward: Reward;
};

/** Autogenerated input type of clientApplyCredit */
export type ClientApplyCreditInput = {
  amount: Scalars["Float"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  invoiceId: Scalars["ID"]["input"];
};

/** Autogenerated return type of clientApplyCredit */
export type ClientApplyCreditPayload = {
  __typename?: "clientApplyCreditPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of clientApplyVoucher */
export type ClientApplyVoucherInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  invoiceId: Scalars["ID"]["input"];
  voucherId: Scalars["ID"]["input"];
};

/** Autogenerated return type of clientApplyVoucher */
export type ClientApplyVoucherPayload = {
  __typename?: "clientApplyVoucherPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  invoice: Invoice;
  success: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of clientCancelReservation */
export type ClientCancelReservationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  reservationIds: Array<Scalars["ID"]["input"]>;
};

/** Autogenerated return type of clientCancelReservation */
export type ClientCancelReservationPayload = {
  __typename?: "clientCancelReservationPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  reservations: Array<Reservation>;
};

/** Autogenerated input type of clientCancelVisit */
export type ClientCancelVisitInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  confirmations?: InputMaybe<Array<ConfirmationType>>;
  reason?: InputMaybe<CancelVisitReasonEnum>;
  visitId: Scalars["ID"]["input"];
};

/** Autogenerated return type of clientCancelVisit */
export type ClientCancelVisitPayload = {
  __typename?: "clientCancelVisitPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  visit: Visit;
};

/** Autogenerated input type of clientConfirmPhoneNumber */
export type ClientConfirmPhoneNumberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  onSignUp?: InputMaybe<Scalars["Boolean"]["input"]>;
  phone: Scalars["String"]["input"];
};

/** Autogenerated return type of clientConfirmPhoneNumber */
export type ClientConfirmPhoneNumberPayload = {
  __typename?: "clientConfirmPhoneNumberPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of clientConfirmReservation */
export type ClientConfirmReservationInput = {
  accessType?: InputMaybe<Scalars["String"]["input"]>;
  additionalNotes?: InputMaybe<Scalars["String"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  contractId?: InputMaybe<Scalars["ID"]["input"]>;
  creationSource?: InputMaybe<CreationSourceInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  fullAddress: Scalars["String"]["input"];
  postalCode: Scalars["String"]["input"];
  promoCode?: InputMaybe<Scalars["String"]["input"]>;
  reservationId: Scalars["ID"]["input"];
  salespersonShortcode?: InputMaybe<Scalars["String"]["input"]>;
  unitNumber?: InputMaybe<Scalars["String"]["input"]>;
  workerChosenByClient?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Autogenerated return type of clientConfirmReservation */
export type ClientConfirmReservationPayload = {
  __typename?: "clientConfirmReservationPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  package: Package;
};

/** Autogenerated input type of clientCreateReservation */
export type ClientCreateReservationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  endTime: Scalars["String"]["input"];
  frequencyOfJob: ScheduleJobFrequencyEnum;
  lineItemIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  serviceDate: Scalars["Date"]["input"];
  slotId: Scalars["ID"]["input"];
  startTime: Scalars["String"]["input"];
};

/** Autogenerated return type of clientCreateReservation */
export type ClientCreateReservationPayload = {
  __typename?: "clientCreateReservationPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result?: Maybe<ClientCreateReservationResult>;
};

/** Result of clientCreateReservation mutation */
export type ClientCreateReservationResult =
  | BookingSuspended
  | ClientCreateReservationOk
  | ValidationProblem;

/** Autogenerated input type of clientRemoveVoucher */
export type ClientRemoveVoucherInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  invoiceId: Scalars["ID"]["input"];
};

/** Autogenerated return type of clientRemoveVoucher */
export type ClientRemoveVoucherPayload = {
  __typename?: "clientRemoveVoucherPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  invoice: Invoice;
  success: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of clientRescheduleVisit */
export type ClientRescheduleVisitInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  endTime?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  serviceDate?: InputMaybe<Scalars["Date"]["input"]>;
  startTime?: InputMaybe<Scalars["String"]["input"]>;
  workerId?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Autogenerated return type of clientRescheduleVisit */
export type ClientRescheduleVisitPayload = {
  __typename?: "clientRescheduleVisitPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  visit: Visit;
};

/** Autogenerated input type of clientRescheduleVisitWithToken */
export type ClientRescheduleVisitWithTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  endTime: Scalars["String"]["input"];
  serviceDate: Scalars["Date"]["input"];
  startTime: Scalars["String"]["input"];
  statusComment?: InputMaybe<Scalars["String"]["input"]>;
  token: Scalars["String"]["input"];
  workerId: Scalars["ID"]["input"];
};

/** Autogenerated return type of clientRescheduleVisitWithToken */
export type ClientRescheduleVisitWithTokenPayload = {
  __typename?: "clientRescheduleVisitWithTokenPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  visit: Visit;
};

/** Autogenerated input type of clientValidatePromoCode */
export type ClientValidatePromoCodeInput = {
  clientId?: InputMaybe<Scalars["ID"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  lineItemIds: Array<Scalars["ID"]["input"]>;
  promoCode: Scalars["String"]["input"];
};

/** Autogenerated return type of clientValidatePromoCode */
export type ClientValidatePromoCodePayload = {
  __typename?: "clientValidatePromoCodePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  promo: MasterPromoCode;
};

/** Autogenerated input type of clientVerifyPhoneNumber */
export type ClientVerifyPhoneNumberInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  enableWhatsappOtp?: InputMaybe<Scalars["Boolean"]["input"]>;
  inAndroidFormat?: InputMaybe<Scalars["Boolean"]["input"]>;
  onSignUp?: InputMaybe<Scalars["Boolean"]["input"]>;
  phone: Scalars["String"]["input"];
};

/** Autogenerated return type of clientVerifyPhoneNumber */
export type ClientVerifyPhoneNumberPayload = {
  __typename?: "clientVerifyPhoneNumberPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result?: Maybe<SendPinCodeToPhoneResult>;
};

/** Autogenerated input type of completeVisit */
export type CompleteVisitInput = {
  actualEndTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  actualStartTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  confirmations?: InputMaybe<Array<ConfirmationType>>;
  skipConfirmation?: InputMaybe<Scalars["Boolean"]["input"]>;
  visitId: Scalars["ID"]["input"];
};

/** Autogenerated return type of completeVisit */
export type CompleteVisitPayload = {
  __typename?: "completeVisitPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  visit: Visit;
};

/** Autogenerated input type of completeWorkerTask */
export type CompleteWorkerTaskInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  workerTaskId: Scalars["ID"]["input"];
};

/** Autogenerated return type of completeWorkerTask */
export type CompleteWorkerTaskPayload = {
  __typename?: "completeWorkerTaskPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  workerTask?: Maybe<WorkerTask>;
};

/** Autogenerated input type of confirmInvoiceBatch */
export type ConfirmInvoiceBatchInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  invoicesIds: Array<Scalars["ID"]["input"]>;
};

/** Autogenerated return type of confirmInvoiceBatch */
export type ConfirmInvoiceBatchPayload = {
  __typename?: "confirmInvoiceBatchPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors?: Maybe<Array<InvoiceErrors>>;
  invoices: Array<Invoice>;
  result: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of confirmInvoice */
export type ConfirmInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  invoiceId: Scalars["ID"]["input"];
};

/** Autogenerated return type of confirmInvoice */
export type ConfirmInvoicePayload = {
  __typename?: "confirmInvoicePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  invoice: Invoice;
};

/** Autogenerated input type of confirmPackageRequest */
export type ConfirmPackageRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  packageRequestId: Scalars["ID"]["input"];
};

/** Autogenerated return type of confirmPackageRequest */
export type ConfirmPackageRequestPayload = {
  __typename?: "confirmPackageRequestPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of confirmPayrollBatch */
export type ConfirmPayrollBatchInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  payrollsIds: Array<Scalars["ID"]["input"]>;
};

/** Autogenerated return type of confirmPayrollBatch */
export type ConfirmPayrollBatchPayload = {
  __typename?: "confirmPayrollBatchPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  payrolls: Array<Payroll>;
};

/** Autogenerated input type of confirmPayroll */
export type ConfirmPayrollInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  payrollId: Scalars["ID"]["input"];
};

/** Autogenerated return type of confirmPayroll */
export type ConfirmPayrollPayload = {
  __typename?: "confirmPayrollPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  payroll: Payroll;
};

/** Autogenerated input type of convertBankStatementCsv */
export type ConvertBankStatementCsvInput = {
  bankAccount: BankAccountEnum;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  fileString: Scalars["String"]["input"];
};

/** Autogenerated return type of convertBankStatementCsv */
export type ConvertBankStatementCsvPayload = {
  __typename?: "convertBankStatementCsvPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Bas64 encoded csv file as a string */
  fileString: Scalars["String"]["output"];
};

/** Autogenerated input type of createAddressBatch */
export type CreateAddressBatchInput = {
  addresses: Array<AddressInput>;
  clientId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of createAddressBatch */
export type CreateAddressBatchPayload = {
  __typename?: "createAddressBatchPayload";
  addresses: Array<Address>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated input type of createAddress */
export type CreateAddressInput = {
  clientId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  fullAddress: Scalars["String"]["input"];
  postalCode: Scalars["String"]["input"];
  primary: Scalars["Boolean"]["input"];
  unitNumber?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of createAddress */
export type CreateAddressPayload = {
  __typename?: "createAddressPayload";
  address: Address;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated input type of createAdhocPackage */
export type CreateAdhocPackageInput = {
  accessType?: InputMaybe<Scalars["String"]["input"]>;
  additionalNotes?: InputMaybe<Scalars["String"]["input"]>;
  billingType?: InputMaybe<BillingTypeEnum>;
  billingUnit: PackageDetailBillingUnitEnum;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  contractId: Scalars["ID"]["input"];
  creationReason: CreationReasonEnum;
  creationSource?: InputMaybe<CreationSourceInput>;
  department?: InputMaybe<PackageDepartmentEnum>;
  endTime?: InputMaybe<Scalars["String"]["input"]>;
  fullAddress: Scalars["String"]["input"];
  packageDescription?: InputMaybe<Scalars["String"]["input"]>;
  packageDetailId?: InputMaybe<Scalars["ID"]["input"]>;
  packageLineItems?: InputMaybe<Array<PackageLineItemInput>>;
  postalCode: Scalars["String"]["input"];
  promoCode?: InputMaybe<Scalars["String"]["input"]>;
  serviceDate: Scalars["DateTime"]["input"];
  skipOverdueInvoiceCheck?: InputMaybe<Scalars["Boolean"]["input"]>;
  startTime?: InputMaybe<Scalars["String"]["input"]>;
  unitNumber?: InputMaybe<Scalars["String"]["input"]>;
  unitValue?: InputMaybe<Scalars["Float"]["input"]>;
  workerContractId?: InputMaybe<Scalars["ID"]["input"]>;
  workerId?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Autogenerated return type of createAdhocPackage */
export type CreateAdhocPackagePayload = {
  __typename?: "createAdhocPackagePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result?: Maybe<CreatePackageResult>;
};

/** Autogenerated input type of createBookingRequest */
export type CreateBookingRequestInput = {
  clientId?: InputMaybe<Scalars["ID"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  durationPerSession: Scalars["Int"]["input"];
  email?: InputMaybe<Scalars["String"]["input"]>;
  jobFrequency: JobFrequencyEnum;
  name?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  postalCode: Scalars["String"]["input"];
  sessionPerWeek: Scalars["Int"]["input"];
};

/** Autogenerated return type of createBookingRequest */
export type CreateBookingRequestPayload = {
  __typename?: "createBookingRequestPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of createClientFeedback */
export type CreateClientFeedbackInput = {
  clientId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  feedbackMsg?: InputMaybe<Scalars["String"]["input"]>;
  platform: UserPlatformEnum;
};

/** Autogenerated return type of createClientFeedback */
export type CreateClientFeedbackPayload = {
  __typename?: "createClientFeedbackPayload";
  clientFeedback: ClientFeedback;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated input type of createClient */
export type CreateClientInput = {
  accountType: ClientAccountTypeEnum;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  otherAddresses?: InputMaybe<Array<AddressInput>>;
  otherContacts?: InputMaybe<Array<ContactInput>>;
  primaryAddress: AddressInput;
  primaryContact: ContactInput;
};

/** Autogenerated input type of createClientIssueRequest */
export type CreateClientIssueRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  comment: Scalars["String"]["input"];
  issueId: Scalars["ID"]["input"];
  visitId: Scalars["ID"]["input"];
};

/** Autogenerated return type of createClientIssueRequest */
export type CreateClientIssueRequestPayload = {
  __typename?: "createClientIssueRequestPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of createClientIssueRequestWithToken */
export type CreateClientIssueRequestWithTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  comment: Scalars["String"]["input"];
  issueId: Scalars["ID"]["input"];
  token: Scalars["String"]["input"];
};

/** Autogenerated return type of createClientIssueRequestWithToken */
export type CreateClientIssueRequestWithTokenPayload = {
  __typename?: "createClientIssueRequestWithTokenPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of createClientNote */
export type CreateClientNoteInput = {
  body: Scalars["String"]["input"];
  clientId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of createClientNote */
export type CreateClientNotePayload = {
  __typename?: "createClientNotePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  clientNote: ClientNote;
};

/** Autogenerated return type of createClient */
export type CreateClientPayload = {
  __typename?: "createClientPayload";
  client: Client;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated input type of createClientVoucher */
export type CreateClientVoucherInput = {
  clientId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  discountValue: Scalars["Float"]["input"];
  masterVoucherId: Scalars["ID"]["input"];
};

/** Autogenerated return type of createClientVoucher */
export type CreateClientVoucherPayload = {
  __typename?: "createClientVoucherPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  clientVoucher: ClientVoucher;
};

/** Autogenerated input type of createContactBatch */
export type CreateContactBatchInput = {
  clientId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  contacts: Array<ContactInput>;
};

/** Autogenerated return type of createContactBatch */
export type CreateContactBatchPayload = {
  __typename?: "createContactBatchPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  contacts: Array<Contact>;
};

/** Autogenerated input type of createContact */
export type CreateContactInput = {
  clientId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  email: Array<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  phoneNumber: Array<Scalars["String"]["input"]>;
  primary: Scalars["Boolean"]["input"];
};

/** Autogenerated return type of createContact */
export type CreateContactPayload = {
  __typename?: "createContactPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  contact: Contact;
};

/** Autogenerated input type of createContract */
export type CreateContractInput = {
  billingAddressId: Scalars["ID"]["input"];
  billingContactId: Scalars["ID"]["input"];
  clientId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  invoicing: InvoicingInput;
};

/** Autogenerated return type of createContract */
export type CreateContractPayload = {
  __typename?: "createContractPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  contract: Contract;
};

/** Autogenerated input type of createFinalPayroll */
export type CreateFinalPayrollInput = {
  billingDate: Scalars["Date"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  workerId: Scalars["ID"]["input"];
};

/** Autogenerated return type of createFinalPayroll */
export type CreateFinalPayrollPayload = {
  __typename?: "createFinalPayrollPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  payroll: Payroll;
};

/** Autogenerated input type of createJob */
export type CreateJobInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  packageId: Scalars["ID"]["input"];
  startDate: Scalars["Date"]["input"];
  tasks: Array<JobTaskInput>;
  workerContractId?: InputMaybe<Scalars["ID"]["input"]>;
  workerId?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Autogenerated return type of createJob */
export type CreateJobPayload = {
  __typename?: "createJobPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  job: Job;
};

/** Autogenerated input type of createLeaveApplication */
export type CreateLeaveApplicationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  endDate: Scalars["DateTime"]["input"];
  hoursApplied: Scalars["Float"]["input"];
  leaveType: LeaveTypeEnum;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  startDate: Scalars["DateTime"]["input"];
  workerId: Scalars["ID"]["input"];
};

/** Autogenerated return type of createLeaveApplication */
export type CreateLeaveApplicationPayload = {
  __typename?: "createLeaveApplicationPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  leaveApplication: LeaveApplication;
};

/** Autogenerated input type of createMasterPromoCode */
export type CreateMasterPromoCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  departments?: InputMaybe<Array<PackageDepartmentEnum>>;
  discountType: DiscountTypeEnum;
  discountValue: Scalars["Float"]["input"];
  firstTransactionOnly: Scalars["Boolean"]["input"];
  promoCode: Scalars["String"]["input"];
  validUntil?: InputMaybe<Scalars["DateTime"]["input"]>;
};

/** Autogenerated return type of createMasterPromoCode */
export type CreateMasterPromoCodePayload = {
  __typename?: "createMasterPromoCodePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  masterPromoCode: MasterPromoCode;
};

/** Autogenerated input type of createPackage */
export type CreatePackageInput = {
  accessType?: InputMaybe<Scalars["String"]["input"]>;
  additionalNotes?: InputMaybe<Scalars["String"]["input"]>;
  billingType?: InputMaybe<BillingTypeEnum>;
  billingUnit?: InputMaybe<PackageDetailBillingUnitEnum>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  contractId: Scalars["ID"]["input"];
  creationReason: CreationReasonEnum;
  creationSource?: InputMaybe<CreationSourceInput>;
  department?: InputMaybe<PackageDepartmentEnum>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  endTime?: InputMaybe<Scalars["String"]["input"]>;
  firstVisitLineItems?: InputMaybe<Array<PackageLineItemInput>>;
  fullAddress: Scalars["String"]["input"];
  packageDescription?: InputMaybe<Scalars["String"]["input"]>;
  packageDetailId?: InputMaybe<Scalars["ID"]["input"]>;
  packageLineItems?: InputMaybe<Array<PackageLineItemInput>>;
  postalCode: Scalars["String"]["input"];
  promoCode?: InputMaybe<Scalars["String"]["input"]>;
  skipOverdueInvoiceCheck?: InputMaybe<Scalars["Boolean"]["input"]>;
  startDate: Scalars["DateTime"]["input"];
  startTime?: InputMaybe<Scalars["String"]["input"]>;
  tasks?: InputMaybe<Array<TaskInput>>;
  unitNumber?: InputMaybe<Scalars["String"]["input"]>;
  unitValue?: InputMaybe<Scalars["Float"]["input"]>;
  workerContractId?: InputMaybe<Scalars["ID"]["input"]>;
  workerId?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Autogenerated input type of createPackageIssueRequest */
export type CreatePackageIssueRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  comment: Scalars["String"]["input"];
  issueId: Scalars["ID"]["input"];
  packageId: Scalars["ID"]["input"];
};

/** Autogenerated return type of createPackageIssueRequest */
export type CreatePackageIssueRequestPayload = {
  __typename?: "createPackageIssueRequestPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: Scalars["Boolean"]["output"];
};

/** Autogenerated return type of createPackage */
export type CreatePackagePayload = {
  __typename?: "createPackagePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result?: Maybe<CreatePackageResult>;
};

/** Autogenerated input type of createPackageRequest */
export type CreatePackageRequestInput = {
  additionalNotes?: InputMaybe<Scalars["String"]["input"]>;
  addressId: Scalars["ID"]["input"];
  clientId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  contractId?: InputMaybe<Scalars["ID"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  packageDetailId: Scalars["ID"]["input"];
  startDate: Scalars["DateTime"]["input"];
  tasks: Array<TaskInput>;
};

/** Autogenerated return type of createPackageRequest */
export type CreatePackageRequestPayload = {
  __typename?: "createPackageRequestPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  packageRequest: PackageRequest;
};

/** Result of createPackage mutation */
export type CreatePackageResult =
  | BookingSuspended
  | CreatePackageOk
  | ValidationProblem;

/** Autogenerated input type of createPaymentMethod */
export type CreatePaymentMethodInput = {
  clientId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  token: Scalars["String"]["input"];
};

/** Autogenerated return type of createPaymentMethod */
export type CreatePaymentMethodPayload = {
  __typename?: "createPaymentMethodPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  paymentMethod?: Maybe<StripePaymentMethod>;
};

/** Autogenerated input type of createPayrollAdjustment */
export type CreatePayrollAdjustmentInput = {
  amount: Scalars["Float"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  comment: Scalars["String"]["input"];
  cpfContributable: Scalars["Boolean"]["input"];
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  startDate: Scalars["Date"]["input"];
  workerId: Scalars["ID"]["input"];
};

/** Autogenerated return type of createPayrollAdjustment */
export type CreatePayrollAdjustmentPayload = {
  __typename?: "createPayrollAdjustmentPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  payrollAdjustment: PayrollAdjustment;
};

/** Autogenerated input type of createPromotion */
export type CreatePromotionInput = {
  buttonLabel?: InputMaybe<Scalars["String"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  files?: InputMaybe<Array<Scalars["UploadedFile"]["input"]>>;
  startDate: Scalars["Date"]["input"];
  status: PromotionStatusEnum;
  title: Scalars["String"]["input"];
  url: Scalars["String"]["input"];
};

/** Autogenerated return type of createPromotion */
export type CreatePromotionPayload = {
  __typename?: "createPromotionPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  promotion: Promotion;
};

/** Autogenerated input type of createRentalPackage */
export type CreateRentalPackageInput = {
  accessType?: InputMaybe<Scalars["String"]["input"]>;
  additionalNotes?: InputMaybe<Scalars["String"]["input"]>;
  billingType?: InputMaybe<BillingTypeEnum>;
  billingUnit?: InputMaybe<PackageDetailBillingUnitEnum>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  contractId: Scalars["ID"]["input"];
  creationReason: CreationReasonEnum;
  department?: InputMaybe<PackageDepartmentEnum>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  fullAddress: Scalars["String"]["input"];
  packageDescription?: InputMaybe<Scalars["String"]["input"]>;
  packageDetailId?: InputMaybe<Scalars["ID"]["input"]>;
  packageLineItems?: InputMaybe<Array<PackageLineItemInput>>;
  postalCode: Scalars["String"]["input"];
  startDate: Scalars["DateTime"]["input"];
  unitNumber?: InputMaybe<Scalars["String"]["input"]>;
  unitValue?: InputMaybe<Scalars["Float"]["input"]>;
};

/** Autogenerated return type of createRentalPackage */
export type CreateRentalPackagePayload = {
  __typename?: "createRentalPackagePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result?: Maybe<CreatePackageResult>;
};

/** Autogenerated input type of createStripeSetupIntent */
export type CreateStripeSetupIntentInput = {
  clientId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  deleteOldCard?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Autogenerated return type of createStripeSetupIntent */
export type CreateStripeSetupIntentPayload = {
  __typename?: "createStripeSetupIntentPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  clientSecret: Scalars["String"]["output"];
};

/** Autogenerated input type of createVisitBillingAdjustment */
export type CreateVisitBillingAdjustmentInput = {
  adjustmentType: VisitBillingAdjustmentTypes;
  amount: Scalars["Float"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  reason: Scalars["String"]["input"];
  visitId: Scalars["ID"]["input"];
};

/** Autogenerated return type of createVisitBillingAdjustment */
export type CreateVisitBillingAdjustmentPayload = {
  __typename?: "createVisitBillingAdjustmentPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  visitBillingAdjustment: VisitBillingAdjustment;
};

/** Autogenerated input type of createVisitPayrollAdjustment */
export type CreateVisitPayrollAdjustmentInput = {
  adjustmentType: VisitPayrollAdjustmentTypes;
  amount: Scalars["Float"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  reason: Scalars["String"]["input"];
  visitId: Scalars["ID"]["input"];
  workerId: Scalars["ID"]["input"];
};

/** Autogenerated return type of createVisitPayrollAdjustment */
export type CreateVisitPayrollAdjustmentPayload = {
  __typename?: "createVisitPayrollAdjustmentPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  visitPayrollAdjustment: VisitPayrollAdjustment;
};

/** Autogenerated input type of createWorkerAttendance */
export type CreateWorkerAttendanceInput = {
  attendanceStatus: WorkerAttendanceStatusEnum;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  files: Array<Scalars["UploadedFile"]["input"]>;
  submittedDate?: InputMaybe<Scalars["Date"]["input"]>;
  submittedTime?: InputMaybe<Scalars["String"]["input"]>;
  workerId: Scalars["ID"]["input"];
};

/** Autogenerated return type of createWorkerAttendance */
export type CreateWorkerAttendancePayload = {
  __typename?: "createWorkerAttendancePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  workerAttendance: WorkerAttendance;
};

/** Autogenerated input type of createWorkerContract */
export type CreateWorkerContractInput = {
  annualLeave?: InputMaybe<Scalars["Int"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  contractName: Scalars["String"]["input"];
  contractType: ContractTypeEnum;
  files?: InputMaybe<Array<Scalars["UploadedFile"]["input"]>>;
  hospitalLeave?: InputMaybe<Scalars["Int"]["input"]>;
  jobId?: InputMaybe<Scalars["ID"]["input"]>;
  payrollUnit?: InputMaybe<ContractPayrollUnitEnum>;
  sickLeave?: InputMaybe<Scalars["Int"]["input"]>;
  signedContractUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  startDate: Scalars["Date"]["input"];
  unitValue?: InputMaybe<Scalars["Float"]["input"]>;
  workerContractDays: Array<WorkerContractDayInput>;
  workerContractDetailId: Scalars["ID"]["input"];
  workerContractPayrollPolicies?: InputMaybe<Array<PayrollPolicyInput>>;
  workerId: Scalars["ID"]["input"];
  workerSkillIds: Array<Scalars["ID"]["input"]>;
};

/** Autogenerated return type of createWorkerContract */
export type CreateWorkerContractPayload = {
  __typename?: "createWorkerContractPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  workerContract: WorkerContract;
};

/** Autogenerated input type of createWorkerContractPayrollPolicy */
export type CreateWorkerContractPayrollPolicyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  workerContractId: Scalars["ID"]["input"];
  workerContractPayrollPolicies?: InputMaybe<Array<PayrollPolicyInput>>;
};

/** Autogenerated return type of createWorkerContractPayrollPolicy */
export type CreateWorkerContractPayrollPolicyPayload = {
  __typename?: "createWorkerContractPayrollPolicyPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  workerContract: WorkerContract;
};

/** Autogenerated input type of createWorker */
export type CreateWorkerInput = {
  accountNumber: Scalars["String"]["input"];
  address: Scalars["String"]["input"];
  addressCheckRequired: Scalars["Boolean"]["input"];
  addressUnitNumber?: InputMaybe<Scalars["String"]["input"]>;
  avatar?: InputMaybe<Scalars["UploadedFile"]["input"]>;
  bankName: BankNameEnum;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  contactNumber: Scalars["String"]["input"];
  department: WorkerDepartmentEnum;
  dob: Scalars["Date"]["input"];
  email?: InputMaybe<Scalars["String"]["input"]>;
  emergencyContactName?: InputMaybe<Scalars["String"]["input"]>;
  emergencyContactNumber?: InputMaybe<Scalars["String"]["input"]>;
  employmentStartDate: Scalars["Date"]["input"];
  firstName: Scalars["String"]["input"];
  gender: SexEnum;
  languageSpoken: Array<LanguageSpokenEnum>;
  lastName: Scalars["String"]["input"];
  managerId?: InputMaybe<Scalars["ID"]["input"]>;
  nameOnId?: InputMaybe<Scalars["String"]["input"]>;
  nationality: NationalityEnum;
  nric: Scalars["String"]["input"];
  payrollAcknowledgementRequired: Scalars["Boolean"]["input"];
  postalCode: Scalars["String"]["input"];
  servicePostalCode: Scalars["String"]["input"];
  techSavy?: InputMaybe<Scalars["Boolean"]["input"]>;
  workerType: WorkerWorkerTypeEnum;
};

/** Autogenerated return type of createWorker */
export type CreateWorkerPayload = {
  __typename?: "createWorkerPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  worker: Worker;
};

/** Autogenerated input type of createWorkerTask */
export type CreateWorkerTaskInput = {
  accessInstruction?: InputMaybe<Scalars["String"]["input"]>;
  additionalNotes?: InputMaybe<Scalars["String"]["input"]>;
  allowOverlap?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  endTime: Scalars["String"]["input"];
  fullAddress: Scalars["String"]["input"];
  paymentAmount?: InputMaybe<Scalars["Float"]["input"]>;
  paymentType: TaskPaymentTypesEnum;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  postalCode: Scalars["String"]["input"];
  startTime: Scalars["String"]["input"];
  taskDate: Scalars["Date"]["input"];
  unitNumber?: InputMaybe<Scalars["String"]["input"]>;
  workerContractId?: InputMaybe<Scalars["ID"]["input"]>;
  workerId: Scalars["ID"]["input"];
};

/** Autogenerated return type of createWorkerTask */
export type CreateWorkerTaskPayload = {
  __typename?: "createWorkerTaskPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  workerTask?: Maybe<WorkerTask>;
};

/** Autogenerated input type of createWorkerTip */
export type CreateWorkerTipInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  department: WorkerDepartmentEnum;
  message: Scalars["String"]["input"];
  nationality: NationalityEnum;
  title: Scalars["String"]["input"];
};

/** Autogenerated return type of createWorkerTip */
export type CreateWorkerTipPayload = {
  __typename?: "createWorkerTipPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  workerTip: WorkerTip;
};

/** Autogenerated input type of deleteAddressBatch */
export type DeleteAddressBatchInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  ids: Array<Scalars["ID"]["input"]>;
};

/** Autogenerated return type of deleteAddressBatch */
export type DeleteAddressBatchPayload = {
  __typename?: "deleteAddressBatchPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  results: Array<Scalars["Boolean"]["output"]>;
};

/** Autogenerated input type of deleteAddress */
export type DeleteAddressInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

/** Autogenerated return type of deleteAddress */
export type DeleteAddressPayload = {
  __typename?: "deleteAddressPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of deleteClientDocuments */
export type DeleteClientDocumentsInput = {
  clientId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  ids: Array<Scalars["ID"]["input"]>;
};

/** Autogenerated return type of deleteClientDocuments */
export type DeleteClientDocumentsPayload = {
  __typename?: "deleteClientDocumentsPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  deletedFiles: Scalars["Int"]["output"];
};

/** Autogenerated input type of deleteClient */
export type DeleteClientInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

/** Autogenerated input type of deleteClientNote */
export type DeleteClientNoteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

/** Autogenerated return type of deleteClientNote */
export type DeleteClientNotePayload = {
  __typename?: "deleteClientNotePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  deleted: Scalars["Boolean"]["output"];
};

/** Autogenerated return type of deleteClient */
export type DeleteClientPayload = {
  __typename?: "deleteClientPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of deleteClientVoucher */
export type DeleteClientVoucherInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  voucherId: Scalars["ID"]["input"];
};

/** Autogenerated return type of deleteClientVoucher */
export type DeleteClientVoucherPayload = {
  __typename?: "deleteClientVoucherPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of deleteContactBatch */
export type DeleteContactBatchInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  ids: Array<Scalars["ID"]["input"]>;
};

/** Autogenerated return type of deleteContactBatch */
export type DeleteContactBatchPayload = {
  __typename?: "deleteContactBatchPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  results: Array<Scalars["Boolean"]["output"]>;
};

/** Autogenerated input type of deleteContact */
export type DeleteContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

/** Autogenerated return type of deleteContact */
export type DeleteContactPayload = {
  __typename?: "deleteContactPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of deleteContract */
export type DeleteContractInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

/** Autogenerated return type of deleteContract */
export type DeleteContractPayload = {
  __typename?: "deleteContractPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of deleteInvoice */
export type DeleteInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  invoiceId: Scalars["ID"]["input"];
};

/** Autogenerated return type of deleteInvoice */
export type DeleteInvoicePayload = {
  __typename?: "deleteInvoicePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  invoice: Invoice;
};

/** Autogenerated input type of deleteJob */
export type DeleteJobInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

/** Autogenerated return type of deleteJob */
export type DeleteJobPayload = {
  __typename?: "deleteJobPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of deleteLeaveApplication */
export type DeleteLeaveApplicationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

/** Autogenerated return type of deleteLeaveApplication */
export type DeleteLeaveApplicationPayload = {
  __typename?: "deleteLeaveApplicationPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  deleted: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of deletePackage */
export type DeletePackageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

/** Autogenerated return type of deletePackage */
export type DeletePackagePayload = {
  __typename?: "deletePackagePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of deletePackageRequest */
export type DeletePackageRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

/** Autogenerated return type of deletePackageRequest */
export type DeletePackageRequestPayload = {
  __typename?: "deletePackageRequestPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of deletePaymentMethod */
export type DeletePaymentMethodInput = {
  clientId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  paymentMethodId: Scalars["String"]["input"];
};

/** Autogenerated return type of deletePaymentMethod */
export type DeletePaymentMethodPayload = {
  __typename?: "deletePaymentMethodPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of deletePayrollAdjustment */
export type DeletePayrollAdjustmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  payrollAdjustmentId: Scalars["ID"]["input"];
};

/** Autogenerated return type of deletePayrollAdjustment */
export type DeletePayrollAdjustmentPayload = {
  __typename?: "deletePayrollAdjustmentPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  payrollAdjustment?: Maybe<PayrollAdjustment>;
};

/** Autogenerated input type of deletePortfolios */
export type DeletePortfoliosInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  ids: Array<Scalars["ID"]["input"]>;
  workerContractId: Scalars["ID"]["input"];
};

/** Autogenerated return type of deletePortfolios */
export type DeletePortfoliosPayload = {
  __typename?: "deletePortfoliosPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  deletedFiles: Scalars["Int"]["output"];
};

/** Autogenerated input type of deletePromotion */
export type DeletePromotionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  promotionId: Scalars["ID"]["input"];
};

/** Autogenerated return type of deletePromotion */
export type DeletePromotionPayload = {
  __typename?: "deletePromotionPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  promotionDeleted: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of deleteProofOfAgreements */
export type DeleteProofOfAgreementsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  ids: Array<Scalars["ID"]["input"]>;
  workerContractId: Scalars["ID"]["input"];
};

/** Autogenerated return type of deleteProofOfAgreements */
export type DeleteProofOfAgreementsPayload = {
  __typename?: "deleteProofOfAgreementsPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  deletedFiles: Scalars["Int"]["output"];
};

/** Autogenerated input type of deleteStaffReviewAttachment */
export type DeleteStaffReviewAttachmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  idFiles: Array<Scalars["ID"]["input"]>;
  visitId: Scalars["ID"]["input"];
};

/** Autogenerated return type of deleteStaffReviewAttachment */
export type DeleteStaffReviewAttachmentPayload = {
  __typename?: "deleteStaffReviewAttachmentPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: Scalars["Int"]["output"];
};

/** Autogenerated input type of deleteTransaction */
export type DeleteTransactionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  transactionId: Scalars["ID"]["input"];
};

/** Autogenerated return type of deleteTransaction */
export type DeleteTransactionPayload = {
  __typename?: "deleteTransactionPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  transaction?: Maybe<Transaction>;
};

/** Autogenerated input type of deleteVisitBillingAdjustment */
export type DeleteVisitBillingAdjustmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

/** Autogenerated return type of deleteVisitBillingAdjustment */
export type DeleteVisitBillingAdjustmentPayload = {
  __typename?: "deleteVisitBillingAdjustmentPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of deleteVisitLineItem */
export type DeleteVisitLineItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

/** Autogenerated return type of deleteVisitLineItem */
export type DeleteVisitLineItemPayload = {
  __typename?: "deleteVisitLineItemPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of deleteVisitPayrollAdjustment */
export type DeleteVisitPayrollAdjustmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

/** Autogenerated return type of deleteVisitPayrollAdjustment */
export type DeleteVisitPayrollAdjustmentPayload = {
  __typename?: "deleteVisitPayrollAdjustmentPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of deleteWorkerAttendance */
export type DeleteWorkerAttendanceInput = {
  attendanceId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of deleteWorkerAttendance */
export type DeleteWorkerAttendancePayload = {
  __typename?: "deleteWorkerAttendancePayload";
  attendanceDeleted: Scalars["Boolean"]["output"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated input type of deleteWorkerContract */
export type DeleteWorkerContractInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

/** Autogenerated return type of deleteWorkerContract */
export type DeleteWorkerContractPayload = {
  __typename?: "deleteWorkerContractPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of deleteWorkerDocumentFiles */
export type DeleteWorkerDocumentFilesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  idFiles: Array<Scalars["ID"]["input"]>;
  workerDocumentId: Scalars["ID"]["input"];
};

/** Autogenerated return type of deleteWorkerDocumentFiles */
export type DeleteWorkerDocumentFilesPayload = {
  __typename?: "deleteWorkerDocumentFilesPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: Scalars["Int"]["output"];
};

/** Autogenerated input type of deleteWorkerDocument */
export type DeleteWorkerDocumentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  workerDocumentId: Scalars["ID"]["input"];
};

/** Autogenerated return type of deleteWorkerDocument */
export type DeleteWorkerDocumentPayload = {
  __typename?: "deleteWorkerDocumentPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  deletedWorkerDocument: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of deleteWorker */
export type DeleteWorkerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

/** Autogenerated return type of deleteWorker */
export type DeleteWorkerPayload = {
  __typename?: "deleteWorkerPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of deleteWorkerTask */
export type DeleteWorkerTaskInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  workerTaskId: Scalars["ID"]["input"];
};

/** Autogenerated return type of deleteWorkerTask */
export type DeleteWorkerTaskPayload = {
  __typename?: "deleteWorkerTaskPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of deleteWorkerTip */
export type DeleteWorkerTipInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

/** Autogenerated return type of deleteWorkerTip */
export type DeleteWorkerTipPayload = {
  __typename?: "deleteWorkerTipPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  workerTipDeleted: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of finishVisit */
export type FinishVisitInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  visitId: Scalars["ID"]["input"];
};

/** Autogenerated return type of finishVisit */
export type FinishVisitPayload = {
  __typename?: "finishVisitPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  visit: Visit;
};

/** Autogenerated input type of generateInvoiceCsv */
export type GenerateInvoiceCsvInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  invoiceIds: Array<Scalars["ID"]["input"]>;
};

/** Autogenerated return type of generateInvoiceCsv */
export type GenerateInvoiceCsvPayload = {
  __typename?: "generateInvoiceCsvPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Bas64 encoded csv file as a string */
  fileString: Scalars["String"]["output"];
};

/** Autogenerated input type of generateInvoicePdf */
export type GenerateInvoicePdfInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  token: Scalars["String"]["input"];
};

/** Autogenerated return type of generateInvoicePdf */
export type GenerateInvoicePdfPayload = {
  __typename?: "generateInvoicePdfPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Bas64 encoded pdf file as a string */
  fileString: Scalars["String"]["output"];
};

/** Autogenerated input type of generateInvoiceReceiptPdf */
export type GenerateInvoiceReceiptPdfInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  token: Scalars["String"]["input"];
};

/** Autogenerated return type of generateInvoiceReceiptPdf */
export type GenerateInvoiceReceiptPdfPayload = {
  __typename?: "generateInvoiceReceiptPdfPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Bas64 encoded pdf file as a string */
  fileString: Scalars["String"]["output"];
};

/** Autogenerated input type of generateOutstandingPayrollsCsv */
export type GenerateOutstandingPayrollsCsvInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  payrollIds: Array<Scalars["ID"]["input"]>;
};

/** Autogenerated return type of generateOutstandingPayrollsCsv */
export type GenerateOutstandingPayrollsCsvPayload = {
  __typename?: "generateOutstandingPayrollsCsvPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Bas64 encoded csv file as a string */
  fileString: Scalars["String"]["output"];
};

/** Autogenerated input type of generatePayrollCsv */
export type GeneratePayrollCsvInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  token: Scalars["String"]["input"];
};

/** Autogenerated return type of generatePayrollCsv */
export type GeneratePayrollCsvPayload = {
  __typename?: "generatePayrollCsvPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Bas64 encoded csv file as a string */
  fileString: Scalars["String"]["output"];
};

/** Autogenerated input type of inviteUser */
export type InviteUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

/** Autogenerated return type of inviteUser */
export type InviteUserPayload = {
  __typename?: "inviteUserPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result?: Maybe<InviteUserResult>;
};

/** Autogenerated input type of payInvoice */
export type PayInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  invoiceId: Scalars["ID"]["input"];
};

/** Autogenerated return type of payInvoice */
export type PayInvoicePayload = {
  __typename?: "payInvoicePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  invoice: Invoice;
};

/** Autogenerated input type of rateVisit */
export type RateVisitInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  files?: InputMaybe<Array<Scalars["UploadedFile"]["input"]>>;
  rating: Scalars["Float"]["input"];
  ratingComment?: InputMaybe<Scalars["String"]["input"]>;
  visitId: Scalars["ID"]["input"];
};

/** Autogenerated return type of rateVisit */
export type RateVisitPayload = {
  __typename?: "rateVisitPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  visit: Visit;
};

/** Autogenerated input type of rateVisitWithToken */
export type RateVisitWithTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  rating: Scalars["Float"]["input"];
  ratingComment?: InputMaybe<Scalars["String"]["input"]>;
  token: Scalars["String"]["input"];
};

/** Autogenerated return type of rateVisitWithToken */
export type RateVisitWithTokenPayload = {
  __typename?: "rateVisitWithTokenPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  visit: Visit;
};

/** Autogenerated input type of readNotification */
export type ReadNotificationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  notificationId: Scalars["ID"]["input"];
};

/** Autogenerated return type of readNotification */
export type ReadNotificationPayload = {
  __typename?: "readNotificationPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  notification: Notification;
};

/** Autogenerated input type of regenerateInvoice */
export type RegenerateInvoiceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  invoiceId: Scalars["ID"]["input"];
};

/** Autogenerated return type of regenerateInvoice */
export type RegenerateInvoicePayload = {
  __typename?: "regenerateInvoicePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  invoice: Invoice;
};

/** Autogenerated input type of regeneratePayroll */
export type RegeneratePayrollInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  payrollId: Scalars["ID"]["input"];
};

/** Autogenerated return type of regeneratePayroll */
export type RegeneratePayrollPayload = {
  __typename?: "regeneratePayrollPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  payroll: Payroll;
};

/** Autogenerated input type of rejectLeaveApplication */
export type RejectLeaveApplicationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  leaveApplicationId: Scalars["ID"]["input"];
};

/** Autogenerated return type of rejectLeaveApplication */
export type RejectLeaveApplicationPayload = {
  __typename?: "rejectLeaveApplicationPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  leaveApplication: LeaveApplication;
};

/** Autogenerated input type of rejectPackageRequest */
export type RejectPackageRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  packageRequestId: Scalars["ID"]["input"];
};

/** Autogenerated return type of rejectPackageRequest */
export type RejectPackageRequestPayload = {
  __typename?: "rejectPackageRequestPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of removePaymentProof */
export type RemovePaymentProofInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  invoiceId: Scalars["ID"]["input"];
};

/** Autogenerated return type of removePaymentProof */
export type RemovePaymentProofPayload = {
  __typename?: "removePaymentProofPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of removeVisitNoteAttachments */
export type RemoveVisitNoteAttachmentsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  noteAttachmentIds: Array<Scalars["ID"]["input"]>;
  visitId: Scalars["ID"]["input"];
};

/** Autogenerated return type of removeVisitNoteAttachments */
export type RemoveVisitNoteAttachmentsPayload = {
  __typename?: "removeVisitNoteAttachmentsPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of rescheduleVisitWithToken */
export type RescheduleVisitWithTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  endTime?: InputMaybe<Scalars["String"]["input"]>;
  serviceDate?: InputMaybe<Scalars["Date"]["input"]>;
  startTime?: InputMaybe<Scalars["String"]["input"]>;
  token: Scalars["String"]["input"];
  workerId?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Autogenerated return type of rescheduleVisitWithToken */
export type RescheduleVisitWithTokenPayload = {
  __typename?: "rescheduleVisitWithTokenPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  visit: Visit;
};

export enum ReservationStatusEnum {
  Active = "ACTIVE",
  Cancelled = "CANCELLED",
  Confirmed = "CONFIRMED",
}

/** Autogenerated input type of resetPasswordFromEmail */
export type ResetPasswordFromEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
  newPassword: Scalars["String"]["input"];
  pinNumber: Scalars["String"]["input"];
};

/** Autogenerated return type of resetPasswordFromEmail */
export type ResetPasswordFromEmailPayload = {
  __typename?: "resetPasswordFromEmailPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  user?: Maybe<User>;
};

/** Autogenerated input type of resetPasswordFromPhone */
export type ResetPasswordFromPhoneInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  newPassword: Scalars["String"]["input"];
  phone: Scalars["String"]["input"];
  pinNumber: Scalars["String"]["input"];
};

/** Autogenerated return type of resetPasswordFromPhone */
export type ResetPasswordFromPhonePayload = {
  __typename?: "resetPasswordFromPhonePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  user?: Maybe<User>;
};

/** Autogenerated input type of restoreLeaveApplication */
export type RestoreLeaveApplicationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  leaveApplicationId: Scalars["ID"]["input"];
  reassignWorker: Scalars["Boolean"]["input"];
  workerContractId?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Autogenerated return type of restoreLeaveApplication */
export type RestoreLeaveApplicationPayload = {
  __typename?: "restoreLeaveApplicationPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  leaveApplication: LeaveApplication;
};

/** Autogenerated input type of restoreVisit */
export type RestoreVisitInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  visitId: Scalars["ID"]["input"];
};

/** Autogenerated return type of restoreVisit */
export type RestoreVisitPayload = {
  __typename?: "restoreVisitPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  visit: Visit;
};

/** Autogenerated input type of restoreWorkerTask */
export type RestoreWorkerTaskInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  workerTaskId: Scalars["ID"]["input"];
};

/** Autogenerated return type of restoreWorkerTask */
export type RestoreWorkerTaskPayload = {
  __typename?: "restoreWorkerTaskPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  workerTask: WorkerTask;
};

export enum RetentionTypeEnum {
  AdHoc = "AD_HOC",
  Mau = "MAU",
  Recurring = "RECURRING",
}

/** Autogenerated input type of sendInvoiceEmail */
export type SendInvoiceEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  invoiceId: Scalars["ID"]["input"];
};

/** Autogenerated return type of sendInvoiceEmail */
export type SendInvoiceEmailPayload = {
  __typename?: "sendInvoiceEmailPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of sendPinCodeToPhone */
export type SendPinCodeToPhoneInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  enableWhatsappOtp?: InputMaybe<Scalars["Boolean"]["input"]>;
  inAndroidFormat?: InputMaybe<Scalars["Boolean"]["input"]>;
  phone: Scalars["String"]["input"];
};

/** Autogenerated return type of sendPinCodeToPhone */
export type SendPinCodeToPhonePayload = {
  __typename?: "sendPinCodeToPhonePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result?: Maybe<SendPinCodeToPhoneResult>;
};

/** Result of sendPinCodeToPhone mutation */
export type SendPinCodeToPhoneResult =
  | AccountDeleted
  | SendPinCodeToPhoneOk
  | ValidationProblem;

/** Autogenerated input type of sendReferralInvitation */
export type SendReferralInvitationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
};

/** Autogenerated return type of sendReferralInvitation */
export type SendReferralInvitationPayload = {
  __typename?: "sendReferralInvitationPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of sendResetPassword */
export type SendResetPasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
  type?: InputMaybe<ResetPasswordEnum>;
};

/** Autogenerated return type of sendResetPassword */
export type SendResetPasswordPayload = {
  __typename?: "sendResetPasswordPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result?: Maybe<SendForgotPasswordResult>;
};

/** Autogenerated input type of sendVerificationUser */
export type SendVerificationUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  userId: Scalars["ID"]["input"];
};

/** Autogenerated return type of sendVerificationUser */
export type SendVerificationUserPayload = {
  __typename?: "sendVerificationUserPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result?: Maybe<InviteUserResult>;
};

/** Autogenerated input type of signInClassic */
export type SignInClassicInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
  installation?: InputMaybe<InstallationInput>;
  password: Scalars["String"]["input"];
  type?: InputMaybe<UserEnum>;
};

/** Autogenerated return type of signInClassic */
export type SignInClassicPayload = {
  __typename?: "signInClassicPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: SignInResult;
};

/** Autogenerated input type of signInClient */
export type SignInClientInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
  installation?: InputMaybe<InstallationInput>;
  password: Scalars["String"]["input"];
  type?: InputMaybe<UserEnum>;
};

/** Autogenerated return type of signInClient */
export type SignInClientPayload = {
  __typename?: "signInClientPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: SignInClientResult;
};

/** Result of signInClient mutation */
export type SignInClientResult =
  | AccountDeleted
  | NotWhitelistedProblem
  | SignInClientOk
  | SignUpRequired
  | ValidationProblem;

/** Autogenerated input type of signInClientSocialAccount */
export type SignInClientSocialAccountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  installation?: InputMaybe<InstallationInput>;
  provider: SignInProviderEnum;
  refreshToken: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
};

/** Autogenerated return type of signInClientSocialAccount */
export type SignInClientSocialAccountPayload = {
  __typename?: "signInClientSocialAccountPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: SignInClientResult;
};

/** Result of signInWithPhone mutation */
export type SignInPhoneNumberResult =
  | AccountDeleted
  | NotWhitelistedProblem
  | SignInClientOk
  | SignInWorkerOk
  | SignUpRequired
  | ValidationProblem;

/** Result of signIn mutation */
export type SignInResult = NotWhitelistedProblem | SignInOk | ValidationProblem;

/** Autogenerated input type of signInWithPhone */
export type SignInWithPhoneInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  installation?: InputMaybe<InstallationInput>;
  phone: Scalars["String"]["input"];
};

/** Autogenerated return type of signInWithPhone */
export type SignInWithPhonePayload = {
  __typename?: "signInWithPhonePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: SignInPhoneNumberResult;
};

/** Autogenerated input type of signOut */
export type SignOutInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of signOut */
export type SignOutPayload = {
  __typename?: "signOutPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  user?: Maybe<User>;
};

/** Autogenerated input type of signUp */
export type SignUpInput = {
  accountType?: InputMaybe<ClientAccountTypeEnum>;
  adTrackingKey?: InputMaybe<Scalars["String"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  creationSource?: InputMaybe<CreationSourceInput>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  installation?: InputMaybe<InstallationInput>;
  inviteCode?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  password?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber: Scalars["String"]["input"];
  primaryAddress?: InputMaybe<AddressInput>;
  primaryContact?: InputMaybe<ContactInput>;
  provider?: InputMaybe<SignInProviderEnum>;
  refreshToken?: InputMaybe<Scalars["String"]["input"]>;
  token?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<UserEnum>;
  utm?: InputMaybe<UtmInput>;
};

/** Autogenerated return type of signUp */
export type SignUpPayload = {
  __typename?: "signUpPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: SignUpResult;
};

/** Result of signUp mutation */
export type SignUpResult =
  | EntityExistsProblem
  | NotWhitelistedProblem
  | SignUpOk
  | ValidationProblem;

/** Autogenerated input type of startVisit */
export type StartVisitInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  visitId: Scalars["ID"]["input"];
};

/** Autogenerated return type of startVisit */
export type StartVisitPayload = {
  __typename?: "startVisitPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  visit: Visit;
};

/** Autogenerated input type of terminateJob */
export type TerminateJobInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  endDate: Scalars["Date"]["input"];
  jobId: Scalars["ID"]["input"];
};

/** Autogenerated return type of terminateJob */
export type TerminateJobPayload = {
  __typename?: "terminateJobPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  job: Job;
};

/** Autogenerated input type of terminatePackage */
export type TerminatePackageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  endDate: Scalars["Date"]["input"];
  packageId: Scalars["ID"]["input"];
  terminationCategory: PackageTerminationCategoryEnum;
  terminationComment?: InputMaybe<Scalars["String"]["input"]>;
  terminationReason: PackageTerminationReasonEnum;
};

/** Autogenerated return type of terminatePackage */
export type TerminatePackagePayload = {
  __typename?: "terminatePackagePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  package: Package;
};

/** Autogenerated input type of terminateWorkerContract */
export type TerminateWorkerContractInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  endDate: Scalars["Date"]["input"];
  id: Scalars["ID"]["input"];
};

/** Autogenerated return type of terminateWorkerContract */
export type TerminateWorkerContractPayload = {
  __typename?: "terminateWorkerContractPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of terminateWorker */
export type TerminateWorkerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  endDate: Scalars["Date"]["input"];
  workerId: Scalars["ID"]["input"];
};

/** Autogenerated return type of terminateWorker */
export type TerminateWorkerPayload = {
  __typename?: "terminateWorkerPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of tokenizePaymentMethod */
export type TokenizePaymentMethodInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  cvc: Scalars["String"]["input"];
  expMonth: Scalars["String"]["input"];
  expYear: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  number: Scalars["String"]["input"];
};

/** Autogenerated return type of tokenizePaymentMethod */
export type TokenizePaymentMethodPayload = {
  __typename?: "tokenizePaymentMethodPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  token?: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated input type of updateAccountPhoneNumber */
export type UpdateAccountPhoneNumberInput = {
  clientId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber: Scalars["String"]["input"];
};

/** Autogenerated return type of updateAccountPhoneNumber */
export type UpdateAccountPhoneNumberPayload = {
  __typename?: "updateAccountPhoneNumberPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of updateAddressBatch */
export type UpdateAddressBatchInput = {
  addresses: Array<UpdateAddressBatch>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of updateAddressBatch */
export type UpdateAddressBatchPayload = {
  __typename?: "updateAddressBatchPayload";
  addresses: Array<Address>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated input type of updateAddress */
export type UpdateAddressInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  fullAddress?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  primary?: InputMaybe<Scalars["Boolean"]["input"]>;
  unitNumber?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of updateAddress */
export type UpdateAddressPayload = {
  __typename?: "updateAddressPayload";
  address: Address;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated input type of updateClient */
export type UpdateClientInput = {
  accountEmail?: InputMaybe<Scalars["String"]["input"]>;
  accountPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  accountType?: InputMaybe<ClientAccountTypeEnum>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<ClientStatusEnum>;
};

/** Autogenerated input type of updateClientNote */
export type UpdateClientNoteInput = {
  body?: InputMaybe<Scalars["String"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
};

/** Autogenerated return type of updateClientNote */
export type UpdateClientNotePayload = {
  __typename?: "updateClientNotePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  clientNote: ClientNote;
};

/** Autogenerated input type of updateClientPassword */
export type UpdateClientPasswordInput = {
  clientId: Scalars["String"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  newPassword: Scalars["String"]["input"];
};

/** Autogenerated return type of updateClientPassword */
export type UpdateClientPasswordPayload = {
  __typename?: "updateClientPasswordPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  user?: Maybe<User>;
};

/** Autogenerated return type of updateClient */
export type UpdateClientPayload = {
  __typename?: "updateClientPayload";
  client: Client;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated input type of updateClientPreferences */
export type UpdateClientPreferencesInput = {
  clientId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  preferences: Scalars["JSON"]["input"];
};

/** Autogenerated return type of updateClientPreferences */
export type UpdateClientPreferencesPayload = {
  __typename?: "updateClientPreferencesPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  preferences: Scalars["JSON"]["output"];
};

/** Autogenerated input type of updateClientVoucher */
export type UpdateClientVoucherInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  discountValue: Scalars["Float"]["input"];
  expirationDate: Scalars["DateTime"]["input"];
  voucherId: Scalars["ID"]["input"];
};

/** Autogenerated return type of updateClientVoucher */
export type UpdateClientVoucherPayload = {
  __typename?: "updateClientVoucherPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  clientVoucher: ClientVoucher;
};

/** Autogenerated input type of updateContactBatch */
export type UpdateContactBatchInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  contacts: Array<UpdateContactBatch>;
};

/** Autogenerated return type of updateContactBatch */
export type UpdateContactBatchPayload = {
  __typename?: "updateContactBatchPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  contacts: Array<Contact>;
};

/** Autogenerated input type of updateContact */
export type UpdateContactInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Array<Scalars["String"]["input"]>>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Array<Scalars["String"]["input"]>>;
  primary?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Autogenerated return type of updateContact */
export type UpdateContactPayload = {
  __typename?: "updateContactPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  contact: Contact;
};

/** Autogenerated input type of updateContract */
export type UpdateContractInput = {
  billingAddressId?: InputMaybe<Scalars["ID"]["input"]>;
  billingContactId?: InputMaybe<Scalars["ID"]["input"]>;
  billingTerms?: InputMaybe<ContractBillingTermsEnum>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  invoicing?: InputMaybe<InvoicingInput>;
  status?: InputMaybe<ContractStatusEnum>;
};

/** Autogenerated return type of updateContract */
export type UpdateContractPayload = {
  __typename?: "updateContractPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  contract: Contract;
};

/** Autogenerated input type of updateInstallation */
export type UpdateInstallationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  installation: InstallationInput;
};

/** Autogenerated return type of updateInstallation */
export type UpdateInstallationPayload = {
  __typename?: "updateInstallationPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  user?: Maybe<User>;
};

/** Autogenerated input type of updateLeaveApplication */
export type UpdateLeaveApplicationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  endDate: Scalars["DateTime"]["input"];
  hoursApplied: Scalars["Float"]["input"];
  id: Scalars["ID"]["input"];
  reason?: InputMaybe<Scalars["String"]["input"]>;
  startDate: Scalars["DateTime"]["input"];
};

/** Autogenerated return type of updateLeaveApplication */
export type UpdateLeaveApplicationPayload = {
  __typename?: "updateLeaveApplicationPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  leaveApplication: LeaveApplication;
};

/** Autogenerated input type of updateManager */
export type UpdateManagerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  hubspotId?: InputMaybe<Scalars["String"]["input"]>;
  lastWorkingDay?: InputMaybe<Scalars["Date"]["input"]>;
  managerId: Scalars["ID"]["input"];
  opsExecCode?: InputMaybe<Scalars["String"]["input"]>;
  role?: InputMaybe<ManagerRoleEnum>;
  status?: InputMaybe<ManagerStatusEnum>;
  userUpdate?: InputMaybe<UserUpdateInput>;
};

/** Autogenerated return type of updateManager */
export type UpdateManagerPayload = {
  __typename?: "updateManagerPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  manager: Manager;
};

/** Autogenerated input type of updateMasterPromoCode */
export type UpdateMasterPromoCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  departments?: InputMaybe<Array<PackageDepartmentEnum>>;
  discountType?: InputMaybe<DiscountTypeEnum>;
  discountValue?: InputMaybe<Scalars["Float"]["input"]>;
  firstTransactionOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["ID"]["input"];
  validUntil?: InputMaybe<Scalars["DateTime"]["input"]>;
};

/** Autogenerated return type of updateMasterPromoCode */
export type UpdateMasterPromoCodePayload = {
  __typename?: "updateMasterPromoCodePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  masterPromoCode: MasterPromoCode;
};

/** Autogenerated input type of updatePackage */
export type UpdatePackageInput = {
  accessType?: InputMaybe<Scalars["String"]["input"]>;
  additionalNotes?: InputMaybe<Scalars["String"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  fullAddress?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  packageDescription?: InputMaybe<Scalars["String"]["input"]>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  unitNumber?: InputMaybe<Scalars["String"]["input"]>;
  unitValue?: InputMaybe<Scalars["Float"]["input"]>;
};

/** Autogenerated input type of updatePackageJobs */
export type UpdatePackageJobsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  jobs: Array<JobInput>;
  packageId: Scalars["ID"]["input"];
};

/** Autogenerated return type of updatePackageJobs */
export type UpdatePackageJobsPayload = {
  __typename?: "updatePackageJobsPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  package: Package;
};

/** Autogenerated return type of updatePackage */
export type UpdatePackagePayload = {
  __typename?: "updatePackagePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  package: Package;
};

/** Autogenerated input type of updatePaymentIndicated */
export type UpdatePaymentIndicatedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  invoiceId: Scalars["ID"]["input"];
};

/** Autogenerated return type of updatePaymentIndicated */
export type UpdatePaymentIndicatedPayload = {
  __typename?: "updatePaymentIndicatedPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result?: Maybe<Scalars["Boolean"]["output"]>;
};

/** Autogenerated input type of updatePaymentMethod */
export type UpdatePaymentMethodInput = {
  clientId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  cvc?: InputMaybe<Scalars["String"]["input"]>;
  expMonth?: InputMaybe<Scalars["String"]["input"]>;
  expYear?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  paymentMethodId: Scalars["String"]["input"];
};

/** Autogenerated return type of updatePaymentMethod */
export type UpdatePaymentMethodPayload = {
  __typename?: "updatePaymentMethodPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  paymentMethod?: Maybe<StripePaymentMethod>;
};

/** Autogenerated input type of updatePayrollAdjustment */
export type UpdatePayrollAdjustmentInput = {
  adjustmentType?: InputMaybe<Scalars["String"]["input"]>;
  amount: Scalars["Float"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  cpfContributable: Scalars["Boolean"]["input"];
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  payrollAdjustmentId: Scalars["ID"]["input"];
  payrollId?: InputMaybe<Scalars["ID"]["input"]>;
  startDate: Scalars["Date"]["input"];
};

/** Autogenerated return type of updatePayrollAdjustment */
export type UpdatePayrollAdjustmentPayload = {
  __typename?: "updatePayrollAdjustmentPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  payrollAdjustment: PayrollAdjustment;
};

/** Autogenerated input type of updatePromotion */
export type UpdatePromotionInput = {
  buttonLabel?: InputMaybe<Scalars["String"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  files?: InputMaybe<Array<Scalars["UploadedFile"]["input"]>>;
  promotionId: Scalars["ID"]["input"];
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  status?: InputMaybe<PromotionStatusEnum>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of updatePromotion */
export type UpdatePromotionPayload = {
  __typename?: "updatePromotionPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  promotion: Promotion;
};

/** Autogenerated input type of updateUserEmail */
export type UpdateUserEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

/** Autogenerated return type of updateUserEmail */
export type UpdateUserEmailPayload = {
  __typename?: "updateUserEmailPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  user?: Maybe<User>;
};

/** Autogenerated input type of updateUserPassword */
export type UpdateUserPasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  newPassword: Scalars["String"]["input"];
  oldPassword: Scalars["String"]["input"];
};

/** Autogenerated return type of updateUserPassword */
export type UpdateUserPasswordPayload = {
  __typename?: "updateUserPasswordPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  user?: Maybe<User>;
};

/** Autogenerated input type of updateViewer */
export type UpdateViewerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  data: ViewerDataInput;
};

/** Autogenerated return type of updateViewer */
export type UpdateViewerPayload = {
  __typename?: "updateViewerPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  viewer: Viewer;
};

/** Autogenerated input type of updateVisitBillingAdjustment */
export type UpdateVisitBillingAdjustmentInput = {
  adjustmentType: VisitBillingAdjustmentTypes;
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  reason?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of updateVisitBillingAdjustment */
export type UpdateVisitBillingAdjustmentPayload = {
  __typename?: "updateVisitBillingAdjustmentPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  visitBillingAdjustment: VisitBillingAdjustment;
};

/** Autogenerated input type of updateVisit */
export type UpdateVisitInput = {
  accessType?: InputMaybe<Scalars["String"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  endTime?: InputMaybe<Scalars["String"]["input"]>;
  extendExpirationDate?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["ID"]["input"];
  packageNotes?: InputMaybe<Scalars["String"]["input"]>;
  serviceDate?: InputMaybe<Scalars["Date"]["input"]>;
  startTime?: InputMaybe<Scalars["String"]["input"]>;
  statusComment?: InputMaybe<Scalars["String"]["input"]>;
  workingHours?: InputMaybe<Array<TaskWorkingHoursInput>>;
};

/** Autogenerated input type of updateVisitLineItem */
export type UpdateVisitLineItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  packageDescription: Scalars["String"]["input"];
  unitValue: Scalars["Float"]["input"];
  units: Scalars["Float"]["input"];
};

/** Autogenerated return type of updateVisitLineItem */
export type UpdateVisitLineItemPayload = {
  __typename?: "updateVisitLineItemPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  visitLineItem: VisitLineItem;
};

/** Autogenerated input type of updateVisitLineItems */
export type UpdateVisitLineItemsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  lineItems: Array<VisitLineItemInput>;
  skipOverlapCheck?: InputMaybe<Scalars["Boolean"]["input"]>;
  visitId: Scalars["ID"]["input"];
};

/** Autogenerated return type of updateVisitLineItems */
export type UpdateVisitLineItemsPayload = {
  __typename?: "updateVisitLineItemsPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  visit?: Maybe<Visit>;
};

/** Autogenerated return type of updateVisit */
export type UpdateVisitPayload = {
  __typename?: "updateVisitPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  visit: Visit;
};

/** Autogenerated input type of updateVisitPayrollAdjustment */
export type UpdateVisitPayrollAdjustmentInput = {
  adjustmentType: VisitPayrollAdjustmentTypes;
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  reason?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of updateVisitPayrollAdjustment */
export type UpdateVisitPayrollAdjustmentPayload = {
  __typename?: "updateVisitPayrollAdjustmentPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  visitPayrollAdjustment: VisitPayrollAdjustment;
};

/** Autogenerated input type of updateWorkerContractDay */
export type UpdateWorkerContractDayInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  workerContractDays: Array<WorkerContractDayInput>;
  workerContractId: Scalars["ID"]["input"];
};

/** Autogenerated return type of updateWorkerContractDay */
export type UpdateWorkerContractDayPayload = {
  __typename?: "updateWorkerContractDayPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  workerContract: WorkerContract;
};

/** Autogenerated input type of updateWorkerContract */
export type UpdateWorkerContractInput = {
  annualLeave?: InputMaybe<Scalars["Int"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  contractName?: InputMaybe<Scalars["String"]["input"]>;
  contractType?: InputMaybe<ContractTypeEnum>;
  hospitalLeave?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["ID"]["input"];
  payrollUnit?: InputMaybe<ContractPayrollUnitEnum>;
  sickLeave?: InputMaybe<Scalars["Int"]["input"]>;
  signedContractUrls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  unitValue?: InputMaybe<Scalars["Float"]["input"]>;
  workerContractDetailId?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Autogenerated return type of updateWorkerContract */
export type UpdateWorkerContractPayload = {
  __typename?: "updateWorkerContractPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  workerContract: WorkerContract;
};

/** Autogenerated input type of updateWorkerContractSkills */
export type UpdateWorkerContractSkillsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  workerContractId: Scalars["ID"]["input"];
  workerSkillIds: Array<Scalars["ID"]["input"]>;
};

/** Autogenerated return type of updateWorkerContractSkills */
export type UpdateWorkerContractSkillsPayload = {
  __typename?: "updateWorkerContractSkillsPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  workerContract: WorkerContract;
};

/** Autogenerated input type of updateWorkerDocument */
export type UpdateWorkerDocumentInput = {
  additionalDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  docNumber?: InputMaybe<Scalars["String"]["input"]>;
  docType?: InputMaybe<WorkerDocumentTypeEnum>;
  expirationDate?: InputMaybe<Scalars["Date"]["input"]>;
  files?: InputMaybe<Array<Scalars["UploadedFile"]["input"]>>;
  issuanceDate?: InputMaybe<Scalars["Date"]["input"]>;
  workerDocumentId: Scalars["ID"]["input"];
};

/** Autogenerated return type of updateWorkerDocument */
export type UpdateWorkerDocumentPayload = {
  __typename?: "updateWorkerDocumentPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  workerDocument: WorkerDocument;
};

/** Autogenerated input type of updateWorker */
export type UpdateWorkerInput = {
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  addressCheckRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  addressUnitNumber?: InputMaybe<Scalars["String"]["input"]>;
  avatar?: InputMaybe<Scalars["UploadedFile"]["input"]>;
  bankName?: InputMaybe<BankNameEnum>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  contactNumber?: InputMaybe<Scalars["String"]["input"]>;
  department?: InputMaybe<WorkerDepartmentEnum>;
  dob?: InputMaybe<Scalars["Date"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  emergencyContactName?: InputMaybe<Scalars["String"]["input"]>;
  emergencyContactNumber?: InputMaybe<Scalars["String"]["input"]>;
  employmentStartDate?: InputMaybe<Scalars["Date"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<SexEnum>;
  id: Scalars["ID"]["input"];
  languageSpoken?: InputMaybe<Array<LanguageSpokenEnum>>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  managerId?: InputMaybe<Scalars["ID"]["input"]>;
  nameOnId?: InputMaybe<Scalars["String"]["input"]>;
  nationality?: InputMaybe<NationalityEnum>;
  nric?: InputMaybe<Scalars["String"]["input"]>;
  payrollAcknowledgementRequired?: InputMaybe<Scalars["Boolean"]["input"]>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  servicePostalCode?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<WorkerStatusEnum>;
  techSavy?: InputMaybe<Scalars["Boolean"]["input"]>;
  workerType?: InputMaybe<WorkerWorkerTypeEnum>;
};

/** Autogenerated return type of updateWorker */
export type UpdateWorkerPayload = {
  __typename?: "updateWorkerPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  worker: Worker;
};

/** Autogenerated input type of updateWorkerPreferences */
export type UpdateWorkerPreferencesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  preferences: Scalars["JSON"]["input"];
  workerId: Scalars["ID"]["input"];
};

/** Autogenerated return type of updateWorkerPreferences */
export type UpdateWorkerPreferencesPayload = {
  __typename?: "updateWorkerPreferencesPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  preferences: Scalars["JSON"]["output"];
};

/** Autogenerated input type of updateWorkerTask */
export type UpdateWorkerTaskInput = {
  accessInstruction?: InputMaybe<Scalars["String"]["input"]>;
  additionalNotes?: InputMaybe<Scalars["String"]["input"]>;
  allowOverlap?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  endTime: Scalars["String"]["input"];
  fullAddress: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
  paymentAmount?: InputMaybe<Scalars["Float"]["input"]>;
  paymentType: TaskPaymentTypesEnum;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  postalCode: Scalars["String"]["input"];
  startTime: Scalars["String"]["input"];
  taskDate: Scalars["Date"]["input"];
  unitNumber?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of updateWorkerTask */
export type UpdateWorkerTaskPayload = {
  __typename?: "updateWorkerTaskPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  workerTask?: Maybe<WorkerTask>;
};

/** Autogenerated input type of updateWorkerTip */
export type UpdateWorkerTipInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  department?: InputMaybe<WorkerDepartmentEnum>;
  id: Scalars["ID"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  nationality?: InputMaybe<NationalityEnum>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of updateWorkerTip */
export type UpdateWorkerTipPayload = {
  __typename?: "updateWorkerTipPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  workerTip: WorkerTip;
};

/** Autogenerated input type of uploadOutstandingPayrollsCsv */
export type UploadOutstandingPayrollsCsvInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** Bas64 encoded csv file as a string */
  fileString: Scalars["String"]["input"];
};

/** Autogenerated return type of uploadOutstandingPayrollsCsv */
export type UploadOutstandingPayrollsCsvPayload = {
  __typename?: "uploadOutstandingPayrollsCsvPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors?: Maybe<Array<CsvErrors>>;
  result: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of verificationAddressWorker */
export type VerificationAddressWorkerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  payrollId: Scalars["ID"]["input"];
  videoAttachment: Scalars["UploadedFile"]["input"];
};

/** Autogenerated return type of verificationAddressWorker */
export type VerificationAddressWorkerPayload = {
  __typename?: "verificationAddressWorkerPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  payroll: Payroll;
};

/** Autogenerated input type of verifyUser */
export type VerifyUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
};

/** Autogenerated return type of verifyUser */
export type VerifyUserPayload = {
  __typename?: "verifyUserPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result: Client;
};

/** Autogenerated input type of withdrawCreditAuthorized */
export type WithdrawCreditAuthorizedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  payoutDate: Scalars["Date"]["input"];
  transactionId: Scalars["ID"]["input"];
};

/** Autogenerated return type of withdrawCreditAuthorized */
export type WithdrawCreditAuthorizedPayload = {
  __typename?: "withdrawCreditAuthorizedPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of withdrawCredit */
export type WithdrawCreditInput = {
  amount: Scalars["Float"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  creditAccountId: Scalars["ID"]["input"];
};

/** Autogenerated return type of withdrawCredit */
export type WithdrawCreditPayload = {
  __typename?: "withdrawCreditPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of workerConfirmReservation */
export type WorkerConfirmReservationInput = {
  accessType?: InputMaybe<Scalars["String"]["input"]>;
  additionalNotes?: InputMaybe<Scalars["String"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  contractId?: InputMaybe<Scalars["ID"]["input"]>;
  creationSource?: InputMaybe<CreationSourceInput>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  fullAddress: Scalars["String"]["input"];
  postalCode: Scalars["String"]["input"];
  promoCode?: InputMaybe<Scalars["String"]["input"]>;
  reservationId: Scalars["ID"]["input"];
  salespersonShortcode?: InputMaybe<Scalars["String"]["input"]>;
  unitNumber?: InputMaybe<Scalars["String"]["input"]>;
  workerChosenByClient?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Autogenerated return type of workerConfirmReservation */
export type WorkerConfirmReservationPayload = {
  __typename?: "workerConfirmReservationPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  package: Package;
};

/** Autogenerated input type of workerCreateReservation */
export type WorkerCreateReservationInput = {
  clientId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  endTime: Scalars["String"]["input"];
  frequencyOfJob: ScheduleJobFrequencyEnum;
  lineItemIds: Array<Scalars["ID"]["input"]>;
  serviceDate: Scalars["Date"]["input"];
  slotId: Scalars["ID"]["input"];
  startTime: Scalars["String"]["input"];
};

/** Autogenerated return type of workerCreateReservation */
export type WorkerCreateReservationPayload = {
  __typename?: "workerCreateReservationPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  result?: Maybe<ClientCreateReservationResult>;
};

export type AddressByPostalCodeQueryVariables = Exact<{
  postalCode: Scalars["String"]["input"];
}>;

export type AddressByPostalCodeQuery = {
  __typename?: "Query";
  addressByPostalCode?: {
    __typename?: "OnemapAddress";
    address: string;
  } | null;
};

export type AllClientPackageDetailsQueryVariables = Exact<{
  filters?: InputMaybe<PackageDetailsFilter>;
}>;

export type AllClientPackageDetailsQuery = {
  __typename?: "Query";
  allPackageDetails: {
    __typename?: "AllPackageDetails";
    packageDetails: Array<
      { __typename?: "PackageDetail" } & {
        " $fragmentRefs"?: {
          PackageDetailFragmentFragment: PackageDetailFragmentFragment;
        };
      }
    >;
  };
};

export type ClientQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ClientQuery = {
  __typename?: "Query";
  client: {
    __typename?: "Client";
    id: string;
    name: string;
    accountType: ClientAccountTypeEnum;
    preferences: any;
    billingAccount: {
      __typename?: "BillingAccount";
      id: string;
      balance: number;
      overdueAmount?: number | null;
      totalOverdueInvoice?: number | null;
    };
    user: {
      __typename?: "ClientUser";
      id: string;
      phoneNumber?: string | null;
      email: string;
      phoneNumberVerifiedAt?: string | null;
      emailVerifiedAt?: string | null;
    };
    addresses: Array<{
      __typename?: "Address";
      id: string;
      fullAddress: string;
      postalCode: string;
      unitNumber?: string | null;
      primary: boolean;
    }>;
    contacts: Array<{
      __typename?: "Contact";
      id: string;
      email: Array<string>;
      firstName: string;
      lastName: string;
      phoneNumber: Array<string>;
      primary: boolean;
    }>;
    contracts?: Array<{
      __typename?: "Contract";
      id: string;
      invoiceFrequency: ContractInvoiceFrequencyEnum;
    }> | null;
    creditAccounts: Array<{
      __typename?: "CreditAccount";
      id: string;
      creditType: CreditTypeEnum;
      balance: number;
    }>;
    paymentMethods: Array<{
      __typename?: "StripePaymentMethod";
      id: string;
      brand: StripeCardBrandsEnum;
      expMonth: string;
      expYear: string;
      last4: string;
      name: string;
    }>;
  };
};

export type ClientCancelReservationMutationVariables = Exact<{
  input: ClientCancelReservationInput;
}>;

export type ClientCancelReservationMutation = {
  __typename?: "Mutation";
  clientCancelReservation?: {
    __typename?: "clientCancelReservationPayload";
    reservations: Array<{
      __typename?: "Reservation";
      id: string;
      status: ReservationStatusEnum;
    }>;
  } | null;
};

export type ClientConfirmPhoneNumberMutationVariables = Exact<{
  input: ClientConfirmPhoneNumberInput;
}>;

export type ClientConfirmPhoneNumberMutation = {
  __typename?: "Mutation";
  clientConfirmPhoneNumber?: {
    __typename?: "clientConfirmPhoneNumberPayload";
    result: boolean;
  } | null;
};

export type ClientConfirmReservationMutationVariables = Exact<{
  input: ClientConfirmReservationInput;
}>;

export type ClientConfirmReservationMutation = {
  __typename?: "Mutation";
  clientConfirmReservation?: {
    __typename?: "clientConfirmReservationPayload";
    package: {
      __typename?: "Package";
      id: string;
      unitValue: number;
      serviceBillingValue: number;
      packageDetail: {
        __typename?: "PackageDetail";
        id: string;
        code: string;
        duration: number;
        repeatEvery: PackageRecurrenceEnum;
        department: PackageDepartmentEnum;
        serviceType: PackageServiceTypeEnum;
      };
      jobs?: Array<{
        __typename?: "Job";
        id: string;
        worker?: {
          __typename?: "Worker";
          id: string;
          firstName: string;
          lastName: string;
          avatarUrl?: string | null;
          workerRating: number;
        } | null;
      }> | null;
    };
  } | null;
};

export type ClientCreateReservationMutationVariables = Exact<{
  input: ClientCreateReservationInput;
}>;

export type ClientCreateReservationMutation = {
  __typename?: "Mutation";
  clientCreateReservation?: {
    __typename?: "clientCreateReservationPayload";
    result?:
      | { __typename: "BookingSuspended"; entity: string; message: string }
      | {
          __typename: "ClientCreateReservationOk";
          reservations: Array<
            { __typename?: "Reservation" } & {
              " $fragmentRefs"?: {
                ReservationDetailFragmentFragment: ReservationDetailFragmentFragment;
              };
            }
          >;
        }
      | { __typename: "ValidationProblem"; entity: string; message: string }
      | null;
  } | null;
};

export type ClientFindBookingScheduleQueryVariables = Exact<{
  department: WorkerDepartmentEnum;
  frequencyOfJob?: InputMaybe<ScheduleJobFrequencyEnum>;
  scheduleMatching?: InputMaybe<ScheduleMatchingEnum>;
  sessionDuration: Scalars["Int"]["input"];
  postalCode: Scalars["String"]["input"];
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  timeRanges?: InputMaybe<Array<TimeRange> | TimeRange>;
  workersIds?: InputMaybe<
    Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]
  >;
  preferredWorkerIds?: InputMaybe<
    Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]
  >;
  workerSkillIds?: InputMaybe<
    Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]
  >;
  lineItemIds?: InputMaybe<
    Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]
  >;
  packageDetailId?: InputMaybe<Scalars["Int"]["input"]>;
  clientId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type ClientFindBookingScheduleQuery = {
  __typename?: "Query";
  clientFindBookingSchedule: Array<{
    __typename?: "ClientScheduleVariant";
    day: number;
    afternoon?: Array<{
      __typename?: "FreeTimeslot";
      id: string;
      startAt: string;
      endAt: string;
      averageTravelTime: number;
      rateValue?: number | null;
      sessionValue?: number | null;
      worker: {
        __typename?: "Worker";
        id: string;
        avatarUrl?: string | null;
        workerRating: number;
        firstName: string;
        lastName: string;
        workerContracts?: Array<{
          __typename?: "WorkerContract";
          portfolios?: Array<{
            __typename?: "SavedFile";
            id: string;
            downloadUrl: string;
            filename: string;
          }> | null;
        }> | null;
      };
    }> | null;
    evening?: Array<{
      __typename?: "FreeTimeslot";
      id: string;
      startAt: string;
      endAt: string;
      averageTravelTime: number;
      rateValue?: number | null;
      sessionValue?: number | null;
      worker: {
        __typename?: "Worker";
        id: string;
        avatarUrl?: string | null;
        workerRating: number;
        firstName: string;
        lastName: string;
        workerContracts?: Array<{
          __typename?: "WorkerContract";
          portfolios?: Array<{
            __typename?: "SavedFile";
            id: string;
            downloadUrl: string;
            filename: string;
          }> | null;
        }> | null;
      };
    }> | null;
    morning?: Array<{
      __typename?: "FreeTimeslot";
      id: string;
      startAt: string;
      endAt: string;
      averageTravelTime: number;
      rateValue?: number | null;
      sessionValue?: number | null;
      worker: {
        __typename?: "Worker";
        id: string;
        avatarUrl?: string | null;
        workerRating: number;
        firstName: string;
        lastName: string;
        workerContracts?: Array<{
          __typename?: "WorkerContract";
          portfolios?: Array<{
            __typename?: "SavedFile";
            id: string;
            downloadUrl: string;
            filename: string;
          }> | null;
        }> | null;
      };
    }> | null;
  }>;
};

export type ClientFindPastWorkersQueryVariables = Exact<{
  clientId: Scalars["ID"]["input"];
  department?: InputMaybe<PackageDepartmentEnum>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type ClientFindPastWorkersQuery = {
  __typename?: "Query";
  clientFindPastWorkers: Array<{
    __typename?: "Worker";
    id: string;
    firstName: string;
    lastName: string;
    workerRating: number;
    avatarUrl?: string | null;
    contactNumber: string;
  }>;
};

export type ClientReferralCodeQueryVariables = Exact<{
  clientId: Scalars["ID"]["input"];
}>;

export type ClientReferralCodeQuery = {
  __typename?: "Query";
  clientReferralCode: {
    __typename: "ClientReferralCode";
    url: string;
    code: string;
  };
};

export type ClientValidatePromoCodeMutationVariables = Exact<{
  input: ClientValidatePromoCodeInput;
}>;

export type ClientValidatePromoCodeMutation = {
  __typename?: "Mutation";
  clientValidatePromoCode?: {
    __typename?: "clientValidatePromoCodePayload";
    promo: {
      __typename?: "MasterPromoCode";
      discountType: DiscountTypeEnum;
      discountValue: number;
    };
  } | null;
};

export type ClientVerifyPhoneNumberMutationVariables = Exact<{
  input: ClientVerifyPhoneNumberInput;
}>;

export type ClientVerifyPhoneNumberMutation = {
  __typename?: "Mutation";
  clientVerifyPhoneNumber?: {
    __typename?: "clientVerifyPhoneNumberPayload";
    result?:
      | { __typename: "AccountDeleted"; entity: string; message: string }
      | { __typename: "SendPinCodeToPhoneOk"; expiresAt: string }
      | { __typename: "ValidationProblem"; entity: string; message: string }
      | null;
  } | null;
};

export type CreateAddressMutationVariables = Exact<{
  input: CreateAddressInput;
}>;

export type CreateAddressMutation = {
  __typename?: "Mutation";
  createAddress?: {
    __typename?: "createAddressPayload";
    address: {
      __typename?: "Address";
      id: string;
      fullAddress: string;
      postalCode: string;
      primary: boolean;
      unitNumber?: string | null;
    };
  } | null;
};

export type CreateContactMutationVariables = Exact<{
  input: CreateContactInput;
}>;

export type CreateContactMutation = {
  __typename?: "Mutation";
  createContact?: {
    __typename?: "createContactPayload";
    contact: {
      __typename?: "Contact";
      id: string;
      firstName: string;
      lastName: string;
      email: Array<string>;
      phoneNumber: Array<string>;
      primary: boolean;
    };
  } | null;
};

export type DeleteClientMutationVariables = Exact<{
  input: DeleteClientInput;
}>;

export type DeleteClientMutation = {
  __typename?: "Mutation";
  deleteClient?: { __typename?: "deleteClientPayload"; result: boolean } | null;
};

export type PackageDetailFragmentFragment = {
  __typename?: "PackageDetail";
  id: string;
  code: string;
  department: PackageDepartmentEnum;
  description: string;
  duration: number;
  durationPerUnit: number;
  serviceType: PackageServiceTypeEnum;
  totalValue: number;
  unitType: PackageUnitTypeEnum;
  unitSize: UnitSizeEnum;
  unitValue: number;
  units: number;
  billingUnit: PackageDetailBillingUnitEnum;
  repeatEvery: PackageRecurrenceEnum;
  repeatEveryTimes: number;
  session: PackageDetailSessionEnum;
  serviceBillingValue: number;
  invoiceFrequency: ContractInvoiceFrequencyEnum;
  workerSkill: { __typename?: "WorkerSkill"; id: string };
} & { " $fragmentName"?: "PackageDetailFragmentFragment" };

export type ReservationByIdQueryVariables = Exact<{
  reservationId: Scalars["ID"]["input"];
}>;

export type ReservationByIdQuery = {
  __typename?: "Query";
  reservationById?:
    | ({ __typename?: "Reservation" } & {
        " $fragmentRefs"?: {
          ReservationDetailFragmentFragment: ReservationDetailFragmentFragment;
        };
      })
    | null;
};

export type ReservationDetailFragmentFragment = {
  __typename?: "Reservation";
  id: string;
  serviceDate: string;
  startTime: string;
  endTime: string;
  frequencyOfJob: ScheduleJobFrequencyEnum;
  status: ReservationStatusEnum;
  client: { __typename?: "Client"; id: string };
  freeTimeslot: {
    __typename?: "FreeTimeslot";
    id: string;
    startAt: string;
    endAt: string;
    worker: {
      __typename?: "Worker";
      id: string;
      avatarUrl?: string | null;
      firstName: string;
      lastName: string;
      totalCompletedVisits?: number | null;
    };
  };
} & { " $fragmentName"?: "ReservationDetailFragmentFragment" };

export type ResetPasswordFromEmailMutationVariables = Exact<{
  input: ResetPasswordFromEmailInput;
}>;

export type ResetPasswordFromEmailMutation = {
  __typename?: "Mutation";
  resetPasswordFromEmail?: {
    __typename?: "resetPasswordFromEmailPayload";
    user?:
      | { __typename?: "ClientUser"; email: string }
      | { __typename?: "RegularUser" }
      | { __typename?: "WorkerUser" }
      | null;
  } | null;
};

export type ResetPasswordFromPhoneMutationVariables = Exact<{
  input: ResetPasswordFromPhoneInput;
}>;

export type ResetPasswordFromPhoneMutation = {
  __typename?: "Mutation";
  resetPasswordFromPhone?: {
    __typename?: "resetPasswordFromPhonePayload";
    user?:
      | { __typename?: "ClientUser"; email: string }
      | { __typename?: "RegularUser" }
      | { __typename?: "WorkerUser" }
      | null;
  } | null;
};

export type SendPinCodeToPhoneMutationVariables = Exact<{
  input: SendPinCodeToPhoneInput;
}>;

export type SendPinCodeToPhoneMutation = {
  __typename?: "Mutation";
  sendPinCodeToPhone?: {
    __typename?: "sendPinCodeToPhonePayload";
    result?:
      | { __typename?: "AccountDeleted"; message: string }
      | { __typename?: "SendPinCodeToPhoneOk"; expiresAt: string }
      | { __typename?: "ValidationProblem"; message: string }
      | null;
  } | null;
};

export type SendResetPasswordMutationVariables = Exact<{
  input: SendResetPasswordInput;
}>;

export type SendResetPasswordMutation = {
  __typename?: "Mutation";
  sendResetPassword?: {
    __typename?: "sendResetPasswordPayload";
    result?:
      | { __typename?: "AccountDeleted"; entity: string; message: string }
      | { __typename?: "NotFoundProblem"; entity: string; message: string }
      | null;
  } | null;
};

export type SignInMutationVariables = Exact<{
  input: SignInClientInput;
}>;

export type SignInMutation = {
  __typename?: "Mutation";
  signInClient?: {
    __typename?: "signInClientPayload";
    result:
      | { __typename?: "AccountDeleted"; entity: string; message: string }
      | { __typename?: "NotWhitelistedProblem" }
      | {
          __typename?: "SignInClientOk";
          jwt: string;
          jwtExpiry: number;
          refreshToken: string;
          client: {
            __typename?: "Client";
            id: string;
            user: {
              __typename?: "ClientUser";
              id: string;
              email: string;
              phoneNumber?: string | null;
              phoneNumberVerifiedAt?: string | null;
              emailVerifiedAt?: string | null;
            };
            contacts: Array<{
              __typename?: "Contact";
              id: string;
              firstName: string;
              lastName: string;
            }>;
            addresses: Array<{
              __typename?: "Address";
              id: string;
              postalCode: string;
            }>;
          };
        }
      | { __typename?: "SignUpRequired" }
      | {
          __typename?: "ValidationProblem";
          entity: string;
          message: string;
          errors?: Array<{
            __typename?: "ValidationError";
            field: string;
            messages: Array<string>;
          }> | null;
        };
  } | null;
};

export type SignInSocialAccountMutationVariables = Exact<{
  input: SignInClientSocialAccountInput;
}>;

export type SignInSocialAccountMutation = {
  __typename?: "Mutation";
  signInClientSocialAccount?: {
    __typename?: "signInClientSocialAccountPayload";
    result:
      | { __typename?: "AccountDeleted"; entity: string; message: string }
      | { __typename?: "NotWhitelistedProblem" }
      | {
          __typename?: "SignInClientOk";
          jwt: string;
          jwtExpiry: number;
          refreshToken: string;
          client: {
            __typename?: "Client";
            id: string;
            user: {
              __typename?: "ClientUser";
              id: string;
              phoneNumber?: string | null;
              email: string;
              phoneNumberVerifiedAt?: string | null;
              emailVerifiedAt?: string | null;
            };
            contacts: Array<{
              __typename?: "Contact";
              id: string;
              firstName: string;
              lastName: string;
            }>;
            addresses: Array<{
              __typename?: "Address";
              id: string;
              postalCode: string;
            }>;
          };
        }
      | { __typename: "SignUpRequired"; entity: string; message: string }
      | {
          __typename?: "ValidationProblem";
          entity: string;
          message: string;
          errors?: Array<{
            __typename?: "ValidationError";
            field: string;
            messages: Array<string>;
          }> | null;
        };
  } | null;
};

export type SignInWithPhoneMutationVariables = Exact<{
  input: SignInWithPhoneInput;
}>;

export type SignInWithPhoneMutation = {
  __typename?: "Mutation";
  signInWithPhone?: {
    __typename?: "signInWithPhonePayload";
    result:
      | { __typename?: "AccountDeleted"; entity: string; message: string }
      | { __typename?: "NotWhitelistedProblem"; message: string }
      | {
          __typename?: "SignInClientOk";
          jwt: string;
          jwtExpiry: number;
          refreshToken: string;
          client: {
            __typename?: "Client";
            id: string;
            user: {
              __typename?: "ClientUser";
              id: string;
              phoneNumber?: string | null;
              email: string;
              phoneNumberVerifiedAt?: string | null;
              emailVerifiedAt?: string | null;
            };
            contacts: Array<{
              __typename?: "Contact";
              id: string;
              firstName: string;
              lastName: string;
            }>;
            addresses: Array<{
              __typename?: "Address";
              id: string;
              postalCode: string;
            }>;
          };
        }
      | { __typename?: "SignInWorkerOk" }
      | { __typename?: "SignUpRequired"; entity: string; message: string }
      | {
          __typename?: "ValidationProblem";
          entity: string;
          message: string;
          errors?: Array<{
            __typename?: "ValidationError";
            field: string;
            messages: Array<string>;
          }> | null;
        };
  } | null;
};

export type SignOutMutationVariables = Exact<{ [key: string]: never }>;

export type SignOutMutation = {
  __typename?: "Mutation";
  signOut?: {
    __typename?: "signOutPayload";
    user?:
      | { __typename?: "ClientUser" }
      | { __typename?: "RegularUser"; id: string }
      | { __typename?: "WorkerUser" }
      | null;
  } | null;
};

export type SignUpMutationVariables = Exact<{
  input: SignUpInput;
}>;

export type SignUpMutation = {
  __typename?: "Mutation";
  signUp?: {
    __typename?: "signUpPayload";
    result:
      | { __typename?: "EntityExistsProblem"; entity: string; message: string }
      | { __typename?: "NotWhitelistedProblem" }
      | {
          __typename?: "SignUpOk";
          jwt: string;
          refreshToken: string;
          jwtExpiry: number;
          viewer: {
            __typename?: "Viewer";
            currentUser?:
              | { __typename?: "ClientUser"; id: string }
              | { __typename?: "RegularUser" }
              | { __typename?: "WorkerUser" }
              | null;
          };
          client: {
            __typename?: "Client";
            id: string;
            user: {
              __typename?: "ClientUser";
              id: string;
              phoneNumber?: string | null;
              email: string;
              phoneNumberVerifiedAt?: string | null;
              emailVerifiedAt?: string | null;
            };
            contacts: Array<{
              __typename?: "Contact";
              id: string;
              firstName: string;
              lastName: string;
            }>;
            addresses: Array<{
              __typename?: "Address";
              id: string;
              postalCode: string;
            }>;
          };
        }
      | {
          __typename?: "ValidationProblem";
          entity: string;
          message: string;
          errors?: Array<{
            __typename?: "ValidationError";
            field: string;
            messages: Array<string>;
          }> | null;
        };
  } | null;
};

export type UpdateAccountPhoneNumberMutationVariables = Exact<{
  input: UpdateAccountPhoneNumberInput;
}>;

export type UpdateAccountPhoneNumberMutation = {
  __typename?: "Mutation";
  updateAccountPhoneNumber?: {
    __typename?: "updateAccountPhoneNumberPayload";
    success: boolean;
  } | null;
};

export type UpdateClientMutationVariables = Exact<{
  input: UpdateClientInput;
}>;

export type UpdateClientMutation = {
  __typename?: "Mutation";
  updateClient?: {
    __typename?: "updateClientPayload";
    client: {
      __typename?: "Client";
      id: string;
      name: string;
      accountType: ClientAccountTypeEnum;
      status: ClientStatusEnum;
    };
  } | null;
};

export type VerifyUserMutationVariables = Exact<{
  input: VerifyUserInput;
}>;

export type VerifyUserMutation = {
  __typename?: "Mutation";
  verifyUser?: {
    __typename?: "verifyUserPayload";
    result: { __typename?: "Client"; id: string };
  } | null;
};

export type ClientIssueDetailsQueryVariables = Exact<{
  type: IssueTypeEnum;
}>;

export type ClientIssueDetailsQuery = {
  __typename?: "Query";
  clientIssueDetails: Array<{
    __typename?: "ClientIssueDetails";
    id: string;
    name: string;
  }>;
};

export type ClientReviewsQueryVariables = Exact<{
  filters?: InputMaybe<ClientReviewsInput>;
}>;

export type ClientReviewsQuery = {
  __typename?: "Query";
  clientReviews: {
    __typename?: "ClientReviews";
    count: number;
    averageRating: number;
    clientReviews: Array<{
      __typename?: "ClientReview";
      id: string;
      clientName: string;
      rating: number;
      ratingComment?: string | null;
      serviceDate?: string | null;
      serviceType?: Array<PackageServiceTypeEnum> | null;
      department?: PackageDepartmentEnum | null;
      clientLifetimeBooking?: number | null;
      ratingAttachment?: Array<{
        __typename?: "SavedFile";
        id: string;
        downloadUrl: string;
      }> | null;
    }>;
  };
};

export type CreateClientIssueRequestMutationVariables = Exact<{
  input: CreateClientIssueRequestInput;
}>;

export type CreateClientIssueRequestMutation = {
  __typename?: "Mutation";
  createClientIssueRequest?: {
    __typename?: "createClientIssueRequestPayload";
    result: boolean;
  } | null;
};

export type CreateClientIssueRequestWithTokenMutationVariables = Exact<{
  input: CreateClientIssueRequestWithTokenInput;
}>;

export type CreateClientIssueRequestWithTokenMutation = {
  __typename?: "Mutation";
  createClientIssueRequestWithToken?: {
    __typename?: "createClientIssueRequestWithTokenPayload";
    result: boolean;
  } | null;
};

export type ClientApplyVoucherMutationVariables = Exact<{
  input: ClientApplyVoucherInput;
}>;

export type ClientApplyVoucherMutation = {
  __typename?: "Mutation";
  clientApplyVoucher?: {
    __typename?: "clientApplyVoucherPayload";
    success: boolean;
    invoice: { __typename?: "Invoice" } & {
      " $fragmentRefs"?: {
        InvoiceDetailFragmentFragment: InvoiceDetailFragmentFragment;
      };
    };
  } | null;
};

export type ClientRemoveVoucherMutationVariables = Exact<{
  input: ClientRemoveVoucherInput;
}>;

export type ClientRemoveVoucherMutation = {
  __typename?: "Mutation";
  clientRemoveVoucher?: {
    __typename?: "clientRemoveVoucherPayload";
    success: boolean;
    invoice: { __typename?: "Invoice" } & {
      " $fragmentRefs"?: {
        InvoiceDetailFragmentFragment: InvoiceDetailFragmentFragment;
      };
    };
  } | null;
};

export type ClientViewVouchersQueryVariables = Exact<{
  clientId: Scalars["ID"]["input"];
}>;

export type ClientViewVouchersQuery = {
  __typename?: "Query";
  clientViewVouchers: Array<{
    __typename?: "ClientVoucher";
    id: string;
    discountType: VoucherDiscountTypeEnum;
    discountValue: number;
    description?: string | null;
    validUntil: string;
    expiresInDays: number;
  }>;
};

export type GenerateInvoicePdfMutationVariables = Exact<{
  input: GenerateInvoicePdfInput;
}>;

export type GenerateInvoicePdfMutation = {
  __typename?: "Mutation";
  generateInvoicePdf?: {
    __typename?: "generateInvoicePdfPayload";
    fileString: string;
  } | null;
};

export type GenerateInvoiceReceiptPdfMutationVariables = Exact<{
  input: GenerateInvoiceReceiptPdfInput;
}>;

export type GenerateInvoiceReceiptPdfMutation = {
  __typename?: "Mutation";
  generateInvoiceReceiptPdf?: {
    __typename?: "generateInvoiceReceiptPdfPayload";
    fileString: string;
  } | null;
};

export type InvoiceByIdQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type InvoiceByIdQuery = {
  __typename?: "Query";
  invoiceById: { __typename?: "Invoice" } & {
    " $fragmentRefs"?: {
      InvoiceDetailFragmentFragment: InvoiceDetailFragmentFragment;
    };
  };
};

export type InvoiceDetailFragmentFragment = {
  __typename?: "Invoice";
  id: string;
  amount: number;
  paidAmount: number;
  baseAmount: number;
  paymentStatus: InvoicePaymentStatusEnum;
  fromDate: string;
  toDate?: string | null;
  issueDate?: string | null;
  dueDate?: string | null;
  status: InvoiceStatusEnum;
  pdfUrl?: string | null;
  totalAmount: number;
  pdfReceiptUrl?: string | null;
  clientPaymentIndicated?: string | null;
  appliedVoucher?: {
    __typename?: "ClientVoucher";
    id: string;
    discountType: VoucherDiscountTypeEnum;
    discountValue: number;
    expiresInDays: number;
  } | null;
  lineItems?: Array<{
    __typename?: "InvoiceLineItem";
    amount: number;
    description: string;
    productCode: string;
    quantity: number;
  }> | null;
} & { " $fragmentName"?: "InvoiceDetailFragmentFragment" };

export type InvoicesByFiltersQueryVariables = Exact<{
  filters?: InputMaybe<InvoicesFilter>;
}>;

export type InvoicesByFiltersQuery = {
  __typename?: "Query";
  invoicesByFilters: {
    __typename?: "InvoicesByFilters";
    count: number;
    invoices: Array<{
      __typename?: "Invoice";
      id: string;
      totalAmount: number;
      paidAmount: number;
      paymentStatus: InvoicePaymentStatusEnum;
      issueDate?: string | null;
      dueDate?: string | null;
      clientPaymentIndicated?: string | null;
    }>;
  };
};

export type PayInvoiceMutationVariables = Exact<{
  input: PayInvoiceInput;
}>;

export type PayInvoiceMutation = {
  __typename?: "Mutation";
  payInvoice?: {
    __typename?: "payInvoicePayload";
    invoice: { __typename?: "Invoice"; id: string };
  } | null;
};

export type UpdatePaymentIndicatedMutationVariables = Exact<{
  input: UpdatePaymentIndicatedInput;
}>;

export type UpdatePaymentIndicatedMutation = {
  __typename?: "Mutation";
  updatePaymentIndicated?: {
    __typename?: "updatePaymentIndicatedPayload";
    result?: boolean | null;
  } | null;
};

export type CreatePackageIssueRequestMutationVariables = Exact<{
  input: CreatePackageIssueRequestInput;
}>;

export type CreatePackageIssueRequestMutation = {
  __typename?: "Mutation";
  createPackageIssueRequest?: {
    __typename?: "createPackageIssueRequestPayload";
    result: boolean;
  } | null;
};

export type PackageQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type PackageQuery = {
  __typename?: "Query";
  package: {
    __typename?: "Package";
    id: string;
    status: PackageStatusEnum;
    startDate: string;
    repeatEvery: PackageRecurrenceEnum;
    repeatEveryTimes: number;
    department: PackageDepartmentEnum;
    endDate?: string | null;
    packageDescription: string;
    fullAddress: string;
    postalCode: string;
    creditAccount?: {
      __typename?: "CreditAccount";
      id: string;
      balance: number;
      creditType: CreditTypeEnum;
      debitedAmount: number;
      expirationDate?: string | null;
      transactions: Array<{
        __typename?: "CreditAccountTransaction";
        id: string;
        description: string;
        createdAt: string;
        amount: number;
        transactionType: CreditAccountTransactionTypeEnum;
      }>;
    } | null;
    packageLineItems: Array<{
      __typename?: "PackageLineItem";
      id: string;
      packageDescription: string;
      billingUnit: PackageDetailBillingUnitEnum;
      billingValue: number;
      units: number;
      unitValue: number;
      packageDetail: { __typename?: "PackageDetail" } & {
        " $fragmentRefs"?: {
          PackageDetailFragmentFragment: PackageDetailFragmentFragment;
        };
      };
    }>;
    jobs?: Array<{
      __typename?: "Job";
      id: string;
      startDate: string;
      endDate?: string | null;
      tasks: Array<{
        __typename?: "Task";
        id: string;
        day: number;
        startTime: string;
        endTime: string;
      }>;
    }> | null;
  };
};

export type PackagesByFiltersQueryVariables = Exact<{
  filters: PackagesByFiltersInput;
}>;

export type PackagesByFiltersQuery = {
  __typename?: "Query";
  packagesByFilters: {
    __typename?: "PackagesByFilters";
    count: number;
    packages: Array<{
      __typename?: "Package";
      id: string;
      startDate: string;
      department: PackageDepartmentEnum;
      endDate?: string | null;
      packageDescription: string;
      fullAddress: string;
      status: PackageStatusEnum;
      repeatEvery: PackageRecurrenceEnum;
    }>;
  };
};

export type ClientAddressesQueryVariables = Exact<{
  filters: ClientAddressesFilterInput;
}>;

export type ClientAddressesQuery = {
  __typename?: "Query";
  clientAddresses: {
    __typename?: "ClientAddresses";
    count: number;
    addresses: Array<{
      __typename?: "Address";
      id: string;
      fullAddress: string;
      postalCode: string;
      unitNumber?: string | null;
      primary: boolean;
    }>;
  };
};

export type ClientContactsQueryVariables = Exact<{
  filters: ClientContactsFilterInput;
}>;

export type ClientContactsQuery = {
  __typename?: "Query";
  clientContacts: {
    __typename?: "ClientContacts";
    contacts: Array<{
      __typename?: "Contact";
      id: string;
      email: Array<string>;
      firstName: string;
      lastName: string;
      phoneNumber: Array<string>;
      primary: boolean;
    }>;
  };
};

export type CreatePaymentMethodMutationVariables = Exact<{
  input: CreatePaymentMethodInput;
}>;

export type CreatePaymentMethodMutation = {
  __typename?: "Mutation";
  createPaymentMethod?: {
    __typename?: "createPaymentMethodPayload";
    paymentMethod?: { __typename?: "StripePaymentMethod"; id: string } | null;
  } | null;
};

export type CreditAccountByIdQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type CreditAccountByIdQuery = {
  __typename?: "Query";
  creditAccountById: {
    __typename?: "CreditAccount";
    id: string;
    creditType: CreditTypeEnum;
    balance: number;
    expirationDate?: string | null;
    client: { __typename?: "Client"; id: string };
    transactions: Array<{
      __typename?: "CreditAccountTransaction";
      id: string;
      amount: number;
      description: string;
      createdAt: string;
      transactionType: CreditAccountTransactionTypeEnum;
    }>;
  };
};

export type DeleteAddressMutationVariables = Exact<{
  input: DeleteAddressInput;
}>;

export type DeleteAddressMutation = {
  __typename?: "Mutation";
  deleteAddress?: {
    __typename?: "deleteAddressPayload";
    result: boolean;
  } | null;
};

export type DeleteContactMutationVariables = Exact<{
  input: DeleteContactInput;
}>;

export type DeleteContactMutation = {
  __typename?: "Mutation";
  deleteContact?: {
    __typename?: "deleteContactPayload";
    result: boolean;
  } | null;
};

export type DeletePaymentMethodMutationVariables = Exact<{
  input: DeletePaymentMethodInput;
}>;

export type DeletePaymentMethodMutation = {
  __typename?: "Mutation";
  deletePaymentMethod?: {
    __typename?: "deletePaymentMethodPayload";
    result: boolean;
  } | null;
};

export type TokenizePaymentMethodMutationVariables = Exact<{
  input: TokenizePaymentMethodInput;
}>;

export type TokenizePaymentMethodMutation = {
  __typename?: "Mutation";
  tokenizePaymentMethod?: {
    __typename?: "tokenizePaymentMethodPayload";
    token?: string | null;
  } | null;
};

export type UpdateAddressMutationVariables = Exact<{
  input: UpdateAddressInput;
}>;

export type UpdateAddressMutation = {
  __typename?: "Mutation";
  updateAddress?: {
    __typename?: "updateAddressPayload";
    address: {
      __typename?: "Address";
      id: string;
      fullAddress: string;
      postalCode: string;
      primary: boolean;
      unitNumber?: string | null;
    };
  } | null;
};

export type UpdateClientPreferencesMutationVariables = Exact<{
  input: UpdateClientPreferencesInput;
}>;

export type UpdateClientPreferencesMutation = {
  __typename?: "Mutation";
  updateClientPreferences?: {
    __typename?: "updateClientPreferencesPayload";
    preferences: any;
  } | null;
};

export type UpdateContactMutationVariables = Exact<{
  input: UpdateContactInput;
}>;

export type UpdateContactMutation = {
  __typename?: "Mutation";
  updateContact?: {
    __typename?: "updateContactPayload";
    contact: {
      __typename?: "Contact";
      id: string;
      firstName: string;
      lastName: string;
      email: Array<string>;
      phoneNumber: Array<string>;
      primary: boolean;
    };
  } | null;
};

export type GetPromotionQueryVariables = Exact<{ [key: string]: never }>;

export type GetPromotionQuery = {
  __typename?: "Query";
  getPromotion?: {
    __typename?: "Promotion";
    id: string;
    title: string;
    startDate: string;
    endDate: string;
    status: PromotionStatusEnum;
    buttonLabel?: string | null;
    url: string;
    promotionImages: Array<{
      __typename?: "SavedFile";
      id: string;
      downloadUrl: string;
      filename: string;
      createdAt: string;
    }>;
  } | null;
};

export type RewardsByFiltersQueryVariables = Exact<{
  filters?: InputMaybe<RewardsFilter>;
}>;

export type RewardsByFiltersQuery = {
  __typename?: "Query";
  rewardsByFilters: {
    __typename?: "RewardsByFilters";
    count: number;
    rewards: Array<{
      __typename?: "Reward";
      id: string;
      validFrom: string;
      validTo?: string | null;
      status?: RewardStatusEnum | null;
      masterReward: {
        __typename?: "MasterReward";
        id: string;
        name: string;
        description?: string | null;
        incentiveObject?:
          | {
              __typename?: "MasterPromoCode";
              promoCode: string;
              discountType: DiscountTypeEnum;
              discountValueFloat: number;
            }
          | {
              __typename?: "MasterVoucher";
              promoCode: string;
              discountValueInt: number;
              discountTypeString: string;
            }
          | null;
      };
    }>;
  };
};

export type CancelVisitWithTokenMutationVariables = Exact<{
  input: CancelVisitWithTokenInput;
}>;

export type CancelVisitWithTokenMutation = {
  __typename?: "Mutation";
  cancelVisitWithToken?: {
    __typename?: "cancelVisitWithTokenPayload";
    visit: {
      __typename?: "Visit";
      id: string;
      status: VisitStatusEnum;
      statusComment?: string | null;
      task: {
        __typename?: "Task";
        id: string;
        package: {
          __typename?: "Package";
          id: string;
          unitValue: number;
          serviceBillingValue: number;
          packageDetail: {
            __typename?: "PackageDetail";
            id: string;
            code: string;
            duration: number;
            repeatEvery: PackageRecurrenceEnum;
          };
        };
      };
    };
  } | null;
};

export type ClientCancelVisitMutationVariables = Exact<{
  input: ClientCancelVisitInput;
}>;

export type ClientCancelVisitMutation = {
  __typename?: "Mutation";
  clientCancelVisit?: {
    __typename?: "clientCancelVisitPayload";
    visit: {
      __typename?: "Visit";
      id: string;
      status: VisitStatusEnum;
      statusComment?: string | null;
      task: {
        __typename?: "Task";
        id: string;
        package: {
          __typename?: "Package";
          id: string;
          unitValue: number;
          serviceBillingValue: number;
          packageDetail: {
            __typename?: "PackageDetail";
            id: string;
            code: string;
            duration: number;
            repeatEvery: PackageRecurrenceEnum;
          };
        };
      };
    };
  } | null;
};

export type ClientRescheduleVisitMutationVariables = Exact<{
  input: ClientRescheduleVisitInput;
}>;

export type ClientRescheduleVisitMutation = {
  __typename?: "Mutation";
  clientRescheduleVisit?: {
    __typename?: "clientRescheduleVisitPayload";
    visit: {
      __typename?: "Visit";
      id: string;
      serviceDate: string;
      startTime?: string | null;
      endTime?: string | null;
      status: VisitStatusEnum;
      statusComment?: string | null;
      worker?: {
        __typename?: "Worker";
        id: string;
        firstName: string;
        lastName: string;
        totalCompletedVisits?: number | null;
        avatarUrl?: string | null;
      } | null;
    };
  } | null;
};

export type RateVisitMutationVariables = Exact<{
  input: RateVisitInput;
}>;

export type RateVisitMutation = {
  __typename?: "Mutation";
  rateVisit?: {
    __typename?: "rateVisitPayload";
    visit: {
      __typename?: "Visit";
      id: string;
      status: VisitStatusEnum;
      rating?: number | null;
      reviewAttachments?: Array<{
        __typename?: "SavedFile";
        downloadUrl: string;
        filename: string;
      }> | null;
    };
  } | null;
};

export type RateVisitWithTokenMutationVariables = Exact<{
  input: RateVisitWithTokenInput;
}>;

export type RateVisitWithTokenMutation = {
  __typename?: "Mutation";
  rateVisitWithToken?: {
    __typename?: "rateVisitWithTokenPayload";
    visit: { __typename?: "Visit"; id: string };
  } | null;
};

export type RescheduleVisitWithTokenMutationVariables = Exact<{
  input: RescheduleVisitWithTokenInput;
}>;

export type RescheduleVisitWithTokenMutation = {
  __typename?: "Mutation";
  rescheduleVisitWithToken?: {
    __typename?: "rescheduleVisitWithTokenPayload";
    visit: {
      __typename?: "Visit";
      id: string;
      serviceDate: string;
      startTime?: string | null;
      endTime?: string | null;
      status: VisitStatusEnum;
      statusComment?: string | null;
      worker?: {
        __typename?: "Worker";
        id: string;
        firstName: string;
        lastName: string;
        totalCompletedVisits?: number | null;
        avatarUrl?: string | null;
      } | null;
    };
  } | null;
};

export type UnratedVisitsByFilterQueryVariables = Exact<{
  filters: UnratedVisitsByFiltersInput;
}>;

export type UnratedVisitsByFilterQuery = {
  __typename?: "Query";
  unratedVisitsByFilters: {
    __typename?: "UnratedVisitsByFilters";
    visits: Array<
      { __typename?: "Visit" } & {
        " $fragmentRefs"?: {
          VisitClientFragmentFragment: VisitClientFragmentFragment;
        };
      }
    >;
  };
};

export type VisitQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type VisitQuery = {
  __typename?: "Query";
  visit: { __typename?: "Visit" } & {
    " $fragmentRefs"?: {
      VisitClientFragmentFragment: VisitClientFragmentFragment;
    };
  };
};

export type VisitByClientWithTokenQueryVariables = Exact<{
  token: Scalars["String"]["input"];
}>;

export type VisitByClientWithTokenQuery = {
  __typename?: "Query";
  visitByClientWithToken?:
    | ({ __typename?: "Visit" } & {
        " $fragmentRefs"?: {
          VisitClientFragmentFragment: VisitClientFragmentFragment;
        };
      })
    | null;
};

export type VisitsByClientQueryVariables = Exact<{
  clientId: Scalars["ID"]["input"];
  from: Scalars["Date"]["input"];
  to: Scalars["Date"]["input"];
  status?: InputMaybe<Array<VisitStatusEnum> | VisitStatusEnum>;
}>;

export type VisitsByClientQuery = {
  __typename?: "Query";
  visitsByClient: Array<{
    __typename?: "Visit";
    id: string;
    rating?: number | null;
    serviceDate: string;
    startTime?: string | null;
    endTime?: string | null;
    status: VisitStatusEnum;
    clientBillingValue?: number | null;
    originalBillingAmount: number;
    job?: {
      __typename?: "Job";
      id: string;
      package: {
        __typename?: "Package";
        id: string;
        department: PackageDepartmentEnum;
        serviceType: PackageServiceTypeEnum;
        fullAddress: string;
        postalCode: string;
        unitNumber?: string | null;
      };
    } | null;
    worker?: {
      __typename?: "Worker";
      id: string;
      firstName: string;
      lastName: string;
      avatarUrl?: string | null;
      contactNumber: string;
    } | null;
  }>;
};

export type VisitsByFiltersQueryVariables = Exact<{
  filters?: InputMaybe<VisitsFilter>;
}>;

export type VisitsByFiltersQuery = {
  __typename?: "Query";
  visitsByFilters: {
    __typename?: "VisitsByFilters";
    count: number;
    visits: Array<{
      __typename?: "Visit";
      id: string;
      rating?: number | null;
      serviceDate: string;
      startTime?: string | null;
      endTime?: string | null;
      status: VisitStatusEnum;
      clientBillingValue?: number | null;
      originalBillingAmount: number;
      job?: {
        __typename?: "Job";
        id: string;
        package: {
          __typename?: "Package";
          id: string;
          department: PackageDepartmentEnum;
          serviceType: PackageServiceTypeEnum;
          fullAddress: string;
          postalCode: string;
          unitNumber?: string | null;
        };
      } | null;
      worker?: {
        __typename?: "Worker";
        id: string;
        firstName: string;
        lastName: string;
        avatarUrl?: string | null;
        contactNumber: string;
      } | null;
    }>;
  };
};

export type VisitClientFragmentFragment = {
  __typename?: "Visit";
  id: string;
  rating?: number | null;
  ratingComment?: string | null;
  serviceDate: string;
  startTime?: string | null;
  endTime?: string | null;
  reportDateIssue?: string | null;
  clientBillingValue?: number | null;
  originalBillingAmount: number;
  status: VisitStatusEnum;
  requiredPackageWorkerSkillIds: Array<string>;
  job?: {
    __typename?: "Job";
    id: string;
    status: JobStatusEnum;
    package: {
      __typename?: "Package";
      id: string;
      department: PackageDepartmentEnum;
      additionalNotes?: string | null;
      serviceType: PackageServiceTypeEnum;
      fullAddress: string;
      postalCode: string;
      unitNumber?: string | null;
      packageDescription: string;
    };
  } | null;
  reviewAttachments?: Array<{
    __typename?: "SavedFile";
    id: string;
    downloadUrl: string;
  }> | null;
  worker?: {
    __typename?: "Worker";
    id: string;
    firstName: string;
    lastName: string;
    workerRating: number;
    avatarUrl?: string | null;
    contactNumber: string;
  } | null;
  visitLineItems: Array<{
    __typename?: "VisitLineItem";
    id: string;
    billingUnit: string;
    billingValue: number;
    packageDescription: string;
    unitValue: number;
    units: number;
    packageDetail: { __typename?: "PackageDetail" } & {
      " $fragmentRefs"?: {
        PackageDetailFragmentFragment: PackageDetailFragmentFragment;
      };
    };
  }>;
} & { " $fragmentName"?: "VisitClientFragmentFragment" };

export const ReservationDetailFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ReservationDetailFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Reservation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "serviceDate" } },
          { kind: "Field", name: { kind: "Name", value: "startTime" } },
          { kind: "Field", name: { kind: "Name", value: "endTime" } },
          { kind: "Field", name: { kind: "Name", value: "frequencyOfJob" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "client" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "freeTimeslot" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "startAt" } },
                { kind: "Field", name: { kind: "Name", value: "endAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "worker" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "avatarUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCompletedVisits" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReservationDetailFragmentFragment, unknown>;
export const InvoiceDetailFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "InvoiceDetailFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Invoice" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "paidAmount" } },
          { kind: "Field", name: { kind: "Name", value: "baseAmount" } },
          { kind: "Field", name: { kind: "Name", value: "paymentStatus" } },
          { kind: "Field", name: { kind: "Name", value: "fromDate" } },
          { kind: "Field", name: { kind: "Name", value: "toDate" } },
          { kind: "Field", name: { kind: "Name", value: "issueDate" } },
          { kind: "Field", name: { kind: "Name", value: "dueDate" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "pdfUrl" } },
          { kind: "Field", name: { kind: "Name", value: "totalAmount" } },
          { kind: "Field", name: { kind: "Name", value: "pdfReceiptUrl" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "clientPaymentIndicated" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "appliedVoucher" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "discountType" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "discountValue" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expiresInDays" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "productCode" } },
                { kind: "Field", name: { kind: "Name", value: "quantity" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InvoiceDetailFragmentFragment, unknown>;
export const PackageDetailFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PackageDetailFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PackageDetail" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "code" } },
          { kind: "Field", name: { kind: "Name", value: "department" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "duration" } },
          { kind: "Field", name: { kind: "Name", value: "durationPerUnit" } },
          { kind: "Field", name: { kind: "Name", value: "serviceType" } },
          { kind: "Field", name: { kind: "Name", value: "totalValue" } },
          { kind: "Field", name: { kind: "Name", value: "unitType" } },
          { kind: "Field", name: { kind: "Name", value: "unitSize" } },
          { kind: "Field", name: { kind: "Name", value: "unitValue" } },
          { kind: "Field", name: { kind: "Name", value: "units" } },
          { kind: "Field", name: { kind: "Name", value: "billingUnit" } },
          { kind: "Field", name: { kind: "Name", value: "repeatEvery" } },
          { kind: "Field", name: { kind: "Name", value: "repeatEveryTimes" } },
          { kind: "Field", name: { kind: "Name", value: "session" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "serviceBillingValue" },
          },
          { kind: "Field", name: { kind: "Name", value: "invoiceFrequency" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "workerSkill" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PackageDetailFragmentFragment, unknown>;
export const VisitClientFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "VisitClientFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Visit" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "job" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "package" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "department" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "additionalNotes" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "serviceType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fullAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "unitNumber" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "packageDescription" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "rating" } },
          { kind: "Field", name: { kind: "Name", value: "ratingComment" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "reviewAttachments" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "downloadUrl" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "serviceDate" } },
          { kind: "Field", name: { kind: "Name", value: "startTime" } },
          { kind: "Field", name: { kind: "Name", value: "endTime" } },
          { kind: "Field", name: { kind: "Name", value: "reportDateIssue" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "clientBillingValue" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "originalBillingAmount" },
          },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "worker" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "workerRating" },
                },
                { kind: "Field", name: { kind: "Name", value: "avatarUrl" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contactNumber" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "visitLineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "billingUnit" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "billingValue" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "packageDescription" },
                },
                { kind: "Field", name: { kind: "Name", value: "unitValue" } },
                { kind: "Field", name: { kind: "Name", value: "units" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "packageDetail" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "PackageDetailFragment" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "requiredPackageWorkerSkillIds" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PackageDetailFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PackageDetail" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "code" } },
          { kind: "Field", name: { kind: "Name", value: "department" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "duration" } },
          { kind: "Field", name: { kind: "Name", value: "durationPerUnit" } },
          { kind: "Field", name: { kind: "Name", value: "serviceType" } },
          { kind: "Field", name: { kind: "Name", value: "totalValue" } },
          { kind: "Field", name: { kind: "Name", value: "unitType" } },
          { kind: "Field", name: { kind: "Name", value: "unitSize" } },
          { kind: "Field", name: { kind: "Name", value: "unitValue" } },
          { kind: "Field", name: { kind: "Name", value: "units" } },
          { kind: "Field", name: { kind: "Name", value: "billingUnit" } },
          { kind: "Field", name: { kind: "Name", value: "repeatEvery" } },
          { kind: "Field", name: { kind: "Name", value: "repeatEveryTimes" } },
          { kind: "Field", name: { kind: "Name", value: "session" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "serviceBillingValue" },
          },
          { kind: "Field", name: { kind: "Name", value: "invoiceFrequency" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "workerSkill" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VisitClientFragmentFragment, unknown>;
export const AddressByPostalCodeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AddressByPostalCode" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "postalCode" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addressByPostalCode" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "postalCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "postalCode" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "address" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddressByPostalCodeQuery,
  AddressByPostalCodeQueryVariables
>;
export const AllClientPackageDetailsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AllClientPackageDetails" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filters" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "PackageDetailsFilter" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "allPackageDetails" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filters" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "packageDetails" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "PackageDetailFragment" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PackageDetailFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PackageDetail" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "code" } },
          { kind: "Field", name: { kind: "Name", value: "department" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "duration" } },
          { kind: "Field", name: { kind: "Name", value: "durationPerUnit" } },
          { kind: "Field", name: { kind: "Name", value: "serviceType" } },
          { kind: "Field", name: { kind: "Name", value: "totalValue" } },
          { kind: "Field", name: { kind: "Name", value: "unitType" } },
          { kind: "Field", name: { kind: "Name", value: "unitSize" } },
          { kind: "Field", name: { kind: "Name", value: "unitValue" } },
          { kind: "Field", name: { kind: "Name", value: "units" } },
          { kind: "Field", name: { kind: "Name", value: "billingUnit" } },
          { kind: "Field", name: { kind: "Name", value: "repeatEvery" } },
          { kind: "Field", name: { kind: "Name", value: "repeatEveryTimes" } },
          { kind: "Field", name: { kind: "Name", value: "session" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "serviceBillingValue" },
          },
          { kind: "Field", name: { kind: "Name", value: "invoiceFrequency" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "workerSkill" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AllClientPackageDetailsQuery,
  AllClientPackageDetailsQueryVariables
>;
export const ClientDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Client" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "client" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "accountType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "billingAccount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "balance" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "overdueAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalOverdueInvoice" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "phoneNumber" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "phoneNumberVerifiedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "emailVerifiedAt" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "addresses" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fullAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "unitNumber" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "primary" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contacts" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "phoneNumber" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "primary" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contracts" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "invoiceFrequency" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "creditAccounts" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creditType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "balance" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paymentMethods" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "brand" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expMonth" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expYear" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "last4" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "preferences" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientQuery, ClientQueryVariables>;
export const ClientCancelReservationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ClientCancelReservation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "clientCancelReservationInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "clientCancelReservation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "reservations" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClientCancelReservationMutation,
  ClientCancelReservationMutationVariables
>;
export const ClientConfirmPhoneNumberDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "clientConfirmPhoneNumber" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "clientConfirmPhoneNumberInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "clientConfirmPhoneNumber" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "result" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClientConfirmPhoneNumberMutation,
  ClientConfirmPhoneNumberMutationVariables
>;
export const ClientConfirmReservationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ClientConfirmReservation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "clientConfirmReservationInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "clientConfirmReservation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "package" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "unitValue" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "serviceBillingValue" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "packageDetail" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "duration" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "repeatEvery" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "department" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "serviceType" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "jobs" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "worker" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "avatarUrl" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "workerRating",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClientConfirmReservationMutation,
  ClientConfirmReservationMutationVariables
>;
export const ClientCreateReservationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ClientCreateReservation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "clientCreateReservationInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "clientCreateReservation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "result" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "ClientCreateReservationOk",
                          },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "reservations" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "ReservationDetailFragment",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "BookingSuspended" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "entity" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ValidationProblem" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "entity" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ReservationDetailFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Reservation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "serviceDate" } },
          { kind: "Field", name: { kind: "Name", value: "startTime" } },
          { kind: "Field", name: { kind: "Name", value: "endTime" } },
          { kind: "Field", name: { kind: "Name", value: "frequencyOfJob" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "client" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "freeTimeslot" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "startAt" } },
                { kind: "Field", name: { kind: "Name", value: "endAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "worker" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "avatarUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCompletedVisits" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClientCreateReservationMutation,
  ClientCreateReservationMutationVariables
>;
export const ClientFindBookingScheduleDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ClientFindBookingSchedule" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "department" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "WorkerDepartmentEnum" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "frequencyOfJob" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "ScheduleJobFrequencyEnum" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "scheduleMatching" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "ScheduleMatchingEnum" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sessionDuration" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "postalCode" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "startDate" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "endDate" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "timeRanges" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "TimeRange" },
              },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "workersIds" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "preferredWorkerIds" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "workerSkillIds" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "lineItemIds" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "packageDetailId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "clientId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "clientFindBookingSchedule" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "department" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "department" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "frequencyOfJob" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "frequencyOfJob" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "scheduleMatching" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "scheduleMatching" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sessionDuration" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "sessionDuration" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "postalCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "postalCode" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "startDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "startDate" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "endDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "endDate" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "timeRanges" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "timeRanges" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "workersIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "workersIds" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "preferredWorkerIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "preferredWorkerIds" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "workerSkillIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "workerSkillIds" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "lineItemIds" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "lineItemIds" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "packageDetailId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "packageDetailId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "clientId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "clientId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "day" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "afternoon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "startAt" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "endAt" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "averageTravelTime" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rateValue" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sessionValue" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "worker" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "workerRating" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "workerContracts" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "portfolios" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "downloadUrl",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "filename",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "evening" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "startAt" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "endAt" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "averageTravelTime" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rateValue" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sessionValue" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "worker" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "workerRating" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "workerContracts" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "portfolios" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "downloadUrl",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "filename",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "morning" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "startAt" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "endAt" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "averageTravelTime" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rateValue" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sessionValue" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "worker" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "workerRating" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "workerContracts" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "portfolios" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "downloadUrl",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "filename",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClientFindBookingScheduleQuery,
  ClientFindBookingScheduleQueryVariables
>;
export const ClientFindPastWorkersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ClientFindPastWorkers" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "clientId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "department" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "PackageDepartmentEnum" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "limit" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "clientFindPastWorkers" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "clientId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "clientId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "department" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "department" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "limit" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "limit" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "workerRating" },
                },
                { kind: "Field", name: { kind: "Name", value: "avatarUrl" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contactNumber" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClientFindPastWorkersQuery,
  ClientFindPastWorkersQueryVariables
>;
export const ClientReferralCodeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ClientReferralCode" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "clientId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "clientReferralCode" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "clientId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "clientId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "url" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClientReferralCodeQuery,
  ClientReferralCodeQueryVariables
>;
export const ClientValidatePromoCodeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ClientValidatePromoCode" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "clientValidatePromoCodeInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "clientValidatePromoCode" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "promo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "discountType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "discountValue" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClientValidatePromoCodeMutation,
  ClientValidatePromoCodeMutationVariables
>;
export const ClientVerifyPhoneNumberDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "clientVerifyPhoneNumber" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "clientVerifyPhoneNumberInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "clientVerifyPhoneNumber" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "result" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "SendPinCodeToPhoneOk" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "expiresAt" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ValidationProblem" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "entity" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "AccountDeleted" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "entity" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClientVerifyPhoneNumberMutation,
  ClientVerifyPhoneNumberMutationVariables
>;
export const CreateAddressDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "createAddress" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "createAddressInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createAddress" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fullAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "primary" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "unitNumber" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateAddressMutation,
  CreateAddressMutationVariables
>;
export const CreateContactDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "createContact" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "createContactInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createContact" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contact" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "phoneNumber" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "primary" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateContactMutation,
  CreateContactMutationVariables
>;
export const DeleteClientDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteClient" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "deleteClientInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteClient" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "result" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteClientMutation,
  DeleteClientMutationVariables
>;
export const ReservationByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ReservationById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "reservationId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "reservationById" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "reservationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "reservationId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ReservationDetailFragment" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ReservationDetailFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Reservation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "serviceDate" } },
          { kind: "Field", name: { kind: "Name", value: "startTime" } },
          { kind: "Field", name: { kind: "Name", value: "endTime" } },
          { kind: "Field", name: { kind: "Name", value: "frequencyOfJob" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "client" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "freeTimeslot" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "startAt" } },
                { kind: "Field", name: { kind: "Name", value: "endAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "worker" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "avatarUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCompletedVisits" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReservationByIdQuery,
  ReservationByIdQueryVariables
>;
export const ResetPasswordFromEmailDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "resetPasswordFromEmail" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "resetPasswordFromEmailInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "resetPasswordFromEmail" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ClientUser" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResetPasswordFromEmailMutation,
  ResetPasswordFromEmailMutationVariables
>;
export const ResetPasswordFromPhoneDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "resetPasswordFromPhone" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "resetPasswordFromPhoneInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "resetPasswordFromPhone" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ClientUser" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResetPasswordFromPhoneMutation,
  ResetPasswordFromPhoneMutationVariables
>;
export const SendPinCodeToPhoneDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SendPinCodeToPhone" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "sendPinCodeToPhoneInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sendPinCodeToPhone" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "result" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "SendPinCodeToPhoneOk" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "expiresAt" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "AccountDeleted" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ValidationProblem" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SendPinCodeToPhoneMutation,
  SendPinCodeToPhoneMutationVariables
>;
export const SendResetPasswordDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "sendResetPassword" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "sendResetPasswordInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sendResetPassword" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "result" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "NotFoundProblem" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "entity" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "AccountDeleted" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "entity" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SendResetPasswordMutation,
  SendResetPasswordMutationVariables
>;
export const SignInDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "signIn" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "signInClientInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "signInClient" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "result" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "SignInClientOk" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "jwt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "jwtExpiry" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "refreshToken" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "client" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "phoneNumber",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "phoneNumberVerifiedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "emailVerifiedAt",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "contacts" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "addresses" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "postalCode",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ValidationProblem" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "errors" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "field" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "messages" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "entity" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "AccountDeleted" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "entity" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SignInMutation, SignInMutationVariables>;
export const SignInSocialAccountDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "signInSocialAccount" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "signInClientSocialAccountInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "signInClientSocialAccount" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "result" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "SignInClientOk" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "jwt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "jwtExpiry" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "refreshToken" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "client" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "phoneNumber",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "phoneNumberVerifiedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "emailVerifiedAt",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "contacts" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "addresses" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "postalCode",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ValidationProblem" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "errors" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "field" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "messages" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "entity" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "SignUpRequired" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "entity" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "AccountDeleted" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "entity" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SignInSocialAccountMutation,
  SignInSocialAccountMutationVariables
>;
export const SignInWithPhoneDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SignInWithPhone" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "signInWithPhoneInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "signInWithPhone" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "result" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "SignInClientOk" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "jwt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "jwtExpiry" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "refreshToken" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "client" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "phoneNumber",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "phoneNumberVerifiedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "emailVerifiedAt",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "contacts" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "addresses" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "postalCode",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ValidationProblem" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "errors" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "field" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "messages" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "entity" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "AccountDeleted" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "entity" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "SignUpRequired" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "entity" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "NotWhitelistedProblem",
                          },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SignInWithPhoneMutation,
  SignInWithPhoneMutationVariables
>;
export const SignOutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "signOut" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "signOut" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "ObjectValue", fields: [] },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "RegularUser" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SignOutMutation, SignOutMutationVariables>;
export const SignUpDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "signUp" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "signUpInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "signUp" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "result" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "SignUpOk" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "viewer" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "currentUser",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: {
                                              kind: "Name",
                                              value: "ClientUser",
                                            },
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "client" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "phoneNumber",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "phoneNumberVerifiedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "emailVerifiedAt",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "contacts" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "addresses" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "postalCode",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "jwt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "refreshToken" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "jwtExpiry" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "ValidationProblem" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "errors" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "field" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "messages" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "entity" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "EntityExistsProblem" },
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "entity" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "message" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SignUpMutation, SignUpMutationVariables>;
export const UpdateAccountPhoneNumberDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateAccountPhoneNumber" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "updateAccountPhoneNumberInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateAccountPhoneNumber" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAccountPhoneNumberMutation,
  UpdateAccountPhoneNumberMutationVariables
>;
export const UpdateClientDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateClient" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "updateClientInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateClient" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "client" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "accountType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateClientMutation,
  UpdateClientMutationVariables
>;
export const VerifyUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "verifyUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "verifyUserInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "verifyUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "result" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VerifyUserMutation, VerifyUserMutationVariables>;
export const ClientIssueDetailsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ClientIssueDetails" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "IssueTypeEnum" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "clientIssueDetails" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "type" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "type" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClientIssueDetailsQuery,
  ClientIssueDetailsQueryVariables
>;
export const ClientReviewsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ClientReviews" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filters" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "ClientReviewsInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "clientReviews" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filters" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "count" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "averageRating" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "clientReviews" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "clientName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rating" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ratingComment" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ratingAttachment" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "downloadUrl" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "serviceDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "serviceType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "department" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "clientLifetimeBooking" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientReviewsQuery, ClientReviewsQueryVariables>;
export const CreateClientIssueRequestDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateClientIssueRequest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "createClientIssueRequestInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createClientIssueRequest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "result" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateClientIssueRequestMutation,
  CreateClientIssueRequestMutationVariables
>;
export const CreateClientIssueRequestWithTokenDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateClientIssueRequestWithToken" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "createClientIssueRequestWithTokenInput",
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createClientIssueRequestWithToken" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "result" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateClientIssueRequestWithTokenMutation,
  CreateClientIssueRequestWithTokenMutationVariables
>;
export const ClientApplyVoucherDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ClientApplyVoucher" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "clientApplyVoucherInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "clientApplyVoucher" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "invoice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "InvoiceDetailFragment" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "InvoiceDetailFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Invoice" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "paidAmount" } },
          { kind: "Field", name: { kind: "Name", value: "baseAmount" } },
          { kind: "Field", name: { kind: "Name", value: "paymentStatus" } },
          { kind: "Field", name: { kind: "Name", value: "fromDate" } },
          { kind: "Field", name: { kind: "Name", value: "toDate" } },
          { kind: "Field", name: { kind: "Name", value: "issueDate" } },
          { kind: "Field", name: { kind: "Name", value: "dueDate" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "pdfUrl" } },
          { kind: "Field", name: { kind: "Name", value: "totalAmount" } },
          { kind: "Field", name: { kind: "Name", value: "pdfReceiptUrl" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "clientPaymentIndicated" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "appliedVoucher" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "discountType" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "discountValue" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expiresInDays" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "productCode" } },
                { kind: "Field", name: { kind: "Name", value: "quantity" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClientApplyVoucherMutation,
  ClientApplyVoucherMutationVariables
>;
export const ClientRemoveVoucherDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ClientRemoveVoucher" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "clientRemoveVoucherInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "clientRemoveVoucher" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "invoice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "InvoiceDetailFragment" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "InvoiceDetailFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Invoice" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "paidAmount" } },
          { kind: "Field", name: { kind: "Name", value: "baseAmount" } },
          { kind: "Field", name: { kind: "Name", value: "paymentStatus" } },
          { kind: "Field", name: { kind: "Name", value: "fromDate" } },
          { kind: "Field", name: { kind: "Name", value: "toDate" } },
          { kind: "Field", name: { kind: "Name", value: "issueDate" } },
          { kind: "Field", name: { kind: "Name", value: "dueDate" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "pdfUrl" } },
          { kind: "Field", name: { kind: "Name", value: "totalAmount" } },
          { kind: "Field", name: { kind: "Name", value: "pdfReceiptUrl" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "clientPaymentIndicated" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "appliedVoucher" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "discountType" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "discountValue" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expiresInDays" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "productCode" } },
                { kind: "Field", name: { kind: "Name", value: "quantity" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClientRemoveVoucherMutation,
  ClientRemoveVoucherMutationVariables
>;
export const ClientViewVouchersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ClientViewVouchers" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "clientId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "clientViewVouchers" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "clientId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "clientId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "discountType" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "discountValue" },
                },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "validUntil" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expiresInDays" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClientViewVouchersQuery,
  ClientViewVouchersQueryVariables
>;
export const GenerateInvoicePdfDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "GenerateInvoicePdf" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "generateInvoicePdfInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "generateInvoicePdf" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "fileString" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GenerateInvoicePdfMutation,
  GenerateInvoicePdfMutationVariables
>;
export const GenerateInvoiceReceiptPdfDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "GenerateInvoiceReceiptPdf" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "generateInvoiceReceiptPdfInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "generateInvoiceReceiptPdf" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "fileString" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GenerateInvoiceReceiptPdfMutation,
  GenerateInvoiceReceiptPdfMutationVariables
>;
export const InvoiceByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "InvoiceById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "invoiceById" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "InvoiceDetailFragment" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "InvoiceDetailFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Invoice" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "paidAmount" } },
          { kind: "Field", name: { kind: "Name", value: "baseAmount" } },
          { kind: "Field", name: { kind: "Name", value: "paymentStatus" } },
          { kind: "Field", name: { kind: "Name", value: "fromDate" } },
          { kind: "Field", name: { kind: "Name", value: "toDate" } },
          { kind: "Field", name: { kind: "Name", value: "issueDate" } },
          { kind: "Field", name: { kind: "Name", value: "dueDate" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "pdfUrl" } },
          { kind: "Field", name: { kind: "Name", value: "totalAmount" } },
          { kind: "Field", name: { kind: "Name", value: "pdfReceiptUrl" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "clientPaymentIndicated" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "appliedVoucher" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "discountType" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "discountValue" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expiresInDays" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "productCode" } },
                { kind: "Field", name: { kind: "Name", value: "quantity" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InvoiceByIdQuery, InvoiceByIdQueryVariables>;
export const InvoicesByFiltersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "InvoicesByFilters" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filters" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "InvoicesFilter" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "invoicesByFilters" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filters" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "invoices" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "paidAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "paymentStatus" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "issueDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dueDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "clientPaymentIndicated" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "count" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InvoicesByFiltersQuery,
  InvoicesByFiltersQueryVariables
>;
export const PayInvoiceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "PayInvoice" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "payInvoiceInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "payInvoice" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "invoice" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PayInvoiceMutation, PayInvoiceMutationVariables>;
export const UpdatePaymentIndicatedDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdatePaymentIndicated" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "updatePaymentIndicatedInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updatePaymentIndicated" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "result" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePaymentIndicatedMutation,
  UpdatePaymentIndicatedMutationVariables
>;
export const CreatePackageIssueRequestDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreatePackageIssueRequest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "createPackageIssueRequestInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createPackageIssueRequest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "result" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePackageIssueRequestMutation,
  CreatePackageIssueRequestMutationVariables
>;
export const PackageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Package" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "package" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "startDate" } },
                { kind: "Field", name: { kind: "Name", value: "repeatEvery" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "repeatEveryTimes" },
                },
                { kind: "Field", name: { kind: "Name", value: "department" } },
                { kind: "Field", name: { kind: "Name", value: "endDate" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "packageDescription" },
                },
                { kind: "Field", name: { kind: "Name", value: "fullAddress" } },
                { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "creditAccount" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "balance" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creditType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "debitedAmount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expirationDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "transactions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "transactionType" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "packageLineItems" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "packageDescription" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "billingUnit" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "billingValue" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "units" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "unitValue" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "packageDetail" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "PackageDetailFragment",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "jobs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "startDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "endDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "tasks" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "day" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "startTime" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "endTime" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PackageDetailFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PackageDetail" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "code" } },
          { kind: "Field", name: { kind: "Name", value: "department" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "duration" } },
          { kind: "Field", name: { kind: "Name", value: "durationPerUnit" } },
          { kind: "Field", name: { kind: "Name", value: "serviceType" } },
          { kind: "Field", name: { kind: "Name", value: "totalValue" } },
          { kind: "Field", name: { kind: "Name", value: "unitType" } },
          { kind: "Field", name: { kind: "Name", value: "unitSize" } },
          { kind: "Field", name: { kind: "Name", value: "unitValue" } },
          { kind: "Field", name: { kind: "Name", value: "units" } },
          { kind: "Field", name: { kind: "Name", value: "billingUnit" } },
          { kind: "Field", name: { kind: "Name", value: "repeatEvery" } },
          { kind: "Field", name: { kind: "Name", value: "repeatEveryTimes" } },
          { kind: "Field", name: { kind: "Name", value: "session" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "serviceBillingValue" },
          },
          { kind: "Field", name: { kind: "Name", value: "invoiceFrequency" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "workerSkill" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PackageQuery, PackageQueryVariables>;
export const PackagesByFiltersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PackagesByFilters" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filters" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "PackagesByFiltersInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "packagesByFilters" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filters" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "count" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "packages" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "startDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "department" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "endDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "packageDescription" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fullAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "repeatEvery" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PackagesByFiltersQuery,
  PackagesByFiltersQueryVariables
>;
export const ClientAddressesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ClientAddresses" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filters" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ClientAddressesFilterInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "clientAddresses" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filters" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "count" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "addresses" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fullAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "unitNumber" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "primary" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClientAddressesQuery,
  ClientAddressesQueryVariables
>;
export const ClientContactsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ClientContacts" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filters" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ClientContactsFilterInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "clientContacts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filters" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contacts" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "phoneNumber" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "primary" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientContactsQuery, ClientContactsQueryVariables>;
export const CreatePaymentMethodDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreatePaymentMethod" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "createPaymentMethodInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createPaymentMethod" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paymentMethod" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePaymentMethodMutation,
  CreatePaymentMethodMutationVariables
>;
export const CreditAccountByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CreditAccountById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "creditAccountById" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "client" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "creditType" } },
                { kind: "Field", name: { kind: "Name", value: "balance" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "expirationDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "transactions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "transactionType" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreditAccountByIdQuery,
  CreditAccountByIdQueryVariables
>;
export const DeleteAddressDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteAddress" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "deleteAddressInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteAddress" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "result" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteAddressMutation,
  DeleteAddressMutationVariables
>;
export const DeleteContactDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteContact" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "deleteContactInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteContact" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "result" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteContactMutation,
  DeleteContactMutationVariables
>;
export const DeletePaymentMethodDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DeletePaymentMethod" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "deletePaymentMethodInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deletePaymentMethod" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "result" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePaymentMethodMutation,
  DeletePaymentMethodMutationVariables
>;
export const TokenizePaymentMethodDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "TokenizePaymentMethod" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "tokenizePaymentMethodInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tokenizePaymentMethod" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "token" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TokenizePaymentMethodMutation,
  TokenizePaymentMethodMutationVariables
>;
export const UpdateAddressDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateAddress" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "updateAddressInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateAddress" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fullAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "primary" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "unitNumber" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAddressMutation,
  UpdateAddressMutationVariables
>;
export const UpdateClientPreferencesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateClientPreferences" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "updateClientPreferencesInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateClientPreferences" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "preferences" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateClientPreferencesMutation,
  UpdateClientPreferencesMutationVariables
>;
export const UpdateContactDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateContact" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "updateContactInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateContact" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contact" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "phoneNumber" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "primary" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateContactMutation,
  UpdateContactMutationVariables
>;
export const GetPromotionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetPromotion" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getPromotion" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "startDate" } },
                { kind: "Field", name: { kind: "Name", value: "endDate" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "buttonLabel" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "promotionImages" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "downloadUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "filename" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPromotionQuery, GetPromotionQueryVariables>;
export const RewardsByFiltersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "RewardsByFilters" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filters" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "RewardsFilter" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "rewardsByFilters" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filters" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "count" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "rewards" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "masterReward" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "incentiveObject" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "MasterPromoCode",
                                      },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "promoCode",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "discountValueFloat",
                                          },
                                          name: {
                                            kind: "Name",
                                            value: "discountValue",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "discountType",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: {
                                        kind: "Name",
                                        value: "MasterVoucher",
                                      },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "promoCode",
                                          },
                                          name: { kind: "Name", value: "code" },
                                        },
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "discountValueInt",
                                          },
                                          name: {
                                            kind: "Name",
                                            value: "discountValue",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          alias: {
                                            kind: "Name",
                                            value: "discountTypeString",
                                          },
                                          name: {
                                            kind: "Name",
                                            value: "discountType",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "validFrom" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "validTo" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RewardsByFiltersQuery,
  RewardsByFiltersQueryVariables
>;
export const CancelVisitWithTokenDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CancelVisitWithToken" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "cancelVisitWithTokenInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cancelVisitWithToken" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "visit" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "statusComment" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "task" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "package" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unitValue" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "serviceBillingValue",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "packageDetail",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "duration",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "repeatEvery",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CancelVisitWithTokenMutation,
  CancelVisitWithTokenMutationVariables
>;
export const ClientCancelVisitDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ClientCancelVisit" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "clientCancelVisitInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "clientCancelVisit" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "visit" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "statusComment" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "task" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "package" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unitValue" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "serviceBillingValue",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "packageDetail",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "code" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "duration",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "repeatEvery",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClientCancelVisitMutation,
  ClientCancelVisitMutationVariables
>;
export const ClientRescheduleVisitDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ClientRescheduleVisit" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "clientRescheduleVisitInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "clientRescheduleVisit" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "visit" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "serviceDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "startTime" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "endTime" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "statusComment" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "worker" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalCompletedVisits",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClientRescheduleVisitMutation,
  ClientRescheduleVisitMutationVariables
>;
export const RateVisitDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "rateVisit" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "rateVisitInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "rateVisit" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "visit" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rating" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reviewAttachments" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "downloadUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "filename" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RateVisitMutation, RateVisitMutationVariables>;
export const RateVisitWithTokenDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RateVisitWithToken" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "rateVisitWithTokenInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "rateVisitWithToken" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "visit" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RateVisitWithTokenMutation,
  RateVisitWithTokenMutationVariables
>;
export const RescheduleVisitWithTokenDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RescheduleVisitWithToken" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "rescheduleVisitWithTokenInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "rescheduleVisitWithToken" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "visit" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "serviceDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "startTime" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "endTime" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "statusComment" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "worker" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "totalCompletedVisits",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RescheduleVisitWithTokenMutation,
  RescheduleVisitWithTokenMutationVariables
>;
export const UnratedVisitsByFilterDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "unratedVisitsByFilter" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filters" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UnratedVisitsByFiltersInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "unratedVisitsByFilters" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filters" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "visits" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "VisitClientFragment" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PackageDetailFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PackageDetail" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "code" } },
          { kind: "Field", name: { kind: "Name", value: "department" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "duration" } },
          { kind: "Field", name: { kind: "Name", value: "durationPerUnit" } },
          { kind: "Field", name: { kind: "Name", value: "serviceType" } },
          { kind: "Field", name: { kind: "Name", value: "totalValue" } },
          { kind: "Field", name: { kind: "Name", value: "unitType" } },
          { kind: "Field", name: { kind: "Name", value: "unitSize" } },
          { kind: "Field", name: { kind: "Name", value: "unitValue" } },
          { kind: "Field", name: { kind: "Name", value: "units" } },
          { kind: "Field", name: { kind: "Name", value: "billingUnit" } },
          { kind: "Field", name: { kind: "Name", value: "repeatEvery" } },
          { kind: "Field", name: { kind: "Name", value: "repeatEveryTimes" } },
          { kind: "Field", name: { kind: "Name", value: "session" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "serviceBillingValue" },
          },
          { kind: "Field", name: { kind: "Name", value: "invoiceFrequency" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "workerSkill" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "VisitClientFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Visit" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "job" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "package" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "department" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "additionalNotes" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "serviceType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fullAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "unitNumber" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "packageDescription" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "rating" } },
          { kind: "Field", name: { kind: "Name", value: "ratingComment" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "reviewAttachments" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "downloadUrl" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "serviceDate" } },
          { kind: "Field", name: { kind: "Name", value: "startTime" } },
          { kind: "Field", name: { kind: "Name", value: "endTime" } },
          { kind: "Field", name: { kind: "Name", value: "reportDateIssue" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "clientBillingValue" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "originalBillingAmount" },
          },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "worker" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "workerRating" },
                },
                { kind: "Field", name: { kind: "Name", value: "avatarUrl" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contactNumber" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "visitLineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "billingUnit" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "billingValue" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "packageDescription" },
                },
                { kind: "Field", name: { kind: "Name", value: "unitValue" } },
                { kind: "Field", name: { kind: "Name", value: "units" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "packageDetail" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "PackageDetailFragment" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "requiredPackageWorkerSkillIds" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UnratedVisitsByFilterQuery,
  UnratedVisitsByFilterQueryVariables
>;
export const VisitDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Visit" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "visit" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "VisitClientFragment" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PackageDetailFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PackageDetail" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "code" } },
          { kind: "Field", name: { kind: "Name", value: "department" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "duration" } },
          { kind: "Field", name: { kind: "Name", value: "durationPerUnit" } },
          { kind: "Field", name: { kind: "Name", value: "serviceType" } },
          { kind: "Field", name: { kind: "Name", value: "totalValue" } },
          { kind: "Field", name: { kind: "Name", value: "unitType" } },
          { kind: "Field", name: { kind: "Name", value: "unitSize" } },
          { kind: "Field", name: { kind: "Name", value: "unitValue" } },
          { kind: "Field", name: { kind: "Name", value: "units" } },
          { kind: "Field", name: { kind: "Name", value: "billingUnit" } },
          { kind: "Field", name: { kind: "Name", value: "repeatEvery" } },
          { kind: "Field", name: { kind: "Name", value: "repeatEveryTimes" } },
          { kind: "Field", name: { kind: "Name", value: "session" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "serviceBillingValue" },
          },
          { kind: "Field", name: { kind: "Name", value: "invoiceFrequency" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "workerSkill" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "VisitClientFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Visit" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "job" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "package" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "department" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "additionalNotes" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "serviceType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fullAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "unitNumber" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "packageDescription" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "rating" } },
          { kind: "Field", name: { kind: "Name", value: "ratingComment" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "reviewAttachments" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "downloadUrl" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "serviceDate" } },
          { kind: "Field", name: { kind: "Name", value: "startTime" } },
          { kind: "Field", name: { kind: "Name", value: "endTime" } },
          { kind: "Field", name: { kind: "Name", value: "reportDateIssue" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "clientBillingValue" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "originalBillingAmount" },
          },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "worker" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "workerRating" },
                },
                { kind: "Field", name: { kind: "Name", value: "avatarUrl" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contactNumber" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "visitLineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "billingUnit" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "billingValue" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "packageDescription" },
                },
                { kind: "Field", name: { kind: "Name", value: "unitValue" } },
                { kind: "Field", name: { kind: "Name", value: "units" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "packageDetail" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "PackageDetailFragment" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "requiredPackageWorkerSkillIds" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VisitQuery, VisitQueryVariables>;
export const VisitByClientWithTokenDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "VisitByClientWithToken" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "token" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "visitByClientWithToken" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "token" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "token" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "VisitClientFragment" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PackageDetailFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "PackageDetail" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "code" } },
          { kind: "Field", name: { kind: "Name", value: "department" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "duration" } },
          { kind: "Field", name: { kind: "Name", value: "durationPerUnit" } },
          { kind: "Field", name: { kind: "Name", value: "serviceType" } },
          { kind: "Field", name: { kind: "Name", value: "totalValue" } },
          { kind: "Field", name: { kind: "Name", value: "unitType" } },
          { kind: "Field", name: { kind: "Name", value: "unitSize" } },
          { kind: "Field", name: { kind: "Name", value: "unitValue" } },
          { kind: "Field", name: { kind: "Name", value: "units" } },
          { kind: "Field", name: { kind: "Name", value: "billingUnit" } },
          { kind: "Field", name: { kind: "Name", value: "repeatEvery" } },
          { kind: "Field", name: { kind: "Name", value: "repeatEveryTimes" } },
          { kind: "Field", name: { kind: "Name", value: "session" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "serviceBillingValue" },
          },
          { kind: "Field", name: { kind: "Name", value: "invoiceFrequency" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "workerSkill" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "VisitClientFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Visit" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "job" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "package" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "department" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "additionalNotes" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "serviceType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fullAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postalCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "unitNumber" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "packageDescription" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "rating" } },
          { kind: "Field", name: { kind: "Name", value: "ratingComment" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "reviewAttachments" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "downloadUrl" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "serviceDate" } },
          { kind: "Field", name: { kind: "Name", value: "startTime" } },
          { kind: "Field", name: { kind: "Name", value: "endTime" } },
          { kind: "Field", name: { kind: "Name", value: "reportDateIssue" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "clientBillingValue" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "originalBillingAmount" },
          },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "worker" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "workerRating" },
                },
                { kind: "Field", name: { kind: "Name", value: "avatarUrl" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contactNumber" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "visitLineItems" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "billingUnit" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "billingValue" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "packageDescription" },
                },
                { kind: "Field", name: { kind: "Name", value: "unitValue" } },
                { kind: "Field", name: { kind: "Name", value: "units" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "packageDetail" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "PackageDetailFragment" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "requiredPackageWorkerSkillIds" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VisitByClientWithTokenQuery,
  VisitByClientWithTokenQueryVariables
>;
export const VisitsByClientDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "VisitsByClient" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "clientId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "from" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "to" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "VisitStatusEnum" },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "visitsByClient" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "clientId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "clientId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "from" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "from" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "to" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "to" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "status" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "status" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "job" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "package" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "department" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "serviceType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fullAddress" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "postalCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unitNumber" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "rating" } },
                { kind: "Field", name: { kind: "Name", value: "serviceDate" } },
                { kind: "Field", name: { kind: "Name", value: "startTime" } },
                { kind: "Field", name: { kind: "Name", value: "endTime" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "worker" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "avatarUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "contactNumber" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "clientBillingValue" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "originalBillingAmount" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VisitsByClientQuery, VisitsByClientQueryVariables>;
export const VisitsByFiltersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "VisitsByFilters" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filters" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "VisitsFilter" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "visitsByFilters" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filters" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "count" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "visits" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "job" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "package" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "department" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "serviceType",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "fullAddress",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "postalCode" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "unitNumber" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rating" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "serviceDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "startTime" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "endTime" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "worker" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatarUrl" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "contactNumber" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "clientBillingValue" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "originalBillingAmount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VisitsByFiltersQuery,
  VisitsByFiltersQueryVariables
>;
