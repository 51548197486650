import { Divider } from "@/components/shared/divider";
import { Typography } from "@/components/shared/typography";
import { Card, View } from "@/components/ui";
import { formatPrice } from "@/lib/helpers/number";
import { cn } from "@/lib/utils";

export const NormalTimeSlotCard = ({
  active,
  timeRange,
  rateValue,
  discount,
  priceWithTax,
  isCheaper,
  onCardClick,
}: {
  rateValue?: number;
  timeRange: string;
  discount?: number;
  active?: boolean;
  priceWithTax: string;
  isCheaper?: boolean;
  onCardClick?: () => void;
}) => {
  return (
    <Card
      onClick={onCardClick}
      className={cn(
        "grow-0 cursor-pointer px-2 py-3 hover:divide-primary-border hover:bg-primary-surface md:p-4",
        {
          "border-primary-border bg-primary-surface": active,
        },
      )}
    >
      <Typography variant="label-sm">{timeRange}</Typography>

      <Divider dashed className={cn({ "border-primary-border": active })} />

      <View className="flex flex-col gap-1">
        <Typography
          variant={{ md: "label-md", sm: "label-sm" }}
          color={isCheaper ? "success" : "brand-primary"}
        >
          {rateValue && `${formatPrice(rateValue)}/hr`}{" "}
          {discount && `(${discount}% OFF!)`}
        </Typography>
        <Typography
          variant={{ md: "body-md", sm: "body-xs" }}
          color="foreground-intermediate"
        >
          {priceWithTax}
        </Typography>
      </View>
    </Card>
  );
};
