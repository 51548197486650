import { Card, CardContent, View, Image, Button } from "@/components/ui";
import { Container, Column } from "@/components/ui/layout";
import type { ServiceName } from "@/types/service";
import MultiPleStar from "@/assets/images/multiple-star.png";
import { Package } from "@/components/shared/icons";
import { ServiceProfessional } from "./service-professional";
import { getPlatform } from "@/lib/utils";
import {
  useBookingRoute,
  useBookingRouterInterceptor,
} from "@/components/hooks/use-booking-route";
import { useBookingState } from "@/store/booking/useBookingState";
import { Typography } from "@/components/shared/typography";
import { RecommendedServices } from "@/components/shared/recommended-services";

export const BookingCompleteDoneButton = () => {
  const { forward } = useBookingRoute();
  return (
    <Button
      variant="primary"
      color="CTA"
      fullWidth="full"
      onClick={forward}
      children="Done"
    />
  );
};

export const BookingComplete = (props: { name: ServiceName }) => {
  useBookingRouterInterceptor();
  const platform = getPlatform();
  const { serviceReviews, confirmedWorker } = useBookingState(
    (state) => state.data,
  );

  return (
    <View>
      <Container center>
        <Column desktop="8">
          <View className="flex flex-col gap-4 p-6 pb-16">
            <Card className="shadow-slate-200" border={"none"} shadow={"lg"}>
              <CardContent className="p-4">
                <View className="flex flex-col items-center gap-2 border-b-2 border-secondary-border p-4">
                  <View className="h-14 w-14">
                    <Image src={MultiPleStar} className="w-full" />
                  </View>

                  <Typography
                    variant={{ md: "bold-2xl", sm: "bold-xl" }}
                    color="brand-primary"
                  >
                    We're excited to serve you
                  </Typography>
                  <Typography
                    variant={{ md: "body-lg", sm: "body-md" }}
                    color="secondary-text"
                    align="center"
                  >
                    Your booking is confirmed! Sit back, relax, and let us take
                    care of everything so you can enjoy the results stress-free.
                  </Typography>
                </View>
                <View>
                  <ServiceProfessional
                    reviews={serviceReviews.reviews.slice(0, 3) || []}
                    cleanerInfo={{
                      star: String(confirmedWorker?.workerRating || 5),
                      name: `${confirmedWorker?.firstName || ""} ${confirmedWorker?.lastName || ""}`,
                      avatar: confirmedWorker?.avatarUrl || "",
                    }}
                  />
                </View>
              </CardContent>
            </Card>

            <View className="hidden flex-col items-center py-2 md:flex">
              <View className="w-48">
                <BookingCompleteDoneButton />
              </View>
            </View>

            <RecommendedServices title="People also book" icon={Package} />
          </View>
        </Column>
      </Container>
      {platform === "web" && (
        <>
          <View className="h-12 md:hidden"></View>
          <View className="fixed bottom-0 z-20 flex w-full flex-row-reverse items-center gap-4 rounded-md bg-white p-3 shadow-lg md:hidden">
            <BookingCompleteDoneButton />
          </View>
        </>
      )}
    </View>
  );
};
