import { getStorageKey } from "../utils";

const getItem = <T = unknown>(key: string): Promise<T> => {
  try {
    const value = localStorage.getItem(getStorageKey(key));
    return Promise.resolve(value ? JSON.parse(value) : value) as Promise<T>;
  } catch (e) {
    console.error("Error getting item from localStorage:", e);
    return Promise.resolve(null) as Promise<T>;
  }
};

const setItem = async (key: string, value: unknown): Promise<void> => {
  try {
    localStorage.setItem(getStorageKey(key), JSON.stringify(value));
    return Promise.resolve();
  } catch (e) {
    console.error("Error setting item in localStorage:", e);
  }
};

const getItemWithExpiry = async <T = unknown>(key: string): Promise<T> => {
  try {
    const itemStr = localStorage.getItem(getStorageKey(key));
    if (!itemStr) {
      return Promise.resolve() as Promise<T>;
    }
    const item = JSON.parse(itemStr) as { value: unknown; expiry: number };
    const now = new Date();

    if (!item.expiry || now.getTime() > item.expiry) {
      localStorage.removeItem(getStorageKey(key));
      return Promise.resolve() as Promise<T>;
    }
    return Promise.resolve(item.value) as Promise<T>;
  } catch (e) {
    console.error("Error getting item from localStorage:", e);
    return Promise.resolve() as Promise<T>;
  }
};

const setItemWithExpiry = (
  key: string,
  value: unknown,
  expiryInMinutes: number,
): Promise<void> => {
  const now = new Date();
  const item = {
    value,
    expiry: now.getTime() + expiryInMinutes * 60000,
  };
  localStorage.setItem(getStorageKey(key), JSON.stringify(item));
  return Promise.resolve();
};

const removeItem = async (key: string): Promise<void> => {
  try {
    localStorage.removeItem(getStorageKey(key));
    return Promise.resolve();
  } catch (e) {
    console.error("Error deleting item from localStorage:", e);
  }
};

const deleteAll = async (): Promise<void> => {
  try {
    localStorage.clear();
    return Promise.resolve();
  } catch (e) {
    console.error("Error clearing localStorage:", e);
  }
};

export const storage = {
  getItem,
  setItem,
  removeItem,
  deleteAll,
  getItemWithExpiry,
  setItemWithExpiry,
};
