import React from "react";
import { LoginPageWrapper } from "@/containers/auth/login/login-page-wrapper";
import { useAuth } from "../../auth-provider";

interface PrivateRouteProps {
  children: React.ReactElement;
}

export function PrivateRoute({ children }: PrivateRouteProps) {
  const { isGuest } = useAuth();

  if (isGuest) {
    return <LoginPageWrapper />;
  }

  return children;
}
