import { PayNowScreenModal } from "./paynow-screen-modal";
import { SelectPaymentScreenModal } from "./select-payment-screen-modal";
import {
  ACCOUNT_NUMBER,
  UEN_NUMBER,
  MERCHANT_NAME,
} from "@/constants/invoices";
import { copyClipboard } from "@/lib/helpers/copy-clipboard";
import { useInvoiceState } from "@/store/invoices";
import { PaymentMethodScreenModal } from "@/types/invoice";
import { CreditCardScreenModal } from "./credit-card-screen-modal";
import { useUpdatePaymentIndicatedStore } from "@/store/invoices/updatePaymentIndicated";
import { useRoute } from "@/components/shared/router";
import { generatePaymentQRUrl } from "@/lib/helpers/string";
import { showToast } from "@/components/ui/toast/show-toast";
import { downloadFromUrl } from "@/lib/platform";

export type SelectPaymentScreenModalProps = {
  open: boolean;
  onShowModal: (...modalNames: PaymentMethodScreenModal[]) => void;
};

export type PayNowScreenModalProps = {
  open: boolean;
  invoiceId: string;
  accountNumber: string;
  eunNumber: string;
  merchantName: string;
  onCopyAccountNumber: () => void;
  onCopyUenNumber: () => void;
  paymentQrUri: string;
  onDownloadQr: () => void;
  onClose: () => void;
  onConfirmTransfer: () => Promise<void>;
  loading: boolean;
};

export const PaymentMethodContainer = () => {
  const { push } = useRoute();
  const {
    data: {
      invoiceId,
      totalAmount,
      selectPaymentScreenModal,
      paynowScreenModal,
      creditCardScreenModal,
    },
    showPaymentMethodModal,
  } = useInvoiceState();

  const { fetch, loading } = useUpdatePaymentIndicatedStore();
  const paymentQrUri = generatePaymentQRUrl({ invoiceId, totalAmount });

  const onCopyAccountNumber = () => {
    copyClipboard(ACCOUNT_NUMBER);
  };

  const onCopyUenNumber = () => {
    copyClipboard(UEN_NUMBER);
  };

  const onDownloadQr = () => {
    downloadFromUrl(paymentQrUri, "LUCE_QR_INVOICE_" + invoiceId + ".png");
  };

  const onConfirmTransfer = async () => {
    await fetch(
      { requestPayload: { input: { invoiceId } } },
      { selfHandleError: true },
    );

    const successMsg = `Success update payment Invoice #${invoiceId}`;
    showToast({
      title: successMsg,
      type: "success",
    });

    showPaymentMethodModal();
    push({ pageKey: "invoices" });
  };

  return (
    <>
      <SelectPaymentScreenModal
        open={selectPaymentScreenModal}
        onShowModal={showPaymentMethodModal}
      />

      <PayNowScreenModal
        open={paynowScreenModal}
        invoiceId={invoiceId}
        accountNumber={ACCOUNT_NUMBER}
        eunNumber={UEN_NUMBER}
        merchantName={MERCHANT_NAME}
        onCopyAccountNumber={onCopyAccountNumber}
        onCopyUenNumber={onCopyUenNumber}
        paymentQrUri={paymentQrUri}
        onDownloadQr={onDownloadQr}
        onClose={() =>
          showPaymentMethodModal(PaymentMethodScreenModal.SELECT_PAYMENT)
        }
        onConfirmTransfer={onConfirmTransfer}
        loading={loading}
      />

      <CreditCardScreenModal
        open={creditCardScreenModal}
        onClose={() =>
          showPaymentMethodModal(PaymentMethodScreenModal.SELECT_PAYMENT)
        }
      />
    </>
  );
};
