import { useFragment } from "@/__generated__";
import type {
  UnratedVisitsByFilterQuery,
  UnratedVisitsByFilterQueryVariables,
} from "@/__generated__/graphql";
import {
  UnratedVisitsByFilterDocument,
  VisitClientFragmentFragmentDoc,
} from "@/__generated__/graphql";
import type { VisitDetailData } from "@/components/shared/visits/visit-detail";
import { mapToVisitDetailData } from "@/components/shared/visits/visit-detail";
import { createRequestFactory } from "@/lib/request-factory";

export const useUnratedVisitByFilterStore = createRequestFactory<
  UnratedVisitsByFilterQuery,
  VisitDetailData[],
  UnratedVisitsByFilterQueryVariables
>({
  method: "query",
  fetchPolicy: "network-only",
  graphqlDocument: UnratedVisitsByFilterDocument,
  transformFunction(data) {
    if (data.unratedVisitsByFilters) {
      const visits = useFragment(
        VisitClientFragmentFragmentDoc,
        data.unratedVisitsByFilters.visits,
      );

      return visits.map((v) => mapToVisitDetailData(v));
    }
    throw new Error("failed to fetch unrated visits");
  },
});
