import { View } from "@/components/ui";
import { Column, Container } from "@/components/ui/layout";
import type { ServiceName } from "@/types/service";
import { BookingInfoMain } from "./booking-info-main";
import { BookingInfoNavigation } from "./booking-info-navigation";
import { useBookingInfoForm } from "./useBookingInfoForm";
import { getPlatform } from "@/lib/utils";
import { useAuthState } from "@/store/auth";
import type { ContactFormData } from "@/components/shared/add-another-contact-modal";
import { AddAnotherContactModal } from "@/components/shared/add-another-contact-modal";
import { useEffect, useState } from "react";
import { useCreateContactStore } from "@/store/booking/createContact";
import { useClientStore } from "@/store/auth/client";
import type { Contact } from "@/__generated__/graphql";
import { AccessMode } from "@/types/booking";
import { useBookingRouterInterceptor } from "@/components/hooks/use-booking-route";
import { useBookingState } from "@/store/booking/useBookingState";
import { WebBackButtonHeader } from "@/components/shared/back-button";

export const BookingInfo = ({ name }: { name: ServiceName }) => {
  useBookingRouterInterceptor();
  const {
    data: { userInfo },
  } = useAuthState();
  const {
    data: { selectedAddress },
    setBookingState,
  } = useBookingState();
  const bookingInfoFormReturn = useBookingInfoForm();
  const { control, setValue, reset } = bookingInfoFormReturn;
  const platform = getPlatform();
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const { fetch: createContact, loading: createContactLoading } =
    useCreateContactStore();
  const { fetch: getClientInfo } = useClientStore();

  useEffect(() => {
    setBookingState({ bookingInfoFormReturn: bookingInfoFormReturn });
  }, []);

  useEffect(() => {
    const primaryContact = userInfo.contacts[0] as Contact;
    reset({
      name: `${primaryContact.firstName} ${primaryContact.lastName}`,
      phoneNumber: primaryContact.phoneNumber[0],
      email: primaryContact.email[0],
      fullAddress: selectedAddress?.fullAddress,
      postalCode: selectedAddress?.postalCode,
      accessMode: AccessMode.RingDoorbell,
      accessInstructions: "",
      note: "",
    });
  }, []);

  const onAddAnotherContact = () => {
    setContactModalOpen(true);
  };

  const onSubmitContactForm = (contacts: ContactFormData) => {
    const { firstName, lastName, primary, email, phoneNumber } = contacts;
    createContact({
      requestPayload: {
        input: {
          clientId: userInfo.id,
          firstName,
          lastName,
          email: email.map((e) => e.value),
          phoneNumber: phoneNumber.map(
            (item) => `+${item.nationCode.split("/")[1]}${item.phoneNumber}`,
          ),
          primary,
        },
      },
    }).then((res) => {
      if (!res.data || res.error) {
        return;
      }
      const { email, phoneNumber, lastName, firstName } = res.data;
      reset({
        name: `${firstName} ${lastName}`,
        phoneNumber: phoneNumber[0],
        email: email[0],
      });
      setContactModalOpen(false);
      getClientInfo({
        requestPayload: {
          id: userInfo.id,
        },
      });
    });
  };

  return (
    <View className="pb-4">
      <Container>
        <Column desktop="8">
          <WebBackButtonHeader title="Review Details" />
          <BookingInfoMain
            control={control}
            setValue={setValue}
            contacts={userInfo.contacts}
            onAddAnotherContact={onAddAnotherContact}
          />
        </Column>

        {platform === "web" && (
          <Column desktop="4">
            <View className="pt-6 md:sticky md:top-14">
              <BookingInfoNavigation name={name} />
            </View>
          </Column>
        )}

        <AddAnotherContactModal
          open={contactModalOpen}
          loading={createContactLoading}
          setOpen={setContactModalOpen}
          onSubmitContactForm={onSubmitContactForm}
        />
      </Container>
    </View>
  );
};
