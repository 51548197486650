import { View } from "@/components/ui";
import type { LineItemDetail } from "@/types/line-item";
import { LuceSparkle } from "@/components/shared/icons";
import { useMemo } from "react";
import { formatPrice } from "@/lib/helpers/number";
import { PackageDepartmentEnum } from "@/__generated__/graphql";
import { Typography } from "../typography";
import { getDepartmentUnit } from "@/lib/booking-lib";

export function LineItemList({
  lineItems,
  withPrice,
}: {
  lineItems: LineItemDetail[];
  withPrice?: boolean;
}) {
  const lineItemsGroup = useMemo(() => {
    const [firstItem] = lineItems;
    const shouldCombineItemValue =
      firstItem &&
      [
        PackageDepartmentEnum.CarpetUpholstery,
        PackageDepartmentEnum.HomeBeauty,
      ].includes(firstItem.department);

    return lineItems.reduce<Record<string, LineItemDetail[]>>((group, item) => {
      if (!group[item.serviceTitle]) {
        group[item.serviceTitle] = [];
      }

      if (shouldCombineItemValue) {
        const sameItemIndex = group[item.serviceTitle].findIndex(
          ({ packageDetailId }) => item.packageDetailId === packageDetailId,
        );

        if (sameItemIndex > -1) {
          group[item.serviceTitle][sameItemIndex] = {
            ...group[item.serviceTitle][sameItemIndex],
            billingValue:
              group[item.serviceTitle][sameItemIndex].billingValue +
              item.billingValue,
            unitsNumber:
              group[item.serviceTitle][sameItemIndex].unitsNumber +
              item.unitsNumber,
          };
        } else {
          group[item.serviceTitle].push(item);
        }
      } else {
        group[item.serviceTitle].push(item);
      }

      return group;
    }, {});
  }, [lineItems]);

  return (
    <View>
      {Object.entries(lineItemsGroup).map(([serviceTypeTitle, lineItems]) => (
        <View key={serviceTypeTitle}>
          <ServiceTitle value={serviceTypeTitle} />
          {lineItems.map((item, index) => (
            <ServiceItemList
              key={item.packageDetailId + index}
              label={
                item.department === PackageDepartmentEnum.HomeBeauty
                  ? item.serviceLabel
                  : item.description
              }
              units={`${item.unitsNumber || ""} ${getDepartmentUnit(item.department)}`}
              price={item.billingValue}
              showPrice={withPrice}
            />
          ))}
        </View>
      ))}
    </View>
  );
}

export function ServiceTitle({ value }: { value: string }) {
  return (
    <View className="flex flex-row items-start p-1">
      <View className="mr-3 mt-1.5 w-3">
        <LuceSparkle className="size-3 text-brand-primary" />
      </View>
      <Typography variant="label-md" className="flex-1">
        {value}
      </Typography>
    </View>
  );
}

export function ServiceItemList({
  label,
  units,
  price,
  showPrice = true,
}: {
  label: string;
  units: string;
  price: number;
  showPrice?: boolean;
}) {
  return (
    <View className="ml-[9px] flex flex-row gap-1 border-l border-border-high py-1 pl-[18px]">
      <Typography
        variant="label-md"
        className="flex-1"
        color="foreground-intermediate"
      >
        {label}
      </Typography>
      <View className="flex flex-row items-center gap-1">
        <Typography variant="label-md">{units}</Typography>
        {showPrice && (
          <Typography variant="label-md">({formatPrice(price)})</Typography>
        )}
      </View>
    </View>
  );
}
