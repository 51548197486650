/*
  Inspired by Apollo-Link-Token-Refresh library
  See: https://github.com/newsiberian/apollo-link-token-refresh/blob/master/src/queuing.ts

  Apollo uses zen-observable library: https://github.com/zenparsing/zen-observable
  Apollo client: https://github.com/apollographql/apollo-client
*/

import type { Operation, NextLink, FetchResult } from "@apollo/client/core";
import { Observable } from "@apollo/client/core";

export interface Subscriber {
  next?: (result: FetchResult) => void;
  error?: (error: Error) => void;
  complete?: () => void;
}

export interface QueuedRequest {
  operation: Operation;
  forward?: NextLink;
  subscriber?: Subscriber;

  // Promise is created when the query fetch request is added to the queue
  // and is resolved once the result is back from the server.
  observable?: Observable<FetchResult>;
  next?: (result: FetchResult) => void;
  error?: (error: Error) => void;
  complete?: () => void;
}

export class OperationQueue {
  private queuedRequests: QueuedRequest[] = [];

  constructor() {
    this.queuedRequests = [];
  }

  enqueueRequest(request: QueuedRequest): Observable<FetchResult> {
    const requestCopy = { ...request };

    requestCopy.observable =
      requestCopy.observable ??
      new Observable<FetchResult>((observer) => {
        this.queuedRequests.push(requestCopy);

        if (typeof requestCopy.subscriber === "undefined") {
          requestCopy.subscriber = {};
        }

        requestCopy.subscriber.next =
          requestCopy.next ?? observer.next.bind(observer);
        requestCopy.subscriber.error =
          requestCopy.error ?? observer.error.bind(observer);
        requestCopy.subscriber.complete =
          requestCopy.complete ?? observer.complete.bind(observer);
      });

    return requestCopy.observable;
  }

  consumeQueue() {
    this.queuedRequests.forEach((request) => {
      if (request?.forward) {
        const nextLink = request.forward(request.operation);

        if (request.subscriber) {
          nextLink.subscribe(request?.subscriber);
        }
      }
    });
  }

  clearQueue() {
    this.queuedRequests = [];
  }
}
