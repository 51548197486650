import type {
  GenerateInvoiceReceiptPdfMutation,
  GenerateInvoiceReceiptPdfMutationVariables,
} from "@/__generated__/graphql";
import { GenerateInvoiceReceiptPdfDocument } from "@/__generated__/graphql";
import { generatePDFUrl } from "@/components/shared/pdf-viewer";

import { createRequestFactory } from "@/lib/request-factory";

export const useGenerateInvoiceReceiptPdfStore = createRequestFactory<
  GenerateInvoiceReceiptPdfMutation,
  string,
  GenerateInvoiceReceiptPdfMutationVariables
>({
  method: "mutation",
  graphqlDocument: GenerateInvoiceReceiptPdfDocument,
  transformFunction(data) {
    if (data.generateInvoiceReceiptPdf?.fileString) {
      return generatePDFUrl(data.generateInvoiceReceiptPdf.fileString);
    }
    throw new Error("Unable to generate PDF");
  },
});
