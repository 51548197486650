import { View } from "@/components/ui";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import { CaretDown } from "@/components/shared/icons";
import { cn } from "@/lib/utils";
import { Typography } from "@/components/shared/typography";
import type { PackagesFilterProps } from "./type";

export const PackagesFilter = ({
  openActiveFilter,
  packageStatusFilter,
  setOpenActiveFilter,
  setPackageStatus,
  statusLabel,
  packageStatus,
}: PackagesFilterProps) => {
  return (
    <View className="flex flex-row gap-3">
      <DropdownMenu onOpenChange={setOpenActiveFilter}>
        <DropdownMenuTrigger className="w-full max-w-[125px]">
          <View className="ease flex w-full flex-1 cursor-pointer flex-row items-center justify-between rounded-full border border-secondary-border px-4 py-2 transition">
            <Typography className="truncate">{statusLabel}</Typography>
            <View
              className={cn("ml-2 transition duration-300 ease-in-out", {
                "rotate-180": openActiveFilter,
              })}
            >
              <CaretDown />
            </View>
          </View>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="mt-1 max-w-xs rounded-xl md:w-full">
          {packageStatusFilter
            .filter((status) => status.value !== packageStatus)
            .map((status, index) => (
              <View key={index} onClick={() => setPackageStatus(status.value)}>
                <DropdownMenuItem>
                  <Typography color="brand-primary">{status.label}</Typography>
                </DropdownMenuItem>
              </View>
            ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </View>
  );
};
