import type { StoreApi, UseBoundStore } from "zustand";
import { create } from "zustand";
import { ClientInstance } from "@/lib/apollo";
import type { TypedDocumentNode } from "@graphql-typed-document-node/core";
import type {
  FetchPolicy,
  MutationFetchPolicy,
  OperationVariables,
} from "@apollo/client";
import type { ApolloError } from "@apollo/client/errors";
import type { ConfirmationType } from "@/__generated__/graphql";
import { uniqueById } from "@/lib/utils";
import type { GraphQLFormattedError } from "graphql";
import { showToast } from "@/components/ui/toast/show-toast";

type RequestObject<T, U, P> = {
  url?: string;
  headers?: Record<string, string>;
  method?: "query" | "mutation";
  body?: object | undefined;
  transformFunction?: (data: T, request: P | undefined) => U;
  payload?: P;
  graphqlDocument: TypedDocumentNode<T, P>;
  onFetchSuccess?: (data: U) => void;
  fetchPolicy?: FetchPolicy;
  limit?: number;
  getCountFromResponse?: (data: T) => number;
  hasUpload?: boolean;
};

type RequestStoreBase<U> = {
  data?: null | U;
  loading: boolean;
  error: string | null;
  clear: () => void;
};

type RequestStore<U, P> = RequestStoreBase<U> & {
  requestPayload?: P;
  fetch: (
    p?: { requestPayload: P | undefined },
    opt?: { selfHandleError?: boolean },
  ) => Promise<{
    data: U | null;
    error: string | null;
    graphqlErrors?: ReadonlyArray<GraphQLFormattedError>;
  }>;
  refetch: (
    p?: {
      requestPayload: P | undefined;
    },
    opt?: { selfHandleError?: boolean },
  ) => Promise<{ data: U | null; error: string | null }>;
};

type fetchMoreFunction<P, U> = (p?: {
  requestPayload: P | undefined;
}) => Promise<{
  data: U[] | null;
  error: string | null;
  graphqlErrors?: ReadonlyArray<GraphQLFormattedError>;
}>;

type RequestStorePaginated<U, P> = RequestStoreBase<U[]> & {
  data: U[];
  pagination: {
    offset: number;
    limit: number;
    total: number;
    enabled: boolean;
  };
  requestPayload?: P;
  fetch: fetchMoreFunction<P, U>;
  fetchMore: fetchMoreFunction<P, U>;
  refetch: fetchMoreFunction<P, U>;
};

export type RequestFactoryType<U, P> = UseBoundStore<
  StoreApi<RequestStore<U, P>>
>;

/***
 * T -> Type of the data returned by the request
 * U -> Type of the data stored in the state
 * P -> Request payload
 **/
export function createRequestFactory<T, U, P extends OperationVariables>(
  requestObject: RequestObject<T, U, P>,
  initialState?: U,
) {
  return create<RequestStore<U, P>>((set, get) => ({
    data: initialState,
    requestPayload: undefined,
    loading: false,
    error: null,
    fetch: async (
      opt?: { requestPayload?: P },
      handleOpt?: { selfHandleError?: boolean },
    ) => {
      const variables = opt?.requestPayload || requestObject.payload;
      set({ loading: true, error: null, requestPayload: variables });

      try {
        let serverData: T | undefined;
        if (requestObject.method === "query") {
          const res = await ClientInstance.query<T, P>({
            query: requestObject.graphqlDocument,
            variables,
            fetchPolicy: requestObject.fetchPolicy || "cache-first",
          });
          serverData = res.data;
        } else if (requestObject.method === "mutation") {
          const res = await ClientInstance.mutate<T, P>({
            mutation: requestObject.graphqlDocument,
            variables,
            context: { hasUpload: requestObject.hasUpload },
            fetchPolicy: (requestObject.fetchPolicy ||
              "no-cache") as MutationFetchPolicy,
          });
          serverData = res.data as T;
        } else {
          console.error(
            `request method: ${requestObject.method} is not defined in createRequestFactory`,
          );
          throw new Error(
            `request method: ${requestObject.method} is not defined in createRequestFactory`,
          );
        }

        if (!requestObject.transformFunction) {
          set({
            data: serverData as unknown as U,
            loading: false,
            error: null,
          });
          return { data: serverData as unknown as U, error: null };
        }

        const transformedData = requestObject.transformFunction(
          serverData,
          variables,
        );
        set({
          data: transformedData,
          loading: false,
          error: null,
        });

        const callback = requestObject.onFetchSuccess;
        if (callback) {
          callback(transformedData);
        }
        return { data: transformedData, error: null };
      } catch (err) {
        if (!handleOpt?.selfHandleError) {
          showToast({
            title: (err as Error).message || "unknown error",
            type: "error",
          });
        }

        set({
          ...get(),
          error: (err as Error).message || "unknown error",
          loading: false,
        });
        return {
          data: null,
          error: (err as Error).message || "unknown error",
          graphqlErrors: (err as ApolloError).graphQLErrors,
        };
      }
    },

    refetch: (
      opt?: { requestPayload: P | undefined },
      handleOpt?: { selfHandleError?: boolean },
    ) => {
      return get().fetch(
        opt || { requestPayload: get().requestPayload },
        handleOpt || {},
      );
    },

    clear: () => {
      set({
        data: initialState || null,
        loading: false,
        error: null,
        requestPayload: requestObject.payload,
      });
    },
  }));
}

export const getRequiredConfirmations = (
  graphQLErrors: ReadonlyArray<GraphQLFormattedError>,
): ConfirmationType[] => {
  if (graphQLErrors.length === 0) {
    return [];
  }

  return (graphQLErrors[0]?.extensions?.required_confirmations ??
    []) as ConfirmationType[];
};

export type RequestPaginatedFactoryType<U, P> = UseBoundStore<
  StoreApi<RequestStorePaginated<U, P>>
>;

/***
 * T -> Type of the data returned by the request
 * U -> The item stored in the state, will be placed in an array
 * P -> Request payload
 **/
export function createPaginatedFactory<
  T,
  U extends { id: string | number },
  P extends OperationVariables,
>(requestObject: RequestObject<T, U[], P>, initialState?: U[]) {
  return create<RequestStorePaginated<U, P>>((set, get) => ({
    data: initialState || [],
    requestPayload: undefined,
    loading: false,
    error: null,
    pagination: {
      offset: 0,
      limit: requestObject.limit || 20,
      total: 0,
      enabled: true,
    },

    fetch: async (opt?: { requestPayload: P | undefined }) => {
      const currentPagination = get().pagination;

      let variables = opt?.requestPayload || requestObject.payload;
      variables = {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        filters: {
          ...variables?.filters,
          offset: currentPagination.offset,
          limit: currentPagination.limit,
        },
      } as unknown as P;
      set({ loading: true, error: null, requestPayload: variables });

      try {
        let serverData: T | undefined;
        if (requestObject.method === "query") {
          const res = await ClientInstance.query({
            query: requestObject.graphqlDocument,
            variables,
            fetchPolicy: requestObject.fetchPolicy || "cache-first",
          });
          serverData = res.data;
        } else if (requestObject.method === "mutation") {
          // TODO: should remove this, pagination using mutation?
          const res = await ClientInstance.mutate({
            mutation: requestObject.graphqlDocument,
            variables,
            fetchPolicy: (requestObject.fetchPolicy ||
              "no-cache") as MutationFetchPolicy,
          });
          serverData = res.data as T;
        } else {
          console.error(
            `request method: ${requestObject.method} is not defined in createPaginatedFactory`,
          );
          throw new Error(
            `request method: ${requestObject.method} is not defined in createPaginatedFactory`,
          );
        }

        if (requestObject.getCountFromResponse && !currentPagination.total) {
          const total = requestObject.getCountFromResponse(serverData);
          set({
            pagination: {
              ...get().pagination,
              total,
            },
          });
        }

        if (!requestObject.transformFunction) {
          const newData = uniqueById<U>(
            get().data.concat(serverData as unknown as U[]),
          );

          set({
            data: newData,
            loading: false,
            error: null,
          });

          return { data: newData, error: null };
        }

        const transformedData = requestObject.transformFunction(
          serverData,
          opt?.requestPayload,
        );

        const newData = uniqueById<U>(get().data.concat(transformedData));

        set({
          data: newData,
          loading: false,
          error: null,
        });

        const callback = requestObject.onFetchSuccess;
        if (callback) {
          callback(newData);
        }

        return { data: newData, error: null };
      } catch (err) {
        set({
          ...get(),
          error: (err as Error).message || "unknown error",
          loading: false,
        });
        return {
          data: null,
          error: (err as Error).message || "unknown error",
          graphqlErrors: (err as ApolloError).graphQLErrors,
        };
      }
    },

    refetch: async (opt?: { requestPayload: P | undefined }) => {
      set({
        pagination: {
          ...get().pagination,
          offset: 0,
        },
      });
      const { fetch, requestPayload } = get();
      return await fetch(opt || { requestPayload });
    },

    fetchMore: async (opt?: { requestPayload: P | undefined }) => {
      const { fetch, pagination, data } = get();
      const { offset, limit, total, enabled } = pagination;

      if (!enabled) {
        return { data, error: null };
      }

      set({
        pagination: {
          ...pagination,
          offset: offset + limit,
          enabled: total > data.length,
        },
      });

      return await fetch(opt);
    },

    clear: () => {
      set({
        data: initialState || [],
        loading: false,
        error: null,
        pagination: {
          ...get().pagination,
          offset: 0,
        },
      });
    },
  }));
}
