import {
  Button,
  View,
  Input,
  Textarea,
  Checkbox,
  Label,
} from "@/components/ui";
import { cn } from "@/lib/utils";
import { InputFormControl } from "@/components/shared/input-form-control";
import { useId, useEffect } from "react";
import type { AddressFormData } from "@/store/auth/forms/useAddressForm";
import { useAddressForm } from "@/store/auth/forms/useAddressForm";
import { useAddressByPostalCodeStore } from "@/store/auth/addressByPostalCode";
import { useAuthState } from "@/store/auth";
import { LoginType } from "@/types/users";
import { FormControlError } from "@/components/shared/form-control-error";
import { Spin } from "@/components/shared/spin";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "../error-message";
import { Typography } from "../../typography";
import { useIntl } from "react-intl";

export const AddAddressContent = ({
  isModal = true,
  loading,
  error,
  onAddAddress,
  isDefaultAddress,
}: {
  onAddAddress: (data: AddressFormData) => void;
  isModal?: boolean;
  loading: boolean;
  error?: string;
  isDefaultAddress?: boolean;
}) => {
  const intl = useIntl();
  const id = useId();
  const {
    data: { postalCode },
    setType,
    setAddressFormReturn,
  } = useAuthState();
  const formReturn = useAddressForm();
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    formState: { isSubmitted, errors },
  } = formReturn;

  const { loading: addAddressLoading, fetch: addressByPostalCode } =
    useAddressByPostalCodeStore();

  const onClickAddAddress = handleSubmit(onAddAddress);

  const addAddress = (postalCode: string) => {
    addressByPostalCode(
      {
        requestPayload: {
          postalCode,
        },
      },
      {
        selfHandleError: true,
      },
    ).then(({ data, error }) => {
      if (error || !data) {
        setError("postalCode", { message: error || "Postal code is invalid" });
      } else {
        setValue("fullAddress", data.address);
      }
    });
  };

  useEffect(() => {
    setType(LoginType.SignUp);
    setAddressFormReturn(formReturn);
  }, []);

  useEffect(() => {
    setValue("primary", !!isDefaultAddress);
  }, [isDefaultAddress]);

  useEffect(() => {
    setValue("postalCode", postalCode);
    addAddress(postalCode);
  }, [postalCode]);

  return (
    <View className={cn("flex flex-col", isModal ? "gap-2" : "gap-4")}>
      <View className="pb-3">
        <Typography variant={{ md: "label-2xl", sm: "label-xl" }}>
          {intl.formatMessage({
            defaultMessage: "Add Address",
            id: "add-address",
          })}
        </Typography>
      </View>
      <InputFormControl
        name="postalCode"
        label="Postal Code"
        control={control}
        component={Input}
        componentProps={{
          placeholder: "Enter Postal Code",
          suffix: addAddressLoading && <Spin />,
        }}
        changeFallBack={(value) => {
          if (!value || value.length < 6 || value.length > 10) return;
          addAddress(value);
        }}
      />
      <InputFormControl
        name="fullAddress"
        label={intl.formatMessage({
          defaultMessage: "Address",
          id: "Address",
        })}
        className="pt-3"
        control={control}
        component={Textarea}
        componentProps={{
          placeholder: intl.formatMessage({
            defaultMessage: "Enter Address",
            id: "enter-address",
          }),
        }}
      />
      <Label className="block pb-1 text-sm" htmlFor={id}>
        {intl.formatMessage({
          defaultMessage: "Unit Number",
          id: "unit-number",
        })}
      </Label>

      <View className="flex flex-row gap-3">
        <InputFormControl
          name="unitFloor"
          className="flex-1 pb-0"
          control={control}
          component={Input}
          componentProps={{
            placeholder: "Floor",
            id,
          }}
          hideErrorMessage
        />
        <InputFormControl
          name="apartmentNumber"
          className="flex-1 pb-0"
          control={control}
          component={Input}
          componentProps={{
            placeholder: "Apt no",
          }}
          hideErrorMessage
        />
      </View>

      {errors?.unitFloor || errors.apartmentNumber ? (
        <FormControlError
          error={intl.formatMessage({
            defaultMessage:
              "Floor and Apt No. required. Please indicate 0 if not applicable.",
            id: "addAddress.errorMessage",
          })}
        />
      ) : (
        <Typography variant="body-md" color="secondary-text" className="mt-1">
          {intl.formatMessage({
            defaultMessage: "If not applicable, enter 0 for Floor or Apt No.",
            id: "addAddress.errorDescription",
          })}
        </Typography>
      )}

      <Controller
        control={control}
        name="primary"
        render={({ field: { onChange, value } }) => (
          <View className="flex flex-row items-center gap-2 pt-3">
            <Checkbox
              id="is_primary_checkbox"
              checked={value}
              disabled={isDefaultAddress}
              onCheckedChange={onChange}
            />
            <Label htmlFor="is_primary_checkbox" className="cursor-pointer">
              {intl.formatMessage({
                defaultMessage: "Set as default address",
                id: "make-default-contact",
              })}
            </Label>
          </View>
        )}
      />

      {isSubmitted && (
        <ErrorMessage errorMessage={error || ""} className="mt-3" />
      )}
      <Button
        variant="primary"
        color="CTA"
        rounded="full"
        fullWidth="full"
        onClick={onClickAddAddress}
        className="mt-3"
        loading={loading}
        children={intl.formatMessage({
          defaultMessage: "Add Address",
          id: "add-address",
        })}
      />
    </View>
  );
};
