import type {
  VisitsByFiltersQuery,
  VisitsByFiltersQueryVariables,
} from "@/__generated__/graphql";
import { VisitsByFiltersDocument } from "@/__generated__/graphql";
import type { VisitData } from "@/components/shared/visits/visit-card";
import { mapToVisitData } from "@/components/shared/visits/visit-card";
import { useVisitListState } from "./visitList";
import { sortVisitByDateAsc } from "@/containers/visits/lib";
import { createPaginatedFactory } from "@/lib/request-factory";

export const useNext7DayVisitsStore = createPaginatedFactory<
  VisitsByFiltersQuery,
  VisitData,
  VisitsByFiltersQueryVariables
>({
  method: "query",
  graphqlDocument: VisitsByFiltersDocument,
  fetchPolicy: "network-only",
  getCountFromResponse: (res) => {
    return res.visitsByFilters.count;
  },
  transformFunction(data) {
    return sortVisitByDateAsc(data.visitsByFilters.visits).map(mapToVisitData);
  },
  onFetchSuccess(data) {
    const { setListEmpty } = useVisitListState.getState();
    setListEmpty("next7days", !data.length);
  },
});
