import type { TimeSlot, WorkerPortfolio } from "@/types/booking";
import { PackageFrequencyGroupEnum } from "@/types/booking";
import { formatDate, shortDayByIndex, timeFormat } from "@/lib/helpers/date";
import type {
  ClientFindBookingScheduleQuery,
  PackageDetailFragmentFragment,
} from "@/__generated__/graphql";
import {
  PackageDepartmentEnum,
  PackageDetailSessionEnum,
  PackageRecurrenceEnum,
  ScheduleJobFrequencyEnum,
} from "@/__generated__/graphql";
import type { ServiceName, UpholsteryServiceName } from "@/types/service";
import { UpholsteryServiceNames } from "@/types/service";
import { addDays, addHours, isBefore, isSameDay } from "date-fns";

export function groupTimeSlotByDate(
  timeSlots: TimeSlot[],
): Record<string, TimeSlot[]> {
  return timeSlots.reduce<Record<string, TimeSlot[]>>((group, timeslot) => {
    if (group[timeslot.date]) {
      group[timeslot.date].push(timeslot);
    } else {
      group[timeslot.date] = [timeslot];
    }

    return group;
  }, {});
}

export function transformScheduleVariantToTimeSlot(
  scheduleVariants: ClientFindBookingScheduleQuery["clientFindBookingSchedule"],
): TimeSlot[] {
  const now = new Date();
  const fiveHoursFromNow = addHours(now, 5);

  return scheduleVariants.reduce<TimeSlot[]>((allTimeSlot, scheduleVariant) => {
    scheduleVariant.morning?.forEach((item, i) => {
      const startDate = new Date(item.startAt);
      const endDate = new Date(item.endAt);

      const isToday = isSameDay(startDate, now);
      const isWithinFiveHours =
        isToday && isBefore(startDate, fiveHoursFromNow);

      if (isWithinFiveHours) return;

      allTimeSlot.push({
        startAt: item.startAt,
        id: `${scheduleVariant.day}-morning-${i}`,
        slotId: item.id,
        date: formatDate(startDate),
        day: shortDayByIndex(scheduleVariant.day),
        partOfDay: "Morning",
        startTime: formatDate(startDate, timeFormat),
        endTime: formatDate(endDate, timeFormat),
        averageTravelTime: item.averageTravelTime,
        workerId: item.worker.id,
        avatarUrl: item.worker.avatarUrl ?? "",
        workerRating: item.worker.workerRating,
        workerName: `${item.worker.firstName} ${item.worker.lastName}`,
        portfolios: getWorkerPortfolios(item.worker.workerContracts ?? []),

        discount: undefined, // TODO: fix this, our schedule query doesnt have item.discount
        rateValue: item.rateValue ?? undefined,
      });
    });

    scheduleVariant.afternoon?.forEach((item, i) => {
      const startDate = new Date(item.startAt);
      const endDate = new Date(item.endAt);

      const isToday = isSameDay(startDate, now);
      const isWithinFiveHours =
        isToday && isBefore(startDate, fiveHoursFromNow);

      if (isWithinFiveHours) return;

      allTimeSlot.push({
        startAt: item.startAt,
        id: `${scheduleVariant.day}-afternoon-${i}`,
        slotId: item.id,
        date: formatDate(startDate),
        day: shortDayByIndex(scheduleVariant.day),
        partOfDay: "Afternoon",
        startTime: formatDate(startDate, timeFormat),
        endTime: formatDate(endDate, timeFormat),
        averageTravelTime: item.averageTravelTime,
        workerId: item.worker.id,
        avatarUrl: item.worker.avatarUrl ?? "",
        workerRating: item.worker.workerRating,
        workerName: `${item.worker.firstName} ${item.worker.lastName}`,
        portfolios: getWorkerPortfolios(item.worker.workerContracts ?? []),
        discount: undefined, // TODO: fix this, our schedule query doesnt have item.discount
        rateValue: item.rateValue ?? undefined,
      });
    });

    scheduleVariant.evening?.forEach((item, i) => {
      const startDate = new Date(item.startAt);
      const endDate = new Date(item.endAt);

      const isToday = isSameDay(startDate, now);
      const isWithinFiveHours =
        isToday && isBefore(startDate, fiveHoursFromNow);

      if (isWithinFiveHours) return;

      allTimeSlot.push({
        startAt: item.startAt,
        id: `${scheduleVariant.day}-evening-${i}`,
        slotId: item.id,
        date: formatDate(startDate),
        day: shortDayByIndex(scheduleVariant.day),
        partOfDay: "Evening",
        startTime: formatDate(startDate, timeFormat),
        endTime: formatDate(endDate, timeFormat),
        averageTravelTime: item.averageTravelTime,
        workerId: item.worker.id,
        avatarUrl: item.worker.avatarUrl ?? "",
        workerRating: item.worker.workerRating,
        workerName: `${item.worker.firstName} ${item.worker.lastName}`,
        portfolios: getWorkerPortfolios(item.worker.workerContracts ?? []),

        discount: undefined, // TODO: fix this, our schedule query doesnt have item.discount
        rateValue: item.rateValue ?? undefined,
      });
    });

    return allTimeSlot;
  }, []);
}

type WorkerContractPorto = NonNullable<
  NonNullable<
    ClientFindBookingScheduleQuery["clientFindBookingSchedule"][0]["morning"]
  >[0]["worker"]["workerContracts"]
>[0];
export const getWorkerPortfolios = (
  workerContracts: WorkerContractPorto[],
): WorkerPortfolio[] => {
  const workerPortfolios = workerContracts.reduce<WorkerPortfolio[]>(
    (workerPortfolios, contracts) => {
      const itemPortfolios: WorkerPortfolio[] = contracts.portfolios
        ? contracts.portfolios.map((itemPortfolio) => {
            return {
              id: itemPortfolio.id,
              fileName: itemPortfolio.filename,
              imgUrl: itemPortfolio.downloadUrl,
            };
          })
        : [];

      return [...workerPortfolios, ...itemPortfolios];
    },
    [],
  );

  return workerPortfolios;
};

export const getWorkerLabel = (department: PackageDepartmentEnum): string => {
  switch (department) {
    case PackageDepartmentEnum.CarpetUpholstery:
      return "technician";
    case PackageDepartmentEnum.Aircon:
      return "technician";
    case PackageDepartmentEnum.HomeCleaning:
      return "cleaner";
    case PackageDepartmentEnum.HomeBeauty:
      return "beauty technician";
    case PackageDepartmentEnum.OfficeCleaning:
      return "cleaner";

    default:
      return "cleaner";
  }
};

export function getRepeatEveryFrequency(
  frequency: PackageFrequencyGroupEnum,
): [PackageRecurrenceEnum[] | undefined, number | undefined] {
  switch (frequency) {
    case (PackageFrequencyGroupEnum.AD_HOC,
    PackageFrequencyGroupEnum.NEXT_DAY_AD_HOC):
      return [[PackageRecurrenceEnum.AdHoc], 0];
    case PackageFrequencyGroupEnum.WEEKLY:
      return [[PackageRecurrenceEnum.Week], 1];
    case PackageFrequencyGroupEnum.FORTNIGHT:
      return [[PackageRecurrenceEnum.Fortnight], 1];
    case PackageFrequencyGroupEnum.TRI_YEARLY:
      return [[PackageRecurrenceEnum.Year], 3];
    case PackageFrequencyGroupEnum.QUARTERLY:
      return [[PackageRecurrenceEnum.Year], 4];
    default:
      return [undefined, undefined];
  }
}

export function getDateRangeFromFrequency(
  frequency: PackageFrequencyGroupEnum,
): [Date, Date] {
  const todayDate = new Date();
  const tomorrowDate = addDays(new Date(), 1);

  switch (frequency) {
    case PackageFrequencyGroupEnum.AD_HOC:
      return [todayDate, addDays(todayDate, 6)];
    case PackageFrequencyGroupEnum.NEXT_DAY_AD_HOC:
      return [todayDate, addDays(todayDate, 6)];
    case PackageFrequencyGroupEnum.WEEKLY:
    case PackageFrequencyGroupEnum.FORTNIGHT:
      return [tomorrowDate, addDays(tomorrowDate, 6)];
    default:
      return [todayDate, addDays(todayDate, 6)];
  }
}

export function getServiceDepartments(name: ServiceName) {
  switch (name) {
    case "home-cleaning":
      return PackageDepartmentEnum.HomeCleaning;
    case "aircon-service":
      return PackageDepartmentEnum.Aircon;
    case "home-beauty":
      return PackageDepartmentEnum.HomeBeauty;
    case "mattress-cleaning":
    case "rug-cleaning":
    case "sofa-cleaning":
    case "pram-seat":
      return PackageDepartmentEnum.CarpetUpholstery; // TODO: will update this again after product aligning service name
    default:
      return PackageDepartmentEnum.HomeCleaning;
  }
}
export function getServiceNameFromDepartment(
  department: PackageDepartmentEnum,
): ServiceName {
  switch (department) {
    case PackageDepartmentEnum.HomeCleaning:
      return "home-cleaning";
    case PackageDepartmentEnum.Aircon:
      return "aircon-service";
    case PackageDepartmentEnum.HomeBeauty:
      return "home-beauty";
    case PackageDepartmentEnum.CarpetUpholstery:
      return "mattress-cleaning";
    default:
      return "home-cleaning";
  }
}

export const getBookingPackages = (
  department: PackageDepartmentEnum,
  packageDetails: PackageDetailFragmentFragment[],
  selectedSlot?: TimeSlot | null,
) => {
  if (department === PackageDepartmentEnum.HomeCleaning) {
    if (selectedSlot) {
      return packageDetails.filter(({ session }) => {
        const sessionSlot =
          selectedSlot.partOfDay === "Evening"
            ? PackageDetailSessionEnum.Evening
            : PackageDetailSessionEnum.Day;

        return session === sessionSlot;
      });
    } else {
      return packageDetails.filter(
        ({ session }) => session === PackageDetailSessionEnum.Day,
      );
    }
  }
  return packageDetails;
};

const DISCOUNT_UPHOLSTERY_TWO_UNITS = 5;
const DISCOUNT_UPHOLSTERY_THREE_UNITS = 10;
const DISCOUNT_UPHOLSTERY_MORE_THAN_THREE_UNITS = 15;

export function calculateServiceDiscount(
  totalUnit: number,
  totalBillingValue: number,
  department: PackageDepartmentEnum,
): { percent: number; total: number } {
  let percentDiscount = 0;
  let totalDiscount = 0;

  if (department === PackageDepartmentEnum.CarpetUpholstery) {
    if (totalUnit > 3) {
      percentDiscount = DISCOUNT_UPHOLSTERY_MORE_THAN_THREE_UNITS;
    } else if (totalUnit === 3) {
      percentDiscount = DISCOUNT_UPHOLSTERY_THREE_UNITS;
    } else if (totalUnit === 2) {
      percentDiscount = DISCOUNT_UPHOLSTERY_TWO_UNITS;
    }

    totalDiscount = (percentDiscount * totalBillingValue) / 100;
  }

  return {
    percent: percentDiscount,
    total: totalDiscount,
  };
}

export const getDepartment = (name: ServiceName) => {
  if (UpholsteryServiceNames.includes(name as UpholsteryServiceName)) {
    return PackageDepartmentEnum.CarpetUpholstery;
  }
  switch (name) {
    case "home-cleaning":
      return PackageDepartmentEnum.HomeCleaning;
    case "aircon-service":
      return PackageDepartmentEnum.Aircon;
    case "home-beauty":
      return PackageDepartmentEnum.HomeBeauty;
    default:
      return PackageDepartmentEnum.HomeCleaning;
  }
};

export function getSelectedPackagesFormIds(
  selectedPackageIds: string[],
  packageDetails: PackageDetailFragmentFragment[],
) {
  return selectedPackageIds.map(
    (id) =>
      packageDetails.find(
        (pkg) => pkg.id === id,
      ) as PackageDetailFragmentFragment,
  );
}

export function getWorkerIdsFromPackages(
  packageDetails: PackageDetailFragmentFragment[],
) {
  return [...new Set(packageDetails.map((pkg) => pkg.workerSkill.id))];
}

// TODO: will delete this function after BE optimize `frequencyOfJob` input;
export const getFrequencyOfJobFromRecurrence = (
  recurrence: PackageRecurrenceEnum,
) => {
  switch (recurrence) {
    case PackageRecurrenceEnum.AdHoc:
    case PackageRecurrenceEnum.Day:
    case PackageRecurrenceEnum.Month:
      return ScheduleJobFrequencyEnum.AdHoc;
    case PackageRecurrenceEnum.Fortnight:
      return ScheduleJobFrequencyEnum.Fortnight;
    case PackageRecurrenceEnum.Week:
      return ScheduleJobFrequencyEnum.Weekly;
    case PackageRecurrenceEnum.Year:
      return ScheduleJobFrequencyEnum.Year;
  }
};

export const getServiceUnit = (name: ServiceName) => {
  switch (name) {
    case "home-cleaning":
      return "hr";
    case "aircon-service":
      return "unit";
    case "mattress-cleaning":
    case "pram-seat":
    case "rug-cleaning":
    case "sofa-cleaning":
      return "item";
    case "home-beauty":
      return "session";
    default:
      return "unit";
  }
};

export const getDepartmentUnit = (department: PackageDepartmentEnum) => {
  switch (department) {
    case PackageDepartmentEnum.HomeCleaning:
      return "hr";
    case PackageDepartmentEnum.Aircon:
      return "unit";
    case PackageDepartmentEnum.CarpetUpholstery:
      return "item";
    case PackageDepartmentEnum.HomeBeauty:
      return "session";
    default:
      return "unit";
  }
};
