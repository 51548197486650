import { View } from "@/components/ui";
import { Column, Container } from "@/components/ui/layout";
import type { ServiceName } from "@/types/service";
import { BookingConfirmationMain } from "./booking-confirmation-main";
import { BookingConfirmationNavigation } from "./booking-confirmation-navigation";
import { getPlatform } from "@/lib/utils";
import { formatDateToComplete } from "@/lib/helpers/date";
import { useForm } from "react-hook-form";
import { useBookingRouterInterceptor } from "@/components/hooks/use-booking-route";
import { useClientValidatePromoCodeStore } from "@/store/booking/clientValidatePromoCode";
import { useAuthState } from "@/store/auth";
import { getBookingPackages } from "@/lib/booking-lib";
import { DiscountTypeEnum } from "@/__generated__/graphql";
import { useBookingOrder } from "@/components/hooks/use-booking-order";
import { useBookingState } from "@/store/booking/useBookingState";
import type { BookingInfoFormData } from "../booking-info/useBookingInfoForm";
import { capitalizeFirstLetter } from "@/lib/helpers/string";
import { showToast } from "@/components/ui/toast/show-toast";
import { WebBackButtonHeader } from "@/components/shared/back-button";

export interface PromoCodeFormData {
  promoCode: string;
}

export const BookingConfirmation = ({ name }: { name: ServiceName }) => {
  useBookingRouterInterceptor();
  const platform = getPlatform();
  const {
    data: {
      selectedSlot,
      packageDetails,
      bookingInfo,
      department,
      promo,
      packageDetailsFilter,
    },
    setBookingState,
  } = useBookingState();
  const promoCodeFormReturn = useForm<PromoCodeFormData>({
    mode: "onChange",
    defaultValues: { promoCode: "" },
  });
  const { setValue, handleSubmit } = promoCodeFormReturn;

  const { userInfo } = useAuthState((state) => state.data);

  const { totalAfterDiscount, serviceDuration } = useBookingOrder();

  const { fetch: getPromo, loading: promoCodeLoading } =
    useClientValidatePromoCodeStore();

  const formatTime = (time: string) => {
    return time.slice(0, 5);
  };

  const onApplyPromoCode = handleSubmit((promoFormData) => {
    if (!selectedSlot) {
      showToast({
        title: "Please select time slot first.",
        type: "info",
      });
      return;
    }

    getPromo({
      requestPayload: {
        input: {
          clientId: userInfo.id,
          lineItemIds: getBookingPackages(
            department,
            packageDetails,
            selectedSlot,
          ).map((pkg) => pkg.id),
          promoCode: promoFormData.promoCode,
        },
      },
    }).then((res) => {
      if (res.error || !res.data) {
        setBookingState({
          promo: null,
        });
        promoCodeFormReturn.setError("promoCode", {
          message: res.error || "Invalid promo code",
        });
        return;
      }
      const data = res.data;
      let computedDiscount = data.discountValue;
      if (data.discountType === DiscountTypeEnum.Percent) {
        computedDiscount = (totalAfterDiscount * data.discountValue) / 100;
      }
      setBookingState({
        promo: {
          appliedPromoCode: promoFormData.promoCode,
          computedDiscount,
        },
      });
    });
  });

  const onDeletePromoCode = () => {
    setValue("promoCode", "");
    setBookingState({ promo: null });
  };

  if (!selectedSlot) return null;

  return (
    <View className="pb-4">
      <Container>
        <Column desktop="8">
          <WebBackButtonHeader title="Confirmation" />
          <BookingConfirmationMain
            promoCodeLoading={promoCodeLoading}
            promoCode={promo?.appliedPromoCode}
            onApplyPromoCode={onApplyPromoCode}
            onDeletePromoCode={onDeletePromoCode}
            promoCodeFormReturn={promoCodeFormReturn}
            serviceDate={formatDateToComplete(new Date(selectedSlot?.startAt))}
            frequencyAndTime={`${capitalizeFirstLetter(
              packageDetailsFilter.repeatEvery?.[0] || "",
            )}
          • ${formatTime(selectedSlot?.startTime)} - ${formatTime(
            selectedSlot?.endTime,
          )} • ${serviceDuration}`}
            {...(bookingInfo as BookingInfoFormData)}
          />
        </Column>

        {platform === "web" && (
          <Column desktop="4">
            <View className="pt-6 md:sticky md:top-14">
              <BookingConfirmationNavigation name={name} />
            </View>
          </Column>
        )}
      </Container>
    </View>
  );
};
