import { createRequestFactory } from "@/lib/request-factory";
import type { Contact } from "@/__generated__/graphql";
import {
  CreateContactDocument,
  type CreateContactMutation,
  type CreateContactMutationVariables,
} from "@/__generated__/graphql";
import { useAuthState } from "@/store/auth";

type Response = {
  contact: Contact;
};

export const useCreateContactStore = createRequestFactory<
  CreateContactMutation,
  Response,
  CreateContactMutationVariables
>({
  method: "mutation",
  graphqlDocument: CreateContactDocument,
  transformFunction: (res) => {
    if (res.createContact?.contact) {
      return { contact: res.createContact.contact } as Response;
    }
    throw new Error("Create contact failed");
  },
  onFetchSuccess: (data) => {
    const {
      setUserInfo,
      data: { userInfo },
    } = useAuthState.getState();

    const newContact = data.contact;
    const existingContacts = userInfo.contacts || [];
    const updatedContacts = [...existingContacts, newContact];
    setUserInfo({ contacts: updatedContacts });
  },
});
