import { View } from "@/components/ui";
import { useAuthState } from "@/store/auth";
import { AccountInfoCard } from "@/components/shared/account-info-card";
import { NotePencil } from "@/components/shared/icons";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import { AddressMoreActions } from "./address-more-actions";
import { useClientAddressesStore } from "@/store/profile/clientAddresses";
import { useEffect } from "react";
import { Spin } from "@/components/shared/spin";
import { Typography } from "@/components/shared/typography";
import { useRoute } from "@/components/shared/router";

const AddressActions = ({ primary, id }: { primary: boolean; id: string }) => {
  const { push } = useRoute();

  const handleClick = () => {
    push({
      pageKey: "address",
      params: {
        id,
      },
    });
  };
  return (
    <View className="flex w-full flex-1 flex-row items-center gap-2">
      <View
        className="flex w-full flex-1 cursor-pointer flex-row items-center justify-center gap-1 rounded-full border border-primary-border p-2"
        onClick={handleClick}
      >
        <NotePencil className="size-5 text-primary" size={20} />
        <Typography variant="label-md" color="brand-primary">
          Edit Address
        </Typography>
      </View>

      {!primary && <AddressMoreActions addressId={id} />}
    </View>
  );
};

export const AddressListContainer = () => {
  const userInfo = useAuthState((state) => state.data.userInfo);
  const { fetch, data, loading } = useClientAddressesStore();
  const { isMobile } = useWindowDimensions();

  useEffect(() => {
    fetch({
      requestPayload: {
        filters: {
          clientId: userInfo.id,
        },
      },
    });
  }, [userInfo.addresses]);

  const addresses = data?.clientAddresses;

  //TODO: report to sentry if address null
  if (!addresses) return null;

  //TODO: use sort from BE, need to create a FS task to update this to align
  const sortedAddress = [...addresses].sort((a, b) => {
    return Number(b.primary) - Number(a.primary);
  });

  return (
    <View className="flex flex-col gap-4 px-4 py-4 pb-12 md:px-0 md:pt-0">
      {loading ? (
        <Spin />
      ) : (
        sortedAddress.map((item) => (
          <AccountInfoCard
            key={item.id}
            primary={item.primary}
            header={item.postalCode ?? ""}
            bodyText={item.fullAddress ?? ""}
            subBodyText={item.unitNumber ?? ""}
            actions={<AddressActions primary={item.primary} id={item.id} />}
            direction={isMobile ? "col" : "row"}
          />
        ))
      )}
    </View>
  );
};
