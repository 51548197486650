import { SALESPERSON_COOKIE_KEY, SALESPERSON_PARAM_KEY } from "@/constants";
import { getCookie, setCookie } from "@/lib/cookie";

export const getSalesPersonData = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const salesParams = urlParams.get(SALESPERSON_PARAM_KEY);

  if (salesParams) {
    return setCookie(SALESPERSON_COOKIE_KEY, salesParams);
  }

  const salesPersonShortCode = getCookie(SALESPERSON_COOKIE_KEY);

  if (salesPersonShortCode) {
    return salesPersonShortCode;
  }

  return null;
};
