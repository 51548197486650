import { STORAGE_PREFIX } from "@/constants";
import { pageConfig } from "@/components/shared/router/config";
import type { PushParams } from "@/types/global/route";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { app } from "@/config/env";

export const isLocal = app.ENV === "local";
export const isStaging = app.ENV === "staging";
export const isProduction = app.ENV === "production";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getPlatform() {
  if (window && window.document) {
    return "web";
  }

  return "native";
}

export const breakpoint = 800;

export function chunkBySize<T>(items: T[], size = 2) {
  return items.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / size);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, [] as T[][]);
}

export const isBetween = (value: number, floor: number, ceil: number) =>
  value >= floor && value <= ceil;

export const getStorageKey = (key: string) => {
  return `${STORAGE_PREFIX}_${key}`;
};

export const getTitleCase = (word: string): string => {
  return word
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

export const singaporeTaxCalculation = (
  price: number,
  taxOnly?: boolean,
): number => {
  const sgTax = 0.09;
  if (taxOnly) {
    return price * sgTax;
  }
  return price * sgTax + price;
};

export const getCapitalizeWord = (string: string): string => {
  return string.toLowerCase().replace(/\b[a-z]/g, function (letter) {
    return letter.toUpperCase();
  });
};

export const getRoutePath = (options: PushParams) => {
  const { pageKey, params, query } = options;
  const platform = getPlatform();
  let path = pageConfig[pageKey][platform] as string;

  if (params) {
    Object.keys(params).forEach((key) => {
      path = path.replace(`:${key}`, params[key].toString());
    });
  }

  if (query) {
    const queryString = Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join("&");
    path = `${path}?${queryString}`;
  }
  return path;
};

export const offsetCountPagination = (
  page: number,
  rowsPerPage: number,
): { offset: number; limit: number } => {
  return {
    offset: page > 0 ? page * rowsPerPage : 0,
    limit: rowsPerPage,
  };
};

export function safeGet<T, K extends keyof T>(
  obj: T,
  path: string | K[],
  defaultValue: T[K] | null | undefined = null,
): T[K] | null | undefined {
  const parts: K[] = Array.isArray(path) ? path : (path.split(".") as K[]);

  let result: unknown = obj;

  for (const part of parts) {
    if (result == null || typeof result !== "object" || !(part in result)) {
      return defaultValue;
    }
    result = (result as T)[part];
  }

  return result !== undefined ? (result as T[K]) : defaultValue;
}

export function uniqueById<T extends { id: string | number }>(items: T[]): T[] {
  const mergedItemsMap = new Map<string | number, T>();

  for (const item of items) {
    if (mergedItemsMap.has(item.id)) {
      const existingItem = mergedItemsMap.get(item.id);
      mergedItemsMap.set(item.id, { ...existingItem, ...item });
    } else {
      mergedItemsMap.set(item.id, item);
    }
  }

  return Array.from(mergedItemsMap.values());
}

export const formatDecimalHour = (decimalHours: number) => {
  const hours = Math.floor(decimalHours);
  const minutes = Math.round((decimalHours - hours) * 60);

  if (hours > 0 && minutes > 0) {
    return `${hours}h ${minutes}m`;
  } else if (hours > 0) {
    return `${hours}h`;
  } else if (minutes > 0) {
    return `${minutes}m`;
  } else {
    return "0h";
  }
};

export const debounce = <T extends (...args: unknown[]) => void>(
  func: T,
  delay: number,
) => {
  let timeout: NodeJS.Timeout | null = null;

  return (...args: Parameters<T>) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};
