import { app } from "@/config/env";
import { isLocal } from "@/lib/utils";
import { hotjar } from "react-hotjar";

export function initHotjar() {
  if (isLocal) {
    return;
  }
  if (app.HOTJAR_ID) {
    hotjar.initialize({ id: app.HOTJAR_ID, sv: 6 });
  }
}

export { hotjar };
