// import { CollapsibleScrollCard } from "@/components/shared/collapsible-scroll-card";
import { ServiceIcon } from "@/components/shared/service-icon";
import { SquaresFour } from "@/components/shared/icons";
import { Card, CardContent } from "@/components/ui/card";
import { ListHeading } from "@/components/shared/list-header";
import { Button, View } from "@/components/ui";
import { AllServicesModal } from "../all-services";
import { useState } from "react";
import { otherServiceItems } from "../lib";
import { useIntl } from "react-intl";

export function OtherServices() {
  const intl = useIntl();
  const serviceItems = otherServiceItems(intl); // Use the imported function

  // const renderItem = (item: (typeof serviceItems)[0]) => (
  //   <ServiceIcon key={item.name} {...item} />
  // );
  const [modalOpen, setModalOpen] = useState(false);
  function handleOpenChange(newState: boolean) {
    setModalOpen(newState);
  }
  return (
    <Card border="none" shadow="sm">
      <CardContent className="flex flex-col gap-4 p-4">
        <ListHeading
          title={intl.formatMessage({
            defaultMessage: "Other Services",
            id: "other-services",
          })}
          icon={SquaresFour}
          action={
            <View className="block md:hidden">
              <Button
                variant="tertiary"
                color="CTA2"
                onClick={() => {
                  setModalOpen(true);
                }}
                children={intl.formatMessage({
                  defaultMessage: "View all",
                  id: "view-all",
                })}
              />
            </View>
          }
        />

        <View className="native:flex native:flex-row native:justify-between grid grid-flow-col grid-cols-4 gap-2">
          {serviceItems.map((service) => (
            <ServiceIcon
              icon={service.icon}
              title={service.title}
              name={service.name}
              key={service.name}
            />
          ))}
        </View>

        <AllServicesModal
          modalOpen={modalOpen}
          handleOpenChange={handleOpenChange}
        />
        {/* TODO: enable this again when we have more than 4 other services */}
        {/* <CollapsibleScrollCard
          items={serviceItems}
          renderItem={renderItem}
          column={4}
          row={2}
        /> */}
      </CardContent>
    </Card>
  );
}
