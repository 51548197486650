import { useEffect, useRef, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AppNavbar } from "@/components/shared/app-navbar/index.web";
import { HomepagePage } from "@/app-web/pages/homepage";
import { ProfilePage } from "@/app-web/pages/profile";
import { ServiceDetailPage } from "@/app-web/pages/service-detail";
import { Overview } from "@/containers/profile/overview";
import { AccountInfo } from "@/containers/profile/account-info";
import { Payment } from "@/containers/profile/payment";
import { ROUTES } from "@/constants/routes";
import { BookingPage } from "@/app-web/pages/booking-page";
import { PrivateRoute } from "@/components/shared/router/private-route";
import { useLanguageStore } from "@/store/language";
import { VisitsPage } from "./pages/visits";
import { VisitsUpcoming } from "@/containers/visits/upcoming";
import { VisitsHistory } from "@/containers/visits/history";
import { RewardsPage } from "./pages/rewards";
import { PromosPage } from "@/containers/rewards/promos";
import { YourRewards } from "@/containers/rewards/your-rewards";
import { AddressesPage } from "@/containers/profile/account-address/addresses-page";
import { CreateAddress } from "@/containers/profile/account-address/create-address";
import { UpdateAddress } from "@/containers/profile/account-address/update-address";
import { ContactsPage } from "@/containers/profile/account-contact/contacts-page";
import { CreateContact } from "@/containers/profile/account-contact/create-contact";
import { UpdateContact } from "@/containers/profile/account-contact/update-contact";
// import { NotificationListPage } from "./pages/notifications/list";
// import { NotificationDetailPage } from "./pages/notifications/detail";
import { Packages } from "@/containers/profile/packages";
import { PackageDetail } from "@/containers/profile/package-detail";
import { BookingInfoPage } from "./pages/booking-info-page";
import { BookingConfirmationPage } from "./pages/booking-confirmation-page";
import { BookingCompletePage } from "./pages/booking-complete-page";
import { SelectSlotPage } from "./pages/select-slot-page";
import { RateVisit } from "./pages/rate-visit";
import { ManageVisit } from "./pages/manage-visit";
import { LoginPageWrapper } from "@/containers/auth/login/login-page-wrapper";
import { SignUpPageWrapper } from "@/containers/auth/signup/sign-up-page-wrapper";
import { NotFoundPage } from "@/app-web/pages/not-found-page";
import { SelectServicePage } from "@/app-web/pages/select-service-page";
import useDocumentTitle from "@/components/hooks/use-document-title";
import { usePathname } from "@/components/hooks/use-pathname";
import { CreditAccount } from "@/containers/profile/credit-account";
import { RescheduleVisitPage } from "./pages/reschedule-visit";
import { InvoicePage } from "./pages/profile/invoice";
import { InvoiceFilterStatusEnum } from "@/types/invoice";
import { InvoiceList } from "@/containers/profile/invoices/invoice-list";
import { InvoiceDetailPage } from "./pages/profile/invoice-detail";
import { MyVouchers } from "@/containers/profile/my-vouchers";
import { IntlProvider } from "react-intl";
import zh from "@/assets/compiled-lang/zh-CN.json";
import en from "@/assets/compiled-lang/en.json";
import { Toaster } from "@/components/ui/toast/toaster";
import { AuthContainer } from "@/containers/auth";
import { LogoutDialog } from "@/components/shared/logout-dialog";
import { ApolloProvider } from "@apollo/client";
import { ClientInstance } from "@/lib/apollo";
import { FeedBackOverlay } from "@/components/shared/feedback-banner";
import { getIdentifiersFromStorage, storeIdentifiers } from "@/lib/identifiers";
import { rudderanalytics } from "@/lib/rudderstack";
import { getServiceLabel } from "@/lib/service/helpers";
import { type ServiceName } from "@/types/service";
import { getSalesPersonData } from "@/lib/get-sales-person-data";
import { ErrorBoundary } from "@sentry/react";
import { ErrorBoundaryPage } from "@/components/shared/error-boundary-page";
import { InvoicePdfPage } from "./pages/profile/invoice-pdf";
import { AuthProvider } from "@/components/shared/auth-provider";
import { getTrackingKey } from "@/lib/monitoring/oci";
import { getCookie } from "@/lib/cookie";
import { GA_ID, LAST_GA_ID } from "@/constants";
import { debounce } from "@/lib/utils";

export default function App() {
  const language = useLanguageStore((state) => state.language);
  const lastTrackedPercentage = useRef<number>(0);

  const storeIdentifiersAndTrackEvent = async () => {
    await storeIdentifiers();
    const identifiers = await getIdentifiersFromStorage();
    rudderanalytics.track("page_view", {
      ...identifiers,
    });
  };

  useEffect(() => {
    storeIdentifiersAndTrackEvent();
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollHeight = document.body.scrollHeight;
      const windowHeight = window.innerHeight;
      const scrollPercentage =
        (scrollPosition / (scrollHeight - windowHeight)) * 100;

      if (scrollPercentage >= 90 && lastTrackedPercentage.current < 90) {
        rudderanalytics.track("scroll", {
          scroll_percentage: 90,
        });
        lastTrackedPercentage.current = 90;
      } else if (scrollPercentage >= 75 && lastTrackedPercentage.current < 75) {
        rudderanalytics.track("scroll", {
          scroll_percentage: 75,
        });
        lastTrackedPercentage.current = 75;
      } else if (scrollPercentage >= 50 && lastTrackedPercentage.current < 50) {
        rudderanalytics.track("scroll", {
          scroll_percentage: 50,
        });
        lastTrackedPercentage.current = 50;
      } else if (scrollPercentage >= 25 && lastTrackedPercentage.current < 25) {
        rudderanalytics.track("scroll", {
          scroll_percentage: 25,
        });
        lastTrackedPercentage.current = 25;
      }
    };

    const debounceHandleScroll = debounce(handleScroll, 200);

    window.addEventListener("scroll", debounceHandleScroll);
    return () => {
      window.removeEventListener("scroll", debounceHandleScroll);
    };
  }, []);

  useEffect(() => {
    getSalesPersonData();
    // this timer is to solve: race condition between app render and gaId set into cookie
    setTimeout(() => {
      const lastGaId = getCookie(LAST_GA_ID);
      const gaId = getCookie(GA_ID);
      if (gaId && lastGaId !== gaId) getTrackingKey();
    }, 1000);
  }, []);

  return (
    <ErrorBoundary fallback={ErrorBoundaryPage}>
      <IntlProvider
        messages={language === "zh-CN" ? zh : en}
        defaultLocale="en"
        locale={language}
      >
        <ApolloProvider client={ClientInstance}>
          <AuthProvider>
            <BrowserRouter>
              <DocumentTitle />
              <FeedBackOverlay />
              <AppNavbar />
              <Routes>
                <Route path={ROUTES.Root} element={<HomepagePage />} />
                <Route path={ROUTES.Login} element={<LoginPageWrapper />} />
                <Route path={ROUTES.Signup} element={<SignUpPageWrapper />} />
                <Route
                  path={ROUTES.Profile.Root}
                  element={
                    <PrivateRoute>
                      <ProfilePage />
                    </PrivateRoute>
                  }
                >
                  <Route
                    index
                    element={<Navigate to={ROUTES.Profile.Children.Overview} />}
                  />
                  <Route
                    path={ROUTES.Profile.Children.Overview}
                    element={<Overview />}
                  />
                  <Route
                    path={ROUTES.Profile.Children.AccountInfo}
                    element={<AccountInfo />}
                  />
                  <Route
                    path={ROUTES.Profile.Children.Invoices.Root}
                    element={<InvoicePage />}
                  >
                    <Route
                      index
                      element={
                        <Navigate
                          to={ROUTES.Profile.Children.Invoices.Children.Unpaid}
                        />
                      }
                    />
                    <Route
                      path={ROUTES.Profile.Children.Invoices.Children.Unpaid}
                      element={
                        <InvoiceList type={InvoiceFilterStatusEnum.UNPAID} />
                      }
                    />
                    <Route
                      path={ROUTES.Profile.Children.Invoices.Children.Paid}
                      element={
                        <InvoiceList type={InvoiceFilterStatusEnum.PAID} />
                      }
                    />
                  </Route>
                  <Route
                    path={ROUTES.Profile.Children.Invoices.Detail}
                    element={<InvoiceDetailPage />}
                  />
                  <Route
                    path={ROUTES.Profile.Children.Invoices.Detail}
                    element={<InvoiceDetailPage />}
                  />
                  <Route
                    path={ROUTES.Profile.Children.Payment}
                    element={<Payment />}
                  />

                  <Route
                    path={ROUTES.Profile.Children.CreditAccount}
                    element={<CreditAccount />}
                  />

                  <Route
                    path={ROUTES.Profile.Children.MyVouchers}
                    element={<MyVouchers />}
                  />
                  {/* Packages page related */}
                  <Route
                    path={ROUTES.Profile.Children.Packages}
                    element={<Packages />}
                  />

                  <Route
                    path={ROUTES.Profile.Children.Package}
                    element={<PackageDetail />}
                  />

                  {/* Addresses page related */}
                  <Route
                    path={ROUTES.Profile.Children.Addresses}
                    element={<AddressesPage />}
                  />
                  <Route
                    path={ROUTES.Profile.Children.Address}
                    element={<UpdateAddress />}
                  />

                  <Route
                    path={ROUTES.Profile.Children.NewAddress}
                    element={<CreateAddress />}
                  />

                  {/* Contacts page related */}
                  <Route
                    path={ROUTES.Profile.Children.Contacts}
                    element={<ContactsPage />}
                  />

                  <Route
                    path={ROUTES.Profile.Children.Contact}
                    element={<UpdateContact />}
                  />

                  <Route
                    path={ROUTES.Profile.Children.NewContact}
                    element={<CreateContact />}
                  />
                </Route>

                <Route
                  path={
                    ROUTES.Profile.Root +
                    "/" +
                    ROUTES.Profile.Children.Invoices.InvoicePdf
                  }
                  element={<InvoicePdfPage type="invoice" />}
                />
                <Route
                  path={
                    ROUTES.Profile.Root +
                    "/" +
                    ROUTES.Profile.Children.Invoices.ReceiptPdf
                  }
                  element={<InvoicePdfPage type="receipt" />}
                />

                <Route
                  path={ROUTES.Visits.Root}
                  element={
                    <PrivateRoute>
                      <VisitsPage />
                    </PrivateRoute>
                  }
                >
                  <Route
                    index
                    element={<Navigate to={ROUTES.Visits.Children.Upcoming} />}
                  />
                  <Route
                    path={ROUTES.Visits.Children.Upcoming}
                    element={<VisitsUpcoming />}
                  />
                  <Route
                    path={ROUTES.Visits.Children.History}
                    element={<VisitsHistory />}
                  />
                </Route>
                <Route
                  path={ROUTES.Rewards.Root}
                  element={
                    <PrivateRoute>
                      <RewardsPage />
                    </PrivateRoute>
                  }
                >
                  <Route
                    index
                    element={<Navigate to={ROUTES.Rewards.Children.Promos} />}
                  />
                  <Route
                    path={ROUTES.Rewards.Children.Promos}
                    element={<PromosPage />}
                  />
                  <Route
                    path={ROUTES.Rewards.Children.YourRewards}
                    element={<YourRewards />}
                  />
                </Route>
                <Route
                  path={ROUTES.ServiceDetail}
                  element={<ServiceDetailPage />}
                />
                <Route
                  path={ROUTES.SelectService}
                  element={<SelectServicePage />}
                />
                <Route path={ROUTES.Booking.Root} element={<BookingPage />} />
                <Route
                  path={ROUTES.Booking.SelectSlot}
                  element={<SelectSlotPage />}
                />
                <Route
                  path={ROUTES.Booking.BookingInfo}
                  element={
                    <PrivateRoute>
                      <BookingInfoPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={ROUTES.Booking.Confirmation}
                  element={
                    <PrivateRoute>
                      <BookingConfirmationPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={ROUTES.Booking.CompleteBooking}
                  element={
                    <PrivateRoute>
                      <BookingCompletePage />
                    </PrivateRoute>
                  }
                />
                {/* TODO: Disable notification on first launch */}
                {/* <Route
                    path={ROUTES.Notifications.Root}
                    element={
                      <PrivateRoute>
                        <NotificationListPage />
                      </PrivateRoute>
                    }
                  >
                    <Route
                      path={ROUTES.Notifications.Children.Detail}
                      element={<NotificationDetailPage />}
                    />
                  </Route>
              */}
                <Route path={ROUTES.Visits.Rate} element={<RateVisit />} />
                <Route
                  path={ROUTES.Visits.ManageVisit}
                  element={<ManageVisit />}
                />
                <Route
                  path={ROUTES.Visits.RescheduleVisit}
                  element={<RescheduleVisitPage paramName="id" />}
                />
                <Route
                  path={ROUTES.Visits.AirconReschedule}
                  element={<RescheduleVisitPage paramName="token" />}
                />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
              <Toaster />
              <LogoutDialog />
              <AuthContainer />
            </BrowserRouter>
          </AuthProvider>
        </ApolloProvider>
      </IntlProvider>
    </ErrorBoundary>
  );
}

function DocumentTitle() {
  const pathName = usePathname();

  const [currentTitle, setTitle] = useState("Luce SG");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathName]);

  useEffect(() => {
    let title;
    const routeTitles: Record<string, string> = {
      "/": "Home - Luce SG",
      "/visits/upcoming": "Upcoming Visits - Luce SG",
      "/visits/history": "History - Luce SG",
      "/rewards/promos": "Promos - Luce SG",
      "/rewards/your-rewards": "Your Rewards - Luce SG",
    };
    if (pathName.startsWith("/booking/")) {
      const serviceName = pathName.split("/")[2];
      title = `Booking ${getServiceLabel(serviceName as ServiceName)} - Luce SG`;
    } else if (pathName.startsWith("/service-detail/")) {
      const serviceName = pathName.split("/")[2];
      title = `Service ${getServiceLabel(serviceName as ServiceName)} - Luce SG`;
    } else if (pathName.startsWith("/profile")) {
      title = "Profile - Luce SG";
    } else {
      title = routeTitles[pathName] || "Luce SG";
    }
    setTitle(title);
  }, [pathName]);

  useDocumentTitle(currentTitle);

  return null;
}
