import type { TokenizePaymentMethodFormData } from "@/store/profile/forms/useTokenizePaymentMethodForm";
import { useTokenizePaymentMethodForm } from "@/store/profile/forms/useTokenizePaymentMethodForm";
import { useTokenizePaymentMethodStore } from "@/store/profile/tokenizePaymentMethod";
import { getErrorMessage } from "@/lib/helpers/string";
import { showToast } from "@/components/ui/toast/show-toast";
import { useCreatePaymentMethodStore } from "@/store/profile/createPaymentMethod";
import { useAuthState } from "@/store/auth";
import { useClientStore } from "@/store/auth/client";
import { useDeletePaymentMethodStore } from "@/store/profile/deletePaymentMethod";
import { useUpdateClientPreferencesStore } from "@/store/profile/updateClientPreferences";
import { useState } from "react";
import { getExpirationsRequestInput } from "../utils";
import type { Control } from "react-hook-form";
import type { UserPaymentMethod } from "@/types/users";
import { PaymentScreen } from "./payment-screen";
import { useIntl } from "react-intl";
import { usePayInvoiceStore } from "@/store/invoices/payInvoice";
import { useInvoiceState } from "@/store/invoices";
import { useRoute } from "@/components/shared/router";

export type PaymentScreeProps = {
  handleRemoveConfirmation: () => void;
  loading: boolean;
  onConfirm: () => Promise<void>;
  onDeleteCard: () => Promise<void>;
  onUpdateCard: () => void;
  onConfirmUpdateCard: () => void;
  isUpdateCard: boolean;
  creditCardFormTitle: string;
  control: Control<TokenizePaymentMethodFormData>;
  paymentMethod: UserPaymentMethod | null;
  infoCardDesc: string;
  handleCloseRemoveConfirmation: () => void;
  openRemoveConfirmation: boolean;
  setOpenRemoveConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  onPay: () => Promise<void>;
  creditCardPayment: boolean;
};

export const Payment = () => {
  const { push } = useRoute();
  const intl = useIntl();
  const {
    data: { userInfo },
  } = useAuthState();
  const {
    data: { invoiceId, creditCardScreenModal },
    showPaymentMethodModal,
  } = useInvoiceState();

  const [openRemoveConfirmation, setOpenRemoveConfirmation] =
    useState<boolean>(false);
  const [isUpdateCard, setIsUpdateCard] = useState<boolean>(false);

  const creditCardFormTitle = isUpdateCard
    ? "Update Your Credit Account"
    : "Add Your Credit Account";

  const { fetch: client } = useClientStore();
  const {
    fetch: tokenizePaymentMethod,
    loading: tokenizePaymentMethodLoading,
  } = useTokenizePaymentMethodStore();
  const { fetch: createPaymentMethod, loading: createPaymentMethodLoading } =
    useCreatePaymentMethodStore();
  const { fetch: deletePaymentMethod, loading: deletePaymentMethodLoading } =
    useDeletePaymentMethodStore();
  const {
    fetch: updateClientPreferences,
    loading: updateClientPreferencesLoading,
  } = useUpdateClientPreferencesStore();
  const { fetch: payInvoice, loading: payInvoiceLoading } =
    usePayInvoiceStore();

  const { control, handleSubmit, reset } = useTokenizePaymentMethodForm();

  const paymentMethod = userInfo.paymentMethod;

  const onDeleteCard = async () => {
    if (paymentMethod) {
      try {
        const deleteRes = await deletePaymentMethod(
          {
            requestPayload: {
              input: {
                clientId: userInfo.id,
                paymentMethodId: paymentMethod.id,
              },
            },
          },
          { selfHandleError: true },
        );

        if (deleteRes.error) {
          throw new Error(deleteRes.error);
        }

        const updatePreferences = await updateClientPreferences(
          {
            requestPayload: {
              input: {
                clientId: userInfo.id,
                preferences: {
                  auto_pay: false,
                },
              },
            },
          },
          { selfHandleError: true },
        );

        if (updatePreferences.error) {
          throw new Error(updatePreferences.error);
        }

        setOpenRemoveConfirmation(false);

        client({
          requestPayload: {
            id: userInfo.id,
          },
        });
        reset();
      } catch (error) {
        const errMsg = getErrorMessage(error, "Error deleting payment method");
        showToast({
          title: errMsg,
          type: "error",
        });
      }
    }
  };

  const onConfirm = handleSubmit(
    async (data: TokenizePaymentMethodFormData) => {
      const { expMonth, expYear } = getExpirationsRequestInput(data.expDate);
      try {
        const tokenizeRes = await tokenizePaymentMethod(
          {
            requestPayload: {
              input: {
                name: data.name,
                cvc: data.cvc,
                number: data.number,
                expMonth,
                expYear,
              },
            },
          },
          { selfHandleError: true },
        );

        if (tokenizeRes.error) {
          throw new Error(tokenizeRes.error);
        }

        if (tokenizeRes.data?.token) {
          onDeleteCard();
          try {
            const createPaymentRes = await createPaymentMethod(
              {
                requestPayload: {
                  input: {
                    clientId: userInfo.id,
                    token: tokenizeRes.data.token,
                  },
                },
              },
              { selfHandleError: true },
            );

            client({
              requestPayload: {
                id: userInfo.id,
              },
            });
            setIsUpdateCard(false);

            if (createPaymentRes.error) {
              throw new Error(createPaymentRes.error);
            }
          } catch (createPaymentError) {
            const errMsg = getErrorMessage(
              createPaymentError,
              "Error creating payment method",
            );
            showToast({
              title: errMsg,
              type: "error",
            });
          }
        }
      } catch (error) {
        const errMsg = getErrorMessage(
          error,
          "Error tokenizing payment method",
        );
        showToast({
          title: errMsg,
          type: "error",
        });
      }
    },
  );

  const loading =
    tokenizePaymentMethodLoading ||
    createPaymentMethodLoading ||
    deletePaymentMethodLoading ||
    updateClientPreferencesLoading ||
    payInvoiceLoading;

  const handleRemoveConfirmation = () => {
    setOpenRemoveConfirmation(true);
  };

  const handleCloseRemoveConfirmation = () => {
    setOpenRemoveConfirmation(false);
  };

  const onUpdateCard = () => {
    setIsUpdateCard(!isUpdateCard);
    reset();
  };

  const onConfirmUpdateCard = handleSubmit(
    (data: TokenizePaymentMethodFormData) => {
      if (!data) return;
      onConfirm();
    },
  );

  const infoCardDesc = paymentMethod
    ? intl.formatMessage({
        defaultMessage:
          "We will automatically charge your card on file after the due date of your invoice. A processing fee charged by the credit card processor of 3.4% + 50 cents will be added to the bill. Alternatively, you can manually pay via PayNow directly with the invoice which does not have a processing fee.",
        id: "autoPay.info",
      })
    : intl.formatMessage({
        defaultMessage:
          "Debit and Credit card payments are subject to a 3.4% + $0.50 admin charge by the credit card processor.",
        id: "addPayment.notice",
      });

  const handlePay = async () => {
    await payInvoice(
      { requestPayload: { input: { invoiceId } } },
      { selfHandleError: true },
    );

    const successMsg = `Success pay Invoice #${invoiceId}`;
    showToast({
      title: successMsg,
      type: "success",
    });

    showPaymentMethodModal();
    push({ pageKey: "invoices" });
  };

  return (
    <PaymentScreen
      creditCardFormTitle={creditCardFormTitle}
      handleRemoveConfirmation={handleRemoveConfirmation}
      loading={loading}
      onConfirm={onConfirm}
      onDeleteCard={onDeleteCard}
      onUpdateCard={onUpdateCard}
      onConfirmUpdateCard={onConfirmUpdateCard}
      isUpdateCard={isUpdateCard}
      control={control}
      paymentMethod={paymentMethod}
      infoCardDesc={infoCardDesc}
      handleCloseRemoveConfirmation={handleCloseRemoveConfirmation}
      openRemoveConfirmation={openRemoveConfirmation}
      setOpenRemoveConfirmation={setOpenRemoveConfirmation}
      onPay={handlePay}
      creditCardPayment={creditCardScreenModal}
    />
  );
};
