import { View } from "@/components/ui";
import type { ServiceName } from "@/types/service";
import type { ReactNode } from "react";
import { useRoute } from "@/components/shared/router";
import { bookingRoutes } from "@/components/hooks/use-booking-route";
import { Typography } from "../typography";
// import { sendAnalyticData } from "@/lib/monitoring/analytics";
// import { getServiceDepartments } from "@/lib/booking-lib";

export function ServiceIcon({
  icon,
  title,
  name,
}: {
  icon: ReactNode;
  title: string;
  name: ServiceName;
}) {
  const { push } = useRoute();
  const navigateToBooking = () => {
    // TODO: We will enable this once we have analytics aligned
    // sendAnalyticData({
    //   name: 'view_service',
    //   values: {
    //     service_type: getServiceDepartments(name)
    //   }
    // })
    push(bookingRoutes[name][0]);
  };

  return (
    <View
      className="native:max-w-[77px] flex flex-1 cursor-pointer flex-col items-center gap-1"
      onClick={navigateToBooking}
    >
      <View className="flex h-[56px] w-[56px] items-center justify-center rounded-2xl bg-primary-surface p-2">
        {icon}
      </View>
      <Typography
        align="center"
        variant="body-md"
        className="line-clamp-2"
        numberOfLines={2}
      >
        {title}
      </Typography>
    </View>
  );
}
