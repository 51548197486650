import { View } from "@/components/ui";
import { IconButton } from "@/components/ui/icon-button";

// this component is to solve: dialog only closed when clicking close button. so when clicking the outside wrapper, dialog won't close
export const DialogCloseIcon = ({ onClose }: { onClose(): void }) => {
  return (
    <View
      className="absolute right-4 top-3 z-[1000] cursor-pointer"
      onClick={onClose}
    >
      <IconButton
        variant="ghost"
        color="foreground"
        size="md"
        iconName="x"
        onClick={onClose}
      />
    </View>
  );
};
