import { Column, Container } from "@/components/ui/layout";
import { View } from "@/components/ui";
import type { ServiceName } from "@/types/service";
import { SelectServiceNavigation } from "./select-service-navigation";
import { getPlatform } from "@/lib/utils";
import { SelectAirconService } from "./services/select-aircon-service";
import { WebBackButtonHeader } from "@/components/shared/back-button";
import { ChatWithSales } from "@/components/shared/chat-with-sales";

const SelectBookingService = ({ name }: { name: ServiceName }) => {
  if (name === "aircon-service") return <SelectAirconService />;
  // SelectService page is deprecated, leave for a while make sure it not change back
  // if (name === "home-beauty") return <SelectBeautyService />;
};

export const SelectService = ({ name }: { name: ServiceName }) => {
  const platform = getPlatform();

  return (
    <View className="pb-4">
      <Container>
        <Column desktop="8">
          <WebBackButtonHeader title="Select Service" />
          <SelectBookingService name={name} />
        </Column>

        <Column desktop="4">
          {platform === "web" && (
            <View className="py-4 md:sticky md:top-14">
              <SelectServiceNavigation name={name} />
              <View className="hidden md:block">
                <ChatWithSales />
              </View>
            </View>
          )}
        </Column>
      </Container>
    </View>
  );
};
