import { ListHeading } from "@/components/shared/list-header";
import { View, RadioGroup } from "@/components/ui";
import { Package, Cube } from "@/components/shared/icons";
import { Message } from "@/components/shared/message";
import {
  AirconFrequencyOptions,
  getAirconUnitsFromUnit,
} from "@/constants/booking";
import { ServiceRadioItem } from "@/components/shared/service-radio-item";
import { useBookingState } from "@/store/booking/useBookingState";
import React, { useEffect, useMemo } from "react";
import { useAllClientPackageDetailsStore } from "@/store/booking/allClientPackageDetails";
import type { PackageDetailFragmentFragment } from "@/__generated__/graphql";
import {
  PackageDepartmentEnum,
  PackageRecurrenceEnum,
  PackageUnitTypeEnum,
  PackageServiceTypeEnum,
  ServiceTypeEnum,
} from "@/__generated__/graphql";
import type { AirconFrequencyOption } from "@/components/shared/service-radio-item/types";
import { BookingItemCard } from "@/components/shared/booking/booking-item-card";
import { formatPrice } from "@/lib/helpers/number";
import { Spin } from "@/components/shared/spin";
import { Divider } from "@/components/shared/divider";
import { ExpandableView } from "@/components/shared/expandable-view";
import { Typography } from "@/components/shared/typography";
import { useIntl } from "react-intl";
import { IfElse } from "@/components/shared/if-else";

const ConditionExpandView = ({
  children,
  showExpandView,
}: {
  children: React.ReactElement;
  showExpandView: boolean;
}) => {
  if (showExpandView)
    return (
      <ExpandableView initialHeight={280} className="w-full">
        {children}
        {/* for expanded view */}
        <View className="h-8" />
      </ExpandableView>
    );
  return children;
};

export const AirconBooking = () => {
  const intl = useIntl();
  const {
    data: { packageDetailsFilter, selectedPackageIds },
    setBookingState,
  } = useBookingState();

  const {
    fetch: getPackages,
    data: clientPackages,
    loading,
  } = useAllClientPackageDetailsStore();

  const handleDefaultSelect = () => {
    switch (packageDetailsFilter.serviceTypes?.[0]) {
      case ServiceTypeEnum.GeneralServicing:
      case ServiceTypeEnum.ChemicalWash:
      case ServiceTypeEnum.ChemicalOverhaul:
        {
          const pkg = frequencyFilteredPackages.find(
            (pkg: PackageDetailFragmentFragment) =>
              pkg.units === 3 && pkg.unitType === PackageUnitTypeEnum.Wall,
          );
          if (pkg) {
            setBookingState({
              selectedPackageIds: [pkg.id],
            });
          }
        }
        break;
      case ServiceTypeEnum.CondenserWash:
        break;
    }
  };

  useEffect(() => {
    const {
      repeatEvery: _repeatEvery,
      repeatEveryTimes: _repeatEveryTimes,
      ...filters
    } = packageDetailsFilter;
    void getPackages({
      requestPayload: {
        filters: {
          department: PackageDepartmentEnum.Aircon,
          active: true,
          ...filters,
        },
      },
    });
  }, []);

  const frequencyFilteredPackages = useMemo(() => {
    if (!clientPackages) return [];
    return clientPackages.filter(
      (pkg: PackageDetailFragmentFragment) =>
        pkg.repeatEvery === packageDetailsFilter?.repeatEvery?.[0] &&
        pkg.repeatEveryTimes === packageDetailsFilter?.repeatEveryTimes &&
        // @ts-expect-error PackageServiceTypeEnum and ServiceTypeEnum is equal
        pkg.serviceType === packageDetailsFilter.serviceTypes?.[0],
    );
  }, [
    clientPackages,
    packageDetailsFilter.serviceTypes?.[0],
    packageDetailsFilter.repeatEvery?.[0],
    packageDetailsFilter.repeatEveryTimes,
  ]);

  useEffect(() => {
    if (!selectedPackageIds.length) {
      handleDefaultSelect();
    } else {
      // find current selected unit value, change the frequency;
      const newSelectedIds = selectedPackageIds
        .map((id) => {
          const curPkg = clientPackages?.find((pkg) => pkg.id === id);
          return {
            units: curPkg?.units,
            unitType: curPkg?.unitType,
          };
        })
        .map(
          ({ units, unitType }) =>
            frequencyFilteredPackages.find(
              (pkg) => pkg.unitType === unitType && pkg.units === units,
            )?.id || "",
        )
        .filter((id) => !!id);
      setBookingState({
        selectedPackageIds: newSelectedIds,
      });
    }
  }, [frequencyFilteredPackages]);

  const airconUnits: {
    unit: PackageUnitTypeEnum;
    title: string;
    sortOrder: number;
    img: string;
    maxItem: number;
  }[] = useMemo(() => {
    if (!clientPackages) return [];
    return [
      ...new Set(
        clientPackages.map(
          (pkg: PackageDetailFragmentFragment) => pkg.unitType,
        ),
      ),
    ]
      .map((unit) => {
        return {
          unit,
          ...getAirconUnitsFromUnit(
            packageDetailsFilter.serviceTypes?.[0],
            unit,
          ),
        };
      })
      .filter((a) => !!a.title)
      .sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0));
  }, [frequencyFilteredPackages]);

  const onSelectFrequency = (option: AirconFrequencyOption) => {
    setBookingState({
      packageDetailsFilter: {
        ...packageDetailsFilter,
        repeatEvery: [option.repeatEvery],
        repeatEveryTimes: option.repeatEveryTimes,
      },
    });
  };

  const packageFrequencyKey = useMemo(() => {
    if (!packageDetailsFilter.repeatEvery?.[0]) {
      return PackageRecurrenceEnum.Year + 4;
    }
    return (
      packageDetailsFilter.repeatEvery[0] +
      packageDetailsFilter.repeatEveryTimes
    );
  }, [
    packageDetailsFilter.repeatEvery?.[0],
    packageDetailsFilter.repeatEveryTimes,
  ]);

  const selectedPackages: PackageDetailFragmentFragment[] = useMemo(() => {
    return selectedPackageIds.map((id: string) =>
      frequencyFilteredPackages.find(
        (pkg: PackageDetailFragmentFragment) => pkg.id === id,
      ),
    ) as PackageDetailFragmentFragment[];
  }, [clientPackages, selectedPackageIds, selectedPackageIds?.length]);

  const onChangeUnit = (
    unit: PackageUnitTypeEnum,
    value: number,
    currentId?: string,
  ) => {
    const newPkg = frequencyFilteredPackages.find(
      (pkg: PackageDetailFragmentFragment) =>
        pkg.units === value && pkg.unitType === unit,
    );
    const oldPkgIdsWithoutCurrentId = selectedPackageIds.filter(
      (id: string) => id !== currentId,
    );
    setBookingState({
      selectedPackageIds: newPkg
        ? [...oldPkgIdsWithoutCurrentId, newPkg.id]
        : oldPkgIdsWithoutCurrentId,
    });
  };

  const isOnlyAdhocService = useMemo(() => {
    return Boolean(
      packageDetailsFilter?.serviceTypes &&
        [
          PackageServiceTypeEnum.ChemicalWash,
          PackageServiceTypeEnum.ChemicalOverhaul,
          PackageServiceTypeEnum.RepairDiagnostic,
        ].includes(
          packageDetailsFilter
            .serviceTypes[0] as unknown as PackageServiceTypeEnum,
        ),
    );
  }, [packageDetailsFilter?.serviceTypes?.[0]]);

  const listHeadingTitle = useMemo(() => {
    if (packageDetailsFilter?.serviceTypes?.[0] === ServiceTypeEnum.GasTopUp) {
      return "Add Compressor";
    }
    return "Choose Aircon Units";
  }, [packageDetailsFilter?.serviceTypes?.[0]]);

  return (
    <View className="flex flex-col gap-8">
      <View className="mx-4 flex flex-col">
        <ListHeading title={listHeadingTitle} icon={Cube} />
        <IfElse
          if={
            (packageDetailsFilter.serviceTypes?.[0] as ServiceTypeEnum) !==
            ServiceTypeEnum.GasTopUp
          }
        >
          <Message
            variant="info"
            className="my-4 mb-6"
            message={intl.formatMessage({
              defaultMessage: "Add more units for a lower unit cost!",
              id: "airconBooking.addUnit",
            })}
          />
        </IfElse>

        <Typography variant={{ md: "label-xl", sm: "label-lg" }}>
          Aircon Units
        </Typography>
        {loading ? (
          <Spin className="my-12 size-10" />
        ) : (
          <ConditionExpandView
            showExpandView={
              (packageDetailsFilter.serviceTypes?.[0] as ServiceTypeEnum) ===
              ServiceTypeEnum.GeneralServicing
            }
            children={
              <>
                {airconUnits.map((unitInfo) => {
                  const currentPkg = selectedPackages?.find(
                    (pkg) => pkg?.unitType === unitInfo.unit,
                  );
                  const currentPkgId = currentPkg?.id;
                  const currentValue = currentPkg?.units || 0;
                  const oneUnitPkg = frequencyFilteredPackages.find(
                    (pkg: PackageDetailFragmentFragment) =>
                      pkg.units === 1 && pkg.unitType === unitInfo.unit,
                  );
                  return (
                    <View className="ml-2">
                      <BookingItemCard
                        title={unitInfo.title || ""}
                        description={
                          currentPkg?.unitValue
                            ? `${formatPrice(currentPkg.unitValue)}/unit`
                            : `${formatPrice(oneUnitPkg?.unitValue || 0)}/unit`
                        }
                        selected={!!currentPkg?.units && currentPkg?.units > 0}
                        key={unitInfo.unit}
                        img={unitInfo.img || ""}
                        value={currentPkg?.units || 0}
                        max={unitInfo?.maxItem}
                        onAdd={() =>
                          onChangeUnit(
                            unitInfo.unit,
                            currentValue + 1,
                            currentPkgId,
                          )
                        }
                        onMinus={() =>
                          onChangeUnit(
                            unitInfo.unit,
                            currentValue - 1,
                            currentPkgId,
                          )
                        }
                      />
                    </View>
                  );
                })}
              </>
            }
          />
        )}
      </View>

      <IfElse
        if={
          (packageDetailsFilter.serviceTypes?.[0] as ServiceTypeEnum) ===
          ServiceTypeEnum.GasTopUp
        }
      >
        <View>
          <Divider className="my-2 border-b-2" />
          <View className="mx-4 rounded-lg bg-primary-surface p-3">
            <Typography variant="label-lg" color="brand-primary">
              Compressor Count Guidance
            </Typography>
            <Typography variant="body-md" color="info">
              Most homes typically have 1 to 2 compressors. If you're unsure,
              you can select 1 unit, and our technician will advise you on-site
              if you have more compressors.
            </Typography>
          </View>
        </View>
      </IfElse>

      <IfElse
        if={
          ![
            ServiceTypeEnum.ChemicalWash,
            ServiceTypeEnum.ChemicalOverhaul,
            ServiceTypeEnum.CondenserWash,
            ServiceTypeEnum.GasTopUp,
          ].includes(packageDetailsFilter.serviceTypes?.[0] as ServiceTypeEnum)
        }
      >
        <>
          <Divider className="border-b-2" />

          <View className="mx-4 flex flex-col">
            <ListHeading title="Make it a package" icon={Package} />
            <Typography
              variant="body-lg"
              color="foreground-intermediate"
              className="mb-6"
            >
              Have greater savings & don’t worry, the full package value is
              billed after the first visit.
            </Typography>
            <RadioGroup value={packageFrequencyKey} onValueChange={() => {}}>
              {AirconFrequencyOptions.map((item) => {
                return (
                  <ServiceRadioItem
                    disabled={
                      isOnlyAdhocService &&
                      item.repeatEvery !== PackageRecurrenceEnum.AdHoc
                    }
                    subTitle={item.subTitle}
                    key={item.title}
                    slogan={item.slogan}
                    title={item.title}
                    selected={
                      packageFrequencyKey ===
                      item.repeatEvery + item.repeatEveryTimes
                    }
                    value={item.repeatEvery + item.repeatEveryTimes}
                    onSelect={() => onSelectFrequency(item)}
                  />
                );
              })}
            </RadioGroup>
          </View>

          <Divider className="border-b-2" />
        </>
      </IfElse>
    </View>
  );
};
