import type {
  PackageDepartmentEnum,
  VisitClientFragmentFragment,
} from "@/__generated__/graphql";
import {
  PackageDetailFragmentFragmentDoc,
  PackageServiceTypeEnum,
  VisitStatusEnum,
} from "@/__generated__/graphql";
import { Button, Skeleton, View } from "@/components/ui";
import { getWorkerLabel, packageServiceTypeLabel } from "@/lib/service/helpers";
import { VisitStatusBadge } from "../visit-card/visit-status-badge";
import {
  formatVisitAddress,
  formatVisitSchedule,
  visitWorkerFullName,
} from "../utils";
import type { VisitWorker } from "../visit-card";
import { Divider } from "../../divider";
import { WorkerAvatar } from "../visit-card/worker-avatar";
import { Receipt } from "@/components/shared/icons";
import { handleWhatsappNumber } from "@/lib/platform";
import { ListHeading } from "../../list-header";
import { LineItemList } from "../../line-item/line-item-list";
import type { LineItemDetail } from "@/types/line-item";
import { getLineItemClientDetail } from "@/lib/line-items/helpers";
import { useFragment } from "@/__generated__";
import {
  formatPrice,
  SINGAPORE_TAX,
  singaporeTaxCalculation,
} from "@/lib/helpers/number";
import { useMemo } from "react";
import { VisitRating } from "../visit-card/visit-rating";
import { Typography } from "../../typography";
import { formatDecimalHour } from "@/lib/utils";

export type VisitDetailData = {
  id: string;
  department: PackageDepartmentEnum;
  serviceType: PackageServiceTypeEnum;
  status: VisitStatusEnum;
  value: number;
  serviceDate: Date;
  startTime: string;
  endTime: string;
  address: string;
  postalCode: string;
  worker: VisitWorker;
  rating: number;
  ratingComment: string;
  reviewImageUrls: string[];
  lineItems: LineItemDetail[];
  token: string | null;
  reportDate: Date | null;
};

export type VisitDetailHandler = {
  handleReschedule(id: string): void;
  handleChangeWorker(id: string): void;
  handleReportVisit(id: string): void;
  handleSkipVisit(id: string): void;
  handleRateVisit(id: string, rate?: number): void;
};

type Props = {
  visitData: VisitDetailData;
  loading: boolean;
} & VisitDetailHandler;

export function mapToVisitDetailData(
  visit: VisitClientFragmentFragment,
  token: string | null = null,
): VisitDetailData {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const pkg = visit.job!.package;

  let value = 0;

  const lineItems = visit.visitLineItems.map((item) => {
    value += item.billingValue;
    return getLineItemClientDetail(
      useFragment(PackageDetailFragmentFragmentDoc, item.packageDetail),
      {
        billingValue: item.billingValue,
        unitValue: item.unitValue,
        description: item.packageDescription,
        unitNumber: item.units,
      },
    );
  });

  return {
    id: visit.id,
    department: pkg.department,
    serviceType: pkg.serviceType,
    status: visit.status,
    value,
    serviceDate: new Date(visit.serviceDate),
    startTime: String(visit.startTime),
    endTime: String(visit.endTime),
    address: formatVisitAddress(
      pkg.fullAddress,
      pkg.unitNumber ?? "",
      pkg.postalCode,
    ),
    postalCode: pkg.postalCode,
    worker: {
      fullName: visitWorkerFullName(visit.status, visit.worker),
      phoneNumber: visit.worker?.contactNumber ?? null,
      avatarUrl: visit.worker?.avatarUrl ?? null,
    },
    rating: visit.rating ?? 0,
    ratingComment: visit.ratingComment ?? "",
    reviewImageUrls:
      visit.reviewAttachments?.map((attachment) => attachment.downloadUrl) ??
      [],
    reportDate: visit.reportDateIssue ? new Date(visit.reportDateIssue) : null,
    lineItems,
    token,
  };
}

export function VisitDetailContent({
  visitData,
  loading,
  handleReschedule,
  handleChangeWorker,
  handleReportVisit,
  handleSkipVisit,
  handleRateVisit,
}: Props) {
  const handleContactProfessional = () => {
    if (visitData.worker.phoneNumber) {
      handleWhatsappNumber(visitData.worker.phoneNumber);
    }
  };

  const lineItems = useMemo(
    () =>
      visitData.lineItems.filter(
        (item) => item.serviceType !== PackageServiceTypeEnum.Discount,
      ),
    [visitData.lineItems],
  );

  const serviceDuration = useMemo(() => {
    const { totalDuration, totalUnit } = visitData.lineItems.reduce(
      (totals, item) => {
        totals.totalDuration += item.duration;
        totals.totalUnit += item.unitsNumber;

        return totals;
      },
      { totalDuration: 0, totalUnit: 0 },
    );
    return `${formatDecimalHour(totalDuration)} (${totalUnit} Items)`;
  }, [visitData.lineItems]);

  const discounts = visitData.lineItems.filter(
    (item) => item.serviceType === PackageServiceTypeEnum.Discount,
  );

  const price = singaporeTaxCalculation(visitData.value);

  const renderActionButton = useMemo(() => {
    const buttons = [];

    const commonReportButton = (
      <Button
        className="flex-1 px-0"
        variant="link"
        key="report-issue"
        iconName="warningCircle"
        onClick={() => handleReportVisit(visitData.id)}
        children="Report Issue"
        iconAlignment="top"
        fullWidth="full"
      />
    );

    if (visitData.status === VisitStatusEnum.Completed) {
      buttons.push(
        commonReportButton,
        <View className="grow-[3] border-l border-border-medium" key="rating">
          <VisitRating
            variant="visitDetail"
            rating={visitData.rating}
            onClick={(rate) => handleRateVisit(visitData.id, rate)}
          />
        </View>,
      );
    } else if (visitData.status === VisitStatusEnum.PendingClientSchedule) {
      buttons.push(
        <Button
          className="flex-1 px-0"
          variant="link"
          key="reschedule"
          onClick={() => handleReschedule(visitData.id)}
          disabled={loading}
          iconName="calendarDots"
          children="Reschedule"
          iconAlignment="top"
          fullWidth="full"
        />,
        commonReportButton,
      );
    } else if (visitData.status === VisitStatusEnum.Scheduled) {
      buttons.push(
        <Button
          className="flex-1 px-0"
          variant="link"
          key="reschedule"
          onClick={() => handleReschedule(visitData.id)}
          disabled={loading}
          iconName="calendarDots"
          children="Reschedule"
          iconAlignment="top"
          fullWidth="full"
        />,
        <Button
          className="flex-1 px-0"
          variant="link"
          key="changeWorker"
          onClick={() => handleChangeWorker(visitData.id)}
          disabled={loading}
          iconName="userSwitch"
          children={`Change ${getWorkerLabel(visitData.department)}`}
          iconAlignment="top"
          fullWidth="full"
        />,
        commonReportButton,
        <Button
          className="flex-1 px-0"
          variant="link"
          key="skipVisit"
          onClick={() => handleSkipVisit(visitData.id)}
          disabled={loading}
          iconName="xCircle"
          iconColor="danger"
          color="danger"
          children="Skip Visit"
          iconAlignment="top"
          fullWidth="full"
        />,
      );
    } else {
      buttons.push(commonReportButton);
    }

    return buttons;
  }, [visitData, loading]);

  return (
    <View className="flex flex-col gap-4">
      <View className="flex flex-col gap-1">
        <View className="flex flex-row items-center justify-between">
          <Typography variant="label-2xl">
            {loading ? (
              <Skeleton className="h-4 w-[200px]" />
            ) : (
              packageServiceTypeLabel(
                visitData.serviceType,
                visitData.department,
              )
            )}
          </Typography>
          <VisitStatusBadge status={visitData.status} />
        </View>
        {loading ? (
          <Skeleton className="h-2 w-full" />
        ) : (
          <Typography variant="body-md">
            {formatVisitSchedule(
              visitData.startTime,
              visitData.endTime,
              visitData.serviceDate,
            )}
          </Typography>
        )}

        {loading ? (
          <Skeleton className="h-2 w-full" />
        ) : (
          <Typography variant="body-md" color="foreground-intermediate">
            {visitData.address}
          </Typography>
        )}
      </View>
      <Divider />
      <View className="flex flex-row items-center justify-between">
        <View className="flex-1 px-0">
          <WorkerAvatar
            fullName={visitData.worker.fullName}
            avatarUrl={visitData.worker.avatarUrl}
            loading={loading}
          />
        </View>
        {visitData.status === VisitStatusEnum.Scheduled &&
          visitData.worker.phoneNumber && (
            <Button
              variant="secondary"
              color="success"
              fullWidth="full"
              onClick={handleContactProfessional}
              iconName="whatsappLogo"
              iconColor="fill-success"
              children="Contact Professional"
            />
          )}
      </View>
      <Divider dashed />
      <View className="flex flex-row justify-around gap-2">
        {renderActionButton}
      </View>
      <Divider />
      <View className="flex flex-col gap-1">
        <ListHeading icon={Receipt} title="Your Order" />
        {loading ? (
          <Skeleton className="h-10 w-full" />
        ) : (
          <LineItemList lineItems={lineItems} />
        )}
      </View>
      <Divider />
      <View className="flex flex-col gap-1">
        <ListItem label="Service Duration/Visit" value={serviceDuration} />
        <ListItem label="Total Cost" value={formatPrice(visitData.value, 0)} />
        {discounts.map((discount, index) => (
          <ListItem
            key={discount.packageDetailId + index}
            label={discount.description}
            value={formatPrice(discount.billingValue)}
          />
        ))}
        <ListItem
          label={`GST (${SINGAPORE_TAX * 100}%)`}
          value={formatPrice(price.tax)}
        />
      </View>
      <Divider />
      <View className="flex flex-row gap-1">
        <Typography variant="label-2xl" className="flex-1 px-0">
          Total
        </Typography>
        <Typography className="font-medium color-brand-primary">
          {formatPrice(price.price)}{" "}
          <Typography
            variant="body-md"
            color="foreground-intermediate"
            as="span"
          >
            incl. GST
          </Typography>
        </Typography>
      </View>
    </View>
  );
}

export function ListItem({ label, value }: { label: string; value: string }) {
  return (
    <View className="flex flex-row gap-1">
      <Typography
        variant="body-md"
        color="foreground-intermediate"
        className="flex-1 px-0"
      >
        {label}
      </Typography>
      <Typography variant="label-lg">{value}</Typography>
    </View>
  );
}
