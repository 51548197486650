import type {
  GenerateInvoicePdfMutation,
  GenerateInvoicePdfMutationVariables,
} from "@/__generated__/graphql";
import { GenerateInvoicePdfDocument } from "@/__generated__/graphql";
import { generatePDFUrl } from "@/components/shared/pdf-viewer";

import { createRequestFactory } from "@/lib/request-factory";

export const useGenerateInvoicePdfStore = createRequestFactory<
  GenerateInvoicePdfMutation,
  string,
  GenerateInvoicePdfMutationVariables
>({
  method: "mutation",
  graphqlDocument: GenerateInvoicePdfDocument,
  transformFunction(data) {
    if (data.generateInvoicePdf?.fileString) {
      return generatePDFUrl(data.generateInvoicePdf.fileString);
    }
    throw new Error("Unable to generate PDF");
  },
});
