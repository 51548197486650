import { Card, CardContent, View } from "@/components/ui";
import { Wallet, CaretRight, SealPercent } from "@/components/shared/icons";
import { useRoute } from "@/components/shared/router";
import { useAuthState } from "@/store/auth";
import { formatPrice } from "@/lib/helpers/number";
import { generalCreditAccount } from "../utils";
import { Typography } from "@/components/shared/typography";
import { useIntl } from "react-intl";
import { useAvailableClientVouchersStore } from "@/store/invoices/useAvailableClientVouchers";
import { useEffect } from "react";

export const CreditOverview = () => {
  const intl = useIntl();
  const { push } = useRoute();
  const {
    data: { userInfo },
  } = useAuthState();

  const { fetch: getVouchers, data } = useAvailableClientVouchersStore();

  const creditAccount = generalCreditAccount(userInfo.creditAccounts);
  const creditAccountBalance = creditAccount?.balance ?? 0;

  useEffect(() => {
    if (userInfo.id) {
      getVouchers({
        requestPayload: {
          clientId: userInfo.id,
        },
      });
    }
  }, [userInfo.id]);

  const handleGotoCreditAccount = () => {
    push({
      pageKey: "creditAccount",
    });
  };

  const handleGotoRewardList = () => {
    push({
      pageKey: "myVouchers",
    });
  };

  return (
    <View className="flex flex-1 flex-row gap-4">
      <Card
        border="none"
        className="native:bg-primary flex-1 cursor-pointer bg-gradient-to-b from-primary to-blue-500"
        onClick={handleGotoCreditAccount}
      >
        <CardContent className="flex flex-col gap-2 p-4">
          <View className="flex flex-row items-start justify-between">
            <Wallet weight="fill" className="size-8 text-white" />
            <CaretRight className="size-4 text-white" />
          </View>

          <View>
            <Typography variant="body-md" color="white">
              {intl.formatMessage({
                defaultMessage: "Account Credit",
                id: "account.credit",
              })}
            </Typography>

            <Typography color="white">
              {formatPrice(creditAccountBalance)}
            </Typography>
          </View>
        </CardContent>
      </Card>

      <Card
        border="none"
        className="native:bg-[#FF7B43] flex-1 bg-gradient-to-b from-orange-400 to-orange-300"
        onClick={handleGotoRewardList}
      >
        <CardContent className="flex flex-col gap-2 p-4">
          <View className="flex flex-row items-start justify-between">
            <SealPercent weight="fill" className="size-8 text-white" />
            <CaretRight className="size-4 text-white" />
          </View>

          <View>
            <Typography variant="body-md" color="white">
              {intl.formatMessage({
                defaultMessage: "My Voucher",
                id: "rewards.yourRewards",
              })}
            </Typography>
            <Typography color="white">{data?.length ?? 0} Active</Typography>
          </View>
        </CardContent>
      </Card>
    </View>
  );
};
