import { SALES_NUMBER } from "@/constants";
import { handleOpenLink } from "../platform";

export const onChatSupport = () => {
  handleOpenLink(
    "//wa.me/" +
      SALES_NUMBER +
      "?text=" +
      "Hi! I would like to speak to sales about booking a new appointment through your web application.",
  );
};
