import { ListHeading } from "@/components/shared/list-header";
import { View, CardContent, Card, Button } from "@/components/ui";
import { ShoppingCart } from "@/components/shared/icons";
import { Divider } from "@/components/shared/divider";
import { ChatWithSalesBall } from "@/components/shared/chat-with-sales";
import React, { useEffect, useState } from "react";
import { AnimatedCaret } from "@/components/shared/animated-caret";
import { OrderDetails } from "./order-details";
import { useBookingOrder } from "@/components/hooks/use-booking-order";
import { formatPrice } from "@/lib/helpers/number";
import { useBookingState } from "@/store/booking/useBookingState";
import { cn } from "@/lib/utils";
import { IfElse } from "@/components/shared/if-else";
import { Typography } from "../../typography";
import { useIntl } from "react-intl";
import type { IconNames } from "../../icons/icon-map";

export const BookingOrderCard = ({
  buttonText = "Continue",
  loading,
  disabled,
  extraInfo,
  onContinue,
  icon = "arrowRight",
  iconAlignment = "end",
}: {
  loading?: boolean;
  disabled?: boolean;
  buttonText?: string;
  extraInfo?: React.ReactElement;
  onContinue?: () => void;
  icon?: IconNames;
  iconAlignment?: "start" | "end";
}) => {
  const {
    data: { selectedPackageIds },
  } = useBookingState();
  const intl = useIntl();
  return (
    <Card>
      <CardContent className="p-4 pt-0">
        <ListHeading
          title={intl.formatMessage({
            defaultMessage: "Your Order",
            id: "your-order",
          })}
          icon={ShoppingCart}
        />
        <Divider className="my-2" />
        {!selectedPackageIds.length ? (
          <View className="flex flex-col items-center justify-center gap-2 py-8">
            <ShoppingCart
              size={40}
              className="text-foreground-attenuated"
              weight="fill"
            />
            <Typography color="foreground-intermediate" variant="body-lg">
              {intl.formatMessage({
                defaultMessage: "   No items selected.",
                id: "no-items-selected",
              })}
            </Typography>
          </View>
        ) : (
          <>
            <OrderDetails showTotal />
            {extraInfo}
          </>
        )}
        <Button
          variant="primary"
          color="CTA"
          loading={loading}
          fullWidth="full"
          iconAlignment={iconAlignment}
          iconName={icon}
          onClick={onContinue}
          disabled={disabled}
          children={intl.formatMessage({
            defaultMessage: buttonText,
            id: "proceed",
          })}
        />
      </CardContent>
    </Card>
  );
};

export const BookingNavigationBottom = ({
  buttonText = "Continue",
  loading,
  disabled,
  onContinue,
  showExtendIcon = true,
  icon = "arrowRight",
  iconAlignment = "end",
}: {
  loading?: boolean;
  buttonText?: string;
  showExtendIcon?: boolean;
  disabled?: boolean;
  onContinue?: () => void;
  icon?: IconNames;
  iconAlignment?: "start" | "end";
}) => {
  const intl = useIntl();
  const [showDetail, setShowDetail] = useState(false);
  const toggleDetail = () => {
    setShowDetail((prevState) => !prevState);
  };

  const { total, lineItems } = useBookingOrder();

  useEffect(() => {
    if (!showExtendIcon) setShowDetail(false);
  }, [showExtendIcon]);

  return (
    <>
      <View className="native:hidden h-28 md:hidden"></View>
      <Card
        className="fixed bottom-0 left-0 z-20 block w-full gap-4 md:hidden"
        shadow={"sm"}
        radius={"md"}
      >
        <ChatWithSalesBall />
        <CardContent className="p-4">
          <View>
            {showDetail && <OrderDetails />}
            <IfElse if={lineItems.length > 0}>
              <View className="flex flex-row items-center justify-between py-3">
                <Typography variant="body-lg">{`Total (${lineItems.length || 0} Item${lineItems?.length > 1 ? "s" : ""})`}</Typography>
                <View
                  className="flex cursor-pointer flex-row items-center gap-1"
                  onClick={toggleDetail}
                >
                  <Typography color="brand-primary" variant="bold-xl">
                    {formatPrice(total)}
                  </Typography>
                  <Typography variant="body-sm" color="foreground-intermediate">
                    incl. GST
                  </Typography>
                  <View className={cn({ hidden: !showExtendIcon })}>
                    <AnimatedCaret isExpanded={!showDetail} />
                  </View>
                </View>
              </View>
            </IfElse>
          </View>
          <View className="flex flex-row">
            <Button
              variant="primary"
              color="CTA"
              loading={loading}
              className="flex-1"
              iconAlignment={iconAlignment}
              iconName={icon}
              onClick={onContinue}
              disabled={disabled}
              children={intl.formatMessage({
                defaultMessage: buttonText,
                id: "proceed",
              })}
            />
          </View>
        </CardContent>
      </Card>
    </>
  );
};
