import { Divider } from "@/components/shared/divider";
import { Link } from "@/components/shared/link";
import { Button, View } from "@/components/ui";
import { ArrowLeft } from "@/components/shared/icons";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import { useParams } from "@/components/hooks/use-params";
import { Navigate, useSearchParams } from "react-router-dom";
import { PortalHost } from "@/components/shared/portal";
import { Typography } from "@/components/shared/typography";
import { InvoicePdf } from "@/containers/profile/invoices/invoice-pdf";
import { getCapitalizeWord } from "@/lib/utils";
import { Container } from "@/components/ui/layout";
import { Column } from "@/components/ui/layout";

export function InvoicePdfPage({ type }: { type: "invoice" | "receipt" }) {
  const [searchParams] = useSearchParams();
  const { isMobile, isDesktop } = useWindowDimensions();

  const token = searchParams.get("token");
  const { id } = useParams<{ id: string }>();

  if (!id || !token) {
    return <Navigate to="/profile/invoices" />;
  }

  return (
    <Container>
      <Column>
        {isDesktop && (
          <View className="mb-4 mt-10">
            <Link href={`/profile/invoices/${id}`} className="mb-4">
              <Button
                iconName="arrowLeft"
                variant="secondary"
                color="foreground"
              >
                Back
              </Button>
            </Link>
          </View>
        )}
        <View className="mb-4 flex flex-row items-center justify-between gap-2">
          <View className="flex flex-row items-center gap-2 p-4 md:p-0">
            {isMobile && (
              <Link href={`/profile/invoices/${id}`}>
                <ArrowLeft className="size-5" />
              </Link>
            )}
            <Typography variant={{ md: "label-2xl", sm: "label-xl" }}>
              {getCapitalizeWord(type)} {id}
            </Typography>
          </View>
          <PortalHost name="BottomActions" />
        </View>
        {isMobile && <Divider />}
        <InvoicePdf id={id} token={token} type={type} />
      </Column>
    </Container>
  );
}
