import { Message } from "@/components/shared/message";
import { useBookingState } from "@/store/booking/useBookingState";
import { useEffect, useState } from "react";
import { useRoute } from "@/components/shared/router";
import type { ServiceName } from "@/types/service";
import { useParams } from "@/components/hooks/use-params";

const timeZero = "00:00";

export const BookingCountDown = ({ className }: { className?: string }) => {
  const {
    data: { reservationTimeStamp },
    setBookingState,
  } = useBookingState();
  const { replace } = useRoute();
  const { name = "" } = useParams<{ name: ServiceName }>();

  const [timeLeft, setTimeLeft] = useState("");

  const formatter = new Intl.NumberFormat("default", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date().getTime();
      const diff = reservationTimeStamp - now;
      const min = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const sec = Math.floor((diff % (1000 * 60)) / 1000);
      setTimeLeft(
        diff <= 0
          ? timeZero
          : `${formatter.format(min)}:${formatter.format(sec)}`,
      );
    });
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (timeLeft === timeZero) {
      replace({
        pageKey: "selectSlot",
        params: { name },
      });
      setBookingState({
        slotExpiredModalOpen: true,
      });
    }
  }, [timeLeft]);

  return (
    <Message
      variant="danger"
      className={className}
      message={`We're holding on to your chosen slot! Please complete your booking in ${timeLeft}`}
    />
  );
};
