import { createRequestFactory } from "@/lib/request-factory";
import {
  SignInDocument,
  type SignInMutation,
  type SignInMutationVariables,
} from "@/__generated__/graphql";
import type { UserInfo } from "@/types/users";
import { storage } from "@/lib/storage";
import { setAnalyticUserId } from "@/lib/firebase";
import type { LoginArgs } from "@/components/shared/auth-provider";
import { useAuthState } from ".";

type Response = {
  userInfo: UserInfo;
  loginArgs: LoginArgs;
};

// email login
export const useSignInStore = createRequestFactory<
  SignInMutation,
  Response,
  SignInMutationVariables
>({
  method: "mutation",
  graphqlDocument: SignInDocument,
  transformFunction: (res) => {
    if (res.signInClient?.result) {
      if (res.signInClient.result.__typename === "SignInClientOk") {
        const {
          jwt,
          refreshToken,
          client: { id, user, contacts, addresses },
        } = res.signInClient.result;
        const userInfo = {
          id,
          firstName: contacts[0].firstName,
          lastName: contacts[0].lastName,
          user,
          contacts,
          addresses,
        };
        const jwtInfo = {
          jwt,
          clientId: userInfo.id,
          refreshToken,
        };
        return { userInfo, loginArgs: jwtInfo } as Response;
      }
    }
    //@ts-expect-error
    throw new Error(res.signInClient?.result.message || "Login Failed");
  },
  onFetchSuccess: (res) => {
    const { setUserInfo } = useAuthState.getState();
    setUserInfo(res.userInfo);
    setAnalyticUserId(res.userInfo.id);
    storage.setItem("ONBOARDING", "true");
  },
});
