import { createRequestFactory } from "@/lib/request-factory";
import {
  SignInWithPhoneDocument,
  type SignInWithPhoneMutation,
  type SignInWithPhoneMutationVariables,
} from "@/__generated__/graphql";
import type { UserInfo } from "@/types/users";
import { setAnalyticUserId } from "@/lib/firebase";
import type { LoginArgs } from "@/components/shared/auth-provider";
import { useAuthState } from ".";

type Response = {
  userInfo: UserInfo;
  loginArgs: LoginArgs;
};

export const useSignInWithPhoneStore = createRequestFactory<
  SignInWithPhoneMutation,
  Response,
  SignInWithPhoneMutationVariables
>({
  method: "mutation",
  graphqlDocument: SignInWithPhoneDocument,
  transformFunction: (res) => {
    if (res.signInWithPhone?.result.__typename === "SignInClientOk") {
      const {
        jwt,
        refreshToken,
        client: { id, user, contacts, addresses },
      } = res.signInWithPhone.result;
      const userInfo = {
        id,
        firstName: contacts[0].firstName,
        lastName: contacts[0].lastName,
        user,
        contacts,
        addresses,
      };
      const loginArgs = {
        jwt,
        clientId: userInfo.id,
        refreshToken,
      };
      return { userInfo, loginArgs } as Response;
    }

    let errMessage = "Send pin code failed";
    if (
      res.signInWithPhone?.result.__typename === "AccountDeleted" ||
      res.signInWithPhone?.result.__typename === "NotWhitelistedProblem" ||
      res.signInWithPhone?.result.__typename === "SignUpRequired" ||
      res.signInWithPhone?.result.__typename === "ValidationProblem"
    ) {
      errMessage = res.signInWithPhone.result.message;
    }

    throw new Error(errMessage);
  },
  onFetchSuccess: (res) => {
    const { setUserInfo } = useAuthState.getState();
    setUserInfo(res.userInfo);
    setAnalyticUserId(res.userInfo.id);
  },
});
