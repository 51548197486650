import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import { BottomActions } from "@/components/shared/bottom-actions";
import { Message } from "@/components/shared/message";
import { PDFViewer } from "@/components/shared/pdf-viewer";
import { Portal } from "@/components/shared/portal";
import { Button, Skeleton, View } from "@/components/ui";
import { handleOpenLink } from "@/lib/platform";
import { useGenerateInvoicePdfStore } from "@/store/invoices/generateInvoicePdf";
import { useGenerateInvoiceReceiptPdfStore } from "@/store/invoices/generateInvoiceReceiptPdf";
import { useEffect, useMemo } from "react";

const store = {
  invoice: useGenerateInvoicePdfStore,
  receipt: useGenerateInvoiceReceiptPdfStore,
};

export function InvoicePdf({
  id,
  token,
  type,
}: {
  id: string;
  token: string;
  type: "invoice" | "receipt";
}) {
  const useStore = store[type];

  const { data: pdfUrl, fetch, loading, error } = useStore();

  const { isMobile } = useWindowDimensions();

  useEffect(() => {
    if (token) {
      fetch({
        requestPayload: {
          input: {
            token,
          },
        },
      });
    }
  }, [id, token]);

  const handleDownload = () => {
    if (pdfUrl) {
      handleOpenLink(pdfUrl);
    }
  };

  const renderButton = useMemo(() => {
    if (isMobile) {
      return (
        <BottomActions>
          <View className="flex w-full flex-1 flex-col gap-2">
            <Button
              variant="primary"
              color="CTA"
              onClick={handleDownload}
              children="Download PDF"
              disabled={loading}
            />
          </View>
        </BottomActions>
      );
    }

    return (
      <Button
        variant="primary"
        color="CTA"
        className="min-w-[100px]"
        rounded="full"
        onClick={handleDownload}
        children="Download PDF"
        disabled={loading}
      />
    );
  }, [isMobile]);

  if (loading) {
    return <Skeleton className="h-[500px] w-full" />;
  }

  if (error) {
    return <Message variant="danger" message={error} />;
  }

  return (
    <View>
      {pdfUrl ? (
        <PDFViewer url={pdfUrl} />
      ) : (
        <Message variant="danger" message="No Invoice Url" />
      )}
      <Portal name="BottomActions">{renderButton}</Portal>
    </View>
  );
}
