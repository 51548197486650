import * as rudderanalytics from "rudder-sdk-js";
import { app } from "@/config/env";

const WRITE_KEY = app.RUDDERSTACK.WRITE_KEY;
const DATA_PLANE_URL = app.RUDDERSTACK.DATA_PLANE_URL;

if (app.ENV !== "local") {
  rudderanalytics.load(WRITE_KEY, DATA_PLANE_URL);
}

export { rudderanalytics };
