import { View } from "@/components/ui";
import { Checkbox } from "@/components/ui/checkbox";
import type { Control } from "react-hook-form";
import { Controller } from "react-hook-form";
import type { AddressFormData } from "@/store/auth/forms/useAddressForm";
import { Typography } from "@/components/shared/typography";
import { useIntl } from "react-intl";

interface PrimaryAddressCheckboxProps {
  control: Control<AddressFormData>;
}

export const PrimaryAddressCheckbox = ({
  control,
}: PrimaryAddressCheckboxProps) => {
  const intl = useIntl();
  return (
    <View className="native:hidden mt-6 flex flex-row items-center gap-3">
      <Controller
        name="primary"
        control={control}
        render={({ field }) => (
          <Checkbox
            checked={field.value}
            onCheckedChange={field.onChange}
            className="size-6"
          />
        )}
      />
      <Typography variant="body-md" color="muted-foreground">
        {intl.formatMessage({
          defaultMessage: "Make this my default address",
          id: "make-default-address",
        })}
      </Typography>
    </View>
  );
};
