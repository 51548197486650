import { useVisitStore } from "@/store/visits/visitStore";
import { useVisitTokenStore } from "@/store/visits/visitTokenStore";
import { useEffect, useMemo, useState } from "react";
import { TimeSlotWithSearch } from "@/components/shared/time-slot-with-search";
import { getServiceNameFromDepartment } from "@/lib/booking-lib";
import { Button, View } from "@/components/ui";
import { ChooseWorkerModal } from "@/components/shared/booking/choose-worker-modal";
import {
  departmentPackageToWorker,
  getWorkerLabel,
} from "@/lib/service/helpers";
import { getCapitalizeWord } from "@/lib/utils";
import { useVisitDetailStore } from "@/store/visits/visitDetail";
import type { ClientFindBookingScheduleQueryVariables } from "@/__generated__/graphql";
import {
  PackageDepartmentEnum,
  ScheduleJobFrequencyEnum,
} from "@/__generated__/graphql";
import { useClientFindBookingScheduleStore } from "@/store/booking/clientFindBookingSchedule";
import { addDays } from "date-fns";
import type { PastWorker, TimeSlot } from "@/types/booking";
import { useClientRescheduleVisit } from "@/store/visits/useClientRescheduleVisit";
import { useClientRescheduleWithToken } from "@/store/visits/useClientRescheduleWithToken";
import { getErrorMessage } from "@/lib/helpers/string";
import { showToast } from "@/components/ui/toast/show-toast";
import { InfoDialog } from "@/components/shared/info-dialog";
import { useRoute } from "@/components/shared/router";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import { Portal } from "@/components/shared/portal";
import { BottomActions } from "@/components/shared/bottom-actions";
import { formatDate, today } from "@/lib/helpers/date";
import { collectError } from "@/lib/monitoring/sentry";
import { SuitableWorkerModal } from "./suitable-worker-modal";

const timeRangeFindSchedule = [
  {
    startTime: "06:00",
    endTime: "22:00",
    day: 0,
  },
  {
    startTime: "06:00",
    endTime: "22:00",
    day: 1,
  },
  {
    startTime: "06:00",
    endTime: "22:00",
    day: 2,
  },
  {
    startTime: "06:00",
    endTime: "22:00",
    day: 3,
  },
  {
    startTime: "06:00",
    endTime: "22:00",
    day: 4,
  },
  {
    startTime: "06:00",
    endTime: "22:00",
    day: 5,
  },
  {
    startTime: "06:00",
    endTime: "22:00",
    day: 6,
  },
];

export function RescheduleVisitContent({
  visitId,
  token,
}: {
  visitId?: string;
  token?: string;
}) {
  const {
    fetch: getVisit,
    data: visitData,
    loading: loadingVisit,
  } = useVisitStore();
  const {
    fetch: getVisitToken,
    data: visitToken,
    loading: loadingVisitToken,
  } = useVisitTokenStore();

  const { fetch: rescheduleVisit, loading: rescheduleLoading } =
    useClientRescheduleVisit();
  const { fetch: rescheduleToken, loading: rescheduleTokenLoading } =
    useClientRescheduleWithToken();

  const loadingMutation = rescheduleLoading || rescheduleTokenLoading;

  const {
    data,
    fetch: getSlots,
    loading: scheduleLoading,
    refetch: refetchSchedule,
  } = useClientFindBookingScheduleStore();

  const workerType = useVisitDetailStore(
    (state) => state.data.rescheduleVisitWorkerType,
  );

  const visit = token ? visitToken : visitData;
  const loading = loadingVisit || loadingVisitToken;

  const [startDate, setStartDate] = useState(today());
  const [endDate, setEndDate] = useState(addDays(today(), 6));
  const [selectedSlot, setSelectedSlot] = useState<TimeSlot | null>(null);
  const [chooseWorkerModalOpen, setChooseWorkerModalOpen] = useState(false);
  const [currentWorkers, setCurrentWorkers] = useState<PastWorker[] | null>(
    null,
  );
  const [success, setSuccess] = useState(false);
  const [showSuitableWorkerModal, setShowSuitableWorkerModal] = useState(false);

  const { push } = useRoute();
  const { isDesktop } = useWindowDimensions();

  useEffect(() => {
    if (token) {
      getVisitToken({
        requestPayload: {
          token,
        },
      });
    } else if (visitId) {
      getVisit({
        requestPayload: {
          id: visitId,
        },
      });
    }
  }, [visitId, token]);

  useEffect(() => {
    if (visit) {
      const visitLineItemIds = visit.lineItems.map(
        (lineItem) => lineItem.packageDetailId,
      );
      const visitWorkerSkillIds = visit.lineItems.map(
        (lineItem) => lineItem.workerSkillId,
      );

      const filter: ClientFindBookingScheduleQueryVariables = {
        department: departmentPackageToWorker(visit.department),
        frequencyOfJob: ScheduleJobFrequencyEnum.AdHoc,
        sessionDuration: 0,
        postalCode: visit.postalCode,
        timeRanges: timeRangeFindSchedule,
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
        lineItemIds: visitLineItemIds,
        workerSkillIds: visitWorkerSkillIds,
        workersIds: currentWorkers?.length
          ? currentWorkers.map((worker) => worker.id)
          : null,
      };
      getSlots({
        requestPayload: filter,
      });
    }
  }, [visit, startDate, endDate, workerType, currentWorkers]);

  const name = useMemo(
    () =>
      getServiceNameFromDepartment(
        visit?.department ?? PackageDepartmentEnum.HomeCleaning,
      ),
    [visit?.department],
  );

  const workerLabel = useMemo(
    () =>
      getCapitalizeWord(
        getWorkerLabel(visit?.department ?? PackageDepartmentEnum.HomeCleaning),
      ),
    [visit?.department],
  );

  const onConfirm = async () => {
    if (!visit) {
      showToast({ title: "Invalid Visit data", type: "error" });
      return;
    }

    if (!selectedSlot) {
      showToast({ title: "Please select a time slot first", type: "error" });
      return;
    }

    const input = {
      serviceDate: selectedSlot.date,
      startTime: selectedSlot.startTime,
      endTime: selectedSlot.endTime,
      workerId: selectedSlot.workerId,
    };

    try {
      const result = token
        ? await rescheduleToken(
            {
              requestPayload: {
                input: {
                  ...input,
                  token,
                },
              },
            },
            { selfHandleError: true },
          )
        : await rescheduleVisit(
            {
              requestPayload: {
                input: {
                  ...input,
                  id: String(visitId),
                },
              },
            },
            {
              selfHandleError: true,
            },
          );

      if (result.error) {
        throw new Error(result.error);
      }

      if (result.data) {
        setSuccess(true);
      }
    } catch (error) {
      console.log("Unhandled submit request reschedule", error);
      let errorMsg = getErrorMessage(error, "Failed to request reschedule");

      if (errorMsg.includes("Overlap visit found with id:")) {
        errorMsg =
          "The slot has already been taken by another customer, please try another slot.";
      }

      showToast({ type: "error", title: errorMsg });
      refetchSchedule();
      collectError(error, {
        context: {
          name: "rescheduleVisit",
          values: selectedSlot,
        },
      });
    }
  };

  const handleSuccessReschedule = () => {
    setSuccess(false);
    setSelectedSlot(null);
    push({ pageKey: "visits" });
  };

  const onChooseWorkers = () => {
    setChooseWorkerModalOpen(true);
    setShowSuitableWorkerModal(false);
  };

  const _setCurrentWorkers = (workers: PastWorker[]) => {
    setCurrentWorkers(workers);
    if (workers.length) {
      setShowSuitableWorkerModal(true);
    }
  };

  return (
    <View className="native:pt-0 flex flex-col gap-2 pb-8">
      <TimeSlotWithSearch
        name={name}
        initialStartDate={today()}
        department={visit?.department ?? PackageDepartmentEnum.HomeCleaning}
        showTimeRange
        loading={loading || scheduleLoading}
        selectedSlot={selectedSlot}
        timeSlots={data as Record<string, TimeSlot[]>}
        workersIds={currentWorkers?.map((worker) => worker.id) || []}
        workerLabel={workerLabel}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        enableChooseWorker
        onChooseWorkers={onChooseWorkers}
        onSelectSlot={setSelectedSlot}
        onClickWorkerPortfolio={() => {}}
      />
      {isDesktop && (
        <View className="w-1/4 self-end">
          <Button
            variant="primary"
            color="CTA"
            loading={loading}
            fullWidth="full"
            onClick={onConfirm}
            disabled={loadingMutation}
            children="Reschedule"
          />
        </View>
      )}
      <InfoDialog
        open={success}
        title="Success"
        subtitle="Your visit schedule has been updated"
        onClose={handleSuccessReschedule}
      />
      <ChooseWorkerModal
        department={visit?.department ?? PackageDepartmentEnum.HomeCleaning}
        open={chooseWorkerModalOpen}
        workerLabel={workerLabel}
        setOpen={setChooseWorkerModalOpen}
        selectedWorkers={currentWorkers ?? []}
        handleApply={_setCurrentWorkers}
      />
      <SuitableWorkerModal
        open={showSuitableWorkerModal}
        onClose={() => setShowSuitableWorkerModal(false)}
      />
      {!isDesktop && (
        <Portal name="BottomActions">
          <BottomActions>
            <Button
              variant="primary"
              color="CTA"
              loading={loading}
              fullWidth="full"
              onClick={onConfirm}
              disabled={loadingMutation}
              children="Reschedule"
            />
          </BottomActions>
        </Portal>
      )}
    </View>
  );
}
