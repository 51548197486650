/* This class handles token logic:
   - it stores jwt token
   - it knows how to fetch jwt
   - it knows how to handle GraphQL errors (refresh jwt if needed)
*/

import type {
  Observable,
  Operation,
  FetchResult,
  ApolloLink,
} from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import type { ErrorLink, ErrorResponse } from "@apollo/client/link/error";
import { onError } from "@apollo/client/link/error";

import { app } from "@/config/env";

import type { QueuedRequest } from "./operation-queue";
import { OperationQueue } from "./operation-queue";
import { storage } from "@/lib/storage";

const REFRESH_TOKEN_KEY = "refreshToken";

type FetchToken = {
  token: string;
  refreshToken: string;
  operation?: Operation;
};

type RefreshTokenResponse = {
  jwt: string;
  jwt_expiry: number;
  jwt_refresh_token: string;
};

type Tokens = {
  token: string;
  refreshToken: string;
};

const refreshTokenEndpoint = app.REFRESH_TOKEN_ENDPOINT;

class TokenManager {
  private queue: OperationQueue;
  private fetching: boolean;
  private token: string;
  private refreshToken: string;
  private logoutCallback: () => void;

  constructor() {
    this.queue = new OperationQueue();
    this.fetching = false;
    this.token = "";
    this.refreshToken = "";
    this.logoutCallback = () => {};
  }

  setLogoutCallback = (callback: () => void) => {
    this.logoutCallback = callback;
  };

  logout = () => {
    this.logoutCallback();
  };

  enqueueRequest = (request: QueuedRequest): Observable<FetchResult> => {
    return this.queue.enqueueRequest(request);
  };

  setTokens = ({ token, refreshToken }: Tokens) => {
    this.token = token ?? "";
    this.refreshToken = refreshToken ?? "";
    storage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  };

  getTokenDecode = (user: {
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    avatarUrl?: string;
  }) => {
    if (!this.token || !this.refreshToken) {
      return undefined;
    }

    return {
      token: btoa(this.token),
      refresh: btoa(this.refreshToken),
      user: btoa(JSON.stringify(user)),
    };
  };

  clearTokens = () => {
    this.setTokens({ token: "", refreshToken: "" });
  };

  fetchToken = async (operation?: Operation): Promise<FetchToken> => {
    console.log("token manager: fetch token start");

    try {
      const headers = {
        "x-jwt-refresh-token": this.refreshToken,
      };

      const response = await fetch(refreshTokenEndpoint, {
        method: "POST",
        mode: "cors",
        credentials: undefined,
        headers,
      });

      if (response.ok) {
        const body = (await response.json()) as RefreshTokenResponse;
        const token = body?.jwt;
        const refreshToken = body?.jwt_refresh_token;
        console.log("token manager: fetch token done!");

        return { token, refreshToken, operation };
      } else {
        throw new Error(`${response.status}`);
      }
    } catch (err) {
      console.log("token manager: fetch token error", err);
      throw err;
    }
  };

  isTokenAvailable = async (): Promise<boolean> => {
    console.log("token manager: is token available");
    try {
      this.refreshToken = (await storage.getItem(REFRESH_TOKEN_KEY)) ?? "";
    } catch (error) {
      console.log("token manager: error get refresh token from secure store");
      console.log("error:", error);
      return false;
    }

    if (!this.refreshToken) {
      return false;
    }

    const { token, refreshToken } = await this.fetchToken();
    this.setTokens({ token, refreshToken });

    return Boolean(token);
  };

  handleFetchToken = ({ token, refreshToken, operation }: FetchToken) => {
    console.log("token manager: handle fetch token");
    this.setTokens({ token, refreshToken });

    if (operation) {
      operation.setContext({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        headers: {
          ...operation.getContext().headers,
          Authorization: token ? `Bearer ${token}` : "",
        },
      });
    }

    this.queue.consumeQueue();
  };

  handleFetchError = (error: unknown) => {
    console.log("token manager: error fetch token");
    console.log("error:", error);
    this.clearTokens();
    this.queue.clearQueue();
    this.logout();
  };

  errorHandler: ErrorLink.ErrorHandler = ({
    graphQLErrors,
    operation,
    forward,
  }: ErrorResponse) => {
    if (graphQLErrors?.length === 0) {
      // no errors
      return;
    }

    for (const error of graphQLErrors ?? []) {
      const { message } = error;

      if (message === "JWTExpired") {
        if (!this.fetching) {
          console.log("error handler: jwt expired, start fetching");
          this.fetching = true;

          this.fetchToken(operation)
            .then(this.handleFetchToken)
            .catch(this.handleFetchError)
            .finally(() => {
              console.log("token manager: finally");
              this.fetching = false;
              console.log("token manager: refresh token done!");
            });
        }

        return this.queue.enqueueRequest({ operation, forward });
      }

      if (message === "Invalid JWT" && !this.fetching) {
        console.log("token manager: invalid token. Clean Session.");
        console.log("token manager: fetching =", this.fetching);
        this.token = "";
        this.logout();
      }
    }
  };

  errorLink = (): ApolloLink => {
    return onError(this.errorHandler);
  };

  authHeaderLink = () => {
    return setContext((_, { headers }) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      headers: {
        ...headers,
        Authorization: this.token ? `Bearer ${this.token}` : undefined,
      },
    }));
  };
}

export const tokenManager = new TokenManager();
