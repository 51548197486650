import { View } from "@/components/ui";
import { Column, Container } from "@/components/ui/layout";
import { SelectSlotNavigation } from "./select-slot-navigation";
import type { ServiceName, UpholsteryServiceName } from "@/types/service";
import { UpholsteryServiceNames } from "@/types/service";
import { getCapitalizeWord, getPlatform } from "@/lib/utils";
import { useEffect, useMemo, useState } from "react";
import { ChooseWorkerModal } from "@/components/shared/booking/choose-worker-modal";
import {
  getBookingPackages,
  getDateRangeFromFrequency,
  getSelectedPackagesFormIds,
  getWorkerIdsFromPackages,
  getWorkerLabel,
} from "@/lib/booking-lib";
import { useAuthState } from "@/store/auth";
import { WebBackButtonHeader } from "@/components/shared/back-button";
import { SelectSlotSection } from "./select-slot-section";
import { ServiceAddressSection } from "./service-address-section";
import type { TimeSlot } from "@/types/booking";
import { PackageFrequencyGroupEnum } from "@/types/booking";
import { SelectAddressModal } from "@/components/shared/booking/select-address-modal";
import { AddAddressModal } from "@/components/shared/login-register/add-address-modal";
import type { AddressFormData } from "@/store/auth/forms/useAddressForm";
import { useClientFindBookingScheduleStore } from "@/store/booking/clientFindBookingSchedule";
import { formatDate } from "@/lib/helpers/date";
import type { Address } from "@/types/users";
import { usePostalCodeForm } from "@/store/auth/forms/usePostalCodeForm";
import { useAddressByPostalCodeStore } from "@/store/auth/addressByPostalCode";
import { useCreateAddressStore } from "@/store/booking/createAddress";
import { useClientStore } from "@/store/auth/client";
import {
  PackageDepartmentEnum,
  PackageDetailSessionEnum,
  PackageRecurrenceEnum,
  WorkerDepartmentEnum,
} from "@/__generated__/graphql";
import { useBookingRouterInterceptor } from "@/components/hooks/use-booking-route";
import { useBookingState } from "@/store/booking/useBookingState";
import { useClientCancelReservationStore } from "@/store/booking/clientCancelReservation";
import { WorkerInfoModal } from "@/components/shared/worker-infomation";
import { Divider } from "@/components/shared/divider";
import { addDays } from "date-fns";
import { sendAnalyticData } from "@/lib/monitoring/analytics";
import { useRefreshSlot } from "./use-refresh-slot";
import { IfElse } from "@/components/shared/if-else";
import { SlotExpiredModal } from "./slot-expired-modal";
import { useAuth } from "@/components/shared/auth-provider";

const timeRanges = [
  {
    startTime: "06:00",
    endTime: "22:00",
    day: 0,
  },
  {
    startTime: "06:00",
    endTime: "22:00",
    day: 1,
  },
  {
    startTime: "06:00",
    endTime: "22:00",
    day: 2,
  },
  {
    startTime: "06:00",
    endTime: "22:00",
    day: 3,
  },
  {
    startTime: "06:00",
    endTime: "22:00",
    day: 4,
  },
  {
    startTime: "06:00",
    endTime: "22:00",
    day: 5,
  },
  {
    startTime: "06:00",
    endTime: "22:00",
    day: 6,
  },
];

export const SelectSlot = ({ name }: { name: ServiceName }) => {
  useBookingRouterInterceptor();
  const platform = getPlatform();
  const [chooseWorkerModalOpen, setChooseWorkerModalOpen] = useState(false);
  const [selectAddressModalOpen, setSelectAddressModalOpen] = useState(false);
  const [addAddressModalOpen, setAddAddressModalOpen] = useState(false);
  const [postalCode, setPostalCode] = useState("");
  const [workerModalOpen, setWorkerModalOpen] = useState(false);
  const [workerSlot, setWorkerSlot] = useState<TimeSlot | null>(null);
  const {
    data: {
      department,
      selectedWorkers,
      timeSlots,
      startDate,
      endDate,
      selectedSlot,
      selectedAddress,
      packageDetails,
      selectedPackageIds,
      packageDetailsFilter,
      reservations,
    },
    setBookingState,
  } = useBookingState();
  const {
    data: { userInfo },
    showModal,
    setPostalCode: setDefaultPostalCode,
  } = useAuthState();

  const { isAuthenticated } = useAuth();

  const [showSlotSection, setShowSlotSection] = useState(isAuthenticated);

  const {
    fetch: getAddressByPostalCode,
    loading: getAddressLoading,
    error: getAddressError,
  } = useAddressByPostalCodeStore();
  const {
    fetch: createAddress,
    loading: createAddressLoading,
    error: createAddressError,
  } = useCreateAddressStore();
  const { fetch: getClientInfo } = useClientStore();
  const { fetch: getSlots, loading: scheduleLoading } =
    useClientFindBookingScheduleStore();
  const { fetch: cancelReservations } = useClientCancelReservationStore();

  useRefreshSlot(() => {
    refreshSlot();
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = usePostalCodeForm();
  const address = useMemo(
    () =>
      selectedAddress?.fullAddress ||
      userInfo.addresses.find((addr) => addr.primary)?.fullAddress,
    [selectedAddress?.id, userInfo],
  );
  const workerLabel = useMemo(
    () => getCapitalizeWord(getWorkerLabel(department)),
    [],
  );

  const submitAddress = handleSubmit(
    ({ postalCode }: { postalCode: string }) => {
      setPostalCode(postalCode);
    },
  );

  const refreshSlot = async () => {
    setBookingState({ selectedSlot: null });
    if (isAuthenticated && !userInfo?.user?.phoneNumberVerifiedAt)
      await fetchClientInfo();
    if (selectedAddress?.postalCode || postalCode) {
      getSlots({
        requestPayload: {
          clientId: userInfo.id || "",
          // aircon and carpet upholstery share the same workers
          department: UpholsteryServiceNames.includes(
            name as UpholsteryServiceName,
          )
            ? WorkerDepartmentEnum.Aircon
            : (department as unknown as WorkerDepartmentEnum),
          startDate: formatDate(startDate),
          endDate:
            packageDetailsFilter.frontEndFrequency ===
            PackageFrequencyGroupEnum.NEXT_DAY_AD_HOC
              ? formatDate(addDays(startDate, 1))
              : formatDate(endDate),
          lineItemIds: selectedPackageIds,
          postalCode: isAuthenticated
            ? (selectedAddress?.postalCode as string)
            : postalCode,
          sessionDuration: 0,
          timeRanges,
          workerSkillIds: getWorkerIdsFromPackages(
            getSelectedPackagesFormIds(selectedPackageIds, packageDetails),
          ),
          workersIds: selectedWorkers.length
            ? selectedWorkers.map((worker) => worker.id)
            : null,
        },
      }).then((res) => {
        if (res.error || !res.data) {
          return;
        }

        sendAnalyticData({
          name: "find_availability",
          values: {
            currency: "SGD",
            items: packageDetails.map((pkgDetail) => ({
              item_id: pkgDetail.code,
              item_category: pkgDetail.serviceType,
              price: pkgDetail.unitValue,
              quantity: pkgDetail.duration,
            })),
          },
        });

        setBookingState({ timeSlots: res.data });
        setDefaultPostalCode(postalCode);
      });
    }
  };

  const fetchClientInfo = () => {
    return getClientInfo({
      requestPayload: {
        id: userInfo.id,
      },
    });
  };

  const onSearchPostalCode = () => {
    if (!errors.postalCode?.message) {
      setShowSlotSection(true);
      submitAddress();
    }
  };

  const getAddress = handleSubmit(({ postalCode }: { postalCode: string }) => {
    getAddressByPostalCode(
      {
        requestPayload: {
          postalCode,
        },
      },
      { selfHandleError: true },
    );
  });

  useEffect(() => {
    setBookingState({ timeSlots: null });
    if (reservations.length) {
      cancelReservations(
        {
          requestPayload: {
            input: {
              reservationIds: reservations.map((res) => res.id),
            },
          },
        },
        {
          // cancel reservation might fail, no need to notify user cause it's not important for them.
          selfHandleError: true,
        },
      ).finally(() => {
        setBookingState({ reservations: [] });
      });
    }
  }, []);

  useEffect(() => {
    if (getAddressError)
      setError("postalCode", { message: "Invalid PostalCode" });
  }, [getAddressError]);

  useEffect(() => {
    if (isAuthenticated) {
      setBookingState({
        selectedAddress: userInfo.addresses.find(
          (addr) => addr.primary,
        ) as Address,
      });
    }
  }, [userInfo.addresses]);

  useEffect(() => {
    if (name === "home-cleaning") {
      const packages = getBookingPackages(
        department,
        packageDetails,
        selectedSlot,
      );

      // package price is depend on which slot user choose.
      const slotMappedPackageDetails = packageDetails.map((packageDetail) => {
        const pkgSession =
          selectedSlot?.partOfDay === "Evening"
            ? PackageDetailSessionEnum.Evening
            : PackageDetailSessionEnum.Day;
        if (pkgSession === packageDetail.session) {
          return {
            ...packageDetail,
            serviceBillingValue:
              (selectedSlot?.rateValue || 0) * packageDetail.units,
            unitValue: selectedSlot?.rateValue || 0,
          };
        }
        return packageDetail;
      });

      setBookingState({
        selectedPackageIds: packages.map((pkg) => pkg.id),
        packageDetails: slotMappedPackageDetails,
      });
    }
  }, [selectedSlot]);

  useEffect(() => {
    return () => {
      setBookingState({
        startDate: initialStartDate,
        endDate: addDays(initialStartDate, 6),
      });
      setDefaultPostalCode("");
    };
  }, []);

  useEffect(() => {
    refreshSlot();
  }, [startDate, endDate, selectedAddress?.id, postalCode, selectedWorkers]);

  const initialStartDate = useMemo(() => {
    if (packageDetailsFilter.frontEndFrequency) {
      return getDateRangeFromFrequency(
        packageDetailsFilter.frontEndFrequency,
      )[0];
    }
    if (
      packageDetailsFilter?.repeatEvery?.[0] === PackageRecurrenceEnum.AdHoc
    ) {
      return new Date();
    }
    return addDays(new Date(), 1);
  }, [
    packageDetailsFilter.frontEndFrequency,
    packageDetailsFilter.repeatEvery?.[0],
  ]);

  const onSubmitAddressForm = ({
    postalCode,
    fullAddress,
    unitFloor,
    apartmentNumber,
    primary,
  }: AddressFormData) => {
    createAddress({
      requestPayload: {
        input: {
          clientId: userInfo.id,
          postalCode: postalCode,
          fullAddress: fullAddress,
          unitNumber: `#${unitFloor || 0}-${apartmentNumber || 0}`,
          primary: !!primary,
        },
      },
    })
      .then(() => {
        setAddAddressModalOpen(false);
        showModal();
        return fetchClientInfo();
      })
      .then((clientInfo) => {
        const addr = clientInfo.data?.userInfo.addresses?.find(
          (ad) =>
            ad.postalCode === postalCode && ad.fullAddress === fullAddress,
        );
        setBookingState({
          selectedAddress: addr,
        });
      });
  };

  const onClickWorkerPortfolio = (slot: TimeSlot) => {
    setWorkerModalOpen(true);
    setWorkerSlot(slot);
  };

  return (
    <View className="bg-white pb-4">
      <Container>
        <Column desktop="8">
          <WebBackButtonHeader title="Select Service Address and Slot" />
          <View className="flex flex-col gap-4">
            {workerSlot && (
              <WorkerInfoModal
                openModal={workerModalOpen}
                onClose={() => setWorkerModalOpen(false)}
                cleanerProfilePhoto={workerSlot?.avatarUrl || ""}
                serviceCleaner={workerSlot?.workerName || ""}
                serviceRating={workerSlot.workerRating || 0}
                reviewNumber={workerSlot.rateValue || 0}
                slotDay={workerSlot.date}
                slotStartTime={workerSlot.startTime}
                slotEndTime={workerSlot.endTime}
                images={workerSlot.portfolios?.map((p) => p.imgUrl) || []}
              />
            )}
            <View className="px-4">
              <ServiceAddressSection
                getAddressByPostalCode={getAddress}
                loading={getAddressLoading}
                control={control}
                isLoggedIn={!!isAuthenticated}
                address={address}
                onSearchPostalCode={onSearchPostalCode}
                onChangeAddress={() => {
                  setSelectAddressModalOpen(true);
                }}
              />
            </View>

            <IfElse if={showSlotSection}>
              <Divider className="border-2 md:mx-4" />

              <View className="px-4">
                <SelectSlotSection
                  name={name}
                  initialStartDate={initialStartDate}
                  department={department}
                  showTimeRange={Boolean(
                    isAuthenticated || (!isAuthenticated && postalCode),
                  )}
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={(startDate) => setBookingState({ startDate })}
                  setEndDate={(endDate) => setBookingState({ endDate })}
                  setSelectedSlot={(selectedSlot) =>
                    setBookingState({ selectedSlot })
                  }
                  loading={scheduleLoading}
                  workerIds={selectedWorkers?.map((worker) => worker.id) || []}
                  workerLabel={workerLabel}
                  timeSlots={timeSlots}
                  selectedSlot={selectedSlot}
                  onChooseWorkers={() => setChooseWorkerModalOpen(true)}
                  enableChooseWorker={
                    (!!isAuthenticated &&
                      department === PackageDepartmentEnum.HomeCleaning) ||
                    department === PackageDepartmentEnum.HomeBeauty
                  }
                  onClickWorkerPortfolio={onClickWorkerPortfolio}
                />
              </View>
            </IfElse>
          </View>
        </Column>
        {platform === "web" && (
          <Column desktop="4">
            <View className="pt-6 md:sticky md:top-14">
              <SelectSlotNavigation name={name} />
            </View>
          </Column>
        )}

        <ChooseWorkerModal
          open={chooseWorkerModalOpen}
          department={department}
          workerLabel={workerLabel}
          setOpen={setChooseWorkerModalOpen}
          selectedWorkers={selectedWorkers}
          handleApply={(workers) =>
            setBookingState({ selectedWorkers: workers })
          }
        />

        <SelectAddressModal
          open={selectAddressModalOpen}
          selectedAddress={selectedAddress}
          setOpen={setSelectAddressModalOpen}
          setSelectedAddress={(selectedAddress) =>
            setBookingState({ selectedAddress })
          }
          addresses={userInfo.addresses}
          onAddAddress={() => {
            setSelectAddressModalOpen(false);
            setAddAddressModalOpen(true);
          }}
        />

        <AddAddressModal
          onClose={() => setAddAddressModalOpen(false)}
          open={addAddressModalOpen}
          onSubmitForm={onSubmitAddressForm}
          loading={createAddressLoading}
          error={createAddressError || ""}
        />

        <SlotExpiredModal />
      </Container>
    </View>
  );
};
