import { Card, CardContent, View } from "@/components/ui";
import { Link } from "@/components/shared/link";
import { CaretRight } from "@/components/shared/icons";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import { PackageCardLabel } from "./package-card-label";
import type { Package } from "@/__generated__/graphql";
import { formatDateString } from "@/lib/helpers/date";
import { Typography } from "../typography";

export function PackageCard({ item }: { item: Package }) {
  const { isMobile } = useWindowDimensions();

  const {
    packageDescription,
    department,
    repeatEvery,
    startDate,
    endDate,
    fullAddress,
  } = item;

  return (
    <Link href={`/profile/packages/${item.id}`}>
      <Card shadow={isMobile ? "sm" : "none"}>
        <CardContent className="min-w-[275px] p-4 md:min-w-0">
          <View className="mb-3 flex flex-row items-start justify-between border-b border-secondary-border pb-3">
            <Typography
              variant={{ md: "body-xl", sm: "body-md" }}
              className="max-w-[425px] flex-1"
            >
              {packageDescription}
            </Typography>
            <PackageCardLabel
              department={department}
              repeatEvery={repeatEvery}
            />
          </View>

          <View className="flex flex-row items-center justify-between md:items-end">
            <View className="flex max-w-[200px] flex-col gap-1 truncate md:max-w-[320px]">
              <Typography
                variant={{ md: "body-lg", sm: "body-md" }}
                color="foreground-intermediate"
              >
                {`Active from ${formatDateString(startDate)} - ${endDate ? formatDateString(endDate) : "Not Set"}`}
              </Typography>
              <Typography
                variant={{ md: "body-lg", sm: "body-md" }}
                color="foreground-intermediate"
              >
                {fullAddress}
              </Typography>
            </View>

            <View className="flex flex-row items-center">
              <Typography
                variant="body-md"
                className="hidden text-button-cta2-hover md:flex"
              >
                View Detail
              </Typography>
              <CaretRight className="size-3 text-button-cta2-hover" />
            </View>
          </View>
        </CardContent>
      </Card>
    </Link>
  );
}
