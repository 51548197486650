import { startOfDay, endOfDay, addDays, format, parse } from "date-fns";

export const dateFormat = "yyyy-MM-dd";
export const timeFormat = "HH:mm:ss";

export const today = () => new Date();

type FormatTimeOption = {
  separator?: string;
  ignoreZero?: boolean;
  capitalize?: "lowercase" | "uppercase";
};

export enum ShortDay {
  MO = "Mon",
  TU = "Tue",
  WE = "Wed",
  TH = "Thu",
  FR = "Fri",
  SA = "Sat",
  SU = "Sun",
}

export const ShortDaysList: ShortDay[] = [
  ShortDay.MO,
  ShortDay.TU,
  ShortDay.WE,
  ShortDay.TH,
  ShortDay.FR,
  ShortDay.SA,
  ShortDay.SU,
];

// any date related helper
const dateFormatterWithoutYear = new Intl.DateTimeFormat("en-US", {
  month: "short",
  day: "numeric",
});

const dateFormatter = new Intl.DateTimeFormat("en-US", {
  month: "short",
  day: "numeric",
  year: "numeric",
});

const completeDateFormatter = new Intl.DateTimeFormat("en-US", {
  weekday: "long",
  month: "long",
  day: "numeric",
  year: "numeric",
});

export function formatTimeAMPM(
  timeString: string,
  options: FormatTimeOption = {
    separator: ":",
    ignoreZero: false,
    capitalize: "uppercase",
  },
): string {
  const { separator, ignoreZero, capitalize } = options;
  const keyname = `${timeString}-${JSON.stringify(
    options,
  )}-${ignoreZero}-${capitalize}`;

  if (!timeString) {
    return "No Time";
  }

  if (formatTimeAMPM.cache[keyname]) {
    return formatTimeAMPM.cache[keyname];
  }

  const hours =
    splitTimeInt(timeString).hour < 10
      ? `${ignoreZero ? "" : "0"}${splitTimeInt(timeString).hour}`
      : splitTimeInt(timeString).hour;
  const minutes =
    splitTimeInt(timeString).minute < 10
      ? `${ignoreZero ? "" : "0"}${splitTimeInt(timeString).minute}`
      : splitTimeInt(timeString).minute;
  const ampm = splitTimeInt(timeString).hour >= 12 ? "PM" : "AM";

  formatTimeAMPM.cache[keyname] = `${hours}${separator}${minutes} ${
    capitalize === "lowercase" ? ampm.toLocaleLowerCase() : ampm
  }`;

  return formatTimeAMPM.cache[keyname];
}
formatTimeAMPM.cache = {} as Record<string, string>;

export function splitTimeInt(timeString: string): {
  hour: number;
  minute: number;
  second: number;
} {
  const [hour, minute, second] = timeString.split(":").map((v) => Number(v));
  return {
    hour,
    minute,
    second,
  };
}

export function parseDate(
  dateString: string,
  format: string = dateFormat,
  referenceDate: Date | number = new Date(),
): Date {
  if (parseDate.cache[dateString]) {
    return parseDate.cache[dateString];
  }

  parseDate.cache[dateString] = parse(dateString, format, referenceDate);
  return parseDate.cache[dateString];
}
parseDate.cache = {} as Record<string, Date>;
parseDate.date = new Date();

export function formatDate(
  date: Date | number,
  template: string = dateFormat,
): string {
  const cacheName = `${date.valueOf()}-${template}`;
  if (formatDate.cache[cacheName]) {
    return formatDate.cache[cacheName];
  }

  formatDate.cache[cacheName] = format(date, template);
  return formatDate.cache[cacheName];
}
formatDate.cache = {} as Record<string, string>;

export function formatDateTime(
  date: Date | number,
  template = "yyyy-MM-dd HH:mm:ss",
): string {
  return format(date, template);
}

export const shortDayByIndex = (index: number): ShortDay => {
  return ShortDaysList[index];
};

export const formatDateRange = (startDate: Date, endDate: Date) => {
  const formattedStartDate = dateFormatterWithoutYear.format(startDate);
  const formattedEndDate = dateFormatter.format(endDate);

  return `${formattedStartDate} - ${formattedEndDate}`;
};

export const formatDateToComplete = (date: Date) => {
  return completeDateFormatter.format(date);
};

export const getSecondsRemaining = (futureTimeStamp: number) => {
  const currentTimeStamp = new Date().getTime();
  if (currentTimeStamp > futureTimeStamp) {
    return 0;
  }
  const diffInSeconds = Math.ceil((futureTimeStamp - currentTimeStamp) / 1000);
  return diffInSeconds;
};

export const getDateRangeBetween = (startDate: Date, endDate: Date): Date[] => {
  const dates: Date[] = [];
  let currentDate = startOfDay(startDate);
  while (currentDate <= endOfDay(endDate)) {
    dates.push(currentDate);
    currentDate = addDays(currentDate, 1);
  }
  return dates;
};

export const formatDateString = (dateString: string | null): string => {
  if (!dateString) {
    return "N/A";
  }
  return format(parseDate(dateString), "dd MMM yyyy");
};
