import type {
  InvoicesByFiltersQuery,
  InvoicesByFiltersQueryVariables,
} from "@/__generated__/graphql";
import { InvoicesByFiltersDocument } from "@/__generated__/graphql";
import type { InvoiceCardData } from "@/components/shared/invoices/invoice-card";
import { mapToInvoiceCard } from "@/components/shared/invoices/invoice-card";

import { createPaginatedFactory } from "@/lib/request-factory";

export const usePaidInvoicesStore = createPaginatedFactory<
  InvoicesByFiltersQuery,
  InvoiceCardData,
  InvoicesByFiltersQueryVariables
>({
  method: "query",
  graphqlDocument: InvoicesByFiltersDocument,
  fetchPolicy: "network-only",
  getCountFromResponse: (res) => {
    return res.invoicesByFilters.count;
  },
  transformFunction(data) {
    return data.invoicesByFilters.invoices.map(mapToInvoiceCard);
  },
});
