import { app } from "@/config/env";
import { AD_TRACKING_COOKIE_KEY, GA_ID, LAST_GA_ID } from "@/constants";
import { getCookie, setCookie } from "@/lib/cookie";

export const fetchTrackingUrl = async (url: URL): Promise<string> => {
  const response = await fetch(
    `${app.PUBLIC_API_ENDPOINT.replace("/graphql", "")}/api/v1/service/url_shortener/generate`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ url, category: "Tracking" }),
    },
  );

  if (!response.ok) {
    throw new Error("Response failed.");
  }

  const data = (await response.json()) as { shortened_url: string };
  return data.shortened_url;
};

export const getTrackingKey = async () => {
  const LUCE_WEBSITE_URL = import.meta.env.VITE_LUCE_WEBSITE_URL ?? "";
  const gaId = getCookie(GA_ID) || "";

  try {
    const url = new URL(LUCE_WEBSITE_URL);
    url.searchParams.set("gaid", gaId);
    const trackingUrl = await fetchTrackingUrl(url);
    const key = new URL(trackingUrl).searchParams.get("origin") || "";
    setCookie(AD_TRACKING_COOKIE_KEY, key);
    setCookie(LAST_GA_ID, gaId);
    return key;
  } catch (error) {
    console.error("Failed to shorten the url:", error);
    return "";
  }
};
