import { ShareReferralBanner } from "@/containers/banners/share-referral";
import { View } from "@/components/ui";
import { VisitList } from "../visit-list";
import { MakeABooking } from "@/containers/make-a-booking";
import { EmptyVisit } from "../empty-visit";
import { ServiceAllIcon } from "@/components/shared/icons";
import { SuggestionServiceSection } from "@/containers/suggestion-services";
import { useEffect } from "react";
import { useVisitListState } from "@/store/visits/visitList";
import { usePast30DayVisitsStore } from "@/store/visits/past30dayVisits";

export const VisitsHistory = ({
  refreshing,
  setRefreshing,
}: {
  refreshing?: boolean;
  setRefreshing?(value: boolean): void;
}) => {
  const pastRefetch = usePast30DayVisitsStore((state) => state.refetch);

  const isEmpty = useVisitListState((state) => state.data.listEmpty.past30days);

  useEffect(() => {
    async function refetch() {
      await pastRefetch();
      setRefreshing?.(false);
    }

    if (refreshing) {
      refetch();
    }
  }, [refreshing]);
  return (
    <View className="flex flex-col gap-6 px-4 md:px-0">
      <View className="mt-6">
        <ShareReferralBanner />
      </View>
      {isEmpty ? (
        <>
          <EmptyVisit type="history" />
          <MakeABooking />
          <SuggestionServiceSection
            title="You might also want this"
            icon={ServiceAllIcon}
          />
        </>
      ) : (
        <VisitList type="past30days" />
      )}
    </View>
  );
};
