import { createRequestFactory } from "@/lib/request-factory";
import {
  DeleteClientDocument,
  type DeleteClientMutation,
  type DeleteClientMutationVariables,
} from "@/__generated__/graphql";

export const useDeleteClientStore = createRequestFactory<
  DeleteClientMutation,
  DeleteClientMutation,
  DeleteClientMutationVariables
>({
  method: "mutation",
  graphqlDocument: DeleteClientDocument,
  fetchPolicy: "network-only",
});
