import type { ReactNode } from "react";
import { View } from "@/components/ui";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import { cn } from "@/lib/utils";

const containerVariants = cva("container flex px-0 md:px-4 lg:px-0", {
  variants: {
    direction: {
      col: "flex-col",
      row: "flex-col md:flex-row",
    },
    gap: {
      none: "gap-0",
      xs: "gap-0.5",
      sm: "gap-1",
      md: "gap-2",
      lg: "gap-3",
      xl: "gap-4",
      xxl: "gap-6",
      "3xl": "gap-8",
      "4xl": "gap-10",
      "5xl": "gap-12",
      "6xl": "gap-14",
      "7xl": "gap-16",
    },
    center: {
      true: "justify-center",
      false: "justify-between",
    },
  },
  defaultVariants: {
    direction: "row",
    gap: "xl",
    center: false,
  },
});

type Props = VariantProps<typeof containerVariants> & {
  children: ReactNode;
};

export function Container({ direction, gap, center, children }: Props) {
  return (
    <View className={cn(containerVariants({ direction, gap, center }))}>
      {children}
    </View>
  );
}
