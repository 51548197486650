import { View, RadioGroup, Button } from "@/components/ui";
import { useBookingState } from "@/store/booking/useBookingState";
import { BottomDrawerModal } from "@/components/shared/bottom-drawer-modal";
import { ListHeading } from "@/components/shared/list-header";
import { Cube } from "@/components/shared/icons";
import { ServiceRadioCard } from "../service-radio-card";
import { useMemo } from "react";
import {
  getHomeBeautyPackageFromCode,
  homeBeautyServiceTypeOptions,
} from "@/constants/booking";
import { formatPrice } from "@/lib/helpers/number";
import { showToast } from "@/components/ui/toast/show-toast";
import { useBookingRoute } from "@/components/hooks/use-booking-route";
import { useIntl } from "react-intl";
import { getServiceUnit } from "@/lib/booking-lib";
import { Spin } from "@/components/shared/spin";

export const ChoosePackageModal = ({ name }: { name: string }) => {
  const intl = useIntl();
  const { forward } = useBookingRoute();
  const {
    data: {
      choosePackageModalOpen,
      packageDetailsFilter,
      packageDetails,
      selectedPackageIds,
      homeBeautyPackagesLoading,
    },
    setBookingState,
  } = useBookingState();

  const { title, options } = useMemo(() => {
    const defaultValue = {
      title: "",
      options: [],
    };

    if (!packageDetailsFilter.serviceTypes?.[0]) {
      console.error("ServiceType does not exsit");
    }

    switch (name) {
      case "home-beauty": {
        const option = homeBeautyServiceTypeOptions.find(
          (option) => option.value === packageDetailsFilter.serviceTypes?.[0],
        );
        const options = packageDetails
          .filter(
            (option) =>
              // @ts-expect-error: actually `ServiceTypeEnum` is equal to `PackageSericeTypeEnum`. Really should remove one
              option.serviceType === packageDetailsFilter.serviceTypes?.[0],
          )
          .map((pkg) => {
            const homeBeautyPkg = getHomeBeautyPackageFromCode(pkg.code);
            return {
              title: homeBeautyPkg.title,
              description: homeBeautyPkg.description,
              imgUrl: homeBeautyPkg.imgUrl,
              value: pkg.id,
              subTitle: `Starting ${formatPrice(pkg.serviceBillingValue)}/${getServiceUnit(name)}`,
              thirdTitle: `${pkg.duration * 60} mins`,
            };
          });
        if (options.length) {
          setBookingState({
            selectedPackageIds: [options[0].value],
          });
        }

        return {
          title: option?.serviceTitle || "",
          options,
        };
      }
      default:
        return defaultValue;
    }
  }, [choosePackageModalOpen, name]);

  const onSelect = (value: string) => {
    setBookingState({
      selectedPackageIds: [value],
    });
  };

  const onContinue = () => {
    if (!selectedPackageIds.length) {
      return showToast({
        title: "Please choose your package!",
        type: "info",
      });
    }
    setBookingState({
      choosePackageModalOpen: false,
      homeBeautyFreeGelServiceIds: [],
    });
    forward();
  };

  return (
    <BottomDrawerModal
      open={choosePackageModalOpen}
      onOpenChange={() => {
        setBookingState({
          choosePackageModalOpen: false,
        });
      }}
    >
      <View className="flex flex-col gap-2">
        <ListHeading title={title} icon={Cube} />
        {homeBeautyPackagesLoading && (
          <Spin className="my-12 size-12 shrink-0" />
        )}
        <RadioGroup value={selectedPackageIds[0]} onValueChange={() => {}}>
          {options.map((option) => {
            return (
              <ServiceRadioCard
                key={option.value}
                active={selectedPackageIds[0] === option.value}
                onSelect={() => onSelect(option.value)}
                imgUrl={option.imgUrl}
                title={option.title}
                subTitle={option.subTitle}
                thirdTitle={option.thirdTitle}
                description={option.description}
                value={option.value}
              />
            );
          })}
        </RadioGroup>
        <Button
          variant="primary"
          color="CTA"
          rounded="full"
          onClick={onContinue}
          children={intl.formatMessage({
            defaultMessage: "Continue",
            id: "proceed",
          })}
        />
      </View>
    </BottomDrawerModal>
  );
};
