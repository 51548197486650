import { useEffect } from "react";

// refreshSlot after return to this page.
export const useRefreshSlot = (refetch: () => void) => {
  useEffect(() => {
    const onVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        refetch();
      }
    };
    document.addEventListener("visibilitychange", onVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, [refetch]);
};
