import { View, Image } from "@/components/ui";
import QrCode from "@/assets/images/qr-code.jpeg";
import LuceApp from "@/assets/images/luce-app.png";
import { Typography } from "@/components/shared/typography";
import { useIntl } from "react-intl";
import AppStoreSocialBtn from "@/assets/icons/svgs/app-store-social-btn.svg";
import GooglePlaySocialBtn from "@/assets/icons/svgs/google-play-social-btn.svg";
import { links } from "@/constants";
import { rudderanalytics } from "@/lib/rudderstack";
import { getIdentifiersFromStorage } from "@/lib/identifiers";
import { handleOpenLink } from "@/lib/platform";

const images = {
  qrcode: QrCode,
  luceapp: LuceApp,
};

export function GetTheApp({
  imageVariant = "qrcode",
}: {
  imageVariant?: "qrcode" | "luceapp";
}) {
  const intl = useIntl();

  const sendToRudderStack = async (href: string) => {
    await handleOpenLink(href);
    const identifiers = await getIdentifiersFromStorage();
    rudderanalytics.track("app_download_button_click", {
      button_location: href,
      ...identifiers,
    });
  };
  return (
    <View className="flex w-full flex-col gap-4 rounded-lg bg-white p-4 shadow-sm">
      <View className="mb-2 flex flex-col gap-y-1">
        <Typography variant="body-2xl" color="brand-primary">
          {intl.formatMessage({
            defaultMessage: "Get The Luce App!",
            id: "get-the-luce-app",
          })}
        </Typography>
        <Typography variant="body-md" color="foreground-intermediate">
          Be the first to try our Beta App. Book services, get notifications,
          manage cleaning schedule and pay in the app.
        </Typography>
      </View>
      <View className="flex flex-col items-center gap-4">
        <Image
          src={images[imageVariant]}
          className={
            imageVariant === "qrcode" ? "size-[88px] max-w-full" : "w-full"
          }
        />
        <View className="align-center mt-2 flex flex-col items-center gap-2">
          <Typography variant="label-md" color="brand-primary">
            {intl.formatMessage({
              defaultMessage: "Get the app now!",
              id: "get-the-app-now",
            })}
          </Typography>
          <View className="flex gap-2">
            <View
              onClick={() => sendToRudderStack(links.appStoreClientApp)}
              className="cursor-pointer rounded-full border border-primary-border bg-primary-surface px-4 py-2"
            >
              <Image src={AppStoreSocialBtn} />
            </View>
            <View
              onClick={() => sendToRudderStack(links.googlePlayClientApp)}
              className="cursor-pointer rounded-full border border-primary-border bg-primary-surface px-4 py-2"
            >
              <Image src={GooglePlaySocialBtn} />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
