import { SignOutDocument } from "@/__generated__/graphql";
import { DEFAULT_USER_DATA } from "@/constants";
import { storage } from "@/lib/storage";
import { tokenManager } from "@/lib/token-manager";
import { useAuthState } from "@/store/auth";
import { useClientStore } from "@/store/auth/client";
import { useApolloClient, useMutation } from "@apollo/client";
import type { ReactNode } from "react";
import { createContext, useContext, useState, useEffect } from "react";
import { LoadingDialog } from "../loading-dialog";
import { Spin } from "../spin";
import { View } from "@/components/ui";
import { Container } from "@/components/ui/layout";

const CLIENT_ID_KEY = "clientId";

export type LoginArgs = {
  jwt: string;
  refreshToken: string;
  clientId: string;
};

type Props = {
  children: ReactNode;
};

type AuthSession = {
  login: (args: LoginArgs) => void;
  logout: () => Promise<void>;
  isLoading: boolean;
  isAuthenticated: boolean;
  isGuest: boolean;
  clientId: string;
};

const AuthContext = createContext<AuthSession>({
  login: () => {},
  logout: async () => {},
  isLoading: true,
  isAuthenticated: false,
  isGuest: true,
  clientId: "",
});

export function useAuth(): AuthSession {
  return useContext<AuthSession>(AuthContext);
}

export const AuthProvider = ({ children }: Props) => {
  const apolloClient = useApolloClient();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isGuest, setGuest] = useState<boolean>(true);

  const [clientId, setClientId] = useState<string>("");

  const [_signOut, { loading: logoutLoading }] = useMutation(SignOutDocument);

  const restoreSession = async (): Promise<void> => {
    console.log("restore session");

    // Proceed restore session using query URL from old app
    const tokenData = getTokenQuery();

    if (tokenData?.token && tokenData.clientId) {
      login({
        jwt: tokenData.token,
        clientId: tokenData.clientId,
        refreshToken: tokenData.refreshToken,
      });
      setLoading(false);
      return;
    }

    try {
      const _clientId = await storage.getItem<string>(CLIENT_ID_KEY);
      if (_clientId) {
        setClientId(_clientId);
      }

      const result = await tokenManager.isTokenAvailable();
      if (result) {
        setGuest(false);
        await useClientStore.getState().fetch({
          requestPayload: {
            id: _clientId,
          },
        });
      }
    } catch (error) {
      console.log("session: restore session", error);
    }

    setLoading(false);
  };

  /* used in apollo client if we can't refresh jwt */
  const logoutCallback = (): void => {
    setLoading(true);
    setGuest(true);
    tokenManager.clearTokens();
    apolloClient.clearStore();

    // TODO: probably we will delete this code when we less depend to useAuthState
    const { setUserInfo } = useAuthState.getState();
    setUserInfo(DEFAULT_USER_DATA);
    storage.deleteAll();

    setLoading(false);
  };

  useEffect(() => {
    /* Try to get jwt and refresh token from the backend on start */
    tokenManager.setLogoutCallback(logoutCallback);
    restoreSession();
  }, []);

  const storeClientID = async (id: string): Promise<void> => {
    setClientId(id);
    return storage.setItem(CLIENT_ID_KEY, id);
  };

  const login = ({ jwt, refreshToken, clientId }: LoginArgs): void => {
    tokenManager.setTokens({ token: jwt, refreshToken });
    storeClientID(clientId);
    setGuest(false);
    setLoading(false);
    useClientStore.getState().fetch({
      requestPayload: {
        id: clientId,
      },
    });
  };

  const logout = async (): Promise<void> => {
    try {
      await _signOut();
    } catch (error) {
      console.log("session: logout error");
      console.log("error:", error);
    } finally {
      logoutCallback();
    }
  };

  if (isLoading) {
    return (
      <Container center>
        <View className="flex h-screen flex-row items-center justify-center">
          <Spin className="size-10" />
        </View>
      </Container>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        isGuest,
        isAuthenticated: !isGuest,
        login,
        logout,
        clientId,
      }}
    >
      <LoadingDialog open={logoutLoading} />
      {children}
    </AuthContext.Provider>
  );
};

const getTokenQuery = () => {
  if (window && window.document) {
    const URL = window.location.search;
    const params = new URLSearchParams(URL);

    const token = atob(params.get("token") ?? "");
    const refreshToken = atob(params.get("refresh") ?? "");
    const clientId = atob(params.get("clientId") ?? "");

    return {
      token,
      refreshToken,
      clientId,
    };
  }

  return undefined;
};
