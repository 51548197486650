import { createResponsiveCVA } from "@/lib/styles";

type IconButtonVariants = {
  variant: {
    primary: string;
    secondary: string;
    tertiary: string;
    subtle: string;
    ghost: string;
  };
  color: {
    CTA: string;
    CTA2: string;
    danger: string;
    success: string;
    foreground: string;
    white: string;

    "CTA2-subtle": string;
    "danger-subtle": string;

    "CTA2-tertiary": string;

    "fill-CTA": string;
    "fill-CTA2": string;
    "fill-danger": string;
    "fill-success": string;
    "fill-foreground": string;

    "fill-CTA2-tertiary": string;
  };
  size: {
    xs: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
  };
  rounded: {
    md: string;
    lg: string;
    full: string;
  };
  fullWidth: {
    none: string;
    full: string;
  };
};

export const iconButtonVariants = createResponsiveCVA<IconButtonVariants>(
  "flex flex-row items-center justify-center whitespace-nowrap transition-colors web:ring-offset-background web:focus-visible:outline-none web:focus-visible:ring-2 web:focus-visible:ring-offset-2 disabled:pointer-events-none rounded-lg",
  {
    // the latest class will be prioritized
    // thats mean the latest class on the line will be override the previously class

    // for subtle variant, use color with SUBTLE/subtle prefix

    default: {
      color: {
        CTA: "bg-button-cta hover:bg-button-cta-hover border border-button-cta",
        CTA2: "bg-button-cta2 hover:bg-button-cta2-hover border border-button-cta2 hover:border-button-cta2-hover",
        danger: "bg-danger border border-danger",
        success: "bg-success border border-success",
        foreground:
          "bg-foreground hover:bg-foreground-intermediate border border-input",
        white: "",
        "CTA2-subtle":
          "bg-button-cta2-surface hover:bg-button-cta2-surface-hover border border-button-cta2-surface hover:border-button-cta2-surface-hover",
        "danger-subtle": "bg-danger-surface border border-danger-surface",
        "CTA2-tertiary":
          "bg-button-cta2 hover:bg-button-cta2-hover border border-button-cta2 hover:border-button-cta2-hover",
        "fill-CTA":
          "bg-button-cta hover:fill-bg-button-cta-hover border border-button-cta",
        "fill-CTA2":
          "bg-button-cta2 hover:bg-button-cta2-hover border border-button-cta2 hover:border-button-cta2-hover",
        "fill-danger": "bg-danger",
        "fill-foreground": "bg-foreground hover:bg-foreground-intermediate",
        "fill-success": "bg-success",

        "fill-CTA2-tertiary":
          "bg-button-cta2 hover:bg-button-cta2-hover border border-button-cta2 hover:border-button-cta2-hover",
      },
      variant: {
        primary: "hover:text-button-on-cta disabled:bg-background-attenuated",
        secondary:
          "bg-transparent hover:bg-transparent disabled:border-secondary-border",
        tertiary:
          "border-0 bg-white hover:bg-white disabled:bg-background-attenuated",
        subtle: "disabled:bg-background-attenuated",
        ghost:
          "bg-transparent hover:bg-transparent disabled:border-secondary-border border-none",
      },
      size: {
        xs: "p-[6px]",
        sm: "p-2",
        md: "p-2",
        lg: "p-2",
        xl: "p-2",
      },
      rounded: {
        md: "rounded-md",
        lg: "rounded-lg",
        full: "rounded-full",
      },
      fullWidth: {
        none: "flex",
        full: "w-full flex-1",
      },
    },
  },
  {
    variant: "secondary",
    color: "CTA2",
    size: "lg",
    // will be adjust the default button on next fix
  },
);

export const iconVariants = createResponsiveCVA(
  "",
  {
    default: {
      size: {
        xs: "size-2",
        sm: "size-4",
        md: "size-5",
        lg: "size-6",
        xl: "size-8",
      },
      color: {
        CTA: "text-button-cta",
        CTA2: "text-button-cta2",
        danger: "text-danger",
        success: "text-success",
        foreground: "text-foreground",
        white: "text-white",

        "CTA2-subtle": "hover:text-button-cta2-hover text-button-cta2",
        "danger-subtle": "text-danger",
        "CTA2-tertiary": "text-primary",
        "fill-CTA": "fill-button-cta",
        "fill-CTA2": "fill-button-cta2",
        "fill-danger": "fill-danger",
        "fill-success": "fill-success",
        "fill-foreground": "fill-text-foreground",
        "fill-CTA2-tertiary": "fill-text-primary",
      },
      variant: {
        primary: "text-button-on-cta",
        secondary: "",
        tertiary: "",
        subtle: "",
        ghost: "",
      },
    },
  },
  {
    color: "CTA2",
    size: "lg",
  },
);

export const iconButtonLoadingVariants = createResponsiveCVA(
  "animate-spin",
  {
    default: {
      size: {
        xs: "size-2",
        sm: "size-4",
        md: "size-6",
        lg: "size-8",
        xl: "size-10",
      },
    },
  },
  {
    size: "lg",
  },
);
