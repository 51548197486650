import { Text, View } from "@/components/ui";
import { UserProfileIcon } from "@/components/shared/icons";
import { IfElse } from "@/components/shared/if-else";
import { useAuthState } from "@/store/auth";
import { useMemo } from "react";
import { getUserNameShortCut } from "@/lib/helpers/string";
import { useAuth } from "../../auth-provider";

export function UserAvatar({ badged }: { badged?: boolean }) {
  const {
    data: { userInfo },
  } = useAuthState();

  const nameShortCut = useMemo(() => {
    return getUserNameShortCut({
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
    });
  }, [userInfo]);

  const { isAuthenticated } = useAuth();

  return (
    <View>
      <IfElse
        if={isAuthenticated}
        else={<UserProfileIcon className="h-8 w-8 color-brand-primary" />}
      >
        <View className="relative flex size-8 items-center justify-center rounded-full bg-white text-brand-primary">
          <IfElse if={!!badged}>
            <View className="absolute right-0 top-0 flex size-3 items-center justify-center rounded-full bg-white">
              <View className="size-2 rounded-full bg-danger"></View>
            </View>
          </IfElse>
          <Text>{nameShortCut}</Text>
        </View>
      </IfElse>
    </View>
  );
}
