export const SENTRY_IGNORE_STRS = [
  "dynamically imported module",
  "javascript mime type",
  "importing a module",
  "failed to fetch",
  "network error",
  "fetch error",
  "xhr error",
  "adsbygoogle",
];
