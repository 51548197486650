const getItem = (key: string) => {
  return sessionStorage.getItem(key);
};

const clear = () => {
  return sessionStorage.clear();
};

export const session = {
  getItem,
  clear,
};
