import type { UserInfo } from "@/types/users";
import { AuthModals, LoginType, SignUpStep } from "@/types/users";
import type { StoreApi } from "zustand";
import { create } from "zustand";
import type { AddressFormReturn } from "./forms/useAddressForm";
import type { SignUpFormReturn } from "./forms/useSignUpForm";
import { DEFAULT_USER_DATA } from "@/constants";
import { isLocal } from "@/lib/utils";

type ConfigFn<T extends { data: AuthState }> = (
  set: StoreApi<T>["setState"],
  get: StoreApi<T>["getState"],
  api: StoreApi<T>,
) => T;

// Define the log function with the correct types
const log =
  <T extends { data: AuthState }>(config: ConfigFn<T>) =>
  (
    set: StoreApi<T>["setState"],
    get: StoreApi<T>["getState"],
    api: StoreApi<T>,
  ) => {
    if (!isLocal) {
      return config(set, get, api);
    }

    return config(
      (...args) => {
        console.groupCollapsed(
          `AuthState - ${Object.keys(args[0] || {})[0] || "Unknown state change"}`,
        );
        console.log("Applying new state", args[0]);
        set(...args);
        console.log("New State", get().data);
        console.groupEnd();
      },
      get,
      api,
    );
  };

type AuthState = {
  phone: string;
  pinCode: string;
  postalCode: string;
  loginModalOpen: boolean;
  otpModalOpen: boolean;
  signUpModalOpen: boolean;
  emailLoginModalOpen: boolean;
  forgotPasswordModalOpen: boolean;
  addressModalOpen: boolean;
  logoutModalOpen: boolean;
  accessToken?: string;
  userInfo: UserInfo;
  welcomeModalOpen: boolean;
  type: LoginType;
  addressFormReturn: AddressFormReturn | null;
  signUpFormReturn: SignUpFormReturn | null;
  changePhoneOtp: boolean;
  loginByModal: boolean;
  signUpStep: SignUpStep;
};

export const useAuthState = create(
  log<{
    data: AuthState;
    setLoginByModal: (loginByModal: boolean) => void;
    setPhone: (phone: string) => void;
    setPinCode: (pinCode: string) => void;
    setPostalCode: (postalCode: string) => void;
    setUserInfo: (userInfo: Partial<UserInfo>) => void;
    setType: (type: LoginType) => void;
    setAddressFormReturn: (data: AddressFormReturn | null) => void;
    setSignUpFormReturn: (data: SignUpFormReturn | null) => void;
    setChangePhoneOtp: (changePhoneOtp: boolean) => void;
    showModal: (...modalNames: AuthModals[]) => void;
    setSignUpStep: (signUpStep: SignUpStep) => void;
  }>((set, get) => ({
    data: {
      phone: "",
      pinCode: "",
      postalCode: "",
      loginModalOpen: false,
      otpModalOpen: false,
      signUpModalOpen: false,
      emailLoginModalOpen: false,
      forgotPasswordModalOpen: false,
      addressModalOpen: false,
      welcomeModalOpen: false,
      logoutModalOpen: false,
      type: LoginType.Login,
      addressFormReturn: null,
      signUpFormReturn: null,
      userInfo: DEFAULT_USER_DATA,
      changePhoneOtp: false,
      loginByModal: true,
      signUpStep: SignUpStep.ValidateNumber,
    },
    setSignUpStep: (signUpStep: SignUpStep) => {
      set({ data: { ...get().data, signUpStep } });
    },
    setLoginByModal: (loginByModal: boolean) => {
      set({ data: { ...get().data, loginByModal } });
    },
    setAddressFormReturn: (addressFormReturn: AddressFormReturn | null) => {
      set({ data: { ...get().data, addressFormReturn } });
    },
    setSignUpFormReturn: (signUpFormReturn: SignUpFormReturn | null) => {
      set({ data: { ...get().data, signUpFormReturn } });
    },
    setType: (type: LoginType) => {
      set({ data: { ...get().data, type } });
    },
    setPhone: (phone: string) => {
      set({ data: { ...get().data, phone } });
    },
    setPinCode: (pinCode: string) => {
      set({ data: { ...get().data, pinCode } });
    },
    setPostalCode: (postalCode: string) => {
      set({ data: { ...get().data, postalCode } });
    },
    setUserInfo: (userInfo: Partial<UserInfo>) => {
      set({
        data: {
          ...get().data,
          userInfo: { ...DEFAULT_USER_DATA, ...userInfo },
        },
      });
    },
    setChangePhoneOtp: (changePhoneOtp: boolean) => {
      set({ data: { ...get().data, changePhoneOtp } });
    },
    showModal: (...modalNames: (AuthModals | null)[]) => {
      set({
        data: {
          ...get().data,
          loginModalOpen: modalNames.includes(AuthModals.LOGIN),
          otpModalOpen: modalNames.includes(AuthModals.OTP),
          signUpModalOpen: modalNames.includes(AuthModals.SIGN_UP),
          emailLoginModalOpen: modalNames.includes(AuthModals.EMAIL_LOGIN),
          forgotPasswordModalOpen: modalNames.includes(
            AuthModals.FORGOT_PASSWORD,
          ),
          addressModalOpen: modalNames.includes(AuthModals.ADDRESS),
          welcomeModalOpen: modalNames.includes(AuthModals.WELCOME),
          logoutModalOpen: modalNames.includes(AuthModals.LOGOUT),
        },
      });
    },
  })),
);
