import {
  Card,
  CardContent,
  View,
  Image,
  Button,
  Skeleton,
} from "@/components/ui";
import {
  FileText,
  Notepad,
  Files,
  CaretRight,
  XCircle,
} from "@/components/shared/icons";
import ContactUs from "@/assets/images/contact-us.png";
import { useWindowDimensions } from "@/components/hooks/use-window-dimension";
import { getPackageJobMonthsName, mapJobsToSchedules } from "../utils";
import {
  PackageIssueType,
  usePackageIssueStore,
} from "@/store/report-issue/package-issue/packageIssue";
import { useState } from "react";
import { CancelPackageConfirmation } from "../packages/report-package-issue/cancel-package-confirmation";
import { usePackage } from "@/store/packages/useGetPackage";
import { formatDateString } from "@/lib/helpers/date";
import { LineItemList } from "@/components/shared/line-item/line-item-list";
import {
  getFrequencyLabel,
  getLineItemClientDetail,
} from "@/lib/line-items/helpers";
import { PackageCredit } from "./package-credit";
import { onChatSupport } from "@/lib/chat-support";
import { Typography } from "@/components/shared/typography";
import { Link } from "@/components/shared/link";
import { links } from "@/constants";

export const PackageDetailCard = ({ id }: { id: string }) => {
  const { isMobile } = useWindowDimensions();
  const [openCancelConfirmation, setOpenCancelConfirmation] =
    useState<boolean>(false);

  const { packageData, loading } = usePackage({ packageId: id });
  const schedules = mapJobsToSchedules(packageData?.jobs ?? []);

  const frequencyLabel = packageData
    ? getFrequencyLabel(packageData.repeatEvery, packageData.repeatEveryTimes)
    : "-";

  const repeatJobEvery = packageData
    ? getPackageJobMonthsName(packageData)
    : "-";

  const { openPackageIssueModal } = usePackageIssueStore();

  const handleModifyPlan = () => {
    if (packageData) {
      openPackageIssueModal(PackageIssueType.modifyPlan, packageData.id);
    }
  };

  const handleEditAddress = () => {
    if (packageData) {
      openPackageIssueModal(PackageIssueType.editAddress, packageData.id);
    }
  };

  const handleOpenCancelConfirmation = () => {
    setOpenCancelConfirmation(true);
  };

  const handleCancelPackage = () => {
    if (packageData) {
      setOpenCancelConfirmation(false);
      openPackageIssueModal(PackageIssueType.cancelPackage, packageData.id);
    }
  };

  const handleCloseCancelConfirmation = () => {
    setOpenCancelConfirmation(false);
  };

  const lineItems = packageData?.packageLineItems.map((pkg) =>
    getLineItemClientDetail(pkg.packageDetail, {
      description: pkg.packageDescription,
    }),
  );

  return (
    <>
      <Card border={isMobile ? "none" : "default"}>
        <CardContent className="md:p-0">
          <View className="flex flex-col gap-1 border-b border-secondary-border px-4 py-3">
            {loading ? (
              <Skeleton className="h-6 w-[400px]" />
            ) : (
              <>
                <Typography variant="body-xl">
                  {packageData?.packageDescription}
                </Typography>
                <Typography variant="body-md" color="foreground-intermediate">
                  {packageData?.fullAddress}
                </Typography>
              </>
            )}
          </View>

          <View className="border-b border-secondary-border px-4 pt-3">
            <View className="flex flex-row gap-3">
              <FileText weight="fill" className="text-primary" size={24} />
              <Typography className="text-lg">Details</Typography>
            </View>

            <View className="flex flex-row justify-between border-b border-secondary-border py-4">
              <Typography variant="body-md" color="foreground-intermediate">
                Frequency
              </Typography>
              {loading ? (
                <Skeleton className="h-4 w-[200px]" />
              ) : (
                <>
                  <Typography variant="body-md">{frequencyLabel}</Typography>
                </>
              )}
            </View>
            <View className="flex flex-row justify-between border-b border-secondary-border py-4">
              <Typography variant="body-md" color="foreground-intermediate">
                Repeats Every
              </Typography>
              {loading ? (
                <Skeleton className="h-4 w-[200px]" />
              ) : (
                <Typography variant="body-md">{repeatJobEvery}</Typography>
              )}
            </View>
            <View className="flex flex-row justify-between border-b border-secondary-border py-4">
              <Typography variant="body-md" color="foreground-intermediate">
                Start Date
              </Typography>
              {loading ? (
                <Skeleton className="h-4 w-[100px]" />
              ) : (
                <Typography variant="body-md">
                  {packageData?.startDate
                    ? formatDateString(packageData.startDate)
                    : "Not Set"}
                </Typography>
              )}
            </View>
            <View className="flex flex-row justify-between border-b border-secondary-border py-4">
              <Typography variant="body-md" color="foreground-intermediate">
                End Date
              </Typography>
              {loading ? (
                <Skeleton className="h-4 w-[100px]" />
              ) : (
                <Typography variant="body-md">
                  {packageData?.endDate
                    ? formatDateString(packageData.endDate)
                    : "Not Set"}
                </Typography>
              )}
            </View>
            <View className="flex flex-row justify-between py-4">
              <Typography variant="body-md" color="foreground-intermediate">
                Scheduled Timing
              </Typography>
              {loading ? (
                <Skeleton className="h-4 w-[120px]" />
              ) : (
                <Typography variant="body-md">{schedules[0]}</Typography>
              )}
            </View>
          </View>

          <View className="border-b border-secondary-border px-4 py-3">
            <View className="flex flex-row gap-3">
              <Notepad weight="fill" className="text-primary" size={24} />
              <Typography className="text-lg">Items</Typography>
            </View>
            {loading ? (
              <Skeleton className="h-10 w-full pt-4" />
            ) : (
              <LineItemList lineItems={lineItems ?? []} />
            )}
          </View>

          <View className="flex flex-row border-b border-secondary-border px-4 py-5">
            <View className="flex flex-1 flex-col items-center">
              <Button
                variant="tertiary"
                iconAlignment="top"
                color="foreground"
                iconName="mapPinSimpleArea"
                onClick={handleEditAddress}
                children="Edit Address"
              />
            </View>

            <View className="flex flex-1 flex-col items-center">
              <Button
                variant="tertiary"
                iconAlignment="top"
                color="foreground"
                iconName="notePencil"
                onClick={handleModifyPlan}
                children="Modify Plan"
              />
            </View>
          </View>

          {packageData?.creditAccount && (
            <PackageCredit creditAccount={packageData.creditAccount} />
          )}

          <View className="border-y border-secondary-border p-4 md:hidden">
            <View className="relative rounded-xl border border-primary-border bg-primary-surface p-2">
              <View>
                <Typography variant="body-md" className="ml-2">
                  Experiencing issues?
                </Typography>
                <Typography
                  variant="body-md"
                  color="secondary-text"
                  className="ml-2"
                >
                  Contact our Customer Support
                </Typography>
                <Button
                  variant="primary"
                  color="success"
                  fullWidth="full"
                  className="relative z-10 mt-4 flex w-full max-w-[187px] flex-row gap-2"
                  onClick={onChatSupport}
                  children="Chat our Support"
                  iconName="whatsappLogo"
                />
              </View>
              <View className="absolute bottom-0 right-2 z-0">
                <Image
                  src={ContactUs}
                  className="h-30 w-20"
                  width={80}
                  height={100}
                />
              </View>
            </View>
          </View>

          <View className="border-y border-secondary-border p-4">
            <View className="flex flex-row items-center justify-between">
              <View className="flex flex-1 cursor-pointer flex-row items-stretch gap-2">
                <Files
                  size={24}
                  weight="fill"
                  className="text-foreground-intermediate"
                />
                <Link href={links.legalPrivacyPolicy} className="flex-1">
                  <Typography variant="body-xl">Terms & Conditions</Typography>
                </Link>
              </View>
              <CaretRight size={16} />
            </View>
          </View>

          <View className="p-4">
            <View
              className="flex cursor-pointer flex-row items-center justify-between"
              onClick={handleOpenCancelConfirmation}
            >
              <View className="flex flex-row items-center gap-2">
                <XCircle size={24} weight="fill" className="text-danger" />
                <Typography variant="body-xl" color="danger">
                  Cancel Plan
                </Typography>
              </View>
              <CaretRight size={16} className="text-danger" />
            </View>
          </View>
        </CardContent>
      </Card>
      <CancelPackageConfirmation
        onConfirm={handleCancelPackage}
        onClose={handleCloseCancelConfirmation}
        open={openCancelConfirmation}
      />
    </>
  );
};
