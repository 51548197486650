import { UserDeviceEnum } from "@/__generated__/graphql";
import { showToast } from "@/components/ui/toast/show-toast";
import { collectError } from "../monitoring/sentry/index.web";

export const handleWhatsappNumber = (number: string, template = "") => {
  const phone = number.replace(/\D/g, "");
  handleOpenLink("//wa.me/" + phone + "?text=" + template);
};

export const getUserDevice = () => {
  return UserDeviceEnum.Browser;
};

export function makeFileFromPicker(fileInfo: File, customFilename?: string) {
  const fileName = fileInfo.name.replace(/\.[^/.]+$/, "");
  const type = fileInfo.type.split("/");
  const format = type[1];

  const file = new File(
    [fileInfo],
    customFilename ? `${customFilename}.${format}` : `${fileName}.${format}`,
    { type: fileInfo.type },
  );

  return Promise.resolve(file);
}

export const handleOpenLink = async (url: string) => {
  window.open(url, "_blank");
  return Promise.resolve(true);
};

export const share = (url: string, message = url) => {
  if (!navigator.canShare) {
    showToast({
      type: "error",
      title: "Your browser doesn't support the Web Share API.",
    });
    return;
  }

  navigator.share({ url, text: message }).catch((error) => {
    if (error instanceof Error) {
      const errString = error.toString();
      if (
        errString.includes("AbortError") ||
        errString.includes("InvalidStateError")
      ) {
        // ignore when user cancel share
        return;
      } else if (errString.includes("NotAllowedError")) {
        showToast({
          type: "error",
          title:
            "Sharing failed due to blocked permissions, inactive window, or security restrictions. Please check your settings and try again",
        });
      }
    }

    collectError(error, {
      context: {
        name: "Web Share",
        values: {
          url,
          message,
        },
      },
    });
  });
};

export const downloadFromUrl = async (url: string, fileName: string) => {
  // TODO: handle download from url correctly
  try {
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.target = "_blank";
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    return Promise.resolve();
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};
