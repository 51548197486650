import type {
  PackageDepartmentEnum,
  PackageServiceTypeEnum,
  VisitStatusEnum,
} from "@/__generated__/graphql";
import { Card, CardContent, View } from "@/components/ui";

import { packageServiceTypeLabel } from "@/lib/service/helpers";
import { WorkerAvatar } from "../visit-card/worker-avatar";
import { VisitStatusBadge } from "../visit-card/visit-status-badge";

import { formatVisitSchedule } from "../utils";
import type { VisitWorker } from "../visit-card";
import { Ratings } from "@/components/ui/ratings";
import type { ReactNode } from "react";
import { cn } from "@/lib/utils";
import { Typography } from "../../typography";

type RateVisitData = {
  id: string;
  status: VisitStatusEnum;
  department: PackageDepartmentEnum;
  serviceType: PackageServiceTypeEnum;
  serviceDate: Date;
  startTime: string;
  endTime: string;
  rating: number;
  worker: VisitWorker;
};

interface Props {
  visitData: RateVisitData;
  handleRateVisit(visitId: string, rate?: number): void;
  actions?: ReactNode;
}

export function RateVisitCard({ visitData, handleRateVisit, actions }: Props) {
  return (
    <Card gap="lg" radius="xl" shadow="sm">
      <CardContent
        className={cn(
          "flex flex-row items-center justify-between gap-2",
          actions ? "border-b border-border-medium" : "pb-0",
        )}
      >
        <Typography variant="h7">Let's Rate It</Typography>
        {actions}
      </CardContent>
      <CardContent className="py-0">
        <Typography variant="label-2xl">
          {packageServiceTypeLabel(visitData.serviceType, visitData.department)}
        </Typography>
        <Typography variant="label-xl" color="foreground-intermediate">
          {formatVisitSchedule(
            visitData.startTime,
            visitData.endTime,
            visitData.serviceDate,
          )}
        </Typography>
      </CardContent>
      <Divider />
      <CardContent className="flex flex-row justify-between py-0">
        <View className="max-w-[50%] flex-1">
          <WorkerAvatar
            fullName={visitData.worker.fullName}
            avatarUrl={visitData.worker.avatarUrl}
          />
        </View>
        <VisitStatusBadge status={visitData.status} />
      </CardContent>

      <Divider />
      <CardContent className="pt-0">
        <View>
          <Ratings
            variant="yellow"
            size={32}
            rating={visitData.rating}
            onClick={(rate) => handleRateVisit(visitData.id, rate)}
          />
        </View>
      </CardContent>
    </Card>
  );
}

function Divider() {
  return (
    <View className="native:border-b-border native:border-solid mx-4 border-b border-dashed border-border-medium" />
  );
}
