import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  type ServerError,
} from "@apollo/client";
import { app } from "@/config/env";
import {
  InMemoryCache,
  type NormalizedCacheObject,
} from "@apollo/client/cache";
import { createUploadLink } from "apollo-upload-client";
import { type ErrorLink, onError } from "@apollo/client/link/error";
import possibleTypes from "@/__generated__/possibleTypes.json";
import { tokenManager } from "./token-manager";

function initClientInstance(): ApolloClient<NormalizedCacheObject> {
  const httpLink: HttpLink = new HttpLink({
    uri: app.PUBLIC_API_ENDPOINT,
  });

  const uploadLink = createUploadLink({
    uri: app.PUBLIC_API_ENDPOINT,
  });

  const link = ApolloLink.split(
    (operation) => !!operation.getContext().hasUpload,
    uploadLink,
    httpLink,
  );

  const onErrorCallback: ErrorLink.ErrorHandler = ({
    graphQLErrors,
    networkError,
  }) => {
    const serverError = networkError as ServerError;
    if ([503, 504].includes(serverError?.statusCode)) {
      console.log(
        `[Maintenance Mode]: Server current on status ${
          serverError.response.status
        }`,
      );
      return;
    }

    if (serverError?.message === "Failed to fetch") {
      return;
    }

    const statusCode = serverError?.statusCode;
    if (statusCode === 204 || statusCode >= 400) {
      // server error
      console.log(`[Network error]: ${serverError.response.status}`);
    }

    if (graphQLErrors?.length === 0) {
      // no errors
      return;
    }

    for (const error of graphQLErrors ?? []) {
      const { message, locations, path } = error;

      const m = `Message: ${message}`;
      const l = `Location: ${JSON.stringify(locations)}`;
      const p = `Path: ${JSON.stringify(path)}`;
      console.log(`[GraphQL error]: ${m}, ${l}, ${p}`);
    }
  };

  const errorLink = onError(onErrorCallback);

  // https://www.apollographql.com/docs/react/data/fragments/#generating-possibletypes-automatically
  const cache = new InMemoryCache({
    possibleTypes,
    typePolicies: {
      FreeTimeslot: {
        keyFields: ["id", "startAt", "endAt"],
      },
    },
  });

  return new ApolloClient({
    link: ApolloLink.from([
      tokenManager.authHeaderLink(),
      errorLink,
      tokenManager.errorLink(),
      link,
    ]),
    cache,
  });
}

export const ClientInstance = initClientInstance();
