import { Typography } from "@/components/shared/typography";
import { Dialog, DialogContent, View, Button } from "@/components/ui";
import { LuceSparkle } from "@/components/shared/icons";
import { Divider } from "@/components/shared/divider";

const slogans = [
  {
    title: "Same Trusted Cleaner",
    description:
      "Get your preferred cleaner every time for consistent, reliable results.",
  },
  {
    title: "Hassle-Free Scheduling",
    description: "Set it once and forget it—no need to rebook each time.",
  },
  {
    title: "Priority Booking",
    description:
      "Guaranteed slots, even during busy periods like weekends or holidays.",
  },
  {
    title: "Better Value for Your Money",
    description: "Enjoy discounted rates compared to one-time cleanings.",
  },
];

export const UnlockMoreModal = ({
  open,
  loading,
  setOpen,
  onTryRecurring,
  onKeepOneTime,
}: {
  open: boolean;
  loading: boolean;
  setOpen(open: boolean): void;
  onTryRecurring(): void;
  onKeepOneTime(): void;
}) => {
  return (
    <Dialog open={open} onOpenChange={() => setOpen(false)}>
      <DialogContent className="max-w-[343px] rounded-xl md:w-[375px]">
        <View className="flex flex-col gap-4">
          <Typography variant={{ md: "label-xl", sm: "label-md" }}>
            Unlock More Value with Weekly/Fortnightly Cleaning!
          </Typography>
          <Divider />
          <View>
            {slogans.map((slogan) => (
              <View
                key={slogan.title}
                className="mb-2 flex flex-row items-start gap-2"
              >
                <LuceSparkle className="mt-2 inline size-3 shrink-0 text-secondary-button md:mt-1.5 md:size-4" />
                <View>
                  <Typography
                    variant={{ md: "bold-lg", sm: "bold-md" }}
                    className="inline"
                  >
                    {slogan.title}
                  </Typography>
                  <Typography variant={{ md: "body-md", sm: "body-sm" }}>
                    {slogan.description}
                  </Typography>
                </View>
              </View>
            ))}
          </View>
          <View className="mt-2 flex flex-col gap-2">
            <Button
              variant="primary"
              color="CTA"
              onClick={onTryRecurring}
              children="Try a Recurring Package"
            />

            <Button
              variant="secondary"
              color="CTA2"
              onClick={onKeepOneTime}
              loading={loading}
              children="Keep One-Time Cleaning"
            />
          </View>
        </View>
      </DialogContent>
    </Dialog>
  );
};
