import { ROUTES } from "@/constants/routes";
import { shortDayByIndex } from "@/lib/helpers/date";
import type { Maybe } from "graphql/jsutils/Maybe";
import type { Job, Package } from "@/__generated__/graphql";
import { CreditTypeEnum, PackageRecurrenceEnum } from "@/__generated__/graphql";
import { useMemo } from "react";
import type { UserCreditAccount } from "@/types/users";
import { parseISO, format } from "date-fns";

export const useGetProfilePath = (profilePath: string, pathname: string) =>
  useMemo(() => {
    const accountInfoPaths = [
      ROUTES.Profile.Children.AccountInfo,
      ROUTES.Profile.Children.Addresses,
    ];

    const overviewPaths = [
      ROUTES.Profile.Children.Packages,
      ROUTES.Profile.Children.Overview,
      ROUTES.Profile.Children.CreditAccount,
    ];

    // @ts-expect-error
    const isInsideAccountInfo = accountInfoPaths.includes(profilePath);
    // @ts-expect-error
    const isInsideOverview = overviewPaths.includes(profilePath);

    return { isInsideAccountInfo, isInsideOverview };
  }, [pathname, profilePath]);

function getMonthName(dateString: string) {
  const date = parseISO(dateString);

  return `${format(date, "MMMM")}`;
}

export const getPackageJobMonthsName = (
  packageData: Package | null,
): string[] | string => {
  if (!packageData?.jobs) {
    return [];
  }
  if (packageData?.repeatEvery === PackageRecurrenceEnum.AdHoc) {
    return "-";
  }
  const months = packageData?.jobs
    ?.map((job) => job.startDate)
    .sort()
    .map((startDate) => getMonthName(startDate));

  const formattedMonths =
    months.length > 1
      ? `${months.slice(0, -1).join(", ")} and ${months[months.length - 1]}`
      : months[0];

  return formattedMonths;
};

export const mapJobsToSchedules = (jobs: Maybe<Job[]>): string[] => {
  if (!jobs) {
    return [];
  }
  const allTasks = jobs
    .flatMap((job) =>
      job.tasks.map(({ day, startTime, endTime }) => {
        const [startHour, startMinute] = startTime.split(":");
        const [endHour, endMinute] = endTime.split(":");

        return {
          day,
          start: `${startHour}.${startMinute}`,
          end: `${endHour}.${endMinute}`,
        };
      }),
    )
    .sort((a, b) => a.day - b.day);

  return allTasks.map(
    (task) => `${shortDayByIndex(task.day)} at ${task.start} - ${task.end}`,
  );
};

export const generalCreditAccount = (
  userCreditAccount: UserCreditAccount[],
): UserCreditAccount | null =>
  userCreditAccount?.find((crt) => crt.creditType === CreditTypeEnum.General) ||
  null;

export const getExpirationsRequestInput = (expDate: string) => {
  const [expMonth, expYear] = expDate.split("/");

  return {
    expMonth,
    expYear,
  };
};
