import {
  FACEBOOK_CLICK_ID,
  GOOGLE_CLICK_ID,
  LINKEDIN_CLICK_ID,
  MICROSOFT_CLICK_ID,
  TIKTOK_CLICK_ID,
  UTM_KEY,
} from "@/constants";
import { storage } from "@/lib/storage";

const SESSION_MINUTES = 30;
const SEVEN_DAYS_MINUTES = 7 * 24 * 60;

interface UTM {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
}

const getIdentifiersParameters = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return {
    utms: {
      utm_source: urlParams.get("utm_source"),
      utm_medium: urlParams.get("utm_medium"),
      utm_campaign: urlParams.get("utm_campaign"),
      utm_term: urlParams.get("utm_term"),
      utm_content: urlParams.get("utm_content"),
    },
    gclid: urlParams.get("gclid"),
    fbclid: urlParams.get("fbclid"),
    msclkid: urlParams.get("msclkid"),
    ttclid: urlParams.get("ttclid"),
    li_fat_id: urlParams.get("li_fat_id"),
  };
};

export const storeIdentifiers = async () => {
  const { utms, gclid, fbclid, msclkid, ttclid, li_fat_id } =
    getIdentifiersParameters();

  if (gclid)
    await storage.setItemWithExpiry(GOOGLE_CLICK_ID, gclid, SEVEN_DAYS_MINUTES);
  if (fbclid)
    await storage.setItemWithExpiry(
      FACEBOOK_CLICK_ID,
      fbclid,
      SEVEN_DAYS_MINUTES,
    );
  if (msclkid)
    await storage.setItemWithExpiry(
      MICROSOFT_CLICK_ID,
      msclkid,
      SEVEN_DAYS_MINUTES,
    );
  if (ttclid)
    await storage.setItemWithExpiry(
      TIKTOK_CLICK_ID,
      ttclid,
      SEVEN_DAYS_MINUTES,
    );
  if (li_fat_id)
    await storage.setItemWithExpiry(
      LINKEDIN_CLICK_ID,
      li_fat_id,
      SEVEN_DAYS_MINUTES,
    );

  if (utms.utm_source) {
    await storage.setItemWithExpiry(UTM_KEY, utms, SESSION_MINUTES);
  }
};

export const getIdentifiersFromStorage = async () => {
  const gclid = await storage.getItemWithExpiry<string | undefined>(
    GOOGLE_CLICK_ID,
  );
  const fbclid = await storage.getItemWithExpiry<string | undefined>(
    FACEBOOK_CLICK_ID,
  );
  const msclkid = await storage.getItemWithExpiry<string | undefined>(
    MICROSOFT_CLICK_ID,
  );
  const ttclid = await storage.getItemWithExpiry<string | undefined>(
    TIKTOK_CLICK_ID,
  );
  const li_fat_id = await storage.getItemWithExpiry<string | undefined>(
    LINKEDIN_CLICK_ID,
  );
  const utms = await storage.getItemWithExpiry<UTM | null>(UTM_KEY);
  return {
    gclid: gclid || "",
    fbclid: fbclid || "",
    msclkid: msclkid || "",
    ttclid: ttclid || "",
    li_fat_id: li_fat_id || "",
    utm_source: utms?.utm_source || "",
    utm_medium: utms?.utm_medium || "",
    utm_campaign: utms?.utm_campaign || "",
    utm_term: utms?.utm_term || "",
    utm_content: utms?.utm_content || "",
  };
};
