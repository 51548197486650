import { useFragment } from "@/__generated__";
import type { VisitQuery, VisitQueryVariables } from "@/__generated__/graphql";
import {
  VisitDocument,
  VisitClientFragmentFragmentDoc,
} from "@/__generated__/graphql";
import type { VisitDetailData } from "@/components/shared/visits/visit-detail";
import { mapToVisitDetailData } from "@/components/shared/visits/visit-detail";
import { createRequestFactory } from "@/lib/request-factory";

export const useVisitStore = createRequestFactory<
  VisitQuery,
  VisitDetailData,
  VisitQueryVariables
>({
  method: "query",
  graphqlDocument: VisitDocument,
  transformFunction(data) {
    const visit = useFragment(VisitClientFragmentFragmentDoc, data.visit);
    return mapToVisitDetailData(visit);
  },
});
