import { Dialog, DialogContent } from "@/components/ui";
import { LoginContent } from "../login-content";
import { useAuthState } from "@/store/auth";
import { DialogCloseIcon } from "@/components/shared/dialog-close";

export const LoginModal = () => {
  const {
    data: { loginModalOpen },
    showModal,
  } = useAuthState();
  const onClose = () => {
    showModal();
  };
  return (
    <Dialog open={loginModalOpen}>
      <DialogContent className="w-[343px] p-6">
        <DialogCloseIcon onClose={onClose} />
        <LoginContent />
      </DialogContent>
    </Dialog>
  );
};
