import { Card, CardContent } from "@/components/ui";
import { Ratings } from "@/components/ui/ratings";
import { useMemo } from "react";
import { Typography } from "../../typography";

const borderVariant = {
  visitCard: "default",
  visitDetail: "none",
} as const;

export function VisitRating({
  rating,
  variant = "visitCard",
  onClick,
}: {
  rating: number;
  variant?: "visitCard" | "visitDetail";
  onClick?(rate?: number): void;
}) {
  const label = useMemo(() => {
    if (!rating) {
      return "Let's rate it";
    }

    switch (variant) {
      case "visitDetail":
        return "Reviewed";

      default:
        return "You have rated it";
    }
  }, [rating, variant]);

  return (
    <Card
      border={borderVariant[variant]}
      className={variant === "visitDetail" ? "bg-transparent" : "bg-white"}
    >
      <CardContent className="flex flex-row justify-between gap-2">
        <Typography variant="label-xl">{label}</Typography>
        <Ratings
          variant="yellow"
          rating={rating}
          className={variant === "visitDetail" ? "flex-1" : undefined}
          onClick={onClick}
        />
      </CardContent>
    </Card>
  );
}
