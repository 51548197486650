import { otherServiceItems, popularServiceItems } from "../lib";
import { View } from "@/components/ui";
import { ServiceIcon } from "@/components/shared/service-icon";
import { ListHeading } from "@/components/shared/list-header";
import { SquaresFour, Sparkle } from "@/components/shared/icons";
import { useIntl } from "react-intl";
import type { Icon } from "@/components/shared/icons";
import { BottomDrawerModal } from "@/components/shared/bottom-drawer-modal";
import type { ServiceName } from "@/types/service";
import { Divider } from "@/components/shared/divider";

function Section({
  title,
  icon,
  services,
}: {
  title: string;
  icon: Icon;
  services: {
    title: string;
    name: ServiceName;
    icon: JSX.Element;
  }[];
}) {
  return (
    <View>
      <ListHeading icon={icon} title={title} />
      <View className="flex flex-1 flex-row flex-wrap justify-start">
        {services.map((service) => (
          <View className="my-2 flex w-1/4 justify-center p-2">
            <ServiceIcon
              icon={service.icon}
              title={service.title}
              name={service.name}
              key={service.title}
            />
          </View>
        ))}
      </View>
    </View>
  );
}

export function AllServicesModal({
  modalOpen,
  handleOpenChange,
}: {
  modalOpen: boolean;
  handleOpenChange(newState: boolean): void;
}) {
  const intl = useIntl();
  return (
    <BottomDrawerModal open={modalOpen} onOpenChange={handleOpenChange}>
      <ListHeading title="All Services" icon={SquaresFour} />
      <Divider className="-mx-4 border-border-low" />
      <Section
        title="Popular Service"
        icon={Sparkle}
        services={popularServiceItems(intl)}
      />
      <Divider className="-mx-4 border-b-[3px] border-border-low" />
      <Section
        title={intl.formatMessage({
          defaultMessage: "Other Services",
          id: "other-services",
        })}
        icon={SquaresFour}
        services={otherServiceItems(intl)}
      />
    </BottomDrawerModal>
  );
}
