import { AD_TRACKING_COOKIE_KEY, CS_NUMBER } from "@/constants";
import { handleWhatsappNumber } from "../platform";
import { sendAnalyticData } from "../monitoring/analytics";
import { rudderanalytics } from "@/lib/rudderstack";
import { getIdentifiersFromStorage } from "../identifiers";
import { getCookie } from "@/lib/cookie";

export const onChatSales = (
  includeTrackingUrl = false,
  message = "Hi! I would like to speak to sales about booking a new appointment through your web application.",
) => {
  if (includeTrackingUrl) {
    message = embedTrackingUrl(message);
  }

  handleWhatsappNumber(CS_NUMBER, message);
  sendToRudderstack();
  sendAnalyticData({
    name: "wa_click",
    values: {},
  });
};

const sendToRudderstack = async () => {
  const identifiers = await getIdentifiersFromStorage();
  rudderanalytics.track("wa_sales_button_click", {
    ...identifiers,
  });
};

const embedTrackingUrl = (message: string) => {
  const trackingKey = getCookie(AD_TRACKING_COOKIE_KEY);
  const LUCE_WEBSITE_URL = import.meta.env.VITE_LUCE_WEBSITE_URL ?? "";

  if (trackingKey && LUCE_WEBSITE_URL) {
    message += ` From your page ${LUCE_WEBSITE_URL}?origin=${trackingKey}`;
  }

  return message;
};
