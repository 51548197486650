import { View, Button, Image } from "@/components/ui"
import { Typography } from "../typography"
import ContactUs from "@/assets/images/contact-us.png";
import { useIntl } from "react-intl";
import { onChatSupport } from "@/lib/chat-support";

export const WhatsappSupportBanner = () => {
    const intl = useIntl()
    return  (
        <View className="relative mt-4 rounded-xl bg-primary-surface p-2">
        <View className="flex flex-col items-start md:items-stretch pt-2 md:pt-0">
          <Typography variant="label-lg" className="ml-2">
            {intl.formatMessage({
              defaultMessage: "Support",
              id: "support",
            })}
          </Typography>
          <Typography variant="body-lg" className="ml-2" color="foreground-intermediate">
            {intl.formatMessage({
              defaultMessage: "Contact Us",
              id: "contact-us",
            })}
          </Typography>
          <Button
            variant="primary"
            color="success"
            className="relative z-10 mt-4 gap-2"
            onClick={onChatSupport}
            iconName="whatsappLogo"
            iconColor="fill-white"
            children={intl.formatMessage({
              defaultMessage: "Chat our Support",
              id: "chat-our-support",
            })}
          />
        </View>
        <Image src={ContactUs} className="web:absolute right-2 top-2 md:top-2.5 z-0 w-24 md:w-14"/>
      </View>
    )
}