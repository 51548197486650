import { View } from "@/components/ui";
import { Typography } from "../typography";
import { LuceSparkle } from "@/components/shared/icons";
import { FeedBackModal } from "@/components/shared/feedback-popup";
import { useState } from "react";

export function FeedBackOverlay() {
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);

  function openModal() {
    setFeedbackModalOpen(true);
  }

  function closeModal() {
    setFeedbackModalOpen(false);
  }

  return (
    <View className="sticky z-50 flex w-full flex-row gap-2 bg-primary-surface px-6 py-4 sm:gap-0">
      <View className="container flex flex-row gap-4">
        <LuceSparkle className="h-4 w-4 color-brand-primary" />

        <Typography color="brand-primary" variant="body-md" className="flex-1">
          We've launched the new Luce App! If you are having trouble, you can
          switch to the old app anytime by{" "}
          <Typography
            as="span"
            onClick={openModal}
            className="cursor-pointer"
            variant="label-md"
            color="button-cta"
          >
            clicking here
          </Typography>
        </Typography>
      </View>
      {feedbackModalOpen && (
        <FeedBackModal
          feedbackModalOpen={feedbackModalOpen}
          onClose={closeModal}
        />
      )}
    </View>
  );
}
