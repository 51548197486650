import { useSegments } from "@/components/hooks/use-segments";
import { WebLayout } from "@/components/shared/layout/homepage";
import { SideNavbar } from "@/components/shared/side-navbar/index.web";
import { View } from "@/components/ui";
import { Column, Container } from "@/components/ui/layout";
import { Outlet } from "react-router-dom";
import { PackageIssueContainer } from "@/containers/profile/packages/report-package-issue/package-issue";

export function ProfilePage() {
  const segments = useSegments();
  const isOverview = segments.includes("overview");
  return (
    <WebLayout expandBackdrop={isOverview}>
      <Container gap="xl">
        <Column mobile="none" desktop="3">
          <View className="md:mt-10">
            <SideNavbar />
          </View>
        </Column>
        <Column mobile="full" desktop="9">
          <View className="relative pb-10 md:mt-10">
            <Outlet />
          </View>
        </Column>
      </Container>
      <PackageIssueContainer />
    </WebLayout>
  );
}
