import { View, Checkbox } from "@/components/ui";
import { Star } from "@/components/shared/icons";
import { Typography } from "@/components/shared/typography";
import { WorkerAvatar } from "@/components/shared/worker-avatar";

export const WorkerListItem = ({
  name,
  avatarUrl,
  rating,
  checked,
  onCheck,
  avatarClassName = "",
}: {
  name: string;
  avatarUrl?: string;
  rating?: number;
  checked?: boolean;
  onCheck(): void;
  avatarClassName?: string;
}) => {
  return (
    <View
      className="flex cursor-pointer flex-row items-center gap-3 px-1 py-3"
      onClick={onCheck}
    >
      <WorkerAvatar src={avatarUrl} className={avatarClassName} />
      <View className="flex-1">
        <Typography variant="label-lg" color="foreground-default">
          {name}
        </Typography>
        {rating && (
          <View className="flex flex-row items-center gap-1">
            <Star weight="fill" className="text-warning" size={14} />
            <Typography variant="body-md" color="secondary-text">
              {rating.toFixed(1)}
            </Typography>
          </View>
        )}
      </View>
      <View>
        <Checkbox checked={!!checked} onCheckedChange={onCheck} />
      </View>
    </View>
  );
};
