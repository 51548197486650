import { View } from "@/components/ui";
import { ServiceIcon } from "@/components/shared/service-icon";
import { Sparkle } from "@/components/shared/icons";
import { ListHeading } from "@/components/shared/list-header";
import { Card, CardContent } from "@/components/ui/card";
import { useIntl } from "react-intl";
import { popularServiceItems } from "../lib";

export function PopularServices() {
  const intl = useIntl();
  return (
    <Card border="none" shadow="sm">
      <CardContent className="flex flex-col gap-4 p-4">
        <ListHeading
          title={intl.formatMessage({
            defaultMessage: "Popular Services",
            id: "popular-services",
          })}
          icon={Sparkle}
        />
        <View className="native:flex native:flex-row native:justify-between grid grid-flow-col grid-cols-4 gap-2">
          {popularServiceItems(intl).map((service) => (
            <ServiceIcon
              icon={service.icon}
              title={service.title}
              name={service.name}
              key={service.title}
            />
          ))}
        </View>
      </CardContent>
    </Card>
  );
}
